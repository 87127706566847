import { UserStubWithEmail } from '../generated_types/training_session'
import UserPicker from '../user_picker'

type UserPickerListProps = {
  userData: UserStubWithEmail[]
  selectedUserIds: number[]
  setSelectedUserIds: (updater: (current: number[]) => number[]) => void
}

const UserPickerList = ({ userData, selectedUserIds, setSelectedUserIds }: UserPickerListProps) => {
  const selectedIdsSet = new Set(selectedUserIds)
  const availableUsers = userData.filter(u => !selectedIdsSet.has(u.id))
  const selectedUsers = userData.filter(u => selectedIdsSet.has(u.id))

  const addUser = (userId: number) => setSelectedUserIds(current => [...new Set([...current, userId])])

  const deleteUser = (userId: number) => {
    setSelectedUserIds(current => {
      return current.filter(id => id !== userId)
    })
  }

  return (
    <>
      <div className="flex gap-1">
        <div className="w-1/12 pt-2">
          <label htmlFor="session_user_ids">Users</label>
        </div>
        <UserPicker id="session_user_ids" label="Add User" users={availableUsers} userIdSelected={addUser} />
      </div>
      <UserList users={selectedUsers} deleteUser={deleteUser} />
    </>
  )
}

export default UserPickerList

type UserListProps = {
  users: UserStubWithEmail[]
  deleteUser: (userId: number) => void
}

const UserList = ({ users, deleteUser }: UserListProps) => {
  if (users.length == 0) {
    return null
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, userId: number) => {
    e.preventDefault()
    e.stopPropagation()
    deleteUser(userId)
  }

  return (
    <table className="selected-users sw-table striped mt-8">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {users.map(user => {
          return (
            <tr key={user.id}>
              <td className="align-middle">{user.name}</td>
              <td className="align-middle">
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </td>
              <td className="align-middle">
                <button
                  className="sw-btn btn-icon is-error h-9 w-9"
                  onClick={e => handleClick(e, user.id)}
                  aria-label="Delete User"
                >
                  <i className="fa-solid fa-xmark" />
                </button>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
