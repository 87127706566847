import { Controller } from '@hotwired/stimulus'

/**
 * Toggles CSS 'open' classes on a "control" element and a "reveal" outlet element
 */
export default class extends Controller<HTMLElement> {
  static targets = ['control', 'content']
  declare readonly controlTarget: HTMLElement // The element being interacted with
  declare readonly contentTarget: HTMLElement // The element beingshown/hidden

  toggle() {
    this.contentTarget.classList.toggle('open')
    this.controlTarget.classList.toggle('rotate-90')
  }
}
