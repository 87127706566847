/*

This should export the identifiers for any flippers in use by the front end.

For an enum entry below of FlagName, usage typically looks like:

import { FeatureFlagsContext } from '../utils/feature_flags/feature_flags_provider'
import Flags from '../utils/feature_flags/feature_flags'

const SomeComponent = () => {
  const someFeatureEnabled = useContext(FeatureFlagsContext)[Flags.FlagName]

  // use someFeatureEnabled here

}
*/

enum Flags {
  HostedEnvironmentCustomisation = 'hosted_environment_customisation'
}

export default Flags
