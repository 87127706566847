import { Formik, Form, Field } from 'formik'
import qs from 'qs'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import PlanPicker from '../plan_picker/plan_picker'
import SWRadio from '../library/sw/sw_radio'
import MultiUserSelector from './multi_user_selector'
import { AddToPlanPageProps, TrainingPlanSummary } from './types'

enum Destination {
  NewPlan = 'new-plan',
  ExistingPlan = 'existing-plan'
}

type FormValues = {
  destination?: Destination
  training_plan_id?: string
  user_ids: string[]
  assessment_id: string
  company_id: number
}

const selectedTrainingPlan = (training_plans: TrainingPlanSummary[], values: FormValues) => {
  return training_plans.find(tp => parseInt(values.training_plan_id || '') === tp.id)
}

const redirectToPlanPreview = (values: FormValues) => {
  const existingPlan = values.destination == Destination.ExistingPlan
  const planURL = existingPlan ? `/plans/${values.training_plan_id}` : `/companies/${values.company_id}/plans/preview`

  const data = {
    training_plan: {
      [existingPlan ? 'new_user_ids' : 'user_ids']: values.user_ids,
      assessment_id: values.assessment_id
    }
  }
  window.location.assign(`${planURL}?${qs.stringify(data, { arrayFormat: 'brackets' })}`)
}

const AddToPlan = ({ users, training_plans, assessment }: AddToPlanPageProps) => {
  const initialValues: FormValues = { user_ids: [], assessment_id: assessment.id, company_id: assessment.company_id }

  return (
    <Formik initialValues={initialValues} onSubmit={redirectToPlanPreview}>
      {({ isSubmitting, values }) => (
        <Form className="sw-card gap-6">
          <div className="sw-snippet flex-col gap-0 m-0">
            <Field type="radio" as={SWRadio} label="Add to a new plan" name="destination" value={Destination.NewPlan} />
            <Field
              type="radio"
              as={SWRadio}
              label="Add to an existing plan"
              name="destination"
              value={Destination.ExistingPlan}
            />
          </div>
          {values.destination == Destination.ExistingPlan && <PlanPicker trainingPlans={training_plans} />}
          <MultiUserSelector users={users} trainingPlan={selectedTrainingPlan(training_plans, values)} />
          {values.destination && (
            <button
              type="submit"
              className="sw-btn btn-primary ml-auto"
              disabled={isSubmitting || values.user_ids.length == 0}
            >
              <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
              Preview
            </button>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default AddToPlan
