import { Learner } from '../types'
import { PlanCostData } from './plan_cost'
import { round } from 'lodash'

interface PlanCostProps {
  costPerLearner: PlanCostData
  learners: Learner[]
}

const PerLearnerCostDisplay = ({ costPerLearner, learners }: PlanCostProps) => {
  return (
    <tr>
      <td className="controls">
        <span className="my-5 font-bold">Total Credits</span>
      </td>
      <td className="estimate-mins"></td>
      {learners.map(learner => (
        <td key={learner.user_id}>
          {learner.user_id in costPerLearner && (
            <span
              className="sw-tooltip font-bold"
              data-tooltip={`${round(costPerLearner[learner.user_id].mandatory, 2)} credits (${round(
                costPerLearner[learner.user_id].includingOptional,
                2
              )} including optional)`}
            >
              {round(costPerLearner[learner.user_id].mandatory, 2)}
            </span>
          )}
        </td>
      ))}
    </tr>
  )
}

export default PerLearnerCostDisplay
