import { useState, useEffect, ReactNode, MouseEventHandler } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { formatAmount, formatDescription } from './formatters'
import LineItem from './line_item'
import NewLineItemModal, { OnSaveCallback } from './new_line_item_modal'
import EditLineItemModal, { SaveEditsCallback } from './edit_line_item_modal'
import DeleteLineItemModal, { OnDeleteCallback } from './delete_line_item_modal'
import { useSave, Response } from '../utils/json_request'
import {
  LineItemType,
  TrainerInvoiceStatus as Status,
  LineItem as LineItemData,
  type TrainerInvoice
} from './constants'
import TrainerInvoiceStatus from './trainer_invoice_status'
import ConfirmationModal from '../library/confirmation_modal'
import {
  CreateTrainerInvoiceLineItemResponse,
  UpdateOrDeleteTrainerInvoiceLineItemResponse,
  UpdateTrainerInvoiceResponse
} from '../generated_types/trainer_invoices'

type Props = {
  invoice: TrainerInvoice
  readOnly: boolean
}

const TrainerInvoice = ({ invoice: initialInvoice, readOnly }: Props) => {
  const [message, setMessage] = useState<ReactNode>(null)
  const [invoice, setInvoice] = useState<TrainerInvoice>(initialInvoice)
  const [adjusting, setAdjusting] = useState<LineItemData | null>(null)
  const [editing, setEditing] = useState<LineItemData | null>(null)
  const [itemToDelete, setItemToDelete] = useState<LineItemData | null>(null)
  const [addingItem, setAddingItem] = useState(false)
  const [itemToMove, setItemToMove] = useState<LineItemData | null>(null)

  const total = invoice.line_items.reduce((acc, lineItem) => acc + lineItem.amount, 0)

  const invoiceMutable = invoice.status === Status.Open || invoice.status === Status.Frozen
  const editConfirmationRequired = invoice.status === Status.Frozen

  const [saveNewItem, newItemErrors, savingNewItem, resetNewItemErrors] = useSave()

  const handleAdjustment = !readOnly ? (lineItem: LineItemData) => setAdjusting(lineItem) : undefined

  const persistLineItem: OnSaveCallback = async ({ line_item, permit_updates_to_frozen_invoice }) => {
    const { ok, data } = (await saveNewItem(`/invoices/${invoice.id}/trainer_invoice_line_items`, {
      method: 'POST',
      payload: {
        trainer_invoice_line_item: line_item,
        permit_updates_to_frozen_invoice
      }
    })) as Response<CreateTrainerInvoiceLineItemResponse>
    if (ok) {
      if (line_item.type === LineItemType.Adjustment) {
        setAdjusting(null)
        if ('line_item' in data) {
          //adjustments to complete invoices are added to the next open invoice instead
          setMessage(
            <div className="sw-toast success">
              Adjustment &quot;{data.line_item.description}&quot; added to{' '}
              <a target="blank" href={`/invoices/${data.line_item.invoice_id}`}>
                current open invoice
              </a>
            </div>
          )
          return
        }
      } else {
        setAddingItem(false)
      }
      if ('invoice' in data) {
        setInvoice(data.invoice)
      }
    }
  }

  /* if the adjusting has changed again, to a non null value, then remove the previous message */
  useEffect(() => {
    if (adjusting != null) {
      setMessage(null)
    }
  }, [adjusting])

  let adjustmentModal: ReactNode

  if (adjusting) {
    const newLineItemProps = { adjusted_line_item_id: adjusting.id, type: LineItemType.Adjustment }
    adjustmentModal = (
      <NewLineItemModal
        extraConfirmationRequired={editConfirmationRequired}
        title="Add Adjustment"
        onClose={() => setAdjusting(null)}
        saving={savingNewItem}
        errors={newItemErrors}
        lineItem={newLineItemProps}
        key={adjusting.id}
        onSave={persistLineItem}
      />
    )
  }

  const handleEdit = invoiceMutable && !readOnly ? (lineItem: LineItemData) => setEditing(lineItem) : undefined

  const [saveEdits, saveEditErrors, savingEdits, resetSaveEditErrors] = useSave()

  const persistEdit: SaveEditsCallback = async ({ id, updates, permit_updates_to_frozen_invoice }) => {
    const { ok, data } = (await saveEdits(`/trainer_invoice_line_items/${id}`, {
      method: 'PATCH',
      payload: { trainer_invoice_line_item: updates, permit_updates_to_frozen_invoice }
    })) as Response<UpdateOrDeleteTrainerInvoiceLineItemResponse>
    if (ok) {
      setEditing(null)
      setInvoice(data.invoice)
    }
  }

  let editModal: ReactNode

  useEffect(() => resetSaveEditErrors(), [editing, resetSaveEditErrors])

  if (editing) {
    editModal = (
      <EditLineItemModal
        extraConfirmationRequired={editConfirmationRequired}
        onClose={() => setEditing(null)}
        lineItem={editing}
        key={editing.id}
        onSave={persistEdit}
        saving={savingEdits}
        errors={saveEditErrors}
      />
    )
  }

  let addWorkItemControls: ReactNode

  useEffect(() => resetNewItemErrors(), [addingItem, adjusting, resetNewItemErrors])

  if (invoiceMutable && !readOnly) {
    const onAddWorkItemClick: MouseEventHandler = e => {
      e.preventDefault()
      setAddingItem(true)
    }
    addWorkItemControls = (
      <button
        className="sw-btn btn-secondary btn-icon sw-tooltip tooltip-left"
        onClick={onAddWorkItemClick}
        aria-label="Add Line Item"
        data-tooltip="Add Line Item"
      >
        <FontAwesomeIcon icon={['fas', 'plus']} />
      </button>
    )
  }

  let addWorkItemModal: ReactNode
  if (addingItem) {
    const newLineItemProps = { type: LineItemType.GenericWork }
    addWorkItemModal = (
      <NewLineItemModal
        extraConfirmationRequired={editConfirmationRequired}
        title="New Line Item"
        saving={savingNewItem}
        errors={newItemErrors}
        onClose={() => setAddingItem(false)}
        onSave={persistLineItem}
        lineItem={newLineItemProps}
      />
    )
  }

  let deleteModal: ReactNode

  const [deleteRequest, deleteErrors, deleting, resetDeleteErrors] = useSave()

  if (itemToDelete) {
    const deleteLineItem: OnDeleteCallback = async (id, permit_updates_to_frozen_invoice) => {
      const { ok, data } = (await deleteRequest(`/trainer_invoice_line_items/${id}`, {
        method: 'DELETE',
        payload: { permit_updates_to_frozen_invoice }
      })) as Response<UpdateOrDeleteTrainerInvoiceLineItemResponse>
      if (ok) {
        setItemToDelete(null)
        setInvoice(data.invoice)
      }
    }
    deleteModal = (
      <DeleteLineItemModal
        extraConfirmationRequired={editConfirmationRequired}
        onClose={() => setItemToDelete(null)}
        lineItem={itemToDelete}
        key={`delete-modal-${itemToDelete.id}`}
        onDelete={deleteLineItem}
        deleting={deleting}
        errors={deleteErrors}
      />
    )
  }

  useEffect(() => resetDeleteErrors(), [itemToDelete, resetDeleteErrors])

  let moveModal: ReactNode

  const [moveRequest, _, moving, _resetMoveErrors] = useSave()
  if (itemToMove) {
    const moveItem = async () => {
      const { ok, data } = (await moveRequest(`/invoices/${invoice.id}/move`, {
        method: 'POST',
        payload: {
          move: {
            destination_invoice_id: 'open',
            line_item_ids: [itemToMove.id]
          }
        }
      })) as Response<UpdateTrainerInvoiceResponse>

      if (ok) {
        setInvoice(data.invoice)
        setItemToMove(null)
      }
    }

    moveModal = (
      <ConfirmationModal
        message={
          <>
            Move line item
            <p className="bg-gray">{formatDescription(itemToMove)}</p>
            to the open invoice for this coach?
          </>
        }
        onConfirm={moveItem}
        submitting={moving}
        onClose={() => setItemToMove(null)}
      />
    )
  }

  const handleDelete =
    invoiceMutable && !readOnly
      ? (lineItem: LineItemData) => {
          setItemToDelete(lineItem)
        }
      : undefined

  const handleMove =
    !readOnly && invoice.status === Status.Frozen
      ? (lineItem: LineItemData) => {
          setItemToMove(lineItem)
        }
      : undefined

  const line_items = invoice.line_items.map(lineItem => (
    <LineItem
      key={lineItem.id}
      lineItem={lineItem}
      onAdjust={handleAdjustment}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onMoveToOpen={handleMove}
    />
  ))

  return (
    <>
      <TrainerInvoiceStatus invoice={invoice} setInvoice={setInvoice} readOnly={readOnly} />
      {message}
      <table className="sw-table striped w-full my-4">
        <thead>
          <tr>
            <th>Session</th>
            <th>Date</th>
            <th>Description</th>
            <th>Amount</th>
            <th className="text-right">{addWorkItemControls}</th>
          </tr>
        </thead>
        <tbody>{line_items}</tbody>
        <tfoot>
          <tr>
            <th />
            <th />
            <th>Total</th>
            <th>{formatAmount(total)}</th>
          </tr>
        </tfoot>
      </table>
      {adjustmentModal}
      {editModal}
      {addWorkItemModal}
      {deleteModal}
      {moveModal}
    </>
  )
}

export default TrainerInvoice
