import React, { PropsWithChildren } from 'react'

type CellEditLinkProps = PropsWithChildren<{
  editable: boolean
  sessionCode: string
}>

const CellEditLink = ({ editable, sessionCode, children }: CellEditLinkProps) => {
  return editable ? (
    <a
      className={`sw-btn btn-icon cell-link`}
      onClick={e => e.stopPropagation()}
      href={`/coaching_sessions/${sessionCode}/edit`}
      target="blank"
    >
      {children}
    </a>
  ) : (
    <>{children}</>
  )
}

export default CellEditLink
