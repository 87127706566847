import { MouseEventHandler } from 'react'

type Props = {
  openModal: () => void
}

const DoNotScheduleStatusButton = ({ openModal }: Props) => {
  const handleClick: MouseEventHandler = event => {
    event.stopPropagation()
    event.preventDefault()
    openModal()
  }

  return (
    <button className="sw-btn btn-icon text-xl bg-transparent shadow-none border-none" onClick={handleClick}>
      🚫
    </button>
  )
}

export default DoNotScheduleStatusButton
