import { Fragment } from 'react'
import { Field } from 'formik'
import SWCheckbox from '../library/sw/sw_checkbox'
import { UserStub, TrainingPlanSummary } from './types'

type Props = {
  users: UserStub[]
  trainingPlan?: TrainingPlanSummary
}

const MultiUserSelector = ({ users, trainingPlan }: Props) => {
  // if the user is already in the plan, it is not from this assessment, since this page
  // exclude users which have been added to the plan from the current assessment
  return (
    <div className="sw-group">
      {users.map(user => {
        const alreadyInPlan = trainingPlan ? trainingPlan.user_ids.includes(user.id) : false
        return (
          <Fragment key={user.id}>
            <Field
              type="checkbox"
              disabled={alreadyInPlan}
              as={SWCheckbox}
              name="user_ids"
              value={user.id.toString()}
              label={user.name}
            />
            {alreadyInPlan && (
              <p className="form-input-hint">
                This user is already in the plan, but has never been imported from the assessment. Delete their column
                from the plan if you want to import assessment results
              </p>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default MultiUserSelector
