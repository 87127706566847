import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type ReviewSectionHeaderProps = {
  icon: IconProp
  title: string
  entries: number
}
const ReviewSectionHeader = ({ icon, title, entries }: ReviewSectionHeaderProps) => (
  <div className="sw-header gap-4 flex-nowrap items-center">
    <FontAwesomeIcon icon={icon} size="lg" className="text-white hidden sm:block" />
    <div className="block truncate">{title}</div>
    <div className="ml-auto hidden sm:block shrink-0">{entries}</div>
  </div>
)

export default ReviewSectionHeader
