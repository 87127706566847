import React from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { Pause as PauseData, User } from './types'

type Props = {
  user?: User
  pause: PauseData
  onEdit: (s: PauseData) => void
  onDelete: (s: PauseData) => void
  editable: boolean
}

const Pause = ({ pause, user, onEdit, onDelete, editable }: Props) => {
  const now = Date.now()
  const historical = pause.end_date ? new Date(pause.end_date).getTime() < now : false

  const tdClasses = historical ? 'text-midgrey' : ''
  return (
    <tr className={historical ? 'bg-lightestgrey' : ''}>
      {user && <td className={tdClasses}>{user.name}</td>}
      <td className={tdClasses}>{pause.start_date}</td>
      <td className={tdClasses}>{pause.end_date}</td>
      <td className={tdClasses}>{pause.description}</td>
      {editable && (
        <td className="whitespace-nowrap text-end">
          <button onClick={() => onEdit(pause)} className="sw-btn btn-icon mr-2" aria-label="Edit">
            <FontAwesomeIcon icon={['fas', 'pencil']} fixedWidth />
          </button>
          <button onClick={() => onDelete(pause)} className="sw-btn btn-icon is-error" aria-label="Delete">
            <FontAwesomeIcon icon={['fas', 'xmark']} fixedWidth />
          </button>
        </td>
      )}
    </tr>
  )
}

export default Pause
