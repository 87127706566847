import React, { createRef, useEffect } from 'react'
import {
  createEmbeddingContext,
  DashboardContentOptions,
  FrameOptions,
  Parameter
} from 'amazon-quicksight-embedding-sdk'

type Props = {
  url: string
  parameters: Parameter[]
}

const QuickSightDashboard = ({ url, parameters }: Props) => {
  const containerRef = createRef<HTMLDivElement>()

  // we really don't want to call embedDashboard a second time
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!containerRef.current) {
      return
    }
    const frameOptions: FrameOptions = {
      height: '700px',
      resizeHeightOnSizeChangedEvent: true,
      container: containerRef.current,
      withIframePlaceholder: true,
      url
    }

    const contentOptions: DashboardContentOptions = { parameters }

    const setupEmbed = async () => {
      const { embedDashboard } = await createEmbeddingContext()
      await embedDashboard(frameOptions, contentOptions)
    }

    setupEmbed()
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return <div ref={containerRef} />
}

export default QuickSightDashboard
