import React, { MouseEventHandler, useState } from 'react'
import ModulePicker from '../components/module_picker'

type PlanModuleFunction = (moduleKey: string) => string | undefined

interface AddPlanModuleProps {
  onModuleSelect: PlanModuleFunction
  onModuleChange: PlanModuleFunction
}

const AddPlanModule = ({ onModuleSelect, onModuleChange }: AddPlanModuleProps) => {
  const [moduleKey, setModuleKey] = useState('')
  const [error, setError] = useState<undefined | string>(undefined)

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    event.stopPropagation()
    const errorMessage = onModuleSelect(moduleKey)
    if (errorMessage) {
      setError(errorMessage)
    } else {
      setModuleKey('')
      setError(undefined)
    }
  }

  const handleChange = (moduleKey: string) => {
    setModuleKey(moduleKey)
    const errorMessage = onModuleChange(moduleKey)
    if (errorMessage) setError(errorMessage)
    else setError(undefined)
  }

  const errorClass = error ? ' has-error' : ''

  return (
    <div className={`grow${errorClass}`}>
      <div className="sw-form-grp">
        <ModulePicker moduleKey={moduleKey} setModuleKey={setModuleKey} onModuleKeyChange={handleChange} />
        <button disabled={!moduleKey} onClick={handleClick} className="sw-btn btn-secondary whitespace-nowrap">
          Add Plan Module
        </button>
      </div>
      {error && <p className="form-input-hint">{error}</p>}
    </div>
  )
}

export default AddPlanModule
