import { useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import Modal from '../library/modal'
import SWFormGroup from '../library/sw/sw_form_group'
import ConfirmEditsToFrozen from './confirm_edits_to_frozen'
import { LineItem } from './constants'
import { ModelErrors } from '../library/errors'

type Props = {
  lineItem: LineItem
  onClose: () => void
  onSave: SaveEditsCallback
  saving: boolean
  errors: ModelErrors
  extraConfirmationRequired: boolean
}

type Updates = {
  amount: number
  description?: string
}

export type SaveEditsCallback = (args: {
  id: number
  updates: Updates
  permit_updates_to_frozen_invoice: boolean
}) => Promise<void>

const EditLineItemModal = ({ lineItem, onClose, onSave, saving, errors, extraConfirmationRequired }: Props) => {
  const [updateFrozen, setUpdateFrozen] = useState(false)
  const [amount, setAmount] = useState(lineItem.amount / 100)
  const [description, setDescription] = useState(lineItem.description)

  const form = (
    <div className="flex flex-col gap-4">
      {errors.base ? <div className="sw-toast error">{errors.base.join(', ')}</div> : null}
      <SWFormGroup errors={errors['description']}>
        <label className="sw-label" htmlFor="line_item_description">
          Description
        </label>
        <input
          id="line_item_description"
          disabled={!lineItem.description_editable}
          value={description}
          onChange={e => setDescription(e.target.value)}
          className="sw-input"
        />
      </SWFormGroup>
      <SWFormGroup errors={errors['amount']}>
        <label className="sw-label" htmlFor="line_item_amount">
          Amount (GBP)
        </label>
        <input
          id="line_item_amount"
          placeholder="0"
          value={amount === 0 ? '' : amount}
          onChange={e => setAmount(parseFloat(e.target.value))}
          type="number"
          className="sw-input"
        />
      </SWFormGroup>
      <ConfirmEditsToFrozen
        extraConfirmationRequired={extraConfirmationRequired}
        updateFrozen={updateFrozen}
        setUpdateFrozen={setUpdateFrozen}
      />
    </div>
  )

  const saveLineItem = () => {
    const updates: Updates = { amount }
    if (lineItem.description_editable) {
      updates.description = description
    }
    onSave({ id: lineItem.id, updates: updates, permit_updates_to_frozen_invoice: updateFrozen })
  }

  const saveDisabled = saving || (extraConfirmationRequired && !updateFrozen)

  return (
    <Modal
      title="Edit Line Item"
      onClose={onClose}
      options={{ active: true }}
      body={form}
      footer={
        <>
          <button onClick={onClose} className="sw-btn mr-auto">
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            Cancel
          </button>
          <button
            disabled={saveDisabled}
            onClick={saveLineItem}
            className={`sw-btn btn-primary${saving ? ' sw-loading' : ''}`}
          >
            Save
            <FontAwesomeIcon icon={['far', 'floppy-disk']} />
          </button>
        </>
      }
    />
  )
}

export default EditLineItemModal
