import getDisplaySession from './session_editor/session_status'
import { PlannedSessionUserData, PlannedSessionState, TrainingSessionState, TrainingSessionStatus } from './types'

// these are the ones where we assume you have actually been to the session
export const attendedStates = [
  TrainingSessionState.Complete,
  TrainingSessionState.Unconfirmed,
  TrainingSessionState.Partial
]

const nextSessionState = (state: PlannedSessionState): PlannedSessionState => {
  switch (state) {
    case PlannedSessionState.Mandatory:
      return PlannedSessionState.Optional
    case PlannedSessionState.Optional:
      return PlannedSessionState.None
    case PlannedSessionState.None:
      return PlannedSessionState.Mandatory
  }
}

const emptyAttendanceState: PlannedSessionUserData = {
  attendance_requirement: PlannedSessionState.None,
  change_requests: []
}

// TODO: attendanceStatus can currently be nil, which means the same as
// PlannedSessionState.None. In the future it would be nice to avoid the need
// for this by explicitly setting PlannedSessionState.None where appropriate
const cycleState = (
  attendanceStatus?: PlannedSessionUserData,
  sessionStatuses?: TrainingSessionStatus[]
): PlannedSessionUserData => {
  attendanceStatus ||= emptyAttendanceState

  const { attendance_requirement, do_not_schedule } = attendanceStatus

  if (do_not_schedule) {
    return attendanceStatus
  }

  const sessionStatus = getDisplaySession(sessionStatuses)?.status
  // attended sessions are immutable
  if (sessionStatus && attendedStates.includes(sessionStatus)) {
    return attendanceStatus
  }

  // always clear the create change request when updating state -
  // this is no longer the update that resulted from the 'create change request' interaction
  return {
    ...attendanceStatus,
    create_change_request: false,
    attendance_requirement: nextSessionState(attendance_requirement)
  }
}

const stateChanged = (firstState?: PlannedSessionUserData, secondState?: PlannedSessionUserData) => {
  // this only looks at the attendance requirements & do-not-schedule, since the scheduledness of a session isn't changeable in this ui
  const firstAttendanceRequirement = firstState?.attendance_requirement ?? PlannedSessionState.None
  const secondAttendanceRequirement = secondState?.attendance_requirement ?? PlannedSessionState.None

  return (
    firstAttendanceRequirement !== secondAttendanceRequirement ||
    firstState?.do_not_schedule !== secondState?.do_not_schedule ||
    firstState?.do_not_schedule_reason !== secondState?.do_not_schedule_reason
  )
}

export { cycleState, stateChanged, nextSessionState, emptyAttendanceState }
