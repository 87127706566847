import { TrainingPlanSummary } from '../generated_types/add_to_plan'
import { Field } from 'formik'
import SWRadio from '../library/sw/sw_radio'

type SelectablePlan = TrainingPlanSummary & { disabled?: boolean }
type PlanPickerProps = {
  trainingPlans: SelectablePlan[]
}

const PlanPicker = ({ trainingPlans }: PlanPickerProps) => {
  return (
    <table className="sw-table striped w-full">
      <thead>
        <tr>
          <th>Name</th>
          <th>Curricula</th>
          <th>Learners from this Assessment</th>
          <th>Total Learners</th>
        </tr>
      </thead>
      <tbody>
        {trainingPlans.map(plan => (
          <tr key={plan.id}>
            <td className="font-semibold whitespace-nowrap">
              <Field
                type="radio"
                as={SWRadio}
                value={plan.id.toString()}
                name="training_plan_id"
                label={plan.name}
                disabled={plan.disabled === true}
              />
            </td>
            <td className="align-middle">{plan.curricula_keys.join(', ')}</td>
            <td className="align-middle">{plan.user_ids_from_this_assessment.length}</td>
            <td className="align-middle">{plan.user_ids.length}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default PlanPicker
