import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { CoachingPlanChannelData } from '../../generated_types/channels'
import { useCoachingPlanChannel } from './use_coaching_plan_channel'

type PlanVersions = Record<number, number>

type PlanVersionProviderProps = PropsWithChildren<{
  lockVersions: PlanVersions
}>

type PlanVersionContextData = {
  /*
   * what we think the current version of the plan is
   */
  lockVersions: PlanVersions
  /*
   *what the backend things the current version of the plan is. if this isn't equal to lock version, saves will fail
   */
  remoteLockVersions: PlanVersions
  planUpdated: (v: VersionUpdateParams) => void
  setRemoteLockVersions: React.Dispatch<React.SetStateAction<PlanVersions>>
}

type VersionUpdateParams = {
  newVersion: number
  planId: number
}
const PlanVersionContext = createContext<PlanVersionContextData>({
  lockVersions: {},
  remoteLockVersions: {},
  planUpdated: () => {},
  setRemoteLockVersions: () => {}
})

const PlanVersionProvider = ({ lockVersions: initialVersions, children }: PlanVersionProviderProps) => {
  const [lockVersions, setLockVersions] = useState(initialVersions)
  const [remoteLockVersions, setRemoteLockVersions] = useState(initialVersions)

  const planUpdated = useCallback(({ newVersion, planId }: VersionUpdateParams) => {
    setLockVersions(current => ({ ...current, [planId]: newVersion }))
    setRemoteLockVersions(current => ({ ...current, [planId]: newVersion }))
  }, [])

  return (
    <PlanVersionContext.Provider value={{ lockVersions, planUpdated, remoteLockVersions, setRemoteLockVersions }}>
      {children}
    </PlanVersionContext.Provider>
  )
}

const usePlanVersion = () => {
  const { lockVersions, planUpdated, remoteLockVersions, setRemoteLockVersions } = useContext(PlanVersionContext)

  const { addListener, removeListener, channel } = useCoachingPlanChannel()

  useEffect(() => {
    const handler = (event: CoachingPlanChannelData) => {
      if (event.type === 'updated') {
        setRemoteLockVersions(current => ({ ...current, [event.payload.plan_id]: event.payload.lock_version }))
      }
    }
    addListener(handler)
    return () => removeListener(handler)
  }, [channel, addListener, removeListener, setRemoteLockVersions])

  return { lockVersions, planUpdated, remoteLockVersions }
}

export { usePlanVersion }
export default PlanVersionProvider
