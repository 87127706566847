import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { ChangeRequestReviewable } from '../generated_types/change_requests'
import { CellContentProps } from './types'

const ResultLinksCellContent = (props: CellContentProps) => <ResultLink changeRequest={props.changeRequest} />

export default ResultLinksCellContent

export const ResultLink = ({ changeRequest }: { changeRequest: ChangeRequestReviewable }) => {
  const { participations } = changeRequest

  if (!participations.length) return <></>

  if (participations.length === 1) return <Link resultsUrl={participations[0].results_url} />

  const mostRecentResultsUrl = participations
    .map(participation => ({ resultsUrl: participation.results_url, date: new Date(participation.updated_at) }))
    .reduce((previous, current) => (previous.date.getTime() > current.date.getTime() ? previous : current)).resultsUrl

  return <Link resultsUrl={mostRecentResultsUrl} />
}

const Link = ({ resultsUrl }: { resultsUrl: string }) => (
  <a
    href={resultsUrl}
    className="sw-btn btn-sm btn-primary hover:bg-oceanblue/60 focus:bg-oceanblue/60 whitespace-nowrap mt-1"
    target="_blank"
    rel="noreferrer"
  >
    <FontAwesomeIcon icon={['fas', 'up-right-from-square']} />
    Answers
  </a>
)
