import React from 'react'
import { Field, useFormikContext } from 'formik'
import SessionDetails from './training_session_form/session_details'
import UserPickerList from './training_session_form/user_picker_list'
import {
  ModuleDurationOverrides,
  NewTrainingSession,
  UserStub,
  UserStubWithEmail
} from './generated_types/training_session'
import { SessionDetailsFormikValues, TrainingModules } from './training_session_form/types'

const UserPickerWrapper = ({ users }) => {
  const { values, setFieldValue } = useFormikContext<SessionDetailsFormikValues>()
  return (
    <Field
      as={UserPickerList}
      selectedUserIds={values.user_ids}
      setSelectedUserIds={updater => setFieldValue('user_ids', updater(values.user_ids))}
      name="user_ids"
      userData={users}
    />
  )
}

type Props = {
  url: string
  trainingSession: NewTrainingSession
  modules: TrainingModules
  trainers: UserStub[]
  users: UserStubWithEmail[]
  moduleDurationOverrides: ModuleDurationOverrides
}

const TrainingSessionNewForm = ({ url, trainingSession, users, trainers, modules, moduleDurationOverrides }: Props) => {
  return (
    <SessionDetails
      url={url}
      trainingSession={trainingSession}
      trainers={trainers}
      modules={modules}
      attendances={[]}
      moduleDurationOverrides={moduleDurationOverrides}
      coachMessagingEnabled={false}
    >
      <UserPickerWrapper users={users} />
    </SessionDetails>
  )
}

export default TrainingSessionNewForm
