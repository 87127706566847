import qs from 'qs'
import { MultiTrainingPlanPath } from '../../utils/api/paths'
import useScheduler from './scheduler_panel/use_scheduler'
import { SelectedPlanCells, SessionDuration } from '../types'

type Props = {
  trainingPlanId: number
  moduleKey: string
}

const ViewInOtherPlan = ({ trainingPlanId, moduleKey }: Props) => {
  const { selectedCells, selectedSessionDuration, selectedTrainerId } = useScheduler()

  return (
    <a
      target="blank"
      href={buildViewInOtherPlanUrl({
        trainingPlanId,
        moduleKeys: [moduleKey],
        selectedCells,
        selectedSessionDuration,
        selectedTrainerId
      })}
      rel="noreferrer"
    >
      View in other Plans
    </a>
  )
}

type BuildViewInOtherPlanUrl = {
  trainingPlanId: number
  moduleKeys: string[]
  selectedCells: SelectedPlanCells
  selectedSessionDuration: SessionDuration
  selectedTrainerId: number
}

const buildViewInOtherPlanUrl = ({
  trainingPlanId,
  moduleKeys,
  selectedCells,
  selectedSessionDuration,
  selectedTrainerId
}: BuildViewInOtherPlanUrl) => {
  const presets = {
    coach_id: selectedTrainerId > 0 ? selectedTrainerId : undefined,
    start_date: selectedSessionDuration.start_date || undefined,
    start_time: selectedSessionDuration.start_time || undefined,
    duration_in_minutes: selectedSessionDuration.duration_in_minutes || undefined,
    learner_ids: Object.keys(selectedCells.users),
    module_keys: selectedCells.modules.map(m => m.key)
  }

  const baseUrl = `/plans/${trainingPlanId}/multi` satisfies MultiTrainingPlanPath

  return `${baseUrl}?${qs.stringify({ module_keys: moduleKeys, scheduler_presets: presets }, { arrayFormat: 'brackets' })}`
}

export { buildViewInOtherPlanUrl }
export default ViewInOtherPlan
