import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { AdminTrainingPlan } from '../types'
import { useState } from 'react'
import ScheduledUntilModal from './scheduled_until_modal'

type Props = {
  trainingPlan: AdminTrainingPlan
}

const dateFormat = new Intl.DateTimeFormat('en-GB', {
  day: '2-digit',
  month: 'short'
})

const ScheduledUntil = ({ trainingPlan }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [scheduledUntilDate, setScheduledUntilDate] = useState(
    trainingPlan.scheduled_until ? new Date(trainingPlan.scheduled_until) : null
  )
  const scheduledUntilHasPassed = scheduledUntilDate && scheduledUntilDate.getTime() < Date.now()

  const control = scheduledUntilDate ? (
    <div className={`flex gap-2 items-center ${scheduledUntilHasPassed ? 'bg-error-bg p-2 rounded' : ''}`}>
      {scheduledUntilHasPassed && <FontAwesomeIcon icon={['fas', 'circle-exclamation']} />}
      Resolved until {dateFormat.format(scheduledUntilDate)}
      <button
        className="sw-btn btn-icon w-10 h-10"
        onClick={() => {
          setShowModal(true)
        }}
      >
        <FontAwesomeIcon icon={['fas', 'pencil']} />
      </button>
    </div>
  ) : (
    <button
      className="sw-btn"
      onClick={() => {
        setShowModal(true)
      }}
    >
      <FontAwesomeIcon icon={['far', 'clock']} />
      Mark as Resolved
    </button>
  )

  return (
    <>
      {control}
      {showModal && (
        <ScheduledUntilModal
          onClose={() => setShowModal(false)}
          trainingPlanId={trainingPlan.id}
          scheduledUntilDate={scheduledUntilDate}
          setScheduledUntilDate={setScheduledUntilDate}
        />
      )}
    </>
  )
}

export default ScheduledUntil
