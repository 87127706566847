import React, { MouseEventHandler } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { Formik, Form, FormikHelpers, Field } from 'formik'

import Modal from '../../library/modal'
import SWFormGroup from '../../library/sw/sw_form_group'
import SWCheckbox from '../../library/sw/sw_checkbox'
import SWFieldWithLabel from '../../library/sw/sw_field_with_label'

import {
  AvailableTrainer,
  PartialTrainingPlanTrainer,
  TrainingPlanTrainerFormData
} from './training_plan_trainer.types'
import { Timeslot } from '../../generated_types/training_plan'
import { timeslotDescription } from '../utils'

interface TrainerModalProps {
  trainers: AvailableTrainer[]
  trainingPlanTrainer: PartialTrainingPlanTrainer
  onCancel: () => void
  onSubmit: (values: TrainingPlanTrainerFormData, formikHelpers: FormikHelpers<TrainingPlanTrainerFormData>) => void
  timeslots: Timeslot[]
}

const TrainerModal = ({ trainers, timeslots, trainingPlanTrainer, onCancel, onSubmit }: TrainerModalProps) => {
  const closeModal: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    onCancel()
  }

  const newTrainer = !trainingPlanTrainer.id
  const action = newTrainer ? (
    <>
      Add
      <FontAwesomeIcon icon={['fas', 'circle-plus']} />
    </>
  ) : (
    <>
      Save
      <FontAwesomeIcon icon={['far', 'floppy-disk']} />
    </>
  )
  const title = newTrainer ? 'Add Coach' : 'Edit Coach'

  const orderedTrainers = Object.values(trainers).sort((left, right) => left.name.localeCompare(right.name))

  const initialValues = {
    id: trainingPlanTrainer.id,
    user_id: trainingPlanTrainer.user_id,
    notes: trainingPlanTrainer.notes,
    timeslot_ids: trainingPlanTrainer.coach_timeslots.map(ct => ct.timeslot_id.toString())
  }

  return (
    <>
      <Formik<TrainingPlanTrainerFormData> onSubmit={onSubmit} initialValues={initialValues}>
        {({ isSubmitting, status, values }) => (
          <Form>
            <Modal
              options={{ active: true }}
              title={title}
              onClose={closeModal}
              footer={
                <>
                  <a onClick={closeModal} className="sw-btn mr-auto">
                    <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                    Cancel
                  </a>
                  <button
                    type="submit"
                    disabled={isSubmitting || !values.user_id}
                    className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
                  >
                    {action}
                  </button>
                </>
              }
            >
              <div className="flex flex-col gap-4">
                {status?.errors?.base ? <div className="sw-toast error">{status?.errors?.base.join(', ')}</div> : null}
                <SWFormGroup errors={status?.errors?.user}>
                  <SWFieldWithLabel
                    name="user_id"
                    as="select"
                    id="training_plan_trainer_user_id"
                    label="Coach"
                    className="sw-select"
                  >
                    <option value="">&lt;none&gt;</option>
                    {orderedTrainers.map(t => (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    ))}
                  </SWFieldWithLabel>
                </SWFormGroup>

                <SWFormGroup errors={status?.errors?.notes}>
                  <SWFieldWithLabel
                    name="notes"
                    as="textarea"
                    id="training_plan_trainer_notes"
                    label="Notes"
                    className="sw-input"
                  />
                </SWFormGroup>

                <SWFormGroup>
                  <label>Timeslots</label>
                  {timeslots.map(t => (
                    <Field
                      name="timeslot_ids"
                      as={SWCheckbox}
                      type="checkbox"
                      key={t.id}
                      label={timeslotDescription(t)}
                      value={t.id.toString()}
                    />
                  ))}
                </SWFormGroup>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default TrainerModal
