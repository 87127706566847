import { MouseEventHandler, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import Errors from '../library/errors'
import { useApiSave } from '../utils/json_request'
import { getLongDateOnly } from '../utils/date_helpers'

type Props = {
  confirmed_at?: Date | null
}

const PlanConfirmation = ({ confirmed_at }: Props) => {
  const content = confirmed_at ? (
    <div className="review-content">
      <p>
        You previously reviewed your plan on{' '}
        <time dateTime={confirmed_at.toISOString()}>
          <strong>{getLongDateOnly(confirmed_at)}</strong>
        </time>
        .
      </p>
      <p>
        If you have any questions about the topics below, or anything else then please email us at{' '}
        <a href="mailto:scheduling@skillerwhale.com">scheduling@skillerwhale.com</a>.
      </p>
    </div>
  ) : (
    <PlanConfirmationUpdater />
  )

  return (
    <div className="review-panel">
      <div className="review-icon">
        <FontAwesomeIcon icon={['far', 'star']} />
      </div>
      {content}
    </div>
  )
}

const PlanConfirmationUpdater = () => {
  const [successMessage, setSuccessMessage] = useState<string | undefined>()
  const { save, errors, saving, resetErrors } = useApiSave('/profile/planned_sessions_confirmation', 'POST')

  const onClick: MouseEventHandler = async e => {
    e.preventDefault()
    e.stopPropagation()
    resetErrors()
    const result = await save({ payload: undefined })
    if (result.ok) {
      setSuccessMessage(result.data.message)
    }
  }

  if (successMessage) {
    return (
      <div className="review-content">
        <strong>{successMessage}</strong>
      </div>
    )
  } else if (Object.keys(errors).length > 0) {
    return (
      <div className="review-content">
        <p>Something hasn&apos;t worked quite right:</p>
        <ul className="text-error-bg list-inside">
          <Errors errors={errors} as="li" />
        </ul>
      </div>
    )
  } else {
    return (
      <>
        <div className="review-content">
          <p>Please take a look through the sessions below, and then confirm that you&apos;ve reviewed your plan.</p>
          <p>
            If you think we&apos;ve left you out of sessions you&apos;d benefit from, or included you in ones you
            don&apos;t need, then please let us know at{' '}
            <a href="mailto:scheduling@skillerwhale.com">scheduling@skillerwhale.com</a>.
          </p>
        </div>

        <button className={`sw-btn review-button${saving ? ' sw-loading' : ''}`} disabled={saving} onClick={onClick}>
          <FontAwesomeIcon icon={['fas', 'check']} />
          I&apos;ve reviewed the plan
        </button>
      </>
    )
  }
}

export default PlanConfirmation
