import React from 'react'

interface ToggleProps {
  id?: string
  checked: boolean
  onChange: (newValue: boolean) => void
  label?: string
  disabled?: boolean
  classNames?: string
}

const Toggle = ({ id, checked, onChange, label, disabled = false, classNames = '' }: ToggleProps) => {
  return (
    <label className={`sw-switch ${classNames}`} htmlFor={id}>
      <input type="checkbox" id={id} checked={checked} disabled={disabled} onChange={e => onChange(e.target.checked)} />
      <span className="sw-toggle"></span>
      {label}
    </label>
  )
}

export default Toggle
