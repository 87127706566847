/**
 * Maps a module key to pre-rendered module title html
 */
export type ModuleTitles = {
  [module_key: string]: string
}

/**
 * Planned session status filters, equating to a set of session statuses (UserSessionStatus)
 * - in_your_plan = 'in-plan' and 'optional'
 * - optional = only 'optional'
 * - not_in_your_plan = 'not-in-plan' and 'not-needed'
 */
export const StatusFilterKeys = ['all_sessions', 'in_your_plan', 'optional', 'not_in_your_plan'] as const

export type StatusFilterKey = (typeof StatusFilterKeys)[number]
