import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

type ChangeRequestsEmptyProps = {
  title: string
  blurb?: string
}

const ChangeRequestsEmpty = ({ title, blurb }: ChangeRequestsEmptyProps) => (
  <div className="sw-empty mt-8 mb-12">
    <div className="fa-layers fa-fw fa-5x">
      <FontAwesomeIcon icon={['fas', 'table-cells']} />
      <FontAwesomeIcon icon={['fas', 'magic-wand-sparkles']} transform="shrink-5 right-6.9 down-5.4" />
    </div>
    <h3 className="my-2">{title}</h3>
    <p>{blurb || 'Please check back later'}</p>
  </div>
)

export default ChangeRequestsEmpty
