import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { ChangeRequest } from '../generated_types/user_plan_viewer'

type ResponseIconProps = {
  response?: ChangeRequest['response']
  tooltip?: string
}

const ResponseIcon = ({ response, tooltip }: ResponseIconProps) => {
  if (!response) return null

  const icon = response === 'approved' ? 'check' : 'xmark'
  const iconColor = response === 'approved' ? 'text-darkestseagreen' : 'text-snapperred'
  const iconBGColor = response === 'approved' ? ' bg-darkestseagreen/20' : ' bg-snapperred/20'

  const wrapperClasses = `bg-white shrink-0 rounded-md${tooltip ? ' sw-tooltip after:text-center' : ''}`

  return (
    <div className={wrapperClasses} data-tooltip={tooltip}>
      <div className={`response-icon${iconBGColor}`}>
        <FontAwesomeIcon icon={['fas', icon]} className={iconColor} size="lg" />
      </div>
    </div>
  )
}

export default ResponseIcon
