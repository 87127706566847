import { useEffect, useState } from 'react'
import { ModelErrors } from '../library/errors'
import { apiRequest } from '../utils/json_request'
import { TrainingModules, SessionDetailsFormikValues } from './types'
import { PreparedModule } from '../generated_types/training_session'
import { useFormikContext } from 'formik'
import ErrorsToast from '../library/errors_toast'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

type Props = {
  modules: TrainingModules
  className?: string
  coachName?: string
}

const CoachNewContentWarning = ({ modules, className, coachName }: Props) => {
  const { values } = useFormikContext<SessionDetailsFormikValues>()
  const [preparedModules, setPreparedModules] = useState<PreparedModule[] | undefined>()
  const [errors, setErrors] = useState<ModelErrors | undefined>()

  useEffect(() => {
    let ignore = false
    const update = async (trainer_id: number, modules: string[], start_date: string, start_time: string) => {
      const response = await getPreparedModules(trainer_id, modules, start_date, start_time)
      if (ignore) {
        return
      }
      if (response.ok) {
        setErrors(undefined)
        setPreparedModules(response.data.prepared_modules)
      } else {
        setPreparedModules(undefined)
        setErrors(response.errors)
      }
    }
    if (values.trainer_id && values.modules.length > 0 && values.start_date && values.start_time) {
      update(values.trainer_id, values.modules, values.start_date, values.start_time)
    } else {
      setPreparedModules(undefined)
      setErrors(undefined)
    }
    return () => {
      ignore = true
    }
  }, [values.trainer_id, values.modules, values.start_date, values.start_time])

  if (errors) {
    return (
      <span className={`sw-popover ${className ?? ''}`}>
        <span className="text-error-text">
          <FontAwesomeIcon icon={['fas', 'circle-exclamation']} className="text-current" />
          Unable to load whether the coach has taught this content before
        </span>
        <div className="popover-content">
          <ErrorsToast errors={errors} />
        </div>
      </span>
    )
  } else if (preparedModules !== undefined) {
    return (
      <UnpreparedModulesWarning
        preparedModules={preparedModules}
        modules={modules}
        moduleKeys={values.modules}
        className={className}
        coachName={coachName}
      />
    )
  }

  return null
}

const getPreparedModules = async (coachId: number, moduleKeys: string[], startDate: string, startTime: string) => {
  return await apiRequest('/prepared_modules', {
    method: 'GET',
    query: { user_id: coachId, module_keys: moduleKeys, start_date: startDate, start_time: startTime },
    payload: undefined
  })
}

type WarningProps = {
  modules: TrainingModules
  moduleKeys: string[]
  preparedModules: PreparedModule[]
  className?: string
  coachName?: string
}

const UnpreparedModulesWarning = ({ modules, moduleKeys, preparedModules, className, coachName }: WarningProps) => {
  const statuses = moduleKeys
    .map(key => {
      const moduleData = modules[key]

      if (!moduleData) {
        return null
      } //module that doesn't exist  - we warn about this elsewhere

      const preparedModule = preparedModules.find(pm => pm.module_key == key)

      if (preparedModule && preparedModule.versions.includes(moduleData.version)) {
        // the coach has prepared the current version of the module
        return null
      } else if (preparedModule) {
        //the coach has prepared a different version
        return (
          <li key={key}>
            {key}: not taught version {moduleData.version} before (previously taught:{' '}
            {preparedModule.versions.join(',')})
          </li>
        )
      } else {
        //not prepared previously
        return <li key={key}>{key}: not taught before</li>
        //not prepared previously
      }
    })
    .filter(s => s !== null)

  if (statuses.length > 0) {
    return (
      <span className={`sw-popover ${className ?? ''}`}>
        <span className="form-input-hint">1️⃣ This is the first time {coachName} is teaching some of these modules</span>
        <div className="popover-content">
          <ul className="list-inside">{statuses}</ul>
        </div>
      </span>
    )
  }
}

export default CoachNewContentWarning
