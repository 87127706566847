import React from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { AdminTrainingPlan } from '../../generated_types/training_plan'
import { useEditor, EditableContent } from '../../library/editor'
import UpdatedAt from '../../library/updated_at'
import { usePlanVersion } from './use_plan_version'

interface SchedulingNotesContentProps {
  formatted: string
  startEditing: () => void
}

const SchedulingNotesContent = ({ formatted, startEditing }: SchedulingNotesContentProps) => {
  return (
    <EditableContent
      formatted={formatted}
      startEditing={startEditing}
      emptyStateTitle="No notes"
      emptyStateAction={
        <>
          <FontAwesomeIcon icon={['far', 'pen-to-square']} />
          Add notes
        </>
      }
      classNames="m-2 sw-box"
    />
  )
}

interface SchedulingNotesProps {
  trainingPlan: AdminTrainingPlan
}

const SchedulingNotes = ({ trainingPlan }: SchedulingNotesProps) => {
  const { lockVersions, planUpdated } = usePlanVersion()
  const { content, updatedAt, updatedBy } = useEditor(SchedulingNotesContent, {
    source: trainingPlan.scheduling_notes,
    formatted: trainingPlan.formatted_scheduling_notes,
    updated_at: trainingPlan.scheduling_notes_updated_at,
    updated_by: trainingPlan.scheduling_notes_updated_by,
    url: `/plans/${trainingPlan.id}`,
    serialize: scheduling_notes => ({
      training_plan: { scheduling_notes, lock_version: lockVersions[trainingPlan.id] }
    }),
    parse: data => {
      planUpdated({ newVersion: data.training_plan.lock_version, planId: data.training_plan.id })
      return {
        source: data.training_plan.scheduling_notes,
        formatted: data.training_plan.formatted_scheduling_notes,
        updated_at: data.training_plan.scheduling_notes_updated_at,
        updated_by: data.training_plan.scheduling_notes_updated_by
      }
    }
  })

  return (
    <div className="training-plan-scheduling-notes">
      <h4 className="mb-0">Scheduling Notes</h4>
      <UpdatedAt name={updatedBy?.name} updated_at={updatedAt} classNames="mx-2" />
      {content}
    </div>
  )
}

export default SchedulingNotes
