import React from 'react'
import HeaderCell from './header_cell'
import { newlyAddedClass } from '../utils'
import { UserFilter, TrainingPlanContext } from '../types'

interface TrainingPlanHeaderProps
  extends Pick<
    TrainingPlanContext,
    'editable' | 'learners' | 'available_learners' | 'userIdsToRemove' | 'newAddedUserIds'
  > {
  planHasChanges: boolean
  updateOrdering: (fromIndex: number, toIndex: number) => void
  toggleUser: (userId: number) => void
  scheduler: boolean
  userIdsMoved: Set<number>
  indefinitePauses: UserFilter
  completeUsers: UserFilter
}

const TrainingPlanHeader = ({
  learners,
  newAddedUserIds,
  userIdsToRemove,
  available_learners,
  updateOrdering,
  editable,
  toggleUser,
  scheduler,
  planHasChanges,
  userIdsMoved,
  indefinitePauses,
  completeUsers
}: TrainingPlanHeaderProps) => {
  const userCells = learners
    .map((learner, index) => [learner, index] as const)
    .filter(([learner]) => available_learners[learner.user_id])
    .map(([learner, index]) => {
      // don't render a header for users who are not in userData (ie are not active)
      // we filter here rather than pre-filtering userIds so that `index` is still the index of the user amongst all users in the plan
      // which is important for reordering of users
      //
      // This is also why we compute indices before filtering rather than after
      //

      const userIndefinitelyPaused = indefinitePauses.userIds.includes(learner.user_id)
      if (userIndefinitelyPaused && !indefinitePauses.show) return null

      if (!completeUsers.show && completeUsers.userIds.includes(learner.user_id)) {
        return null
      }

      return (
        <HeaderCell
          user={available_learners[learner.user_id]}
          learner={learner}
          className={newlyAddedClass(newAddedUserIds, learner.user_id)}
          key={learner.user_id}
          index={index}
          updateOrdering={updateOrdering}
          editable={editable && !scheduler}
          markedForDestruction={userIdsToRemove.has(learner.user_id)}
          toggleUser={toggleUser}
          planHasChanges={planHasChanges}
          userMoved={userIdsMoved.has(learner.user_id)}
          userIndefinitelyPaused={userIndefinitelyPaused}
        />
      )
    })

  return (
    <thead>
      <tr>
        <th className={`controls-header${editable ? '' : ' md:border-r-2'}`}>Plan Modules</th>
        {editable && <th className="estimate-mins-header">Estimated Minutes</th>}
        {userCells}
      </tr>
    </thead>
  )
}

export default TrainingPlanHeader
