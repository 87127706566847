import { useState, useEffect, MouseEventHandler, ChangeEventHandler } from 'react'
import { UserOptionalEmail } from './training_plan_editor/types'

type UserPickerProps = {
  id?: string
  label: string
  users: UserOptionalEmail[]
  userIdSelected?: (id: number) => void
  userSelected?: (user: UserOptionalEmail) => void
  loading?: boolean
}

const UserPicker = ({ id, label, users, userIdSelected, userSelected, loading }: UserPickerProps) => {
  const [selected, setSelected] = useState('')

  const handleChange: ChangeEventHandler<HTMLSelectElement> = event => {
    setSelected(event.target.value)
  }

  const options = users.map((user, index) => {
    return (
      <option key={index} value={index}>
        {user.name}
      </option>
    )
  })

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation()
    event.preventDefault()
    if (selected) {
      const index = parseInt(selected)
      if (userIdSelected) {
        userIdSelected(users[index].id)
      }
      if (userSelected) {
        userSelected(users[index])
      }
    }
  }

  useEffect(() => setSelected(''), [users])

  let buttonClasses = 'sw-btn btn-secondary whitespace-nowrap'
  if (loading) {
    buttonClasses = buttonClasses.concat(' sw-loading')
  }

  return (
    <div className="sw-form-grp">
      <select id={id} className="sw-select" value={selected} onChange={handleChange}>
        <option />
        {options}
      </select>
      <button className={buttonClasses} onClick={handleClick} disabled={loading || !selected}>
        {label}
      </button>
    </div>
  )
}
export default UserPicker
