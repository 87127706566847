import React, { PropsWithChildren } from 'react'
import Rails from '@rails/ujs'

type Props = PropsWithChildren<{
  action: string
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH'
  className?: string
}>

const Form = ({ action, method, className, children }: Props) => {
  const methodOverride = method === 'GET' || method === 'POST' ? null : method
  const cleansedMethod = methodOverride ? 'POST' : method
  const token = Rails.csrfToken()

  return (
    <form action={action} className={className} method={cleansedMethod}>
      {methodOverride && <input type="hidden" name="_method" value={methodOverride} />}
      <input type="hidden" name="authenticity_token" value={token ?? undefined} />
      {children}
    </form>
  )
}

export default Form
