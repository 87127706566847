import { createContext, PropsWithChildren, ReactNode } from 'react'

export type CurrentUserData = {
  first_name: string
  id: number
} | null

export const CurrentUserContext = createContext<CurrentUserData>(null)

interface CurrentUserProviderProps extends PropsWithChildren {
  current_user: CurrentUserData
}

export const CurrentUserProvider = ({ current_user, children }: CurrentUserProviderProps) => (
  <CurrentUserContext.Provider value={current_user}>{children}</CurrentUserContext.Provider>
)

export const withCurrentUser = (component: ReactNode, current_user: CurrentUserData) => (
  <CurrentUserProvider current_user={current_user}>{component}</CurrentUserProvider>
)

export default CurrentUserProvider
