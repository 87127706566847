import { MouseEventHandler } from 'react'
import { Formik, Form } from 'formik'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { LearnerModuleAccessData } from '../generated_types/learner_module_accesses'
import Modal from '../library/modal'
import Errors from '../library/errors'
import { useApiSave } from '../utils/json_request'

type Props = {
  learner_module_access: LearnerModuleAccessData
  onCancel: () => void
  onComplete: (deleted: LearnerModuleAccessData) => void
}

const DeleteLearnerModuleAccessModal = ({ onCancel, learner_module_access, onComplete }: Props) => {
  const closeModal: MouseEventHandler = e => {
    e.preventDefault()
    onCancel()
  }

  const { errors, save } = useApiSave(`/learner_module_accesses/${learner_module_access.id}`, 'DELETE')

  const onSubmit = async () => {
    const result = await save({ payload: undefined })
    if (result.ok) {
      onComplete(learner_module_access)
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={learner_module_access} //react complains if the value of an input is set to null
    >
      {({ isSubmitting }) => (
        <Form>
          <Modal
            options={{ active: true }}
            title="Revoke access"
            onClose={closeModal}
            footer={
              <>
                <a onClick={closeModal} className="sw-btn mr-auto">
                  <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                  Cancel
                </a>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
                >
                  Revoke
                  <FontAwesomeIcon icon={['fas', 'ban']} />
                </button>
              </>
            }
          >
            {Object.keys(errors).length > 0 ? (
              <div className="sw-toast error">
                <ul className="list-inside">
                  <Errors errors={errors} as="li" />
                </ul>
              </div>
            ) : null}
            Revoke access to module <em>{learner_module_access.module_key} for this user</em>?
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

export default DeleteLearnerModuleAccessModal
