import { useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { PlannedSession } from '../../generated_types/user_plan_viewer'
import { UserSessionStatus, getStatus } from '../session_status'
import { ModuleTitles } from '../types'
import { getModuleWarning } from '../helpers'
import WarningHint from '../../library/warning_hint'
import SessionDetails from '../session_details'

type CurriculaSessionRowProps = {
  session: PlannedSession
  moduleTitles: ModuleTitles
}

const CurriculaSessionRow = ({ session, moduleTitles }: CurriculaSessionRowProps) => {
  const [detailsOpen, setDetailsOpen] = useState(false)

  const sessionStatus = getStatus(session)
  const rowWrapperClasses = `session-row-wrapper${
    sessionStatus === UserSessionStatus.NotInPlan || sessionStatus === UserSessionStatus.NotNeeded
      ? ' session-hidden'
      : ''
  }`

  const rowTitle = (
    <>
      <h4 className="title" dangerouslySetInnerHTML={{ __html: session.module_title_html }} />
      <span
        className={`subtitle${detailsOpen ? ' opacity-0' : ''}`}
        dangerouslySetInnerHTML={{ __html: session.overview_html }}
      />
    </>
  )

  const hasDetailsContent =
    !!session.overview_html || session.learnings_html.length > 0 || session.module_dependencies.length > 0
  const moduleWarning = getModuleWarning(session)
  const warningHint = moduleWarning && (
    <WarningHint warningTooltip={moduleWarning} classNames="ml-auto" iconClassNames="text-midgrey" />
  )

  const commonCells = (
    <>
      <div className="session-cell gap-4 col-span-8">{rowTitle}</div>
      <div className="session-cell gap-2 col-span-3">
        <span className="truncate">{session.estimated_minutes} mins</span>
        {warningHint}
      </div>
    </>
  )

  const rowContent = hasDetailsContent ? (
    <a href="#" onClick={() => setDetailsOpen(open => !open)} className={rowWrapperClasses}>
      <div className="session-cell justify-center px-0 rounded-l-md">
        <FontAwesomeIcon
          icon={['fas', 'chevron-right']}
          className={`transition-transform text-orcablue${detailsOpen ? ' rotate-90' : ''}`}
        />
      </div>
      {commonCells}
    </a>
  ) : (
    <div className={rowWrapperClasses}>
      <div className="session-cell rounded-l-md" />
      {commonCells}
    </div>
  )

  return (
    <>
      {rowContent}
      {hasDetailsContent && <SessionDetails session={session} moduleTitles={moduleTitles} open={detailsOpen} />}
    </>
  )
}

export default CurriculaSessionRow
