import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { ObjectError, collectErrorMessages } from '../library/errors'
import Modal from '../library/modal'
import { ConfirmationErrors } from './types'

type ConfirmationWarningsModalProps = {
  confirmErrors: ConfirmationErrors
  submitting: boolean
  onClose: () => void
  onConfirm: () => void
}

const ConfirmationWarningsModal = ({
  confirmErrors,
  submitting,
  onClose,
  onConfirm
}: ConfirmationWarningsModalProps) => {
  const { availabilityErrors, changeRequestErrors } = confirmErrors

  const footer = (
    <>
      <button type="button" disabled={submitting} onClick={onClose} className="sw-btn mr-auto">
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        Cancel
      </button>
      <button
        disabled={submitting}
        type="submit"
        onClick={onConfirm}
        className={`sw-btn btn-primary is-error${submitting ? ' sw-loading' : ''}`}
      >
        Proceed anyway
        <FontAwesomeIcon icon={['fas', 'check']} />
      </button>
    </>
  )

  const availabilityErrorContent = availabilityErrors.length > 0 && (
    <div>
      <h4 className="mb-2">Availability Clashes:</h4>
      <ErrorList errors={availabilityErrors} />
    </div>
  )

  const changeRequestErrorContent = changeRequestErrors.length > 0 && (
    <div>
      <h4 className="mb-2">Change Request Clashes:</h4>
      <ErrorList errors={changeRequestErrors} />
      <div className="sw-toast warning ml-4">
        <FontAwesomeIcon icon={['fas', 'circle-exclamation']} />
        Proceeding will automatically reject their change requests
      </div>
    </div>
  )

  return (
    <Modal options={{ active: true, submitting }} title="Confirmation is required" onClose={onClose} footer={footer}>
      <div className="flex flex-col gap-6">
        {availabilityErrorContent}
        {changeRequestErrorContent}
      </div>
    </Modal>
  )
}

export default ConfirmationWarningsModal

const ErrorList = ({ errors }: { errors: ObjectError[] }) => (
  <ul className="text-error-text list-inside ml-4">
    {collectErrorMessages(errors).map((error, index) => (
      <li key={index}>{error}</li>
    ))}
  </ul>
)
