import { Dispatch, MouseEventHandler, ReactElement, SetStateAction, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import ErrorsToast from '../../library/errors_toast'
import { useApiSave } from '../../utils/json_request'
import { AdminTrainingPlan } from '../types'
import { usePlanVersion } from './use_plan_version'

type Props<K extends keyof AdminTrainingPlan> = {
  attribute: K
  currentValue: AdminTrainingPlan[K]
  formattedValue: ReactElement | string
  onChange: (newValue: AdminTrainingPlan[K]) => void
  trainingPlanId: number
  children: ({
    value,
    setValue
  }: {
    value: AdminTrainingPlan[K]
    setValue: Dispatch<SetStateAction<AdminTrainingPlan[K]>>
  }) => ReactElement
  saveLabel: string
  editLabel: string
}

const CoachingPlanAttributeEditor = <K extends keyof AdminTrainingPlan>({
  attribute,
  currentValue,
  onChange,
  trainingPlanId,
  saveLabel,
  children,
  formattedValue,
  editLabel
}: Props<K>) => {
  const { lockVersions, planUpdated } = usePlanVersion()
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState<AdminTrainingPlan[K]>(currentValue)
  const { save, errors, saving } = useApiSave(`/plans/${trainingPlanId}`, 'PATCH')

  const updatePlan: MouseEventHandler = async e => {
    e.preventDefault()
    e.stopPropagation()

    const { ok, data } = await save({
      payload: {
        training_plan: {
          [attribute]: value,
          lock_version: lockVersions[trainingPlanId]
        }
      }
    })
    if (ok) {
      planUpdated({ newVersion: data.training_plan.lock_version, planId: data.training_plan.id })

      onChange(data.training_plan[attribute])
      setEditing(false)
    }
  }

  if (editing) {
    return (
      <>
        {children({ value, setValue })}
        <div>
          <button
            className="sw-btn btn-icon w-10 h-10 ml-2"
            aria-label="Cancel Edit"
            onClick={() => setEditing(false)}
            disabled={saving}
          >
            <FontAwesomeIcon icon={['fas', 'xmark']} />
          </button>
          <button
            className={`sw-btn btn-primary btn-icon w-10 h-10 ml-2 ${saving ? 'sw-loading' : ''}`}
            aria-label={saveLabel}
            onClick={updatePlan}
            disabled={saving}
          >
            <FontAwesomeIcon icon={['fas', 'check']} />
          </button>
        </div>
        <ErrorsToast errors={errors} />
      </>
    )
  } else {
    return (
      <>
        {formattedValue}
        <button className="sw-btn btn-icon w-10 h-10 ml-16" aria-label={editLabel} onClick={() => setEditing(true)}>
          <FontAwesomeIcon icon={['fas', 'pencil']} />
        </button>
      </>
    )
  }
}

export default CoachingPlanAttributeEditor
