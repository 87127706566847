import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import Modal from '../../library/modal'
import { User } from '../types'
import { useState } from 'react'

type DoNotScheduleModalParams = {
  onClose: () => void
  onConfirm: (reason: string) => void
  sessionTitle: string
  user: User
}

const DoNotScheduleModal = ({ onClose, onConfirm, user, sessionTitle }: DoNotScheduleModalParams) => {
  const [reason, setReason] = useState('')

  return (
    <Modal
      options={{ active: true, stopPropagation: true }}
      onClose={onClose}
      title="Do Not Schedule"
      footer={
        <>
          <button type="button" onClick={onClose} className="sw-btn mr-auto">
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            Cancel
          </button>
          <button
            type="submit"
            onClick={() => onConfirm(reason)}
            className={'sw-btn btn-primary'}
            disabled={reason.length === 0}
          >
            Do Not Schedule
            <FontAwesomeIcon icon={['fas', 'ban']} />
          </button>
        </>
      }
    >
      <p className="text-left">
        Do not schedule <strong>{sessionTitle}</strong> for <strong>{user.name}</strong>
      </p>
      <div className="sw-group text-left">
        <label className="sw-label">Reason</label>
        <textarea
          name="do_not_schedule_reason"
          className="sw-input"
          value={reason}
          onChange={e => setReason(e.target.value)}
        />
        <p className="form-input-hint">Why this session won&apos;t be scheduled. Visible to the manager</p>
      </div>
    </Modal>
  )
}

export default DoNotScheduleModal
