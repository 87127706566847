import { Optional } from '../utils/types'

import {
  PlannedSessionData,
  PlannedSessionState,
  PlannedSessionUserData,
  Attendances,
  Module,
  AdminTrainingPlan,
  TrainingPlanStatus,
  TrainingSessionStatus,
  TrainingSessionState,
  TrainingPlan,
  TrainingPlanUsers,
  TrainingPlanTrainer,
  Unavailability,
  UnavailabilityType,
  Snooze,
  Learner,
  PlannedSessionStatus,
  Timeslot,
  DayOfWeek,
  ChangeRequest,
  ChangeRequestWithReview
} from '../generated_types/training_plan'
import { ModelErrors } from '../library/errors'

export {
  PlannedSessionState,
  PlannedSessionStatus,
  TrainingPlanStatus,
  TrainingSessionState,
  UnavailabilityType,
  type TrainingSessionStatus,
  type PlannedSessionUserData,
  type PlannedSessionData,
  type Attendances,
  type Module,
  type TrainingPlan,
  type TrainingPlanTrainer,
  type AdminTrainingPlan,
  type Unavailability,
  type Snooze,
  type Learner,
  type Timeslot,
  DayOfWeek,
  type ChangeRequest,
  type ChangeRequestWithReview
}

type PersistedPlannedSession = PlannedSessionData & {
  markedForDestruction?: boolean
  reordered?: boolean
  dbState: PlannedSessionData & { index: number }
}

type UnsavedPlannedSession = Omit<PersistedPlannedSession, 'id' | 'dbState'> & {
  id: null
  dbState: null
}

export type PlannedSession = PersistedPlannedSession | UnsavedPlannedSession

export type ModuleWithKey = Module & { key: string }

export type Modules = {
  [moduleKey: string]: Module
}

export type User = TrainingPlanUsers[keyof TrainingPlanUsers]

export type UserOptionalEmail = Optional<User, 'email'>

export type Users = {
  [userId: number]: User
}

export type AvailableTrainer = {
  name: string
  id: number
}

export type AvailableTrainers = Record<number, AvailableTrainer>

type PlanLevelFields = Pick<
  AdminTrainingPlan,
  | 'draft'
  | 'description'
  | 'status'
  | 'scheduling_notes'
  | 'name'
  | 'scheduler_id'
  | 'frequency_in_weeks'
  | 'auto_start_hosted_environments'
  | 'lock_version'
  | 'scheduled_until'
>

type PlannedSessionFields = Omit<PlannedSessionData, 'attendances'> & {
  attendance_attributes: Array<{ user_id: number; optional: boolean }>
  row_order_position: number
  _destroy: boolean
}

export type PlanMetadata = Pick<AdminTrainingPlan, 'draft' | 'status' | 'name'>

/* the updatable properties of a TrainingPlan */

export type TrainingPlanUpdates = Partial<PlanLevelFields & { planned_sessions_attributes: PlannedSessionFields }>

/**
 * Describing the current context/state of a training plan
 */
export type TrainingPlanContext = {
  companyId: number
  editable: boolean
  hasChanges: boolean
  defaultTrainerId?: number
  userIdsToRemove: Set<number>
  newAddedUserIds: Array<number>
  learners: Array<Learner>
  available_learners: Users
  modules: Modules
  trainingPlanId?: number | null // set when we're in a single plan context
}

export type TrainingPlanSaveState = {
  success?: boolean
  inProgress?: boolean
  errors?: ModelErrors
}

/**
 * The dependencies for each user within a group
 */
export type UsersDependencies = {
  [userId: number]: string[]
}

/**
 * The dependencies for every module-user pair within a plan
 */
export type PlanDependencies = {
  [moduleKey: string]: UsersDependencies
}

/**
 * User unavailability indexed by user id
 */
export type Unavailabilities = {
  [userId: number]: Unavailability[]
}

/**
 * In scheduler view, an object containing selected table cells.
 * Every module is scheduled for every user
 */
export type SelectedPlanCells = {
  modules: ModuleWithKey[]
  users: Users
}

/**
 * An object of user attendances indexed by module key
 */
export type ModuleAttendances = {
  [moduleKey: string]: Attendances
}

/**
 * Defines the length of time a session will last
 * The field names match those used in the training_sessions api
 */
export type SessionDuration = {
  duration_in_minutes: string
  start_date: string
  start_time: string
}

export const defaultSessionDuration: SessionDuration = <const>{
  duration_in_minutes: '',
  start_date: '',
  start_time: ''
}

export type TimeslotClickedParams = {
  starts_at: Date
  timezone: string
  coach_id?: number
}

export type TimeslotClickedFunction = (p: TimeslotClickedParams) => void

/**
 * A mapping of a coach's user id to the array of timeslots assigned to them
 */
export type CoachTimeslotMapping = Record<number, Timeslot[]>

/**
 * Object containing data associated with user filters
 */
export type UserFilter = {
  show: boolean
  userIds: number[]
}

export type SetSelectedCellFunction = React.Dispatch<
  React.SetStateAction<{ userId: number; moduleKey: string } | undefined>
>
