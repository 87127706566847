import { MouseEventHandler, PropsWithChildren } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { ChangeRequest } from '../generated_types/user_plan_viewer'

type ChangeRequestDropdownItemProps = {
  /**
   * Determines the styled Yes/No/- displayed before the explainer text
   */
  requestState?: ChangeRequest['requested_state']
  /**
   * Used to determine if the item is "active"
   */
  currentRequestState?: ChangeRequest['requested_state']
  /**
   * The explainer text following the Yes/No/-. Defaults based on requestState if omitted.
   */
  explainer?: string
  /**
   * Called when the user clicks the item
   */
  onChoose?: (newState: ChangeRequest['requested_state'] | undefined) => void
}

const ChangeRequestDropdownItem = ({
  requestState,
  currentRequestState,
  explainer,
  onChoose
}: ChangeRequestDropdownItemProps) => {
  const selected = requestState === currentRequestState

  const linkClasses = selected ? 'active cursor-default' : undefined
  let responseContent = <></>
  let explainerText = ''

  switch (requestState) {
    case 'would_like':
      explainerText = explainer || 'I would like to learn this'
      responseContent = <span className="w-8 font-semibold">Yes</span>
      break
    case 'dont_need':
      explainerText = explainer || "I don't think I need this"
      responseContent = <span className="w-8 font-semibold">No</span>
      break

    default:
      explainerText = explainer || "I'm not sure"
      responseContent = (
        <span className="w-8">
          <FontAwesomeIcon icon={['fas', 'minus']} className="text-lightergrey" />
        </span>
      )
      break
  }

  return (
    <li>
      <DropdownItemWrapper
        isLink={!selected}
        classNames={linkClasses}
        onClick={event => {
          event.stopPropagation()
          onChoose && onChoose(requestState)
        }}
      >
        {responseContent}
        <span> - {explainerText}</span>
        {selected && <FontAwesomeIcon icon={['fas', 'check']} className="ml-auto text-oceanblue" />}
      </DropdownItemWrapper>
    </li>
  )
}

export default ChangeRequestDropdownItem

type DropdownItemWrapperProps = {
  isLink: boolean
  classNames?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
} & PropsWithChildren

const DropdownItemWrapper = ({ isLink, classNames, onClick, children }: DropdownItemWrapperProps) => {
  return isLink ? (
    <a className={classNames} onClick={onClick} href="#">
      {children}
    </a>
  ) : (
    <div className={classNames}>{children}</div>
  )
}
