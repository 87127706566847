import { SessionDetailsFormikValues, TrainingSession } from './types'
import CalendarInvitationStatusDisplay from './calendar_invitation_status_display'

type CoachInfoProps = {
  trainingSession: TrainingSession
  formTrainerId: SessionDetailsFormikValues['trainer_id']
}

const CoachInfo = ({ trainingSession, formTrainerId }: CoachInfoProps) => {
  if (!trainingSession.persisted) return null

  const calendarIcon = trainingSession.trainer_id === formTrainerId && (
    <CalendarInvitationStatusDisplay status={trainingSession.trainer_calendar_event_response_status} />
  )

  const ratingIcon = trainingSession.coach_overall_satisfaction && (
    <a href={trainingSession.feedback_url} rel="noreferrer" target="_blank">
      Rating: {trainingSession.coach_overall_satisfaction}/5
    </a>
  )

  const invoiceLink = trainingSession.trainer_invoice ? (
    <a href={trainingSession.trainer_invoice.url} rel="noreferrer" target="_blank">
      Invoice for Session
    </a>
  ) : (
    <a
      href={`/coaches/${formTrainerId}/invoices`}
      rel="noreferrer"
      target="_blank"
      className="sw-tooltip text-center"
      data-tooltip={`No invoice items currently\nexist for this session`}
    >
      Coach Invoices
    </a>
  )

  return (
    <span className="inline-flex gap-3">
      {calendarIcon}
      {ratingIcon}
      {invoiceLink}
    </span>
  )
}

export default CoachInfo
