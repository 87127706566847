import { useDrop } from 'react-dnd'

/*
    The useDrop code comes from a react-table example
    https://react-table.tanstack.com/docs/examples/row-dnd
  */

const DIRECTION_HORIZONTAL = 'horizontal'
const DIRECTION_VERTICAL = 'vertical'

const useReorderHoverDrop = ({ dropRef, hoverIndex, direction, updateOrdering, itemType }) => {
  return useDrop<{ index: number }>({
    accept: itemType,
    hover: (item, monitor) => {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()

      let hoverMiddle
      if (direction === DIRECTION_VERTICAL) {
        hoverMiddle = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      } else {
        hoverMiddle = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      }

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      if (!clientOffset) {
        return
      }
      let hoverClientPosition
      // Get pixels to the top
      if (direction === DIRECTION_VERTICAL) {
        hoverClientPosition = clientOffset.y - hoverBoundingRect.top
      } else {
        hoverClientPosition = clientOffset.x - hoverBoundingRect.left
      }

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      //
      // Similarly for a horizontal drag

      // Dragging down / right
      if (dragIndex < hoverIndex && hoverClientPosition < hoverMiddle) {
        return
      }
      // Dragging up/left
      if (dragIndex > hoverIndex && hoverClientPosition > hoverMiddle) {
        return
      }
      // Time to actually perform the action
      updateOrdering(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })
}

export default useReorderHoverDrop
export { DIRECTION_VERTICAL, DIRECTION_HORIZONTAL }
