import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

type WarningHintProps = {
  warningTooltip: string
  classNames?: string
  iconClassNames?: string
}

/**
 * Displays Warning icon with Warningful tooltip text
 */
const WarningHint = ({ warningTooltip, classNames = '', iconClassNames = '' }: WarningHintProps) => {
  return (
    <span className={`sw-tooltip tooltip-left group ${classNames}`} data-tooltip={warningTooltip}>
      <FontAwesomeIcon
        icon={['fas', 'circle-exclamation']}
        size="xl"
        className={`opacity-40 group-hover:opacity-90 ${iconClassNames}`}
      />
    </span>
  )
}

export default WarningHint
