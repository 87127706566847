import React, { useState, MouseEventHandler } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { PlannedSession } from '../types'
import { moduleDoesntExistError, moduleInSessionError } from '../utils'
import ModulePicker from '../components/module_picker'
import Modal from '../../library/modal'

enum ErrorType {
  NoError,
  DoesntExist,
  AlreadyInSession
}

interface EditPlanModuleModalProps {
  known_module_keys: string[]
  session: PlannedSession
  sessions: PlannedSession[]
  cancelEdit: () => void
  updateSessionModule: (fromModule: string, toModule: string) => void
}

const EditPlanModuleModal = ({
  known_module_keys,
  session,
  sessions,
  cancelEdit,
  updateSessionModule
}: EditPlanModuleModalProps) => {
  const thisModule = session.training_module
  const [moduleKey, setModuleKey] = useState(thisModule)
  const [error, setError] = useState<undefined | string>(
    checkForError({ known_module_keys, sessions, thisModule, newModuleKey: moduleKey }).message
  )
  const [saveDisabled, SetSaveDisabled] = useState(true)

  const cancelClicked: MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation()
    e.preventDefault()
    cancelEdit()
  }

  const saveClicked: MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation()
    e.preventDefault()
    updateSessionModule(session.training_module, moduleKey)
  }

  const handleChange = (newModuleKey: string) => {
    setModuleKey(newModuleKey)
    const newError = checkForError({ known_module_keys, sessions, thisModule, newModuleKey })
    setError(newError.message)
    SetSaveDisabled(newError.id === ErrorType.AlreadyInSession || !newModuleKey)
  }

  const errorClass = error ? ' has-error' : ''

  const footer = (
    <>
      <button onClick={cancelClicked} className="sw-btn mr-auto" aria-label="Close">
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        Cancel
      </button>
      <button onClick={saveClicked} disabled={saveDisabled} className="sw-btn btn-primary" aria-label="Save">
        Save
        <FontAwesomeIcon icon={['far', 'floppy-disk']} />
      </button>
    </>
  )

  return (
    <Modal options={{ active: true }} title="Edit Session Module" footer={footer} onClose={cancelClicked}>
      <div className={`sw-group${errorClass}`}>
        <label htmlFor="edit-session-module-key" className="sw-label">
          Module Key:
        </label>
        <ModulePicker
          id="edit-session-module-key"
          moduleKey={moduleKey}
          setModuleKey={setModuleKey}
          onModuleKeyChange={handleChange}
        />
        {error && <p className="form-input-hint">{error}</p>}
      </div>
    </Modal>
  )
}

export default EditPlanModuleModal

type Error = {
  id: ErrorType
  message?: ReturnType<typeof moduleDoesntExistError> | ReturnType<typeof moduleInSessionError>
}

interface checkForErrorParams extends Pick<EditPlanModuleModalProps, 'known_module_keys' | 'sessions'> {
  thisModule: string
  newModuleKey?: string
}

const checkForError = ({ known_module_keys, sessions, thisModule, newModuleKey }: checkForErrorParams): Error => {
  if (!newModuleKey) return { id: ErrorType.NoError }

  const doesntExistError = moduleDoesntExistError(known_module_keys, newModuleKey)
  if (doesntExistError) return { id: ErrorType.DoesntExist, message: doesntExistError }

  if (thisModule === newModuleKey) return { id: ErrorType.NoError }

  const inSessionError = moduleInSessionError(sessions, newModuleKey)
  if (inSessionError) return { id: ErrorType.AlreadyInSession, message: inSessionError }

  return { id: ErrorType.NoError }
}
