import { Dispatch, SetStateAction } from 'react'
import PlanFrequencyEditor from './plan_frequency_editor'

type Props = {
  trainingPlanId: number
  frequencyInWeeks: number | null
  setFrequencyInWeeks: Dispatch<SetStateAction<number | null>>
}

const PlanFrequency = ({ frequencyInWeeks, setFrequencyInWeeks, trainingPlanId }: Props) => {
  return (
    <div className="flex flex-wrap items-center justify-end gap-x-2 gap-y-1">
      <label className="basis-36 text-base font-semibold mr-auto" htmlFor="frequency-select">
        Session Frequency:
      </label>
      <PlanFrequencyEditor
        frequencyInWeeks={frequencyInWeeks}
        frequencyChanged={setFrequencyInWeeks}
        trainingPlanId={trainingPlanId}
      />
    </div>
  )
}

export default PlanFrequency
