import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { Attendance, PlanMembership, UserMap } from '../generated_types/training_session'
import Empty from '../library/empty'
import qs from 'qs'
import { MultiTrainingPlanPath } from '../utils/api/paths'

type Props = {
  planMemberships: PlanMembership[]
  users: UserMap
  attendances: Attendance[]
  sessionCode: string
}

const PlanMemberships = ({ planMemberships, users, attendances, sessionCode }: Props) => {
  if (planMemberships.length === 0) {
    return (
      <Empty
        title={'No relevant coaching plans'}
        subtitle="No plan exists containing these learners and unhidden modules"
        icon={<FontAwesomeIcon icon={['fas', 'table-cells']} size="2xl" />}
      />
    )
  }

  const primaryPlan = planMemberships.find(membership => membership.primary)
  return (
    <table className="sw-table w-full" id="plan-memberships">
      <thead>
        <tr>
          <th />
          <th>Plan Name</th>
          <th>Company</th>
          <th>Learners</th>
          <th className="normal-case">
            {primaryPlan && (
              <a
                href={findLearnersUrl({ sessionCode, planId: primaryPlan?.plan_id })}
                className="sw-btn btn-primary btn-sm sw-tooltip"
                data-tooltip="Find more learners to add to this session"
              >
                Add (all plans)
                <FontAwesomeIcon icon={['fas', 'circle-plus']} />
              </a>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {planMemberships.map(pm => (
          <tr key={pm.plan_id}>
            <td className="px-0 sw-tooltip text-center" data-tooltip={pm.status}>
              {planIcon(pm.status)}
            </td>
            <td>
              <a href={pm.url}>{pm.plan_name ?? '<no-name>'}</a>
            </td>
            <td>{pm.company.name}</td>
            <td>
              <UserDisplay user_ids={pm.user_ids} users={users} attendances={attendances} />
            </td>
            <td>
              <a
                href={findLearnersUrl({ sessionCode, planId: pm.plan_id, rootPlanOnly: true })}
                className="sw-btn btn-primary btn-sm sw-tooltop"
                data-tooltip="Add more learners from this plan"
              >
                Add
                <FontAwesomeIcon icon={['fas', 'circle-plus']} />
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

type UserDisplayProps = {
  user_ids: number[]
  users: UserMap
  attendances: Attendance[]
}
const UserDisplay = ({ user_ids, users, attendances }: UserDisplayProps) => {
  return (
    <div className="sw-popover">
      <span>
        {user_ids.length}/{attendances.length}
      </span>
      <div className="popover-content">
        <h5>Learners</h5>
        <ul className="list-none">
          {user_ids.map(id => (
            <li key={id}>{users[id].name}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

type FindLearnersUrlParams = {
  sessionCode: string
  planId: number
  rootPlanOnly?: boolean
}

const findLearnersUrl = ({ sessionCode, planId, rootPlanOnly = undefined }: FindLearnersUrlParams) => {
  const baseUrl = `/plans/${planId}/multi` satisfies MultiTrainingPlanPath

  return `${baseUrl}?${qs.stringify({ root_plan_only: rootPlanOnly, session_code: sessionCode })}`
}

const planIcon = (status: PlanMembership['status']): string => {
  switch (status) {
    case 'active':
      return '🟢'
    case 'inactive':
      return '◻️'
    case 'pending':
      return '⏳'
  }
}

export default PlanMemberships
