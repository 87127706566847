import { useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { apiRequest } from '../utils/json_request'
import { ModelErrors } from '../library/errors'
import { CellContentProps } from './types'
import ErrorIcon from './error_icon'

const ReviewButtonsCellContent = (props: CellContentProps) => {
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState<ModelErrors | undefined>(undefined)

  const currentReview = props.changeRequest.review

  const reviewRequest = async (review: 'approved' | 'rejected') => {
    setSaving(true)

    const results = await apiRequest(`/change_request_reviews/create_or_update`, {
      payload: {
        change_request_reviews: [
          {
            change_request_id: props.changeRequest.id,
            review
          }
        ]
      },
      method: 'POST'
    })

    if (results.ok) {
      if (results.data.results.length !== 1) setErrors({ base: ['Unexpected response from server'] })
      else {
        const result = results.data.results[0]
        if ('errors' in result) setErrors(result.errors)
        else {
          props.updateSingleRequest(result.change_request_review)
          setErrors(undefined)
        }
      }
    } else setErrors(results.errors)

    setSaving(false)
  }

  const approveButtonClasses = `sw-btn sw-tooltip btn-icon-lg approve-button${
    currentReview === 'approved' ? ' approved' : ''
  }`
  const rejectButtonClasses = `sw-btn sw-tooltip btn-icon-lg reject-button${
    currentReview === 'rejected' ? ' rejected' : ''
  }`

  let approveButtonTooltip = 'This request is approved',
    rejectButtonTooltip = 'This request is rejected'
  if (props.changeRequest.requested_state === 'would_like') {
    if (currentReview === 'approved') {
      rejectButtonTooltip = "Actually they shouldn't learn this"
    } else if (currentReview === 'rejected') {
      approveButtonTooltip = 'Actually they do need this'
    } else {
      approveButtonTooltip = "I'm happy for them to learn this"
      rejectButtonTooltip = "They shouldn't learn this"
    }
  } else {
    if (currentReview === 'approved') {
      rejectButtonTooltip = 'Actually they do need this'
    } else if (currentReview === 'rejected') {
      approveButtonTooltip = "Actually they shouldn't learn this"
    } else {
      approveButtonTooltip = "Agree, they shouldn't learn this"
      rejectButtonTooltip = 'No, they need to learn this'
    }
  }

  return (
    <div className={`flex gap-2 items-center${currentReview ? ' reviewed' : ''}${saving ? ' sw-loading' : ''}`}>
      <button
        disabled={saving}
        className={approveButtonClasses}
        data-tooltip={approveButtonTooltip}
        onClick={() => reviewRequest('approved')}
      >
        <FontAwesomeIcon icon={['fas', 'check']} />
      </button>
      <button
        disabled={saving}
        className={rejectButtonClasses}
        data-tooltip={rejectButtonTooltip}
        onClick={() => reviewRequest('rejected')}
      >
        <FontAwesomeIcon icon={['fas', 'xmark']} />
      </button>
      {errors && <ErrorIcon errors={errors} />}
    </div>
  )
}

export default ReviewButtonsCellContent
