import { OverlappingSessionDetail } from '../generated_types/training_plan'
import Modal from '../library/modal'

type Props = {
  sessionDetails: OverlappingSessionDetail[]
  onClose: () => void
}

const OverlappingCoachSessionsModal = ({ sessionDetails, onClose }: Props) => {
  const description = sessionDetails.length == 1 ? 'this session' : 'these sessions'
  return (
    <Modal
      options={{ active: true }}
      title="⚠️ Overlapping Sessions Found"
      onClose={onClose}
      footer={
        <button className="sw-btn btn-primary" onClick={onClose}>
          OK
        </button>
      }
    >
      <>
        <h5>Pause Added</h5>

        <p>The coach is currently scheduled to teach {description} during this period:</p>

        <ul className="list-outside">
          {sessionDetails.map(detail => (
            <li key={detail.code}>
              {detail.description} (
              <a href={detail.session_edit_url} target="_blank" rel="noreferrer">
                Edit
              </a>
              )
            </li>
          ))}
        </ul>
      </>
    </Modal>
  )
}

export default OverlappingCoachSessionsModal
