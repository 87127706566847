import { ChangeEventHandler, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { ChangeRequestFilter, ChangeRequestsReviewProps } from '../generated_types/change_requests'
import { ModelErrors } from '../library/errors'
import ChangeRequestsEmpty from './change_requests_empty'
import ChangeRequestsLayout from './change_requests_layout'
import { apiRequest } from '../utils/json_request'
import ErrorIcon from './error_icon'
import ReviewSectionHeader from './review_section_header'
import { UpdateChangeRequest } from './types'
import { getFilterParams } from './utils'
import { removeUrlParam } from '../utils/url_helpers'

const ChangeRequestsReview = ({ would_like, dont_need, filters }: ChangeRequestsReviewProps) => {
  const [wouldLikeData, setWouldLikeData] = useState(would_like)
  const [dontNeedData, setDontNeedData] = useState(dont_need)
  const [includeReviewed, setIncludeReviewed] = useState(false)

  const [wouldLikeLoading, setWouldLikeLoading] = useState(false)
  const [dontNeedLoading, setDontNeedLoading] = useState(false)
  const [errors, setErrors] = useState<ModelErrors | undefined>()

  const [currentFilters, setCurrentFilters] = useState(filters)
  const currentFilterParams = getFilterParams(currentFilters)

  if (wouldLikeData.pagination.total_entries + dontNeedData.pagination.total_entries < 1)
    return (
      <ChangeRequestsEmpty
        title="No Reviewable Change Request"
        blurb="There is currently nothing needing your review"
      />
    )

  const onToggleReviewed: ChangeEventHandler<HTMLInputElement> = async event => {
    const include_reviewed = event.target.checked
    updateData(include_reviewed, currentFilters)
  }

  const onQueryChipDismiss = (filter: ChangeRequestFilter) => {
    const filterParamKeys = Object.keys(filter.params)
    const newFilters = currentFilters?.filter(
      currentFilter => !Object.keys(currentFilter.params).some(paramKey => filterParamKeys.includes(paramKey))
    )
    Object.keys(filter.params).forEach(paramKey => removeUrlParam(`filters[${paramKey}]`))
    updateData(includeReviewed, newFilters)
  }

  const updateData = async (include_reviewed: boolean, filters?: ChangeRequestFilter[]) => {
    setWouldLikeLoading(true)
    setDontNeedLoading(true)

    const filterParams = filters ? getFilterParams(filters) : undefined

    const results = await apiRequest(`/change_requests/reviewable`, {
      query: {
        would_like_page: 1,
        dont_need_page: 1,
        include_reviewed,
        filters: filterParams
      },
      payload: undefined,
      method: 'GET'
    })

    if (results.ok) {
      if (results.data.would_like) setWouldLikeData(results.data.would_like)
      if (results.data.dont_need) setDontNeedData(results.data.dont_need)

      if (results.data.would_like && results.data.dont_need) {
        setErrors(undefined)
      } else setErrors({ base: ['Some requests could not be fetched'] })

      setIncludeReviewed(include_reviewed)
      setCurrentFilters(filters)
    } else setErrors(results.errors)

    setWouldLikeLoading(false)
    setDontNeedLoading(false)
  }

  const updateSingleRequest: UpdateChangeRequest = changeRequestReview => {
    const setter = changeRequestReview.requested_state === 'would_like' ? setWouldLikeData : setDontNeedData

    setter(current => {
      const currentRequests = current.change_requests
      const index = currentRequests.findIndex(cr => cr.id === changeRequestReview.id)

      // The state might have been updated by moving page, so leave it alone
      if (index < 0 || index >= currentRequests.length) return current

      const currentChangeRequest = currentRequests[index]
      const newChangeRequests = [...currentRequests]
      newChangeRequests[index] = { ...currentChangeRequest, ...changeRequestReview }
      return { ...current, change_requests: newChangeRequests }
    })
  }

  return (
    <>
      <p>
        Here you can review any requests by your team members to take or skip modules. Once you have approved or
        rejected a request, we&apos;ll update their coaching plans accordingly.
      </p>
      <p>
        Once we&apos;ve actioned change requests, they will <strong>no longer be visible here</strong>.
      </p>
      <fieldset className="sw-fieldset relative mb-20">
        <legend>Filter</legend>
        {(wouldLikeLoading || dontNeedLoading) && <div className="filters-loading sw-loading loading-lg" />}
        <div className="flex flex-wrap items-center gap-4">
          {currentFilters?.map(filter => (
            <span key={filter.label} className="sw-chip filter-chip">
              {filter.label}
              <button
                className="sw-btn btn-close-sm sw-tooltip"
                onClick={() => onQueryChipDismiss(filter)}
                data-tooltip="Remove filter"
                disabled={wouldLikeLoading || dontNeedLoading}
              >
                <FontAwesomeIcon icon={['fas', 'xmark']} size="sm" />
              </button>
            </span>
          ))}
        </div>
        <div className="flex items-center gap-4 ml-auto">
          {errors && <ErrorIcon errors={errors} />}
          <label className="sw-switch mr-0 h4">
            Include reviewed requests
            <input
              type="checkbox"
              checked={includeReviewed}
              disabled={wouldLikeLoading || dontNeedLoading}
              onChange={onToggleReviewed}
            />
            <span className="sw-toggle"></span>
          </label>
        </div>
      </fieldset>

      <ReviewSectionHeader
        icon={['far', 'thumbs-up']}
        title="Requests to take a module"
        entries={wouldLikeData.pagination.total_entries}
      />
      <ChangeRequestsLayout
        sectionData={wouldLikeData}
        updateSectionData={setWouldLikeData}
        loading={wouldLikeLoading}
        setLoading={setWouldLikeLoading}
        dataType="would_like"
        includeReviewed={includeReviewed}
        updateSingleRequest={updateSingleRequest}
        filterParams={currentFilterParams}
      >
        <>
          These requests are from learners who would like to take modules not currently planned for them. If you&apos;re
          happy with them doing so you can press the{' '}
          <strong>
            Approve Button <FontAwesomeIcon icon={['fas', 'check']} size="lg" className="ml-0.5 text-darkerseagreen" />
          </strong>
          . If you think it&apos;s not needed, you can select the{' '}
          <strong>
            Reject Button <FontAwesomeIcon icon={['fas', 'xmark']} size="lg" className="ml-0.5 text-snapperred" />
          </strong>
          .
        </>
      </ChangeRequestsLayout>

      <ReviewSectionHeader
        icon={['far', 'thumbs-down']}
        title="Requests to skip a module"
        entries={dontNeedData.pagination.total_entries}
      />
      <ChangeRequestsLayout
        sectionData={dontNeedData}
        updateSectionData={setDontNeedData}
        loading={dontNeedLoading}
        setLoading={setDontNeedLoading}
        dataType="dont_need"
        includeReviewed={includeReviewed}
        updateSingleRequest={updateSingleRequest}
        filterParams={currentFilterParams}
      >
        <>
          These requests are from learners who believe they don&apos;t need a module currently planned for them. If you
          would still like them to cover this topic you can select the{' '}
          <strong>
            Reject Button <FontAwesomeIcon icon={['fas', 'xmark']} size="lg" className="ml-0.5 text-snapperred" />
          </strong>
          . Otherwise, press the{' '}
          <strong>
            Approve Button <FontAwesomeIcon icon={['fas', 'check']} size="lg" className="my-0.5 text-darkerseagreen" />
          </strong>{' '}
          to remove it from their plan.
        </>
      </ChangeRequestsLayout>
    </>
  )
}

export default ChangeRequestsReview
