import { Dispatch, SetStateAction, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { Timeslot, TrainingPlanTrainer } from '../../generated_types/training_plan'
import TimeslotChip from '../training_plan_trainers/timeslot_chip'
import { AvailableTrainers, TimeslotClickedFunction } from '../types'
import TimeslotsModal from './timeslots_modal'
import Empty from '../../library/empty'

type Props = {
  timeslots: Timeslot[]
  setTimeslots: Dispatch<SetStateAction<Timeslot[]>>
  trainingPlanId: number
  trainingPlanTrainers: TrainingPlanTrainer[]
  trainers: AvailableTrainers
  onTimeslotClick?: TimeslotClickedFunction
}

const Timeslots = ({
  timeslots,
  setTimeslots,
  trainingPlanId,
  trainingPlanTrainers,
  trainers,
  onTimeslotClick
}: Props) => {
  const [editing, setEditing] = useState(false)
  const modal = editing ? (
    <TimeslotsModal
      setTimeslots={setTimeslots}
      timeslots={timeslots}
      onClose={() => setEditing(false)}
      trainingPlanId={trainingPlanId}
      trainingPlanTrainers={trainingPlanTrainers}
      trainers={trainers}
    />
  ) : undefined

  const handleChipClick =
    onTimeslotClick && ((starts_at: Date, timezone: string) => onTimeslotClick({ starts_at, timezone }))

  const content =
    timeslots.length > 0 ? (
      <div className="gap-1 flex flex-wrap">
        {timeslots.map(t => (
          <TimeslotChip key={t.id} timeslot={t} onClick={handleChipClick} />
        ))}
      </div>
    ) : (
      <Empty
        icon={<FontAwesomeIcon icon={['fas', 'calendar-day']} size="3x" />}
        title="No Timeslots Defined"
        action={
          <button className="sw-btn btn-primary" onClick={() => setEditing(true)} aria-label="Add Timeslots">
            <FontAwesomeIcon icon={['fas', 'circle-plus']} />
            Add Timeslots
          </button>
        }
      />
    )

  return (
    <>
      <div className="flex items-baseline">
        <h4 className="grow mb-0">Timeslots</h4>
        <button className="sw-btn btn-primary btn-sm" onClick={() => setEditing(true)} aria-label="Edit Timeslots">
          <FontAwesomeIcon icon={['far', 'pen-to-square']} />
          Edit
        </button>
      </div>
      {content}
      {modal}
    </>
  )
}

export default Timeslots
