import { ChangeEventHandler, ReactNode, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { LineItem as LineItemData } from './types'
import EditCreditsForm from './edit_credits_form'
import AutoCalculateModal from './auto_calculate_modal'
import ConfirmCreditsModal from './confirm_credits_modal'
import { FlashSuccess } from '../library/flash_message'

type Props = {
  lineItem: LineItemData
  setLineItems: React.Dispatch<React.SetStateAction<Array<LineItemData>>>
  sessionCode: string
  addUserButton: ReactNode
}

const creditsNumberFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
})

const LineItem = ({ lineItem, addUserButton, sessionCode, setLineItems }: Props) => {
  const [successMessage, setSuccessMessage] = useState<string | undefined>()
  const [displayEditCreditsModal, setDisplayEditCreditsModal] = useState(false)
  const [displayAutoCalculateModal, setDisplayAutoCalculateModal] = useState(false)
  const [displayConfirmCreditsModal, setDisplayConfirmCreditsModal] = useState(false)
  const autoCalculateChanged: ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.checked) {
      setDisplayAutoCalculateModal(true)
    } else {
      setDisplayEditCreditsModal(true)
    }
  }

  return (
    <tr className={lineItem.approval_required ? 'striped-warning-border' : ''}>
      <td className="align-middle">
        <a href={lineItem.company.url}>{lineItem.company.name}</a>
      </td>
      <td className="align-middle whitespace-nowrap">
        {creditsNumberFormat.format(lineItem.credits_used)}
        {lineItem.manually_edited_credits ? (
          <a
            onClick={e => {
              e.preventDefault()
              setDisplayEditCreditsModal(true)
            }}
            className="sw-btn btn-primary btn-sm ml-2"
          >
            <FontAwesomeIcon icon={['far', 'pen-to-square']} />
            <span>Edit</span>
          </a>
        ) : null}
        {lineItem.approval_required ? (
          <a
            onClick={e => {
              e.preventDefault()
              setDisplayConfirmCreditsModal(true)
            }}
            className="sw-btn btn-primary btn-sm ml-2"
          >
            <FontAwesomeIcon icon={['fas', 'check']} />
            <span>Confirm</span>
          </a>
        ) : null}
        {displayEditCreditsModal ? (
          <EditCreditsForm lineItem={lineItem} closeModal={() => setDisplayEditCreditsModal(false)} />
        ) : null}
        {displayAutoCalculateModal ? (
          <AutoCalculateModal lineItem={lineItem} closeModal={() => setDisplayAutoCalculateModal(false)} />
        ) : null}
        {displayConfirmCreditsModal ? (
          <ConfirmCreditsModal
            lineItem={lineItem}
            sessionCode={sessionCode}
            onConfirm={newLineItems => {
              setLineItems(newLineItems)
              setDisplayConfirmCreditsModal(false)
              setSuccessMessage('Credits confirmed')
            }}
            closeModal={() => setDisplayConfirmCreditsModal(false)}
          />
        ) : null}
      </td>
      <td className="align-middle">
        <label className="sw-switch">
          <input
            type="checkbox"
            checked={!lineItem.manually_edited_credits}
            onChange={autoCalculateChanged}
            aria-label="Auto-Calculate"
          />
          <span className="sw-toggle" />
          {lineItem.manual_credits_reason ? `(${lineItem.manual_credits_reason})` : null}
        </label>
      </td>
      <td className="align-middle">
        {lineItem.team_size}

        {lineItem.team_size === 1 ? ' person' : ' people'}
      </td>
      <td>{addUserButton}</td>
      {successMessage && (
        <FlashSuccess sticky onClose={() => setSuccessMessage(undefined)}>
          {successMessage}
        </FlashSuccess>
      )}
    </tr>
  )
}

export default LineItem
