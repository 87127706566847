import { Field } from 'formik'
import SWCheckbox from '../library/sw/sw_checkbox'
import { SessionType } from './types'

type SessionTypeFieldProps = {
  session_type: SessionType | ''
  disabled?: boolean
}

const SessionTypeField = ({ session_type, disabled }: SessionTypeFieldProps) => {
  if (session_type === SessionType.Fake) {
    // this is not a formik field because this field is not actually editable (which formik doesn't really handle)
    // we just want to display to the user that the current session is a fake session
    return <SWCheckbox name="training_session[session_type]" checked={true} disabled={true} label="Fake session" />
  } else {
    return (
      <>
        <label htmlFor="session_type" className="sw-label mr-0">
          Session Type
        </label>
        <Field id="session_type" name="session_type" className="sw-select" as="select" disabled={disabled}>
          <option value={SessionType.Normal}>Normal Session</option>
          <option value={SessionType.Demo}>Demo Session</option>
          <option value={SessionType.CoachInterview}>Coach Interview</option>
          <option value={SessionType.CustomerOnboarding}>Customer Onboarding</option>
        </Field>
        {session_type === SessionType.Demo && (
          <span className="form-input-hint">
            Demo sessions are excluded from statistics. Use this for sessions that won&apos;t contribute towards any
            customer coaching plan
          </span>
        )}
        {session_type === SessionType.CoachInterview && (
          <span className="form-input-hint">
            Use for interviewing prospective coaches (regardless of whether the prospect is joining as a leaner or a
            coach)
          </span>
        )}
        {session_type === SessionType.CustomerOnboarding && (
          <span className="form-input-hint">
            Use for onbording sessions with learners who are new to Skiller Whale.
          </span>
        )}
      </>
    )
  }
}

export default SessionTypeField
