import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { CoachingPlanChannelData, CoachingPlanViewer } from '../../generated_types/channels'
import { CurrentUserContext } from '../../utils/current_user_data'
import { useCoachingPlanChannel } from './use_coaching_plan_channel'

type PresenceProviderProps = PropsWithChildren<{
  scheduler: boolean
}>

type PresenceContextData = {
  viewers: CoachingPlanViewer[] | undefined
}

const PresenceContext = createContext<PresenceContextData | null>(null)

// The time in ms after a ping that a user is considered no longer present
const PRESENCE_THRESHOLD = 10000

const PresenceProvider = ({ scheduler, children }: PresenceProviderProps) => {
  const { addListener, removeListener, channel } = useCoachingPlanChannel()
  const currentUser = useContext(CurrentUserContext)
  const [viewers, setViewers] = useState<CoachingPlanViewer[]>()

  useEffect(() => {
    const handler = (event: CoachingPlanChannelData) => {
      if (event.type === 'presence_ping') {
        if (channel) {
          channel.perform('presence_pong', { mode: scheduler ? 'scheduler' : 'editor' })
        }
      } else if (event.type === 'presence') {
        if (event.payload.user.id !== currentUser?.id) {
          setViewers(existing => {
            const updated = existing ? [...existing] : []
            const existingIndex = updated.findIndex(v => v.user.id === event.payload.user.id)
            if (existingIndex >= 0) {
              //replace existing user
              updated.splice(existingIndex, 1, event.payload)
            } else {
              updated.push(event.payload)
            }

            return updated
          })
        }
      } else if (event.type === 'disconnected') {
        setViewers(existing => {
          if (!existing) return

          return existing.filter(viewer => viewer.user.id !== event.payload.user_id)
        })
      }
    }
    addListener(handler)
    return () => removeListener(handler)
  }, [scheduler, channel, addListener, removeListener, currentUser])

  useEffect(() => {
    const timer = setInterval(() => {
      setViewers(current => {
        if (!current) return
        const updated = current.filter(v => v.ts >= Date.now() - PRESENCE_THRESHOLD)
        return updated.length === current.length ? current : updated
      })
    }, 5000)
    return () => clearInterval(timer)
  }, [])

  return <PresenceContext.Provider value={{ viewers }}>{children}</PresenceContext.Provider>
}

const usePresence = () => {
  const data = useContext(PresenceContext)
  if (!data) {
    throw new Error('usePresence requires a PresenceProvider')
  }
  return data
}

export { PresenceProvider }
export default usePresence
