/**
 * Returns the value of a URL parameter
 * Will throw if the parameter value can not be decoded
 */
export const getUrlParam = (name: string) => {
  const params = new URLSearchParams(window.location.search)
  const results = params.get(name)
  if (results) return decodeURIComponent(results.replace(/\+/g, ' '))
  return ''
}

/**
 * Updates a url parameter in the browser URL,
 * pushing to the history without reloading the page
 */
export const setUrlParam = (name: string, value: string) => {
  const url = new URL(window.location.href)
  url.searchParams.set(name, value)
  window.history.pushState(null, '', url.toString())
}

/**
 * Remove a url parameter from the browser URL,
 * pushing to the history without reloading the page
 */
export const removeUrlParam = (name: string) => {
  const url = new URL(window.location.href)
  url.searchParams.delete(name)
  window.history.pushState(null, '', url.toString())
}

/**
 * Tries to parse the value of a URL parameter to a number
 * Returns undefined if no or non-numeric value
 */
export const getUrlNumberParam = (name: string) => {
  const param = getUrlParam(name)
  if (param) {
    const maybeNumber = Number(param)
    if (isFinite(maybeNumber)) return maybeNumber // Also checks for NaN
  }
}
