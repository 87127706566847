import { useState, useCallback, MouseEventHandler, ChangeEventHandler, useRef } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { CommentData, PersistedComment } from './types'
import useComment from './use_comment'

const numberOfLines = (string: string) => {
  if (string === '') {
    return 0
  }
  return string.split('\n').length
}

type Props = {
  onCancelEdit?: () => void
  onCommentPersisted: (c: PersistedComment) => void
  comment: CommentData
  rows?: number
}

const Composer = ({ onCommentPersisted, comment, onCancelEdit, rows }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [body, setBody] = useState(comment.body)
  const [focussed, setFocussed] = useState(false)
  const { submitting, updateComment } = useComment({
    comment,
    onUpdate: (comment: PersistedComment) => {
      setBody('')
      onCommentPersisted(comment)
      setFocussed(false)
    }
  })

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(event => setBody(event.target.value), [])
  const handleClick: MouseEventHandler = useCallback(
    async event => {
      event.preventDefault()
      event.stopPropagation()

      updateComment({ body: body })
    },
    [body, updateComment]
  )

  const buttonClasses = `sw-btn btn-primary btn-sm ml-auto${submitting ? ' sw-loading' : ''}`

  const buttonLabel =
    'id' in comment ? (
      <>
        Update
        <FontAwesomeIcon icon={['far', 'floppy-disk']} />
      </>
    ) : (
      <>
        Add
        <FontAwesomeIcon icon={['far', 'comment-dots']} />
      </>
    )

  if (!rows) {
    rows = Math.max(focussed ? 2 : 1, numberOfLines(body || '') + 1)
  }

  return (
    <div ref={containerRef}>
      <textarea
        value={body}
        onChange={handleChange}
        className="sw-input w-full min-h-fit mb-1"
        rows={rows}
        onFocus={() => setFocussed(true)}
        // setFocussed to false if focus is going to somewhere outside the composer.
        // in particular, don't clear focussed if one of our buttons are gaining focus
        onBlur={blurEvent =>
          !(blurEvent.relatedTarget && containerRef.current?.contains(blurEvent.relatedTarget)) && setFocussed(false)
        }
      />
      <div className="flex">
        {!!onCancelEdit && (
          <button disabled={submitting} onClick={onCancelEdit} className="sw-btn btn-sm">
            <FontAwesomeIcon icon={['far', 'rectangle-xmark']} />
            Cancel
          </button>
        )}
        {(focussed || submitting || onCancelEdit) && (
          <button disabled={submitting || body === comment.body} onClick={handleClick} className={buttonClasses}>
            {buttonLabel}
          </button>
        )}
      </div>
    </div>
  )
}

export default Composer
