import { useState } from 'react'
import { TrainingSessionSummaryProps } from '../generated_types/components'
import TrainingSessionPagination from './pagination'
import { formatDate } from './utils'

const TrainingSessionSummaries = ({
  training_sessions: initialTrainingSessions,
  pagination: initialPagination,
  user_id
}: TrainingSessionSummaryProps) => {
  const [trainingSessions, setTrainingSessions] = useState(initialTrainingSessions)
  const [pagination, setPagination] = useState(initialPagination)

  return (
    <>
      {pagination && user_id && (
        <TrainingSessionPagination
          pagination={pagination}
          setPagination={setPagination}
          setTrainingSessions={setTrainingSessions}
          url={`/users/${user_id}/past_sessions`}
        />
      )}
      <table className="sw-table w-full">
        <thead>
          <tr>
            <th>Date</th>
            <th>Code</th>
            <th>Coach</th>
            <th>Modules</th>
          </tr>
        </thead>
        <tbody>
          {trainingSessions.map(t => (
            <tr key={t.code} className={t.cancelled ? 'line-through' : ''}>
              <td>{formatDate(t.start)}</td>
              <td className="whitespace-nowrap">{t.edit_url ? <a href={t.edit_url}>{t.code}</a> : t.code}</td>
              <td>{t.coach.name}</td>
              <td>
                {t.modules.map(m => `${m.curriculum_title}: ${m.module_title}`).join(', ')}
                {t.cancelled && ' (Cancelled)'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default TrainingSessionSummaries
