import React, { createContext, PropsWithChildren, ReactNode } from 'react'
import Flags from './feature_flags'

export type FeatureFlags = {
  [flag in Flags]?: boolean
}

export const FeatureFlagsContext = createContext<FeatureFlags>({})

interface FeatureFlagsProviderProps extends PropsWithChildren {
  flags: FeatureFlags
}

export const FeatureFlagsProvider = ({ flags, children }: FeatureFlagsProviderProps) => (
  <FeatureFlagsContext.Provider value={flags}>{children}</FeatureFlagsContext.Provider>
)

export const withFeatureFlags = (component: ReactNode, flags: FeatureFlags) => (
  <FeatureFlagsProvider flags={flags}>{component}</FeatureFlagsProvider>
)

export default FeatureFlagsProvider
