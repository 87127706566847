type Key = string | number | symbol

type ObjectHelper = <T, K extends Key>(key: K, value: T, object: Record<K, T>) => Record<K, T> | Omit<Record<K, T>, K>

type ObjectHelperRemove = <T, K extends Key>(key: K, object: Record<K, T>) => Omit<Record<K, T>, K>

export const objectAdd: ObjectHelper = (key, value, object) => {
  return { ...object, [key]: value }
}

export const objectRemove: ObjectHelperRemove = (key, object) => {
  // use destructuring over delete keyword, to insure return of new object
  const { [key]: _discarded, ...rest } = object
  return rest
}

/**
 * Either removes the value at key if present, or adds it if not,
 * returning the new object
 */
export const objectAddOrRemove: ObjectHelper = (key, value, object) => {
  if (object[key]) return objectRemove(key, object)
  return objectAdd(key, value, object)
}

export const objectRemoveNulls = <T extends object>(object: T): Partial<T> => {
  const entriesWithoutNulls = Object.entries(object).filter(([_key, value]) => value !== null)
  return Object.fromEntries(entriesWithoutNulls) as Partial<T>
}

export const objectShallowEquals = <T extends object>(firstObject?: T, secondObject?: T): boolean => {
  if (firstObject === secondObject) return true
  if (!firstObject || !secondObject) return false
  let isEquals = true
  Object.getOwnPropertyNames({ ...firstObject, ...secondObject }).forEach(propertyName => {
    if (firstObject[propertyName] !== secondObject[propertyName]) isEquals = false
  })
  return isEquals
}
