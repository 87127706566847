import React, { ReactNode, useState } from 'react'
import Empty from '../library/empty'
import Pause from './pause'
import PauseFormModal from './pause_form_modal'
import DeletePauseModal from './delete_pause_modal'

import { apiRequest } from '../utils/json_request'
import { CreateOrUpdateSnoozeResponse, GetSnoozesResponse, Snooze as PauseData } from '../generated_types/training_plan'
import { EditablePause, PauseFormData } from './types'
import { ModelErrors } from '../library/errors'
import { CreateSnoozeData, SnoozesQuery, UpdateSnoozeData } from '../utils/api/types'
import { TrainingPlanContext, UserContext } from './types'
import Toggle from '../library/toggle'
import Pagination from '../library/pagination'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import OverlappingCoachSessionsModal from './overlapping_coach_sessions_modal'
import DeclineOverlappingCoachSessionsModal from './decline_overlapping_coach_sessions_modal'

const dateISOString = (date: Date) => date.toISOString().slice(0, 10) //extract the YYYY-MM-DD oart

type Props = {
  pauses: PauseData[]
  context: TrainingPlanContext | UserContext
  showPlanPicker: boolean
  editable: boolean
  title: ReactNode
  subtitle?: ReactNode
}

type PauseAction = (s: PauseData) => void

const updatePausePayload = (data: PauseFormData): UpdateSnoozeData => {
  const { user_id, ...result } = createPausePayload(data)
  return result
}
const createPausePayload = ({ id, training_plan_ids, ...rest }: PauseFormData): CreateSnoozeData => {
  const data = {
    training_plan_ids: training_plan_ids.map(s => parseInt(s)),
    ...rest
  }
  if (data.global) {
    data.training_plan_ids = []
  }
  return data
}

const Pauses = ({ pauses: initialPauses, context, showPlanPicker, editable, title, subtitle }: Props) => {
  const [editing, setEditing] = useState<EditablePause | null>(null)
  const [deleting, setDeleting] = useState<PauseData | null>(null)
  const [pauses, setPauses] = useState(initialPauses)
  const [pausesPagination, setPausesPagination] = useState<GetSnoozesResponse['pagination'] | undefined>()
  const [showHistorical, setShowHistorical] = useState(false)
  const [overlappingCoachSessions, setOverlappingCoachSessions] =
    useState<CreateOrUpdateSnoozeResponse['overlapping_sessions_as_coach']>()

  const forSingleUser = 'user' in context
  const filter = forSingleUser ? {} : { training_plan_id: context.trainingPlanId }

  const newPauseAttributes = forSingleUser
    ? { user_id: context.user.id, global: true }
    : { training_plan_ids: [context.trainingPlanId], global: false }
  const openAddPauseModal = () => {
    setEditing({
      user_id: undefined,
      start_date: dateISOString(new Date()),
      end_date: undefined,
      description: '',
      ...newPauseAttributes
    })
  }

  const openEditPauseModal: PauseAction = pause => {
    setEditing(pause)
  }

  const openDeletePauseModal: PauseAction = pause => {
    setDeleting(pause)
  }

  const displayable = forSingleUser ? pauses : pauses.filter(pause => context.users[pause.user_id])

  const loadPauses = async ({ page, include_expired }: { page: number; include_expired: boolean }) => {
    const query: SnoozesQuery = forSingleUser
      ? {
          user_id: context.user.id
        }
      : { training_plan_id: context.trainingPlanId }

    query.include_expired = include_expired
    if (include_expired) {
      query.per_page = 20
      query.page = page
    }
    const response = await apiRequest('/snoozes', { method: 'GET', payload: undefined, query })
    if (response.ok) {
      setPauses(response.data.snoozes)
      setPausesPagination(response.data.pagination)
    }
  }

  const showHistoricalChanged = (newValue: boolean) => {
    setShowHistorical(newValue)
    loadPauses({ page: 1, include_expired: newValue })
  }

  const pausesTable =
    displayable.length > 0 ? (
      <>
        <table className="sw-table w-full bg-white">
          <thead>
            <tr>
              {!forSingleUser && <th>User</th>}
              <th>Start</th>
              <th>End</th>
              <th>Comment</th>
              {editable && (
                <th className="text-end">
                  <button onClick={openAddPauseModal} className="sw-btn btn-secondary btn-icon" aria-label="Add Pause">
                    <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
                  </button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {displayable.map(pause => (
              <Pause
                pause={pause}
                user={forSingleUser ? undefined : context.users[pause.user_id]}
                key={pause.id}
                onEdit={openEditPauseModal}
                onDelete={openDeletePauseModal}
                editable={editable}
              />
            ))}
          </tbody>
        </table>
        {pausesPagination && (
          <Pagination
            {...pausesPagination}
            onPageChange={n => loadPauses({ page: n, include_expired: showHistorical })}
            classNames="justify-center gap-6"
          />
        )}
      </>
    ) : (
      <Empty
        title={`No active pauses`}
        action={
          editable && (
            <button onClick={openAddPauseModal} className="sw-btn btn-primary">
              <FontAwesomeIcon icon={['fas', 'circle-plus']} />
              Add Pause
            </button>
          )
        }
        icon={<FontAwesomeIcon icon={['far', 'circle-pause']} size="3x" />}
      />
    )

  let addPauseModal: ReactNode = null

  const [addPauseErrors, setPauseErrors] = useState<ModelErrors>({})

  if (editing) {
    const handlePauseSubmit = async (formData: PauseFormData) => {
      const { id } = formData

      const payload = {
        ...filter,
        snooze: id ? updatePausePayload(formData) : createPausePayload(formData)
      }

      const response = id
        ? await apiRequest(`/snoozes/${id}`, { method: 'PATCH', payload })
        : await apiRequest('/snoozes', { method: 'POST', payload })

      if (response.ok) {
        setPauses(response.data.snoozes)
        setPauseErrors({})
        setOverlappingCoachSessions(response.data.overlapping_sessions_as_coach)
        setEditing(null)
      } else {
        setPauseErrors(response.errors)
      }
    }

    addPauseModal = (
      <PauseFormModal
        context={context}
        pause={editing}
        errors={addPauseErrors}
        onCancel={() => {
          setPauseErrors({})
          setEditing(null)
        }}
        showPlanPicker={showPlanPicker}
        onSubmit={handlePauseSubmit}
      />
    )
  }

  let deletePauseModal: ReactNode = null

  const [deleteErrors, setDeleteErrors] = useState<ModelErrors>({})

  if (deleting) {
    const handleDeleteSubmit = async (snooze: PauseData) => {
      const response = await apiRequest(`/snoozes/${snooze.id}`, {
        method: 'DELETE',
        payload: filter
      })
      if (response.ok) {
        setPauses(response.data.snoozes)
        setDeleting(null)
      } else {
        setDeleteErrors(response.errors)
      }
    }

    deletePauseModal = (
      <DeletePauseModal
        pause={deleting}
        user={forSingleUser ? context.user : { is_self: false, ...context.users[deleting.user_id] }}
        errors={deleteErrors}
        onCancel={() => {
          setDeleteErrors({})
          setDeleting(null)
        }}
        onSubmit={handleDeleteSubmit}
      />
    )
  }

  let overlappingCoachSessionsModal: ReactNode = null
  if (overlappingCoachSessions && overlappingCoachSessions.session_details.length > 0) {
    overlappingCoachSessionsModal = overlappingCoachSessions.can_edit_sessions ? (
      <OverlappingCoachSessionsModal
        sessionDetails={overlappingCoachSessions.session_details}
        onClose={() => setOverlappingCoachSessions(undefined)}
      />
    ) : (
      <DeclineOverlappingCoachSessionsModal
        sessionDetails={overlappingCoachSessions.session_details}
        onClose={() => setOverlappingCoachSessions(undefined)}
        snoozeId={overlappingCoachSessions.snooze_id}
      />
    )
  }

  return (
    <>
      <div className="flex">
        <div className="grow">{title}</div>
        <Toggle checked={showHistorical} onChange={showHistoricalChanged} label="Show Historical" classNames="mb-3" />
      </div>
      {subtitle}
      {pausesTable}
      {editing && addPauseModal}
      {deleting && deletePauseModal}
      {overlappingCoachSessionsModal}
    </>
  )
}

export default Pauses
