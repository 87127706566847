import { ReactElement, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { ModelErrors } from '../library/errors'
import { apiRequest, Response } from '../utils/json_request'
import { ChangeRequest, CreateOrUpdateChangeRequestResponse, PlannedSession } from '../generated_types/user_plan_viewer'

import ResponseIcon from './response_icon'
import ChangeRequestDropdownItem from './change_request_dropdown_item'
import { UserSessionStatus } from './session_status'
import { textifyErrors } from '../utils/error_helpers'

type ChangeRequestDropdownProps = {
  session: PlannedSession
  sessionStatus: UserSessionStatus
  readonly: boolean
}

const ChangeRequestDropdown = ({ session, sessionStatus, readonly }: ChangeRequestDropdownProps) => {
  const [currentRequest, setCurrentRequest] = useState(session.change_request)
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState<ModelErrors | undefined>()

  const request = currentRequest?.requested_state
  const response = currentRequest?.response

  const onChangeRequest = async (change?: ChangeRequest['requested_state']) => {
    setSaving(true)

    const result = await requestChange(change)
    if (result?.ok) setErrors(undefined)
    else setErrors(result?.errors)

    setSaving(false)
  }

  const requestChange = async (change?: ChangeRequest['requested_state']) => {
    if (!change && currentRequest) {
      const result = await deleteRequest(currentRequest.id)
      if (result.ok) setCurrentRequest(undefined)
      return result
    }

    if (!change || !session.id) return

    let result: Response<CreateOrUpdateChangeRequestResponse>

    if (currentRequest) {
      if (currentRequest.response === 'approved') {
        const deleteResult = await deleteRequest(currentRequest.id)

        if (deleteResult?.ok) result = await createRequest(session.id, change)
        else return deleteResult
      } else {
        result = await updateRequest(currentRequest.id, change)
      }
    } else {
      result = await createRequest(session.id, change)
    }

    if (result.ok) setCurrentRequest(result.data.change_request)
    return result
  }

  const createRequest = async (sessionId: number, change: ChangeRequest['requested_state']) =>
    await apiRequest(`/change_requests`, {
      payload: {
        change_request: { planned_session_id: sessionId, requested_state: change }
      },
      method: 'POST'
    })

  const updateRequest = async (requestId: number, change: ChangeRequest['requested_state']) =>
    await apiRequest(`/change_requests/${requestId}`, {
      method: 'PATCH',
      payload: {
        change_request: { requested_state: change }
      }
    })

  const deleteRequest = async (requestId: number) =>
    await apiRequest(`/change_requests/${requestId}`, {
      method: 'DELETE',
      payload: undefined
    })

  const dropdownContent =
    request === 'would_like' ? (
      <div className="truncate">
        <span className="w-8 font-semibold">Yes</span> - I would like to learn this
      </div>
    ) : request === 'dont_need' ? (
      <div className="truncate">
        <span className="w-8 font-semibold">No</span> - I don&apos;t think I need this
      </div>
    ) : (
      <div className="w-full text-center">
        <FontAwesomeIcon icon={['fas', 'minus']} className="text-lightergrey" />
      </div>
    )

  /**
   * For Managers
   */
  if (readonly) {
    const responseTooltip =
      response === 'approved' ? `\nIt was approved` : response === 'rejected' ? `\nIt was rejected` : ''
    if (request)
      return (
        <InactiveDropdown
          content={dropdownContent}
          tooltip={`This is the change\nrequested by the Learner${responseTooltip}`}
          response={response}
        />
      )
    else return dropdownContent
  }

  /**
   * Rejected Requests
   */
  if (response === 'rejected') {
    const tooltip = `A manager at your company has seen\n this feedback, ${
      request === 'would_like'
        ? `but thinks you don't need\nthis session in your plan.`
        : `but asked for the session\nto remain in your plan.`
    }`
    return <InactiveDropdown content={dropdownContent} tooltip={tooltip} response={response} />
  }

  const commonItemProps = {
    currentRequestState: request,
    onChoose: onChangeRequest
  }

  let menuOptions = <></>
  if (request)
    menuOptions = (
      <>
        <ChangeRequestDropdownItem requestState="would_like" {...commonItemProps} />
        <ChangeRequestDropdownItem requestState="dont_need" {...commonItemProps} />
      </>
    )
  else if (sessionStatus === UserSessionStatus.InPlan || sessionStatus === UserSessionStatus.Scheduled)
    menuOptions = (
      <>
        <ChangeRequestDropdownItem requestState="dont_need" {...commonItemProps} />
        <ChangeRequestDropdownItem explainer="No change, this looks great" {...commonItemProps} />
      </>
    )
  else if (sessionStatus === UserSessionStatus.Optional)
    menuOptions = (
      <>
        <ChangeRequestDropdownItem requestState="would_like" {...commonItemProps} />
        <ChangeRequestDropdownItem requestState="dont_need" {...commonItemProps} />
        <ChangeRequestDropdownItem {...commonItemProps} />
      </>
    )
  else if (sessionStatus === UserSessionStatus.NotNeeded)
    menuOptions = (
      <>
        <ChangeRequestDropdownItem requestState="would_like" {...commonItemProps} />
        <ChangeRequestDropdownItem explainer="No change, I don't need this" {...commonItemProps} />
      </>
    )
  // NotInPlan (Explicitly Hidden) or Completed
  else {
    return (
      <InactiveDropdown
        content={dropdownContent}
        tooltip={`A change request can not be\ncreated for this session`}
        response={response}
      />
    )
  }

  const errorIcon = errors ? (
    <div
      className="shrink-0 sw-tooltip"
      data-tooltip={`Error Saving:\n${textifyErrors(errors)}\n\nPlease refresh the page and try again`}
    >
      <FontAwesomeIcon icon={['fas', 'circle-exclamation']} size="lg" className="text-error-border" />
    </div>
  ) : undefined

  const responseTooltip =
    response === 'approved' ? `Your feedback has been reviewed and\nthe session updated in your plan` : undefined
  const dropdownTriggerClasses = `sw-btn btn-white dropdown-trigger${saving ? ' sw-loading' : ''}`

  return (
    <div className="dropdown-wrapper">
      {errorIcon}
      <div className="sw-dropdown dropdown-r change-dropdown">
        <button className={dropdownTriggerClasses} onClick={event => event.stopPropagation()} tabIndex={0}>
          {dropdownContent}
          <FontAwesomeIcon icon={['fas', 'chevron-down']} className="dropdown-chevron" />
        </button>
        {!saving && <ul className="sw-menu">{menuOptions}</ul>}
      </div>
      <ResponseIcon response={response} tooltip={responseTooltip} />
    </div>
  )
}

export default ChangeRequestDropdown

type InactiveDropdownProps = {
  content: ReactElement
  tooltip?: string
  response?: ChangeRequest['response']
}

const InactiveDropdown = ({ content, tooltip, response }: InactiveDropdownProps) => {
  return (
    <div className={`dropdown-wrapper${tooltip ? ' sw-tooltip after:text-center' : ''}`} data-tooltip={tooltip}>
      <div className="inactive-dropdown">{content}</div>
      {response && <ResponseIcon response={response} />}
    </div>
  )
}
