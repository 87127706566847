import { Dispatch, SetStateAction } from 'react'
import { Schedulers, Timeslot, TrainingPlanUsers } from '../../generated_types/training_plan'
import Pauses from '../../pauses/pauses'
import TrainingPlanTrainers from '../training_plan_trainers/training_plan_trainers'
import { AdminTrainingPlan, AvailableTrainers, Snooze, TimeslotClickedFunction, TrainingPlanTrainer } from '../types'
import SchedulingNotes from './scheduling_notes'
import Timeslots from './timeslots'
import Scheduler from './scheduler'
import PlanFrequency from './plan_frequency'
import AutoStartHostedEnvironments from './auto_start_hosted_environments'
import useScheduler from '../session_editor/scheduler_panel/use_scheduler'
import { formatInTimeZone } from 'date-fns-tz'
import InviteOptional from './invite_optional'

type Props = {
  trainingPlan: AdminTrainingPlan
  trainers: AvailableTrainers
  trainingPlanTrainers: TrainingPlanTrainer[]
  setTrainingPlanTrainers: Dispatch<SetStateAction<TrainingPlanTrainer[]>>
  setTimeslots: Dispatch<SetStateAction<Timeslot[]>>
  pauses?: Snooze[]
  schedulers: Schedulers
  setSchedulerId: Dispatch<SetStateAction<number | null>>
  setFrequencyInWeeks: Dispatch<SetStateAction<number | null>>
  setAutoStartHostedEnvironments: Dispatch<SetStateAction<boolean>>
  setInviteOptional: Dispatch<SetStateAction<boolean>>
  availableLearners: TrainingPlanUsers
}
const Scheduling = ({
  trainingPlan,
  trainers,
  trainingPlanTrainers,
  setTrainingPlanTrainers,
  pauses,
  schedulers,
  setTimeslots,
  setSchedulerId,
  setFrequencyInWeeks,
  setAutoStartHostedEnvironments,
  setInviteOptional,
  availableLearners
}: Props) => {
  const { onSchedulerDateChange, setSelectedTrainerId } = useScheduler()
  const onTimeslotClick: TimeslotClickedFunction = async ({ starts_at, timezone, coach_id }) => {
    onSchedulerDateChange({
      start_time: formatInTimeZone(starts_at, timezone, 'HH:mm'),
      start_date: formatInTimeZone(starts_at, timezone, 'yyyy-MM-dd')
    })
    if (coach_id) {
      setSelectedTrainerId(coach_id)
    }
  }

  return (
    <div className="sw-card mt-4 mb-10">
      <h3 className="sw-header header-wavylines h-16 mt-0 text-2xl">Scheduling</h3>
      <div className="flex flex-wrap gap-y-4">
        <div className="flex flex-col basis-full xl:basis-5/12 grow gap-4">
          <Scheduler
            schedulers={schedulers}
            trainingPlanId={trainingPlan.id}
            schedulerId={trainingPlan.scheduler_id}
            setSchedulerId={setSchedulerId}
          />

          <PlanFrequency
            trainingPlanId={trainingPlan.id}
            frequencyInWeeks={trainingPlan.frequency_in_weeks}
            setFrequencyInWeeks={setFrequencyInWeeks}
          />

          <AutoStartHostedEnvironments
            trainingPlanId={trainingPlan.id}
            autoStartHostedEnvironments={trainingPlan.auto_start_hosted_environments}
            setAutoStartHostedEnvironments={setAutoStartHostedEnvironments}
          />

          <InviteOptional
            trainingPlanId={trainingPlan.id}
            inviteOptional={trainingPlan.invite_optional}
            setInviteOptional={setInviteOptional}
          />
          <div className="sw-divider mb-0" />
          <SchedulingNotes trainingPlan={trainingPlan} />
        </div>
        <div className="sw-divider-vert hidden xl:block" />
        <div className="flex flex-col basis-full xl:basis-5/12 grow gap-4">
          <div className="sw-divider mb-0 xl:hidden" />
          <Timeslots
            timeslots={trainingPlan.timeslots}
            trainingPlanTrainers={trainingPlanTrainers}
            trainers={trainers}
            setTimeslots={setTimeslots}
            trainingPlanId={trainingPlan.id}
            onTimeslotClick={onTimeslotClick}
          />
          <div className="sw-divider mb-0" />

          <TrainingPlanTrainers
            trainers={trainers}
            trainingPlanId={trainingPlan.id}
            trainingPlanTrainers={trainingPlanTrainers}
            setTrainingPlanTrainers={setTrainingPlanTrainers}
            timeslots={trainingPlan.timeslots}
            onTimeslotClick={onTimeslotClick}
          />
        </div>
      </div>
      {pauses && (
        <div className="mt-8 sw-box">
          <details className="sw-accordion simple" id="snoozes">
            <summary>
              <h4 className="m-0">Pauses</h4>
            </summary>
            <div className="accordion-content">
              <Pauses
                title={null}
                pauses={pauses}
                context={{
                  trainingPlanId: trainingPlan.id,
                  companyId: trainingPlan.company_id,
                  users: availableLearners,
                  userIds: trainingPlan.learners.map(l => l.user_id)
                }}
                showPlanPicker={true}
                editable={true}
              />
            </div>
          </details>
        </div>
      )}
    </div>
  )
}

export default Scheduling
