import { Formik, Form } from 'formik'
import qs from 'qs'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { MoveToPlanPageProps, TrainingPlanUser } from './types'
import PlanPicker from '../plan_picker/plan_picker'

type FormValues = {
  training_plan_id?: string
  training_plan_user: TrainingPlanUser
}

const redirectToPlanPreview = (values: FormValues) => {
  const planURL = `/plans/${values.training_plan_id}`

  const data = {
    training_plan: {
      move_from_training_plan_user_ids: [values.training_plan_user.id]
    }
  }
  window.location.assign(`${planURL}?${qs.stringify(data, { arrayFormat: 'brackets' })}`)
}

const MoveToPlan = ({ training_plans, training_plan_user }: MoveToPlanPageProps) => {
  const initialValues: FormValues = { training_plan_user }

  const annotatedPlans = training_plans.map(tp => {
    if (tp.id == training_plan_user.training_plan_id) {
      const trainingPlanName = tp.name ? `${tp.name} (Current)` : 'This Current Plan'
      return { ...tp, name: trainingPlanName, disabled: true }
    } else {
      return { ...tp, name: tp.name ?? 'Unnamed Plan' }
    }
  })

  // Check there's at least one non-disabled plan
  const destinationExists = !!annotatedPlans.find(p => !('disabled' in p && p.disabled === true))

  return (
    <Formik initialValues={initialValues} onSubmit={redirectToPlanPreview}>
      {({ isSubmitting, values }) => (
        <Form>
          {!destinationExists && (
            <div className="sw-toast warning">
              No active plans that can be moved to - perhaps you want to create a new coaching plan first?
            </div>
          )}
          <PlanPicker trainingPlans={annotatedPlans} />
          {values.training_plan_id && (
            <div className="my-8 text-right">
              <button type="submit" className="sw-btn btn-primary" disabled={isSubmitting}>
                <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
                Preview
                <FontAwesomeIcon icon={['fas', 'chevron-right']} />
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default MoveToPlan
