import { ChangeRequestReviewable, ReviewModules } from '../generated_types/change_requests'
import { GridColumnConfig, UpdateChangeRequest } from './types'

type ChangeRequestsGridProps = {
  changeRequests: ChangeRequestReviewable[]
  modules: ReviewModules
  columnConfig: GridColumnConfig
  updateSingleRequest: UpdateChangeRequest
}

const ChangeRequestsGrid = ({
  changeRequests,
  columnConfig,
  modules,
  updateSingleRequest
}: ChangeRequestsGridProps) => {
  return (
    <div className="cr-layout" role="table">
      <div role="row" className="contents">
        {columnConfig.map((column, index) => (
          <div key={index} role="columnheader" className={`cr-header ${column.classNames}`}>
            {column.header}
          </div>
        ))}
      </div>
      {changeRequests.map((change_request, crIndex) => (
        <div key={crIndex} role="row" className="contents">
          {columnConfig.map((column, colIndex) => (
            <div key={`${crIndex}-${colIndex}`} role="cell" className={`cr-cell ${column.classNames}`}>
              <column.CellContent
                changeRequest={change_request}
                moduleData={modules[change_request.module_key]}
                updateSingleRequest={updateSingleRequest}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default ChangeRequestsGrid
