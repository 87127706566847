import { PropsWithChildren } from 'react'
import { PropsWithClassNames } from '../../utils/types'
import { collectErrorMessages, FieldErrors } from '../errors'

interface SWFormGroupProps extends PropsWithChildren, PropsWithClassNames {
  errors?: FieldErrors
}

/**
 * Version of library/form_group using sw-* classes
 */
const SWFormGroup = ({ children, classNames = '', errors }: SWFormGroupProps) => {
  let className = `sw-group ${classNames}`
  let error = ''

  if (errors && errors.length > 0) {
    className += ' has-error'
    error = collectErrorMessages(errors).join(', ')
  }

  return (
    <div className={className}>
      {children}
      {error ? <p className="form-input-hint">{error}</p> : null}
    </div>
  )
}

export default SWFormGroup
