import { Dispatch, SetStateAction } from 'react'
import CoachingPlanAttributeEditor from './coaching_plan_attribute_editor'

type Props = {
  trainingPlanId: number
  inviteOptional: boolean
  setInviteOptional: Dispatch<SetStateAction<boolean>>
}

const InviteOptional = ({ trainingPlanId, inviteOptional, setInviteOptional }: Props) => {
  return (
    <div className="flex flex-wrap items-center justify-end gap-x-2 gap-y-1">
      <label
        className="basis-72 text-base font-semibold mr-auto sw-tooltip"
        htmlFor="inviteOptional"
        data-tooltip={'Set to "yes" if optional cells (yellow)\nshould be invited to sessions.'}
      >
        Invite Optional: ℹ️
      </label>

      <CoachingPlanAttributeEditor
        trainingPlanId={trainingPlanId}
        attribute="invite_optional"
        currentValue={inviteOptional}
        onChange={setInviteOptional}
        saveLabel="Update Invite Optional"
        editLabel="Edit Invite Optional"
        formattedValue={inviteOptional ? 'yes' : 'no'}
      >
        {({ value, setValue }) => (
          <select
            id="inviteOptional"
            className="sw-select"
            value={value ? '1' : '0'}
            onChange={e => setValue(e.target.value === '1')}
          >
            <option value="0">no</option>
            <option value="1">yes</option>
          </select>
        )}
      </CoachingPlanAttributeEditor>
    </div>
  )
}

export default InviteOptional
