import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { ChangeRequestWithReview } from '../generated_types/change_requests'
import { PropsWithClassNames } from '../utils/types'

type ChangeRequestIconProps = PropsWithClassNames & {
  changeRequest: ChangeRequestWithReview
  size?: SizeProp
}

const ChangeRequestIcon = ({ changeRequest, size, classNames = '' }: ChangeRequestIconProps) => {
  const iconName = changeRequest.requested_state === 'would_like' ? 'thumbs-up' : 'thumbs-down'

  if (changeRequest.response)
    return <FontAwesomeIcon icon={['fas', iconName]} className={['text-midgrey', classNames].join(' ')} size={size} />

  const colourClass = changeRequest.requested_state === 'would_like' ? 'text-darkerseagreen' : 'text-snapperred'
  return <FontAwesomeIcon icon={['fas', iconName]} className={[colourClass, classNames].join(' ')} size={size} />
}

export default ChangeRequestIcon
