import { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'
import { ExistingTrainingSession } from './types'

// The title is currently not in the main react hierarchy on the edit session page,
// so we use a portal to populate it
const TitleContainer = ({ children }: PropsWithChildren) => {
  const container = document.getElementById('title')

  if (container) {
    return ReactDOM.createPortal(children, container)
  } else {
    return null
  }
}

type TitleProps = { trainingSession: ExistingTrainingSession }

const Title = ({ trainingSession }: TitleProps) => (
  <h1>
    Coaching Session: <span className="text-midgrey"> {trainingSession.code}</span>
    {trainingSession.cancelled && ' (Cancelled)'}
  </h1>
)

export default Title
export { TitleContainer }
