import { MouseEventHandler, useState } from 'react'
import ConfirmationModal, { ConfirmationModalProps } from './confirmation_modal'

type ConfirmationWithReasonModalProps = Omit<ConfirmationModalProps, 'onConfirm'> & {
  onConfirm: (reason: string) => Promise<void> | void
}

const ConfirmationWithReasonModal = ({ message, onConfirm, ...others }: ConfirmationWithReasonModalProps) => {
  const [reason, setReason] = useState('')

  const handleClick: MouseEventHandler = e => {
    e.stopPropagation()
    e.preventDefault()
    onConfirm(reason)
  }

  const modalMessage = (
    <>
      {message}
      <input value={reason} onChange={e => setReason(e.target.value)} className="sw-input w-full" />
    </>
  )

  return <ConfirmationModal message={modalMessage} onConfirm={handleClick} {...others} />
}

export default ConfirmationWithReasonModal
