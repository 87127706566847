import { MouseEventHandler } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { LearnerModuleAccessData } from '../generated_types/learner_module_accesses'
import { longDateFormat } from '../utils/date_helpers'

type Props = {
  learner_module_access: LearnerModuleAccessData
  onDelete: (toDelete: LearnerModuleAccessData) => void
  module_titles: Record<string, string>
}

const LearnerModuleAccess = ({ learner_module_access, onDelete, module_titles }: Props) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.currentTarget.blur()
    onDelete(learner_module_access)
  }

  return (
    <tr>
      <td>
        <span className="sw-tooltip" data-tooltip={learner_module_access.module_key}>
          {module_titles[learner_module_access.module_key] ?? learner_module_access.module_key}
        </span>
      </td>
      <td>
        {learner_module_access.no_session_needed && (
          <span
            className={learner_module_access.no_session_needed ? 'sw-tooltip' : undefined}
            data-tooltip={
              learner_module_access.no_session_needed ? 'No session will be scheduled for this module' : undefined
            }
          >
            <FontAwesomeIcon className="ml-2" icon={['far', 'calendar-xmark']} />{' '}
          </span>
        )}
      </td>
      <td>{longDateFormat.format(new Date(learner_module_access.created_at))}</td>
      <td className="text-end">
        <button onClick={handleClick} className="sw-btn btn-icon is-error" aria-label="Delete">
          <FontAwesomeIcon icon={['fas', 'xmark']} fixedWidth />
        </button>
      </td>
    </tr>
  )
}

export default LearnerModuleAccess
