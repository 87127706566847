import Rails from '@rails/ujs'
import { useState, useCallback } from 'react'
import { ModelErrors } from '../library/errors'
import API, { ApiRequestBody, RequestBody, HttpMethod } from './api/api'
import qs from 'qs'

type BaseQuery = object | undefined
/*
  helper function for making json requests. This:
  - sets content type / Accepts header for you
  - adds the rails csrf token
  - parses the response (if present and json)
*/
const jsonRequest = async <Params = undefined, Query extends BaseQuery = undefined, Result = unknown>(
  url: string,
  request: RequestBody<Params, Query>
): Promise<Response<Result>> => {
  const { method } = request
  const body =
    method == 'GET'
      ? null
      : JSON.stringify({ authenticity_token: Rails.csrfToken(), ...('payload' in request ? request.payload : null) })

  try {
    if ('query' in request && request.query) {
      url = url + '?' + qs.stringify(request.query, { arrayFormat: 'brackets' })
    }
    const response = await window.fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body
    })

    const contentType = response.headers.get('Content-Type')
    const data = contentType?.match(/application\/json/) ? await response.json() : null

    if (response.ok) {
      return { ok: true, status: response.status, data: data }
    } else {
      const errors = data?.errors ?? { base: [`Request failed with status ${response.status}`] }

      return { ok: false, status: response.status, errors, data }
    }
  } catch (error) {
    return { ok: false, status: 0, errors: { base: [`Request failed with error: ${error}`] } }
  }
}

/*
  Type enhanced version of jsonRequest. Instead of having to specify the types for jsonRequest by hand, this uses the defintion in api.ts to look them up based on the path / method specified.
  This makes typos in paths / picking the wrong http method a compile time error.

  It does however mean that the arguments are no longer strings - typescript must be able to match them to the paths it knows about (at compile time)

*/

async function apiRequest<K extends keyof API, M extends HttpMethod>(url: K, request: ApiRequestBody<K, M>) {
  const typedRequest = jsonRequest<API[K][M]['request'], API[K][M]['query'], API[K][M]['response']>
  return typedRequest(url, request)
}

export const getRequest = async <K extends keyof API>(url: K, query?: API[K]['GET']['query']) => {
  return apiRequest(url, { method: 'GET', query: query, payload: undefined })
}

/*
This hook wraps a common use case of using jsonRequest to save the data from a form.

It returns 4 things:

- a `save` function. THis calls jsonRequest on your behalf. It takes the same arguments and returns the same {ok, data} result
- an `errors` hash. If errors are returning from the backend this will be have an entry for each attribute that has an error. A special case is the `base` attribute which
  covers top level errors, including things like 404 errors
- a `saving` boolean. This is true whilst the save is in process and is intended to drive things like disabling submit buttons, showing a spinner etc.
- a `resetErrors` function. This clears all the previously stored errors (for example in response to user input in the form

At its simplest usage might look like

const [save, errors, saving, resetErrors] = useSave()

const onSave = () => {
  const payload = {...} //assemble a payload from form or state
  const {ok, data} = save(some_url, {payload: payload, method: 'POST'})
  if(ok) {
    // do something in response to successful save
  }
}
return <div>
  Errors: {JSON.stringify(errors)}
  { saving ?  <div className="loading" /> : null}
  <button onClick={onSave} disabled={saving}>Save</button>
</div>

*/

export type Response<T> =
  | { ok: true; status: number; data: T }
  | { ok: false; status: number; data?: { errors: ModelErrors }; errors: ModelErrors }

type SaveFunction<PayloadType, QueryType, ResponseType> = (
  url: string,
  { method, payload, query }: RequestBody<PayloadType, QueryType>
) => Promise<Response<ResponseType>>

type useSaveResult<PayloadType, QueryType, ResponseType> = [
  SaveFunction<PayloadType, QueryType, ResponseType>,
  ModelErrors,
  boolean,
  () => void
]

const useSave = <PayloadType, QueryType extends BaseQuery, ResponseType>(): useSaveResult<
  PayloadType,
  QueryType,
  ResponseType
> => {
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState<ModelErrors>({})

  const save: SaveFunction<PayloadType, QueryType, ResponseType> = async (url, { method, payload, query }) => {
    setSaving(true)
    const response = await jsonRequest<PayloadType, QueryType, ResponseType>(url, { method, payload, query })
    setSaving(false)
    setErrors('errors' in response ? response.errors : {})

    return response
  }
  return [save, errors, saving, useCallback(() => setErrors({}), [])]
}

type ApiSaveFunction<K extends keyof API, M extends HttpMethod> = (
  request: Omit<ApiRequestBody<K, M>, 'method'>
) => Promise<Response<API[K][M]['response']>>

type useApiSaveResult<K extends keyof API, M extends HttpMethod> = {
  save: ApiSaveFunction<K, M>
  errors: ModelErrors
  saving: boolean
  resetErrors: () => void
}

/*
  The type enhanced version of useSave. The usage is slightly different, but the principal is the same: this hook will maintain saving & error state for you

  const {save, errors, saving, resetErrors} = useApiSave(`/foos/${foo.id}`, 'PATCH')

  const onClick = () => {
    const payload = {...} //assemble a payload from form or state
    const {ok, data} = save(payload)
    if(ok) {
      // do something in response to successful save
    }
  }
  return <div>
    Errors: {JSON.stringify(errors)}
    { saving ?  <div className="loading" /> : null}
    <button onClick={onClick} disabled={saving}>Save</button>
  </div>
*/

const useApiSave = <K extends keyof API, M extends HttpMethod>(url: K, method: M): useApiSaveResult<K, M> => {
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState<ModelErrors>({})

  const save: ApiSaveFunction<K, M> = async requestBody => {
    setSaving(true)
    const response = await apiRequest(url, { method, ...requestBody })
    setSaving(false)
    setErrors('errors' in response ? response.errors : {})
    return response
  }

  return { save, errors, saving, resetErrors: useCallback(() => setErrors({}), []) }
}

export default jsonRequest
export { useSave, useApiSave, apiRequest }
