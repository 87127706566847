import { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'

const TableMenu = ({ children }: PropsWithChildren) => {
  const container = document.getElementById('table-menus')

  if (container) {
    return ReactDOM.createPortal(children, container)
  } else {
    return null
  }
}

export default TableMenu
