import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { ChangeRequestReviewable, ReviewModuleData } from '../generated_types/change_requests'
import Modal from '../library/modal'
import { ResultLink } from './result_links_cell_content'

type ModuleDetailsModalProps = {
  moduleData: ReviewModuleData
  changeRequest: ChangeRequestReviewable
  active?: boolean
  onClose: () => void
}

/**
 * Displays a modal detailing module content
 * Assumes there is data to show (parent responsible)
 */
const ModuleDetailsModal = ({ moduleData, changeRequest, active, onClose }: ModuleDetailsModalProps) => {
  const userName = changeRequest.user?.name

  const controls = (
    <>
      <div className="flex grow flex-wrap items-baseline justify-end overflow-x-hidden">
        <h2 className="mr-auto">Module Breakdown</h2>
      </div>
      <button className="sw-btn btn-close-lg shrink-0 bg-transparent hover:bg-white/10" onClick={onClose}>
        <FontAwesomeIcon icon={['fas', 'xmark']} />
      </button>
    </>
  )

  const overview = moduleData.overview_html && (
    <>
      <h4 className="text-primary mb-6">OVERVIEW</h4>
      <p className="mb-10" dangerouslySetInnerHTML={{ __html: moduleData?.overview_html }} />
    </>
  )

  const learnings = moduleData.learnings_html.length > 0 && (
    <>
      <h4 className="text-primary mb-6">WHAT&apos;S COVERED?</h4>
      <ul className="ml-8">
        {moduleData.learnings_html.map((learningHTML, index) => (
          <li key={index} className="mb-2.5" dangerouslySetInnerHTML={{ __html: learningHTML }} />
        ))}
      </ul>
    </>
  )

  const dependencies = moduleData.dependency_html_titles.length > 0 && (
    <div className="basis-72">
      <h4 className="text-primary mb-6">DEPENDS ON</h4>
      {moduleData?.dependency_html_titles.map((dependency_title, index) => (
        <p key={index} className="mb-1.5 font-semibold" dangerouslySetInnerHTML={{ __html: dependency_title }} />
      ))}
    </div>
  )

  const footer = (
    <>
      <span className="text-white font-semibold mr-auto">
        {userName ? userName : 'The user'}
        {changeRequest.requested_state === 'would_like' ? ' would like to learn this' : " doesn't think they need this"}
      </span>
      <ResultLink changeRequest={changeRequest} />
    </>
  )
  const options = {
    /**
     * Use active prop rather than an early return of null, for on page for animations
     */
    active,
    focus: true
  }

  return (
    <Modal
      controls={controls}
      footer={footer}
      classNames="module-details-modal"
      footerClassNames="bg-orcablue"
      onClose={onClose}
      options={options}
    >
      <h3 className="text-primary mb-8" dangerouslySetInnerHTML={{ __html: moduleData.title_html }} />
      <div className="flex flex-wrap gap-10 mb-4">
        <div className="grow basis-96">
          {overview}
          {learnings}
        </div>
        {dependencies}
      </div>
    </Modal>
  )
}

export default ModuleDetailsModal
