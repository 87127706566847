import { ChangeEventHandler, ReactNode, useState } from 'react'
import ErrorsToast from '../library/errors_toast'
import { useApiSave } from '../utils/json_request'
import { CancellationChargingMode, ExistingTrainingSession, LineItem } from './types'
import ConfirmationModal from '../library/confirmation_modal'

type Props = {
  close: (message?: string) => void
  trainingSession: ExistingTrainingSession
  setTrainingSession: (v: ExistingTrainingSession) => void
  lineItems: LineItem[]
  setLineItems: (v: LineItem[]) => void
}

const ONE_DAY = 24 * 3600 * 1000

const CancelSessionModal = ({ trainingSession, setTrainingSession, setLineItems, lineItems, close }: Props) => {
  const sessionStartingInLessThanOneDay = new Date(trainingSession.start) < new Date(Date.now() + ONE_DAY)
  const multipleCompanies = lineItems.length > 1
  const noManuallyEditedCredits = lineItems.every(li => !li.manually_edited_credits)
  const internalSession = lineItems.length === 0

  const [reason, setReason] = useState('')
  // default to free when session starting in over one day
  const [chargingMode, setChargingMode] = useState<CancellationChargingMode | undefined>(() => {
    if (internalSession) return 'custom' //it doesn't really matter what we return for internal sessions - there are no line items
    return sessionStartingInLessThanOneDay ? undefined : 'free'
  })

  const credits = lineItems.reduce((total, item) => total + item.credits_used, 0)
  const description =
    'Cancelling a coaching session item will remove the session from calendars and prevent access to the session'

  const { errors, save, saving, resetErrors } = useApiSave(
    `/coaching_sessions/${trainingSession.code}/cancellation`,
    'POST'
  )

  const onConfirm = async () => {
    resetErrors()
    const response = await save({ payload: { training_session_cancellation: { reason, charging_mode: chargingMode } } })
    if (response.ok) {
      setTrainingSession(response.data.training_session)
      setLineItems(response.data.line_items)
      close(response.data.message)
    }
  }

  // there are several cases
  // 1. no line items. this is an internal session, so no credit related information to capture
  // 2. with line items, >24 h ahead of time
  //
  //    - the first (& default) option is to zero rate the session
  //    - custom credits option
  // 3. with line items, <24 h ahead of time
  //
  //    - the first zero rate the session
  //    - leave credits as is (only if autocalculated. if non autocalculated then the current credits
  //        might not reflect all the declines etc - we don't want to make it seem correct if it isn't)
  //    - custom credits option
  let creditsExplanation: ReactNode

  if (internalSession) {
    creditsExplanation = null
  } else {
    const handleChargingModeChange: ChangeEventHandler<HTMLInputElement> = e => {
      setChargingMode(e.target.value as CancellationChargingMode)
      e.stopPropagation()
    }

    creditsExplanation = (
      <>
        <h5 className="mt-8">What should we do with credits?</h5>
        <p>
          Currently this session is being charged at {credits} {credits === 1 ? 'credit' : 'credits'}
          {multipleCompanies && ` across ${lineItems.length} companies`}
          {sessionStartingInLessThanOneDay ? ' and starts in less than 24 hours.' : '.'}
          <label className="sw-label">
            <input
              className="sw-input"
              type="radio"
              value="free"
              name="cancel-charging-mode"
              checked={chargingMode === 'free'}
              onChange={handleChargingModeChange}
            />
            Charge nothing (0 credits)
          </label>
          {sessionStartingInLessThanOneDay && noManuallyEditedCredits && (
            <label className="sw-label">
              <input
                className="sw-input"
                type="radio"
                value="auto"
                name="cancel-charging-mode"
                checked={chargingMode === 'auto'}
                onChange={handleChargingModeChange}
              />
              Charge any late / non declines ({credits} {credits === 1 ? 'credit' : 'credits'})
            </label>
          )}
          <label className="sw-label">
            <input
              className="sw-input"
              type="radio"
              value="custom"
              name="cancel-charging-mode"
              checked={chargingMode === 'custom'}
              onChange={handleChargingModeChange}
            />
            Charge custom amount (edit credit amount directly) / decide later
          </label>
        </p>
      </>
    )
  }

  return (
    <ConfirmationModal
      title="Cancel Coaching Session"
      onClose={() => close()}
      submitting={saving}
      disabled={!chargingMode && !internalSession}
      onConfirm={onConfirm}
    >
      <ErrorsToast errors={errors} />
      <p>{description}</p>
      <p>Please provide a reason for cancelling this session</p>
      <input value={reason} onChange={e => setReason(e.target.value)} className="sw-input w-full" />

      {creditsExplanation}
    </ConfirmationModal>
  )
}

export default CancelSessionModal
