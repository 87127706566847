import { useEffect, useState } from 'react'
import { getUrlParam, setUrlParam } from '../utils/url_helpers'

/**
 * Maintains a state based on a URL parameter,
 * keeping the url and state in sync
 */
const useUrlParam = <T extends string>(
  /**
   * The key of the URL parameter, i.e. http://...?key=value
   */
  key: string,
  /**
   * Callback to check validity of parameter value,
   * and return the value to be stored in state
   */
  checkValue: (param: string) => T
): [T, (value: T) => void] => {
  const getValidParam = () => {
    const param = getUrlParam(key)
    return checkValue(param)
  }

  const [value, setValue] = useState<T>(getValidParam())

  useEffect(() => {
    const onPopState = () => {
      setValue(getValidParam())
    }
    window.addEventListener('popstate', onPopState)

    return () => {
      window.removeEventListener('popstate', onPopState)
    }
  })

  const setter = (value: T) => {
    setValue(value)
    setUrlParam(key, value)
  }

  return [value, setter]
}

export default useUrlParam
