import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { AddressElement, CardElement, ElementsConsumer } from '@stripe/react-stripe-js'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import CsrfInput from './csrf_input'
import {
  Address,
  PaymentMethodCreateParams,
  Stripe,
  StripeAddressElementChangeEvent,
  StripeCardElementChangeEvent,
  StripeElements
} from '@stripe/stripe-js'
import { StripeCustomerBillingData } from './generated_types/billing'
import TaxIdInput from './stripe_form/tax_id_input'
import { EU_VAT_TYPE } from './stripe_form/tax_id_input'

type InjectedProps = {
  stripe: Stripe
  elements: StripeElements
}

type ComponentRequiringStripe<T> = React.ComponentType<T & InjectedProps>

/**
 * This takes a component that needs stripe/elements props & some other props (T), ie a ComponentRequiringStripe<T>.
 * It returns a ComponentType<T>, ie a component that only requires the props from T (because we're wrapping in a consumer that
 * provides it with stripe/elements)
 */
const injectStripe = <T,>(InjectedComponent: ComponentRequiringStripe<T>): React.ComponentType<T> => {
  const injected = (props: T) => {
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => {
          const propsWithStripe = { stripe, elements, ...props } as T & InjectedProps
          return stripe && elements && <InjectedComponent {...propsWithStripe} />
        }}
      </ElementsConsumer>
    )
  }
  injected.displayName = `${InjectedComponent.name} + Stripe`
  return injected
}
// Reusable Stripe Form for taking card details.
//
// Props:
//
// * children - set the rest of the contents around the form
// * form_target - the endpoint a payment method id will be posted to
// * form_method - the HTTP method to use (posted in a Rails-compatible way)
// * form_button_text - the call to action displayed on the form button

type StripeFormProps = PropsWithChildren<{
  form_target: string
  form_method: 'PUT' | 'POST'
  form_button_text: string
  si_secret: string
  stripe: Stripe
  elements: StripeElements
  customer_data?: StripeCustomerBillingData
  detailsDisplayed?: boolean
  cardRequired?: boolean
  googleMapsApiKey?: string
}>

const StripeForm = ({
  children,
  form_target,
  form_method,
  detailsDisplayed: initialDetailsDisplayed,
  customer_data,
  form_button_text,
  stripe,
  elements,
  cardRequired,
  si_secret,
  googleMapsApiKey
}: StripeFormProps) => {
  const [status, setStatus] = useState<string | undefined>(undefined)
  const formRef = useRef<HTMLFormElement>(null)
  const fieldRef = useRef<HTMLInputElement>(null)
  const [addressState, setAddressState] = useState<AddressState>({ complete: false })
  const [detailsDisplayed, setDetailsDisplayed] = useState(initialDetailsDisplayed)
  const [cardEmpty, setCardEmpty] = useState(true)
  // a company can in theory have multiple tax ids. We currently only show/allow editing of the first
  // eu_vat one
  const [taxId, setTaxId] = useState(
    customer_data?.tax_ids.filter(taxId => taxId.type === EU_VAT_TYPE)[0] ?? {
      country: '',
      value: '',
      type: EU_VAT_TYPE,
      verification: {
        status: 'unavailable' as const,
        verified_name: '',
        verified_address: ''
      }
    }
  )
  const [submitting, setSubmitting] = useState(false)

  const submit = async ev => {
    // User clicked submit
    ev.preventDefault()

    const form = formRef.current
    const field = fieldRef.current
    const cardElement = elements.getElement(CardElement)

    if (!cardElement || !form || !field) {
      return
    }

    if (!addressState.complete) {
      setStatus('Please enter a billing address')
      return
    }

    field.value = ''

    setSubmitting(true)

    // card details are empty - update just the other data
    if (cardEmpty && cardRequired === false) {
      return form.submit()
    }

    const result = await stripe.confirmCardSetup(si_secret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          address: addressState.address && removeNullsFromAddress(addressState.address)
        }
      }
    })
    const { error, setupIntent } = result
    if (error) {
      const { message } = error
      setStatus(message)
    } else if (setupIntent) {
      const { payment_method } = setupIntent
      if (payment_method && typeof payment_method === 'string') {
        // this is true because we don't ask for expanded payment_method field from setupIntent
        field.value = payment_method
        form.submit()
        return
      }
    }

    setSubmitting(false)
  }

  const toast = status ? (
    <div className="sw-toast error">
      {status}
      <br /> Please try again.
    </div>
  ) : null

  useEffect(() => {
    const cardElement = elements.getElement(CardElement)
    if (cardElement) {
      cardElement.on('focus', () => setDetailsDisplayed(true))
      cardElement.on('change', (event: StripeCardElementChangeEvent) => setCardEmpty(event.empty))
      return () => {
        cardElement.off('focus')
        cardElement.off('change')
      }
    }
  }, [elements])

  useEffect(() => {
    const addressElement = elements.getElement(AddressElement)
    const addressChanged = (event: StripeAddressElementChangeEvent) => {
      setAddressState({ address: event.value.address, name: event.value.name, complete: event.complete })
    }
    if (addressElement) {
      addressElement.on('change', addressChanged)
      return () => {
        addressElement.off('change')
      }
    }
  }, [elements, detailsDisplayed]) // detailsDisplayed controls whether elements.getElement(AddressElement) will return anything

  const addressDefaultValues = { address: customer_data?.address, name: customer_data?.name }

  return (
    <>
      {toast}
      <form action={form_target} method="POST" ref={formRef}>
        <CsrfInput />
        <input type="hidden" name="_method" value={form_method} />
        <input ref={fieldRef} type="hidden" name="company[stripe_payment_method_id]" />
        <input type="hidden" name="stripe_customer[name]" value={addressState.name || ''} />
        <input type="hidden" name="tax_id[value]" value={taxId.value} />
        <input type="hidden" name="tax_id[type]" value={taxId.type} />
        <AddressHiddenInputs address={addressState.address} />
      </form>
      {children}
      <div className="sw-group gap-6 w-full lg:w-3/4">
        <CardElement
          className="sw-input py-3"
          options={{ style: { base: { fontSize: '16px' } }, hidePostalCode: true }}
        />
        {detailsDisplayed && (
          <>
            <AddressElement
              className="stripe-address"
              options={{
                mode: 'billing',
                display: { name: 'organization' },
                fields: { phone: 'never' },
                defaultValues: addressDefaultValues,
                autocomplete: googleMapsApiKey
                  ? {
                      apiKey: googleMapsApiKey,
                      mode: 'google_maps_api'
                    }
                  : undefined
              }}
            />

            <div className="sw-card">
              <h4 className="text-primary">EU VAT Registered Companies Only:</h4>
              <p>
                Please enter your VAT number below. This is required for a valid invoice under the Reverse Charge
                mechanism.
              </p>
              <TaxIdInput value={taxId} onChange={data => setTaxId(data)} />
              <span className="form-input-hint">
                Only companies within the EU are required to enter their VAT number
              </span>
            </div>
          </>
        )}

        <button
          className={`sw-btn btn-primary ml-auto ${submitting ? 'sw-loading' : ''}`}
          onClick={submit}
          disabled={submitting}
        >
          <FontAwesomeIcon icon={['far', 'credit-card']} />
          {form_button_text}
        </button>
      </div>
    </>
  )
}

type AddressState = {
  address?: Address
  complete: boolean
  name?: string
}

type FormProps = {
  company_id: string
  sub_amount?: string // this is a little lax - ideally we'd have different types, but that would make renderStripeElements complicated
  sub_credits?: string
} & Pick<StripeFormProps, 'si_secret' | 'customer_data' | 'googleMapsApiKey'>

export type StripeFormComponent = React.ComponentType<FormProps>

// Component for rendering a form for creating a payment method and starting a subscription
const CardDetailsForSubscriptionForm = (props: InjectedProps & FormProps) => {
  return (
    <StripeForm
      {...props}
      form_target={`/companies/${props.company_id}/payment_methods`}
      form_method="POST"
      form_button_text="Update"
    >
      <h3>You haven&apos;t entered credit card details</h3>
      <p>
        Enter your card details below and we will charge you {props.sub_amount} a month for {props.sub_credits} credits
      </p>
      <p className="italic">
        In entering your details, you authorise Skiller Whale to instruct the financial institution that issued your
        card to take payment from your card account in accordance with these terms: each month, until you cancel your
        subscription, you will pay the amount described above, unless you agree to a different subscription plan. In
        order to do this, we will instruct our payment provider (Stripe) to save your card details.
      </p>
    </StripeForm>
  )
}

export const StripedForm = injectStripe(CardDetailsForSubscriptionForm)

// Component for rendering a form for creating a payment method for one off payments
const CardDetailsForOneOffPayments = (props: InjectedProps & FormProps) => {
  return (
    <StripeForm
      {...props}
      form_target={`/companies/${props.company_id}/payment_methods`}
      form_method="POST"
      form_button_text="Update"
    >
      <h3>You haven&apos;t entered credit card details</h3>
      <p>
        Please enter your card details below. They will be saved by our payment provider (Stripe) in order to take
        future payments.
      </p>
      <p>Payment will only be taken with your permission, in exchange for Skiller Whale credits.</p>
    </StripeForm>
  )
}

export const StripedOneOffPayments = injectStripe(CardDetailsForOneOffPayments)

// Component for rendering a form for adding payment methods
const CardDetailsForNewPaymentMethod = (props: InjectedProps & FormProps) => {
  return (
    <StripeForm
      {...props}
      detailsDisplayed={true}
      cardRequired={false}
      form_target={`/companies/${props.company_id}/payment_methods`}
      form_method="POST"
      form_button_text="Update"
    >
      <h3>Update card or billing details.</h3>
    </StripeForm>
  )
}

export const StripedPaymentMethodForm = injectStripe(CardDetailsForNewPaymentMethod)

const AddressHiddenInputs = ({ address }: { address?: Address }) => {
  const keys = ['line1', 'line2', 'city', 'state', 'country', 'postal_code'] as const
  return (
    <>
      {keys.map(key => (
        <input
          type="hidden"
          key={key}
          name={`stripe_customer[address][${key}]`}
          value={address ? address[key] || '' : ''}
        />
      ))}
    </>
  )
}

// Address Element returns string | null for its field, but the payment intents api wants string | undefined. Le sigh
//
const removeNullsFromAddress = (address: Address): PaymentMethodCreateParams.BillingDetails.Address => {
  const entriesWithoutNulls = Object.entries(address).filter(([_key, value]) => value !== null)
  return Object.fromEntries(entriesWithoutNulls)
}
