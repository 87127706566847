const toSentence = (words: string[]) => {
  switch (words.length) {
    case 0:
    case 1:
      return words.join()
    default: {
      const allButLast = words.slice(0, -1)
      return `${allButLast.join(', ')} and ${words.slice(-1)}`
    }
  }
}

export { toSentence }
