import React, { ChangeEventHandler, useEffect } from 'react'
import { AvailableTrainer, Unavailabilities, UnavailabilityType } from '../../types'
import { formatUnavailabilityText } from '../session_editor_helpers'
import { splitUnavailabilities } from './scheduler_panel_helpers'
import UnavailabilityIcons from './unavailability_icons'

interface TrainerPickerProps {
  assignedTrainers: AvailableTrainer[]
  trainerUnavailabilities: Unavailabilities
  selectedTrainerId: number
  /**
   * Called when either a different trainer is selected, or moves between blocked/unblocked
   */
  onChange: (trainerId: number, trainerBlocked: boolean) => void
  disabled: boolean
  readonly: boolean
}

const TrainerPicker = ({
  assignedTrainers,
  trainerUnavailabilities,
  selectedTrainerId,
  onChange,
  readonly,
  disabled
}: TrainerPickerProps) => {
  let allUnavailabilities = trainerUnavailabilities[selectedTrainerId] ?? []
  if (readonly) {
    allUnavailabilities = allUnavailabilities.filter(u => !(u.type == UnavailabilityType.Session))
  }
  const { sessionUnavailabilities, otherUnavailabilities } = splitUnavailabilities(allUnavailabilities)
  const sessionUnavailabilitiesCount = sessionUnavailabilities.length

  const selectHandler: ChangeEventHandler<HTMLSelectElement> = event => {
    const trainerId = parseInt(event.target.value, 10)
    if (!isNaN(trainerId)) onChange(trainerId, !!sessionUnavailabilitiesCount)
  }

  useEffect(
    () => onChange(selectedTrainerId, !!sessionUnavailabilitiesCount),
    [onChange, selectedTrainerId, sessionUnavailabilitiesCount]
  )

  return (
    <div className="mb-3">
      <div className="scheduler-item">
        <label className="sw-label mb-0" htmlFor="trainer-picker">
          <strong>Coach</strong>
        </label>
        <div className="item-warnings">
          <UnavailabilityIcons
            sessionUnavailabilities={sessionUnavailabilities}
            otherUnavailabilities={otherUnavailabilities}
          />
        </div>
      </div>
      <select
        id="trainer-picker"
        className={`sw-select w-full ${readonly ? 'sw-readonly' : ''}`}
        value={selectedTrainerId}
        onChange={selectHandler}
        disabled={disabled || readonly}
      >
        <option disabled value="-1">
          - None Selected -
        </option>
        {assignedTrainers.map(trainer => (
          <option key={trainer.id} value={trainer.id}>
            {trainer.name}
          </option>
        ))}
      </select>
      {sessionUnavailabilities.map((unavailability, index) => (
        <p key={`session-error-${index}`} className="form-input-hint text-error-text mb-0">
          ⛔️ {formatUnavailabilityText(unavailability)}
        </p>
      ))}
      {otherUnavailabilities.map((unavailability, index) => (
        <p key={`other-warning-${index}`} className="form-input-hint text-warning-text mb-0">
          ⚠️ {formatUnavailabilityText(unavailability)}
        </p>
      ))}
    </div>
  )
}

export default TrainerPicker
