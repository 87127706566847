import { nextDay, addDays } from 'date-fns'
import { fromZonedTime } from 'date-fns-tz'
import { DayOfWeek } from './types'

const shortDateFormat = new Intl.DateTimeFormat('en-GB', {
  day: '2-digit',
  month: '2-digit',
  timeZone: 'Europe/London'
})

const longDateOnlyFormat = new Intl.DateTimeFormat('en-GB', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  timeZone: 'Europe/London'
})

const shortTimeFormat = new Intl.DateTimeFormat('en-GB', { timeStyle: 'short', timeZone: 'Europe/London' })

export const getShortTime = (date: Date) => shortTimeFormat.format(date)

export const getShortDate = (date: Date) => shortDateFormat.format(date)

export const getLongDateOnly = (date: Date) => longDateOnlyFormat.format(date)

/*
 * Parses a string representation express in the london timezone, and returns the equivalent UTC Date object
 */
export const parseLondonDateToUTC = (dateString: string, timeString: string) => {
  const date = fromZonedTime(`${dateString} ${timeString}`, 'Europe/London')
  if (date instanceof Date && !isNaN(date.getTime())) return date
  return undefined
}

export const longDateFormat = Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
  timeZone: 'Europe/London'
})

export const jsDayNumber = (d: DayOfWeek): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
  switch (d) {
    case DayOfWeek.Sunday:
      return 0
    case DayOfWeek.Monday:
      return 1
    case DayOfWeek.Tuesday:
      return 2
    case DayOfWeek.Wednesday:
      return 3
    case DayOfWeek.Thursday:
      return 4
    case DayOfWeek.Friday:
      return 5
    case DayOfWeek.Saturday:
      return 6
  }
}

export const dayOfWeekFromJsDayNumber = (n: number) => {
  switch (n) {
    case 0:
      return DayOfWeek.Sunday
    case 1:
      return DayOfWeek.Monday
    case 2:
      return DayOfWeek.Tuesday
    case 3:
      return DayOfWeek.Wednesday
    case 4:
      return DayOfWeek.Thursday
    case 5:
      return DayOfWeek.Friday
    case 6:
      return DayOfWeek.Saturday
    default:
      throw new Error('invalid argument')
  }
}

/*
 return the next count occurences on DayOfWeek, in the specified timezone.

`from` should have the desired *local* time of day for the output: if you want the time of day in the output timezone to be 12:00 then the input time should be 12:00 in the system timezone

 The returned values will have the same time of day in the output timezone
 for example:
 - from is `2023-04-01 12:00` (saturday)
 - the system tz is America/New York (ie from = 2023-04-01 16:00Z)
 - the output timezone is Europe/London
 - then nextOccurences(tuesday) returns `2023-04-03 11:00Z`, 2023-04-10 11:00Z etc. which corresponds to 12:00 in Europe/London
*/
export const nextOccurrences = (ofDay: DayOfWeek, from: Date, count: number, timezone: string): Date[] => {
  const day = jsDayNumber(ofDay)

  const base = nextDay(from, day) // returns the next date where day of week is day.

  // return the next count occurences
  return Array.from({ length: count }, (_, index) => fromZonedTime(addDays(base, 7 * index), timezone))
}
