import { Fragment, useState } from 'react'
import { CoachTrainingSessionSummary, CoachTrainingSessionSummaryProps } from '../generated_types/components'
import TrainingSessionPagination from './pagination'
import { formatDate } from './utils'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

const CoachTrainingSessionSummaries = ({
  training_sessions: initialTrainingSessions,
  pagination: initialPagination,
  historical,
  user_id
}: CoachTrainingSessionSummaryProps) => {
  const [trainingSessions, setTrainingSessions] = useState(initialTrainingSessions)
  const [pagination, setPagination] = useState(initialPagination)

  return (
    <>
      {pagination && user_id && (
        <TrainingSessionPagination
          pagination={pagination}
          setPagination={setPagination}
          setTrainingSessions={setTrainingSessions}
          url={`/users/${user_id}/past_sessions`}
          asCoach
        />
      )}
      <table className="sw-table w-full">
        <thead>
          <tr>
            <th>Date</th>
            <th>Code</th>
            <th>Companies</th>
            <th>Learners</th>
            <th></th>
            <th>Modules</th>
            {historical && (
              <>
                <th>Average Rating</th>
                <th>Coach Rating</th>
                <th>Recording</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {trainingSessions.map(t => (
            <tr key={t.code} className={t.cancelled ? 'line-through' : ''}>
              <td>{formatDate(t.start)}</td>
              <td className="whitespace-nowrap">{t.edit_url ? <a href={t.edit_url}>{t.code}</a> : t.code}</td>
              <td>
                {t.companies.map((c, index) => (
                  <Fragment key={index}>
                    {c.name ? c.name : <em>Internal</em>}
                    {index === t.companies.length - 1 ? '' : ', '}
                  </Fragment>
                ))}
              </td>
              <td>{formatLearnerCount(t, historical)}</td>
              <td>
                <FirstTimeModuleInfo modules={t.modules} />
              </td>
              <td>
                {t.modules.map(m => `${m.curriculum_title}: ${m.module_title}`).join(', ')}
                {t.cancelled && ' (Cancelled)'}
              </td>
              {historical && (
                <>
                  <td>
                    {t.overall_satisfaction ? (
                      <a href={t.learner_feedback_url}>{ratingFormat.format(t.overall_satisfaction)}</a>
                    ) : (
                      'No Ratings'
                    )}
                  </td>
                  <td>
                    {t.coach_overall_satisfaction ? (
                      <a href={t.coach_feedback_url}>{ratingFormat.format(t.coach_overall_satisfaction)}</a>
                    ) : (
                      'No Ratings'
                    )}
                  </td>
                  <td>
                    {t.recording_url && (
                      <a href={t.recording_url} className="sw-btn btn-primary btn-sm">
                        <FontAwesomeIcon icon={['fas', 'file-video']} />
                        View
                        <FontAwesomeIcon icon={['fas', 'chevron-right']} size="sm" />
                      </a>
                    )}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

const formatLearnerCount = (t: CoachTrainingSessionSummary, historical?: boolean) => {
  if (historical) {
    if (t.absent_count === 0) return t.learner_count
    else return `${t.learner_count} (${t.absent_count} Absent)`
  }
  if (t.declined_count === 0) return t.learner_count
  else return `${t.learner_count} (${t.declined_count} Declined)`
}

const FirstTimeModuleInfo = ({ modules }: { modules: CoachTrainingSessionSummary['modules'] }) => {
  const firstTime = modules.filter(m => m.taught_for_the_first_time)
  if (firstTime.length === 0) return null
  return (
    <div className="sw-popover">
      <span>1️⃣</span>
      <div className="popover-content">
        <h5>First time coaching {firstTime.length === 1 ? 'this module' : 'these modules'}</h5>
        {firstTime.map((m, index) => (
          <Fragment key={index}>
            {m.curriculum_title}: {m.module_title}
            <br />
          </Fragment>
        ))}
      </div>
    </div>
  )
}

const ratingFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
})

export default CoachTrainingSessionSummaries
