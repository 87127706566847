import { MouseEventHandler } from 'react'
import { Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { LearnerModuleAccessData } from '../generated_types/learner_module_accesses'
import { useApiSave } from '../utils/json_request'

import Modal from '../library/modal'
import Errors from '../library/errors'
import SWCheckbox from '../library/sw/sw_checkbox'
import SWFormGroup from '../library/sw/sw_form_group'
import SWFieldWithLabel from '../library/sw/sw_field_with_label'

type Props = {
  user_id: number
  onCancel: () => void
  onComplete: (created: LearnerModuleAccessData) => void
  user_first_name: string
  /*
   * If set, then the form can only sumbit this value
   */
  moduleKey?: string
}

type FormData = {
  module_key: string
  notify_learner: boolean
  no_session_needed: boolean
}
const LearnerModuleAccessModal = ({ onCancel, user_id, onComplete, user_first_name, moduleKey }: Props) => {
  const closeModal: MouseEventHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    onCancel()
  }

  const { errors, save } = useApiSave(`/users/${user_id}/learner_module_accesses`, 'POST')

  const onSubmit = async (formData: FormData) => {
    const result = await save({
      payload: {
        learner_module_access: { module_key: formData.module_key, no_session_needed: formData.no_session_needed },
        notify_learner: formData.notify_learner
      }
    })
    if (result.ok) {
      onComplete(result.data.learner_module_access)
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ module_key: moduleKey ?? '', notify_learner: true, no_session_needed: false }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Modal
            options={{ active: true, stopPropagation: true }}
            title="Share Module"
            onClose={closeModal}
            footer={
              <>
                <a onClick={closeModal} className="sw-btn mr-auto">
                  <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                  Cancel
                </a>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
                >
                  Share
                  <FontAwesomeIcon icon={['fas', 'share']} />
                </button>
              </>
            }
          >
            {Object.keys(errors).length > 0 ? (
              <div className="sw-toast error">
                <ul className="list-inside">
                  <Errors errors={errors} as="li" />
                </ul>
              </div>
            ) : null}
            <SWFormGroup>
              <SWFieldWithLabel
                label="Module"
                name="module_key"
                className={`sw-input ${moduleKey ? 'readonly' : ''}`}
                id="learner_module_access_module_key"
                list="available-modules"
                disabled={!!moduleKey}
              />
            </SWFormGroup>
            <Field
              type="checkbox"
              as={SWCheckbox}
              name="notify_learner"
              label={`Email ${user_first_name} to let them know`}
              classNames="mt-4"
            />
            <Field
              type="checkbox"
              as={SWCheckbox}
              name="no_session_needed"
              label={`Coaching Session Not Needed`}
              classNames="mt-4"
            />
            {values.no_session_needed && (
              <p className="form-input-hint">
                This will prevent the module from being scheduled for this learner in any coaching plan
              </p>
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

export default LearnerModuleAccessModal
