import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { Company, CompanyPause, PauseFormData } from './types'
import Empty from '../library/empty'
import Pause from './pause'
import { ReactNode, useState } from 'react'
import { EditablePause } from '../pauses/types'
import PauseFormModal from './pause_form_modal'
import { ModelErrors } from '../library/errors'
import { apiRequest } from '../utils/json_request'
import ConfirmationModal from '../library/confirmation_modal'
import ErrorsToast from '../library/errors_toast'
import { OverlappingSession } from '../generated_types/company_context'
import Modal from '../library/modal'

type Props = {
  company: Company
  pauses: CompanyPause[]
}
const CompanyPauses = ({ pauses: initialPauses, company }: Props) => {
  const [pauses, setPauses] = useState(initialPauses)
  const [editing, setEditing] = useState<EditablePause | null>(null)
  const [pauseToDelete, setPauseToDelete] = useState<CompanyPause | null>(null)
  const [pauseErrors, setPauseErrors] = useState<ModelErrors>({})
  const [deleteErrors, setDeleteErrors] = useState<ModelErrors>({})
  const [deleting, setDeleting] = useState(false)

  const [overlappingSessions, setOverlappingSessions] = useState<OverlappingSession[] | undefined>()

  const openAddPauseModal = () =>
    setEditing({ start_date: dateISOString(new Date()), end_date: undefined, description: '' })

  const handlePauseSubmit = async (formData: PauseFormData) => {
    const { id, ...data } = formData

    const response = id
      ? await apiRequest(`/company_pauses/${id}`, { method: 'PATCH', payload: { company_pause: data } })
      : await apiRequest(`/companies/${company.id}/company_pauses`, {
          method: 'POST',
          payload: { company_pause: data }
        })

    if (response.ok) {
      setPauses(response.data.company_pauses)
      setOverlappingSessions(response.data.overlapping_sessions)
      setPauseErrors({})
      setEditing(null)
    } else {
      setPauseErrors(response.errors)
    }
  }

  const addOrEditPauseModal = editing && (
    <PauseFormModal
      pause={editing}
      errors={pauseErrors}
      onCancel={() => {
        setPauseErrors({})
        setEditing(null)
      }}
      onSubmit={handlePauseSubmit}
    />
  )

  const deletePause = async (id: number) => {
    setDeleting(true)

    try {
      const response = await apiRequest(`/company_pauses/${id}`, { method: 'DELETE', payload: undefined })

      if (response.ok) {
        setPauses(response.data.company_pauses)
        setDeleteErrors({})
        setPauseToDelete(null)
      } else {
        setDeleteErrors(response.errors)
      }
    } finally {
      setDeleting(false)
    }
  }

  let deleteModal: ReactNode = null

  if (pauseToDelete) {
    let datesDescription = (
      <>
        from <em>{pauseToDelete.start_date}</em>
      </>
    )
    if (pauseToDelete.end_date) {
      datesDescription = (
        <>
          {datesDescription} to <em>{pauseToDelete.end_date}</em>
        </>
      )
    }

    deleteModal = (
      <ConfirmationModal
        confirmLabel="Delete"
        title="Delete Company Pause"
        onClose={() => setPauseToDelete(null)}
        onConfirm={() => deletePause(pauseToDelete.id)}
        submitting={deleting}
        message={
          <>
            <ErrorsToast errors={deleteErrors} /> Delete Pause {datesDescription} ?
          </>
        }
      />
    )
  }

  const pausesTable =
    pauses.length > 0 ? (
      <>
        <table className="sw-table w-full bg-white mb-2">
          <thead>
            <tr>
              <th>Start</th>
              <th>End</th>
              <th>Comment</th>
              <th className="text-end">
                <button onClick={openAddPauseModal} className="sw-btn btn-secondary btn-icon" aria-label="Add Pause">
                  <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {pauses.map(pause => (
              <Pause
                pause={pause}
                key={pause.id}
                onEdit={pause => setEditing(pause)}
                onDelete={pause => setPauseToDelete(pause)}
              />
            ))}
          </tbody>
        </table>
      </>
    ) : (
      <Empty
        title={`No company pauses`}
        classNames="mb-2"
        action={
          <button onClick={openAddPauseModal} className="sw-btn btn-primary">
            <FontAwesomeIcon icon={['fas', 'circle-plus']} />
            Add Pause
          </button>
        }
        icon={<FontAwesomeIcon icon={['far', 'circle-pause']} size="3x" />}
      />
    )

  const warningModal =
    overlappingSessions && overlappingSessions.length > 0 ? (
      <WarningModal overlappingSessions={overlappingSessions} onClose={() => setOverlappingSessions(undefined)} />
    ) : null
  return (
    <div>
      <h4 className="my-3">Company Pauses</h4>
      {pausesTable}
      {addOrEditPauseModal}
      {deleteModal}
      {warningModal}
    </div>
  )
}

const dateISOString = (date: Date) => date.toISOString().slice(0, 10) //extract the YYYY-MM-DD oart

type WarningProps = {
  overlappingSessions: OverlappingSession[]
  onClose: () => void
}

const WarningModal = ({ overlappingSessions, onClose }: WarningProps) => {
  return (
    <Modal
      options={{ active: true }}
      title="⚠️ Overlapping Sessions Found"
      onClose={onClose}
      footer={
        <button className="sw-btn btn-primary" onClick={onClose}>
          OK
        </button>
      }
    >
      <p>
        The company pause has been created. Please review the list of overlapping sessions below, to check whether they
        should be moved or cancelled
      </p>
      <ul className="list-outside">
        {overlappingSessions.map((s, index) => (
          <li key={index}>
            {s.description} (
            <a href={s.session_edit_url} target="_blank" rel="noreferrer">
              Edit
            </a>
            )
          </li>
        ))}
      </ul>
    </Modal>
  )
}

export default CompanyPauses
