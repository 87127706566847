import { createContext, PropsWithChildren, ReactNode, useContext } from 'react'

export type CableContextData = {
  cable: ActionCable.Consumer | null
} | null

export const CableContext = createContext<CableContextData>(null)

interface CableProviderProps extends PropsWithChildren {
  cable: ActionCable.Consumer | null
}

export const CableProvider = ({ cable, children }: CableProviderProps) => (
  <CableContext.Provider value={{ cable }}>{children}</CableContext.Provider>
)

export const withCable = (component: ReactNode, cable: ActionCable.Consumer | null) => (
  <CableProvider cable={cable}>{component}</CableProvider>
)

export const useCable = () => {
  const cableContext = useContext(CableContext)
  if (!cableContext) {
    throw new Error('useCable called without a surrounding CableProvider')
  }
  return cableContext
}

export default withCable
