import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type ChangeRequestReviewIconProps = {
  response: 'approved' | 'rejected' | null
}

const ChangeRequestReviewIcon = ({ response }: ChangeRequestReviewIconProps) => {
  let icon: IconProp, iconColor: string, iconBGColor: string, tooltipText: string

  switch (response) {
    case 'approved':
      icon = ['fas', 'check']
      iconColor = 'text-darkestseagreen'
      iconBGColor = ' bg-darkestseagreen/5'
      tooltipText = 'Approved by Manager'
      break
    case 'rejected':
      icon = ['fas', 'xmark']
      iconColor = 'text-snapperred'
      iconBGColor = ' bg-snapperred/5'
      tooltipText = 'Rejected by Manager'
      break
    default:
      icon = ['fas', 'minus']
      iconColor = 'text-midgray'
      iconBGColor = ' bg-lightestgrey/50'
      tooltipText = 'Review Pending'
  }

  return (
    <div className={`sw-tooltip tooltip-left inline rounded-md p-2 ${iconBGColor}`} data-tooltip={tooltipText}>
      <FontAwesomeIcon icon={icon} className={iconColor} size="xl" />
    </div>
  )
}

export default ChangeRequestReviewIcon
