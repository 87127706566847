import { ChangeEventHandler } from 'react'
import { UserPlannedSessionsPageProps } from '../generated_types/user_plan_viewer'
import PlanConfirmationStatus from './plan_confirmation_status'
import PlanConfirmation from './plan_confirmation'
import { curriculaToModuleTitles, filterCurricula } from './helpers'
import Curriculum from './curriculum'
import { StatusFilterKey, StatusFilterKeys } from './types'
import StatusFilterButton from './status_filter_button'
import SessionsEmpty from './sessions_empty'
import TechnologyDropdown from './technology_dropdown'
import useUrlParam from './use_url_param'

const UserPlannedSessionsViewer = ({ curricula, user, confirmed_at }: UserPlannedSessionsPageProps) => {
  const technologiesAndTitles = Object.entries(
    Object.fromEntries(curricula.map(curriculum => [curriculum.technology, curriculum.technology_title] as const))
  )

  const checkTechParam = (param: string) => {
    if (param && technologiesAndTitles.some(([key, _]) => key === param)) return param
    return ''
  }
  const [technologyFilter, setTechnologyFilter] = useUrlParam<string>('tech', checkTechParam)

  const checkStatusParam = (param: string) => {
    // Need to use find here to remain type-safe
    const validStatus = StatusFilterKeys.find(key => key === param)
    if (validStatus) return validStatus
    return 'all_sessions'
  }
  const [statusFilter, setStatusFilter] = useUrlParam<StatusFilterKey>('status', checkStatusParam)

  const handleSelectChange: ChangeEventHandler<HTMLSelectElement> = event => setTechnologyFilter(event.target.value)

  const filteredCurricula = filterCurricula(curricula, technologyFilter, statusFilter)
  const confirmedAtDate = typeof confirmed_at === 'string' ? new Date(confirmed_at) : confirmed_at
  const moduleTitles = curriculaToModuleTitles(curricula)

  const confirmation = user ? (
    <PlanConfirmationStatus confirmed_at={confirmedAtDate} user={user} />
  ) : (
    curricula.length > 0 && <PlanConfirmation confirmed_at={confirmedAtDate} />
  )

  return (
    <>
      {confirmation}
      <fieldset className="controls-panel">
        <legend>Filters</legend>
        <div className="sw-btn-grp">
          {StatusFilterKeys.map(status => (
            <StatusFilterButton
              key={status}
              status={status}
              active={statusFilter === status}
              onFilter={setStatusFilter}
            />
          ))}
        </div>
        <TechnologyDropdown
          value={technologyFilter}
          valuesAndOptions={technologiesAndTitles}
          onChangeHandler={handleSelectChange}
        />
      </fieldset>

      <div className="curriculum-header-topper" />
      {filteredCurricula.map(data => (
        <Curriculum key={data.curriculum} data={data} moduleTitles={moduleTitles} readonly={!!user} />
      ))}

      {filteredCurricula.length < 1 && <SessionsEmpty user={user} noSessions={curricula.length < 1} />}
    </>
  )
}

export default UserPlannedSessionsViewer
