import { Field, Form, Formik } from 'formik'
import { Dispatch, SetStateAction, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { DayOfWeek, Timeslot, TrainingPlanTrainer } from '../../generated_types/training_plan'
import ConfirmationModal from '../../library/confirmation_modal'
import ErrorsToast from '../../library/errors_toast'
import Modal from '../../library/modal'
import { TimeslotData } from '../../utils/api/types'
import { useApiSave } from '../../utils/json_request'
import { toSentence } from '../../utils/string_helpers'
import { AvailableTrainer, AvailableTrainers } from '../types'

type Props = {
  timeslots: Timeslot[]
  setTimeslots: Dispatch<SetStateAction<Timeslot[]>>
  onClose: () => void
  trainingPlanId: number
  trainingPlanTrainers: TrainingPlanTrainer[]
  trainers: AvailableTrainers
}

type TimeslotWithUsageData = { slot: TimeslotData; coachesUsing: AvailableTrainer[]; warned: boolean; empty: boolean }
type FormikData = {
  timeslots: TimeslotWithUsageData[]
}

type ConfirmationData = {
  message: string
  resolvePromise: (confirmed: boolean) => void
}
const TimeslotsModal = ({
  timeslots,
  setTimeslots,
  onClose,
  trainingPlanId,
  trainingPlanTrainers,
  trainers
}: Props) => {
  const [initialValues] = useState(() => {
    // Augment each timeslot with the list of users using the slot
    // and whether we have warned the user that editing this slot will affect these coaches.
    const augmentedTimeslots: TimeslotWithUsageData[] = timeslots.map(slot => {
      const trainingPlanTrainersUsing = trainingPlanTrainers.filter(tpt =>
        tpt.coach_timeslots.find(ct => ct.timeslot_id === slot.id)
      )
      const coaches = trainingPlanTrainersUsing.map(tpt => trainers[tpt.user_id])
      return { slot, warned: false, coachesUsing: coaches, empty: false }
    })
    return [...augmentedTimeslots, getEmptyTimeslot()]
  })

  const { errors, save } = useApiSave(`/plans/${trainingPlanId}/timeslots`, 'PATCH')

  const onSubmit = async (values: FormikData) => {
    const timeslots_attributes = values.timeslots.filter(timeslot => !timeslot.empty).map(t => t.slot)
    const response = await save({
      payload: { training_plan: { timeslots_attributes } }
    })
    if (response.ok) {
      setTimeslots(response.data.timeslots)
      onClose()
    }
  }

  const [confirmation, setConfirmation] = useState<ConfirmationData | undefined>()
  const requestConfirmation = async (message: string) => {
    const promise = new Promise<boolean>(resolve => {
      setConfirmation({ resolvePromise: resolve, message })
    })
    const result = await promise
    setConfirmation(undefined)
    return result
  }

  const modal = confirmation && (
    <ConfirmationModal
      message={confirmation.message}
      onClose={() => confirmation.resolvePromise(false)}
      onConfirm={() => confirmation.resolvePromise(true)}
      submitting={false}
    />
  )

  const canMutateTimeslot = (t: TimeslotWithUsageData, message: string) => {
    if (t.warned || t.coachesUsing.length === 0 || t.empty) {
      return true
    }

    const names = t.coachesUsing.map(c => c.name)

    return requestConfirmation(
      `This timeslot is currently assigned to coaches ${toSentence(names)}, ${message}. Do you want to proceed?`
    )
  }

  const canDeleteTimeslot = (t: TimeslotWithUsageData) => {
    return canMutateTimeslot(t, 'and will be removed')
  }

  const canChangeTimeslot = (t: TimeslotWithUsageData) => {
    return canMutateTimeslot(t, 'so will be moved for them')
  }

  const updateTimeslot = async (
    value: string,
    fieldName: string,
    timeslots: TimeslotWithUsageData[],
    timeslotIndex: number,
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    const timeslot = timeslots[timeslotIndex]
    const canEdit = canChangeTimeslot(timeslot)
    if (canEdit === true || (await canEdit)) {
      setFieldValue(`timeslots.${timeslotIndex}.warned`, true)
      setFieldValue(fieldName, value)
      if (timeslot.empty) setFieldValue(`timeslots.${timeslotIndex}.empty`, false)
      if (timeslotIndex + 1 === timeslots.length) setFieldValue(`timeslots.${timeslots.length}`, getEmptyTimeslot())
    }
  }

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={{ timeslots: initialValues }}>
        {({ values, isSubmitting, setFieldValue, dirty }) => (
          <Form id="edit-timeslots">
            <Modal
              onClose={onClose}
              options={{ active: true }}
              title="Edit Timeslots"
              footer={
                <>
                  <button className="sw-btn mr-auto" onClick={onClose}>
                    <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting || !dirty}
                    className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
                  >
                    Save
                    <FontAwesomeIcon icon={['far', 'floppy-disk']} />
                  </button>
                </>
              }
              body={
                <>
                  <ErrorsToast errors={errors} />
                  <table className="sw-table mx-auto">
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Time</th>
                        <th>Duration (minutes)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.timeslots.map((t, index) => {
                        return t.slot._destroy ? null : (
                          <tr key={index} className={t.empty ? 'opacity-50 bg-lightestgrey' : ''}>
                            <td>
                              <Field name={`timeslots.${index}.slot.day_of_week`}>
                                {({ field }) => (
                                  <select
                                    {...field}
                                    className="sw-select"
                                    onChange={e =>
                                      updateTimeslot(e.target.value, field.name, values.timeslots, index, setFieldValue)
                                    }
                                  >
                                    <option value={DayOfWeek.Monday}>Monday</option>
                                    <option value={DayOfWeek.Tuesday}>Tuesday</option>
                                    <option value={DayOfWeek.Wednesday}>Wednesday</option>
                                    <option value={DayOfWeek.Thursday}>Thursday</option>
                                    <option value={DayOfWeek.Friday}>Friday</option>
                                    <option value={DayOfWeek.Saturday}>Saturday</option>
                                    <option value={DayOfWeek.Sunday}>Sunday</option>
                                  </select>
                                )}
                              </Field>
                            </td>
                            <td>
                              <Field name={`timeslots.${index}.slot.starts_at`}>
                                {({ field }) => (
                                  <input
                                    {...field}
                                    className="sw-input"
                                    type="time"
                                    onChange={e =>
                                      updateTimeslot(e.target.value, field.name, values.timeslots, index, setFieldValue)
                                    }
                                  />
                                )}
                              </Field>
                            </td>
                            <td className="text-center">
                              <Field name={`timeslots.${index}.slot.duration_in_minutes`}>
                                {({ field }) => (
                                  <input
                                    {...field}
                                    className="sw-input"
                                    type="number"
                                    min="0"
                                    max="1440"
                                    step="5"
                                    onChange={e =>
                                      updateTimeslot(e.target.value, field.name, values.timeslots, index, setFieldValue)
                                    }
                                  />
                                )}
                              </Field>
                            </td>
                            <td>
                              {!t.empty && (
                                <button
                                  className="sw-btn btn-icon is-error h-9 w-9"
                                  onClick={async e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (await canDeleteTimeslot(t)) {
                                      setFieldValue(`timeslots.${index}.slot._destroy`, true)
                                    }
                                  }}
                                  aria-label="Delete Timeslot"
                                >
                                  <FontAwesomeIcon icon={['fas', 'xmark']} />
                                </button>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </>
              }
            />
          </Form>
        )}
      </Formik>
      {modal}
    </>
  )
}

export default TimeslotsModal

const getEmptyTimeslot = (): TimeslotWithUsageData => {
  return {
    slot: { day_of_week: DayOfWeek.Monday, duration_in_minutes: 0, starts_at: '' },
    coachesUsing: [],
    warned: false,
    empty: true
  }
}
