import { useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import Modal from '../library/modal'
import SWFormGroup from '../library/sw/sw_form_group'
import ConfirmEditsToFrozen from './confirm_edits_to_frozen'
import { NewLineItem } from './constants'
import { ModelErrors } from '../library/errors'

type OnSaveData = {
  line_item: NewLineItem & Required<Pick<NewLineItem, 'amount' | 'description'>>
  permit_updates_to_frozen_invoice: boolean
}

export type OnSaveCallback = (data: OnSaveData) => void

type Props = {
  lineItem: NewLineItem
  onClose: () => void
  onSave: (data: OnSaveData) => void
  title: string
  saving: boolean
  errors: ModelErrors
  extraConfirmationRequired: boolean
}

const NewLineItemModal = ({ lineItem, onClose, onSave, title, errors, saving, extraConfirmationRequired }: Props) => {
  const [updateFrozen, setUpdateFrozen] = useState(false)
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')

  const form = (
    <div className="flex flex-col gap-4">
      {errors.base ? <div className="sw-toast error">{errors.base.join(', ')}</div> : null}
      <SWFormGroup errors={errors['description']}>
        <label className="sw-label" htmlFor="line_item_description">
          Description
        </label>
        <input
          id="line_item_description"
          value={description}
          placeholder="Description"
          onChange={e => setDescription(e.target.value)}
          className="sw-input"
        />
      </SWFormGroup>
      <SWFormGroup errors={errors['amount']}>
        <label className="sw-label" htmlFor="line_item_amount">
          Amount (GBP)
        </label>
        <input
          id="line_item_amount"
          value={amount}
          placeholder="0"
          onChange={e => setAmount(e.target.value)}
          type="number"
          className="sw-input"
        />
      </SWFormGroup>
      <ConfirmEditsToFrozen
        extraConfirmationRequired={extraConfirmationRequired}
        updateFrozen={updateFrozen}
        setUpdateFrozen={setUpdateFrozen}
      />
    </div>
  )

  const saveLineItem = () => {
    onSave({
      line_item: { ...lineItem, amount: parseFloat(amount), description: description },
      permit_updates_to_frozen_invoice: updateFrozen
    })
  }

  const createDisabled = saving || (extraConfirmationRequired && !updateFrozen)

  return (
    <Modal
      title={title}
      onClose={onClose}
      options={{ active: true }}
      body={form}
      footer={
        <>
          <button onClick={onClose} className="sw-btn mr-auto">
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            Cancel
          </button>
          <button
            disabled={createDisabled}
            onClick={saveLineItem}
            className={`sw-btn btn-primary${saving ? ' sw-loading' : ''}`}
          >
            Create
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={['fas', 'file-invoice-dollar']} />
              <FontAwesomeIcon icon={['fas', 'circle-plus']} transform="shrink-2 right-9 down-6" />
            </span>
          </button>
        </>
      }
    />
  )
}

export default NewLineItemModal
