import { MouseEventHandler } from 'react'
import Modal from '../../library/modal'
import LabelledText from '../../library/labelled_text'
import { prettifyRequestState } from './session_editor_helpers'
import { PlannedSessionUserData, TrainingSessionState, TrainingSessionStatus } from '../types'
import { longDateFormat } from '../../utils/date_helpers'
import ChangeRequestReviewIcon from '../../library/change_request_review_icon'
import ChangeRequestResponseIcon from '../../library/change_request_response_icon'
import ChangeRequestIcon from '../../library/change_request_icon'

type Props = {
  learnerName: string
  sessionTitle: string
  sessionUserData?: PlannedSessionUserData
  showCRResponse?: boolean
  /*
   * the complete session history for this cell
   */
  sessionStatuses: TrainingSessionStatus[] | undefined
  onClose: () => void
}
const SessionStatusInfoModal = ({
  onClose,
  learnerName,
  sessionTitle,
  sessionUserData,
  showCRResponse,
  sessionStatuses
}: Props) => {
  const { change_requests, do_not_schedule, do_not_schedule_reason } = sessionUserData || {}
  const hasChangeRequests = change_requests && change_requests.length > 0

  // Show cell calendar button and session history table in modal, if there is more than one session or if a follow up is needed
  const showSessionHistory = sessionStatuses && sessionStatuses.length > 0

  // change_requests is ordered in the BE, so change_requests[0] is the "most relevant" to the admin (See PlannedSessionsPresenter)
  // If editable is false, the user is a manager or "viewing as a manager" so find the most relevant change request that hasn't been actioned
  const handleClose: MouseEventHandler = event => {
    event.stopPropagation()
    onClose()
  }

  const sessionsContent = showSessionHistory && (
    <>
      <h3 className="text-primary text-left">Session History</h3>
      <table className="sw-table requests-table mb-8">
        <thead>
          <tr>
            <th>Created</th>
            <th>Code</th>
            <th>Status</th>
            <th>Charged</th>
            <th>Scheduled For</th>
          </tr>
        </thead>
        <tbody>
          {sessionStatuses.map((session, index) => (
            <tr key={index}>
              <td>{longDateFormat.format(new Date(session.created_at))}</td>
              <td>
                <a onClick={e => e.stopPropagation()} href={`/coaching_sessions/${session.code}/edit`} target="blank">
                  {session.code}
                </a>
              </td>
              <td>{getSessionStatusText(session.status)}</td>
              <td>{session.charged ? '✅' : '❌'}</td>
              <td>{longDateFormat.format(new Date(session.start))}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )

  const requestsContent = hasChangeRequests && (
    <>
      <h3 className="text-primary text-left">Change Requests</h3>
      <table className="sw-table requests-table">
        <thead>
          <tr>
            <th>Request</th>
            <th>Effect</th>
            <th>Originally</th>
            <th className="text-center">Manager Review</th>
            {showCRResponse && <th className="text-center">Admin Response</th>}
          </tr>
        </thead>
        <tbody>
          {change_requests.map((request, index) => (
            <tr key={index}>
              <td>
                {request.requested_state === 'would_like' ? (
                  <span
                    className="sw-tooltip tooltip-right"
                    data-tooltip={
                      request.created_by_id
                        ? `An admin said ${learnerName} would like to learn this`
                        : `${learnerName} said they would like to learn this`
                    }
                  >
                    <ChangeRequestIcon changeRequest={request} classNames="mr-4" size="xl" />
                  </span>
                ) : (
                  <span
                    className="sw-tooltip tooltip-right"
                    data-tooltip={
                      request.created_by_id
                        ? `An admin said ${learnerName} doesn't need this`
                        : `${learnerName} said they don't need this`
                    }
                  >
                    <ChangeRequestIcon changeRequest={request} classNames="mr-4" size="xl" />
                  </span>
                )}
                <span className="font-medium">{longDateFormat.format(new Date(request.created_at))}</span>
              </td>
              <td>{prettifyRequestState(request.requested_state)}</td>
              <td>{prettifyRequestState(request.original_state)}</td>
              <td className="text-center">
                <ChangeRequestReviewIcon response={request.review} />
              </td>
              {showCRResponse && (
                <td className="text-center">
                  <ChangeRequestResponseIcon response={request.response} includeTooltip={true} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )

  const doNotScheduleContent = do_not_schedule && (
    <>
      <h3 className="text-primary text-left">Do Not Schedule</h3>
      <p className="border-l-4 text-left pl-2">{do_not_schedule_reason}</p>
    </>
  )
  return (
    <Modal options={{ active: true, stopPropagation: true }} title="Planned Session Info" onClose={handleClose}>
      <div className="sw-snippet flex-col p-4 mt-0 mb-8">
        <LabelledText label="Learner" text={learnerName} />
        <LabelledText label="Module" text={sessionTitle} />
      </div>
      {doNotScheduleContent}
      {sessionsContent}
      {requestsContent}
    </Modal>
  )
}

const getSessionStatusText = (status: TrainingSessionState) => {
  const statusTextMap: { [key in TrainingSessionState]: string } = {
    [TrainingSessionState.Scheduled]: 'Scheduled',
    [TrainingSessionState.ScheduledAccepted]: 'Accepted',
    [TrainingSessionState.ScheduledDeclined]: 'Declined',
    [TrainingSessionState.Unconfirmed]: 'Unconfirmed',
    [TrainingSessionState.Partial]: 'Partially Completed',
    [TrainingSessionState.Complete]: 'Completed',
    [TrainingSessionState.Absent]: 'Absent',
    [TrainingSessionState.Declined]: 'Declined'
  }
  return statusTextMap[status]
}

export default SessionStatusInfoModal
