import ConfirmationModal from '../library/confirmation_modal'
import ErrorsToast from '../library/errors_toast'
import { ConfirmCreditsTrainingSessionLineItemPath } from '../utils/api/paths'
import { useApiSave } from '../utils/json_request'
import { LineItem } from './types'

type Props = {
  lineItem: LineItem
  sessionCode: string
  closeModal: () => void
  onConfirm: (newLineItems: LineItem[]) => void
}

const ConfirmCreditsModal = ({ lineItem, closeModal, sessionCode, onConfirm }: Props) => {
  const confirmPath: ConfirmCreditsTrainingSessionLineItemPath = `/coaching_sessions/${sessionCode}/line_items/${lineItem.id}/confirm_credits`
  const { saving, errors, save } = useApiSave(confirmPath, 'PATCH')

  const handleConfirm = async () => {
    const response = await save({ payload: undefined })
    if (response.ok) {
      onConfirm(response.data.line_items)
    }
  }
  return (
    <ConfirmationModal
      onClose={closeModal}
      onConfirm={handleConfirm}
      submitting={saving}
      title="Confirm Credits"
      message={
        <>
          <ErrorsToast errors={errors} />
          The current credit amount is correct and does not require amending.
        </>
      }
      confirmLabel="Confirm"
    />
  )
}

export default ConfirmCreditsModal
