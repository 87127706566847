import { useMemo, useState } from 'react'
import {
  Learner,
  MultiPlanViewerPageProps,
  PlanMetadata,
  TrainingPlanTrainer
} from '../../generated_types/training_plan'
import { fromDBState, hiddenState } from '../session_editor/session_editor_helpers'
import { defaultSessionDuration, UserFilter, TrainingPlanContext, Users } from '../types'
import { getIndefinitelyPausedUserIds } from '../utils'
import Toggle from '../../library/toggle'
import SessionsTable from './sessions_table'
import { SchedulerContextProvider } from '../session_editor/scheduler_panel/use_scheduler'
import SchedulingInfo from './scheduling_info'
import { EditTrainingSessionPath } from '../../utils/api/paths'
import CoachingPlanVersionWarning from '../components/coaching_plan_version_warning'

const MultiPlanViewer = ({
  planned_sessions,
  training_plans,
  available_learners,
  snoozes,
  modules,
  trainers,
  training_plan_trainers,
  scheduler_presets,
  shared_notes,
  sessions
}: MultiPlanViewerPageProps) => {
  const [showHidden, setShowHidden] = useState(false)
  const [showIndefinitelyPaused, setShowIndefinitelyPaused] = useState(false)
  const companyId = training_plans[0].company_id

  const plansWithDeduplicatedUsers: typeof training_plans = useMemo(() => {
    const seenUserIds: Set<number> = new Set()

    return training_plans
      .map(tp => {
        const uniqueLearners: Learner[] = []
        tp.learners.forEach(learner => {
          if (seenUserIds.has(learner.user_id)) return
          if (!available_learners[learner.user_id]) return
          seenUserIds.add(learner.user_id)
          uniqueLearners.push(learner)
        })
        return { ...tp, learners: uniqueLearners }
      })
      .filter(tp => tp.learners.length > 0)
  }, [training_plans, available_learners])

  const { learners, trainingPlanIds } = useMemo(() => {
    return {
      learners: plansWithDeduplicatedUsers.flatMap(tp => tp.learners),
      trainingPlanIds: plansWithDeduplicatedUsers.map(tp => tp.id)
    }
  }, [plansWithDeduplicatedUsers])

  const { timeslotsByCoach, assignedTrainers } = useMemo(() => {
    const coachUserIds: Record<number, boolean> = {}
    // find all the coach user ids across all plans (not necessarily unique)
    Object.values(training_plan_trainers).forEach(planTrainers => {
      planTrainers.forEach(planTrainer => (coachUserIds[planTrainer.user_id] = true))
    })
    return {
      timeslotsByCoach: mergeTimeslotData(plansWithDeduplicatedUsers, training_plan_trainers),
      assignedTrainers: Object.keys(coachUserIds)
        .map(id => trainers[id])
        .filter(x => !!x)
    }
  }, [plansWithDeduplicatedUsers, training_plan_trainers, trainers])

  const trainingPlanContext: TrainingPlanContext = useMemo(
    () => ({
      companyId,
      modules,
      editable: false,
      hasChanges: false,
      available_learners,
      learners,
      newAddedUserIds: [],
      userIdsToRemove: new Set()
    }),
    [companyId, modules, available_learners, learners]
  )

  const indefinitePauses: UserFilter = useMemo(
    () => ({
      show: showIndefinitelyPaused,
      userIds: getIndefinitelyPausedUserIds(snoozes)
    }),
    [showIndefinitelyPaused, snoozes]
  )

  const plannedSessions = useMemo(() => fromDBState(planned_sessions), [planned_sessions])

  const hiddenSessionCount = plannedSessions.filter(ps => hiddenState(ps.status)).length

  const initialSessionDuration = {
    start_time: scheduler_presets.start_time ?? defaultSessionDuration.start_time,
    start_date: scheduler_presets.start_date ?? defaultSessionDuration.start_date,
    duration_in_minutes: scheduler_presets.duration_in_minutes ?? defaultSessionDuration.duration_in_minutes
  }

  const selectedUsers: Users = {}

  if (scheduler_presets.learner_ids) {
    scheduler_presets.learner_ids.forEach(id => {
      const user = available_learners[id]
      if (user) {
        selectedUsers[id] = user
      }
    })
  }
  const selectedModules = scheduler_presets.module_keys?.map(key => ({ key, ...modules[key] })).filter(x => x)

  const initialSelectedCells =
    selectedUsers && selectedModules && Object.keys(selectedUsers).length > 0 && selectedModules.length > 0
      ? { users: selectedUsers, modules: selectedModules }
      : undefined

  const initialSelectedTrainerId =
    scheduler_presets.coach_id && trainers[scheduler_presets.coach_id] ? scheduler_presets.coach_id : undefined

  return (
    <>
      {scheduler_presets.session_code ? (
        <h1>
          Add Learners to Session{' '}
          <a
            className="h1"
            href={`/coaching_sessions/${scheduler_presets.session_code}/edit` satisfies EditTrainingSessionPath}
            rel="noreferrer"
            target="_blank"
          >
            {scheduler_presets.session_code}
          </a>
        </h1>
      ) : (
        <h1>Schedule New Session</h1>
      )}
      <div className="training-plan-edit">
        <SchedulerContextProvider
          trainingPlanIds={trainingPlanIds}
          available_learners={available_learners}
          modules={modules}
          assignedTrainers={assignedTrainers}
          learners={learners}
          timeslotsByCoach={timeslotsByCoach}
          initialSessionDuration={initialSessionDuration}
          initialSelectedTrainerId={initialSelectedTrainerId}
          initialSelectedCells={initialSelectedCells}
          sessionCode={scheduler_presets.session_code}
        >
          <div>
            <SchedulingInfo
              plans={plansWithDeduplicatedUsers}
              coaches={trainers}
              coachAssignments={training_plan_trainers}
              className="mx-auto"
            />
          </div>

          <div className="table-controls sw-snippet justify-end">
            <div className="flex flex-col">
              <Toggle
                id="hidden-sessions-toggle"
                disabled={hiddenSessionCount === 0}
                checked={showHidden}
                onChange={setShowHidden}
                label={`Show Hidden Sessions (${hiddenSessionCount})`}
                classNames="mr-0"
              />
              <Toggle
                id="indefinitely-paused-toggle"
                disabled={indefinitePauses.userIds.length === 0}
                checked={showIndefinitelyPaused}
                onChange={setShowIndefinitelyPaused}
                label={`Show Indefinitely Paused Users (${indefinitePauses.userIds.length})`}
                classNames="mr-0"
              />
            </div>
          </div>

          <SessionsTable
            plannedSessions={plannedSessions}
            sharedNotes={shared_notes}
            trainingPlans={plansWithDeduplicatedUsers}
            indefinitePauses={indefinitePauses}
            showHidden={showHidden}
            modules={modules}
            trainingPlanContext={trainingPlanContext}
            sessions={sessions}
          />
        </SchedulerContextProvider>
      </div>
      <CoachingPlanVersionWarning />
    </>
  )
}

const mergeTimeslotData = (plans: PlanMetadata[], trainersByPlan: Record<number, TrainingPlanTrainer[]>) => {
  // make a mapping of coach id to timeslots. Although a coach can be present in multiple plans, we do this per plan and then merge because
  // timeslots are per plan: if two plans have a slot for wednesday 3pm those are separate records in the database

  const coachTimeslotsPerPlan = plans.map(training_plan => {
    const trainingPlanTrainersForPlan = trainersByPlan[training_plan.id] || []
    return Object.fromEntries(
      trainingPlanTrainersForPlan.map(tpt => {
        const timeslot_ids = tpt.coach_timeslots.map(t => t.timeslot_id)
        return [tpt.user_id, training_plan.timeslots.filter(timeslot => timeslot_ids.includes(timeslot.id))]
      })
    )
  })

  const coachTimeslots = coachTimeslotsPerPlan.reduce((accumulator, coachTimeslotsForPlan) => {
    Object.entries(coachTimeslotsForPlan).forEach(([userId, slots]) => {
      if (accumulator[userId]) {
        accumulator[userId] = accumulator[userId].concat(slots)
      } else {
        accumulator[userId] = slots
      }
    })
    return accumulator
  })

  return coachTimeslots
}
export default MultiPlanViewer
