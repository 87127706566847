import React from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { Company } from './types'
import { useEditor, EditableContent } from '../library/editor'
import UpdatedAt from '../library/updated_at'

const CompanyDescriptionContent = ({ formatted, startEditing }) => {
  return (
    <EditableContent
      formatted={formatted}
      startEditing={startEditing}
      emptyStateTitle="No description"
      emptyStateAction={
        <>
          <FontAwesomeIcon icon={['far', 'pen-to-square']} />
          Add description
        </>
      }
      classNames="my-2 sw-box"
    />
  )
}

type Props = {
  company: Company
}

const CompanyDescription = ({ company }: Props) => {
  const { updatedAt, updatedBy, content } = useEditor(CompanyDescriptionContent, {
    source: company.description,
    formatted: company.formatted_description,
    updated_at: company.description_updated_at,
    updated_by: company.description_updated_by,
    url: `/companies/${company.id}`,
    serialize: source => ({ company: { description: source } }),
    parse: ({ company }) => ({
      source: company.description,
      formatted: company.formatted_description,
      updated_at: company.description_updated_at,
      updated_by: company.description_updated_by
    })
  })

  return (
    <div className="company-description">
      <h4 className="mb-0">Description</h4>
      <UpdatedAt name={updatedBy?.name} updated_at={updatedAt} />
      {content}
    </div>
  )
}

export default CompanyDescription
