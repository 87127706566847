import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import TrainerModal from './trainer_modal'
import { useSave } from '../../utils/json_request'
import ConfirmationModal from '../../library/confirmation_modal'
import Trainer from './trainer'
import { FormikHelpers } from 'formik'
import {
  AvailableTrainers,
  PartialTrainingPlanTrainer,
  TrainingPlanTrainer,
  TrainingPlanTrainerFormData,
  TrainingPlanTrainersPayload,
  TrainingPlanTrainersResponse
} from './training_plan_trainer.types'
import { Timeslot } from '../../generated_types/training_plan'
import { TimeslotClickedFunction } from '../types'

interface TrainingPlanTrainersProps {
  trainers: AvailableTrainers
  trainingPlanId: number | null
  trainingPlanTrainers: TrainingPlanTrainer[]
  setTrainingPlanTrainers: Dispatch<SetStateAction<TrainingPlanTrainer[]>>
  timeslots: Timeslot[]
  onTimeslotClick?: TimeslotClickedFunction
}

const TrainingPlanTrainers = ({
  trainers,
  trainingPlanId,
  trainingPlanTrainers,
  setTrainingPlanTrainers,
  timeslots,
  onTimeslotClick
}: TrainingPlanTrainersProps) => {
  const [trainerModalState, setTrainerModalState] = useState<PartialTrainingPlanTrainer | undefined>(undefined)
  const [trainerToDelete, setTrainerToDelete] = useState<PartialTrainingPlanTrainer | undefined>(undefined)

  const openAddTrainerModal = () => setTrainerModalState({ user_id: '', notes: '', coach_timeslots: [] })
  const openEditTrainerModal = (trainingPlanTrainer: TrainingPlanTrainer) => setTrainerModalState(trainingPlanTrainer)

  let trainerModal: ReactElement | undefined = undefined

  const [save, errors] = useSave<TrainingPlanTrainersPayload, undefined, TrainingPlanTrainersResponse>()

  if (trainerModalState) {
    const handleTrainingPlanTrainerSubmit = async (
      formData: TrainingPlanTrainerFormData,
      api: FormikHelpers<TrainingPlanTrainerFormData>
    ) => {
      const { id, ...training_plan_trainer } = formData
      const url = id ? `/training_plan_trainers/${id}` : `/plans/${trainingPlanId}/training_plan_trainers`

      const { ok, data } = await save(url, {
        method: id ? 'PATCH' : 'POST',
        payload: { training_plan_trainer }
      })

      if (ok) {
        setTrainerModalState(undefined)
        setTrainingPlanTrainers(data.training_plan_trainers)
      } else {
        api.setStatus({ errors })
      }
    }

    trainerModal = (
      <TrainerModal
        trainers={Object.values(trainers)}
        trainingPlanTrainer={trainerModalState}
        onSubmit={handleTrainingPlanTrainerSubmit}
        onCancel={() => setTrainerModalState(undefined)}
        timeslots={timeslots}
      />
    )
  }

  const [doDelete, deleteErrors, deleting, resetDeleteErrors] = useSave<
    undefined,
    undefined,
    TrainingPlanTrainersResponse
  >()
  let deleteModal: ReactElement | undefined = undefined

  if (trainerToDelete) {
    const user = trainers[trainerToDelete.user_id]
    const trainer_name = user ? user.name : trainerToDelete.user_id

    const handleDelete = async () => {
      resetDeleteErrors()
      const { ok, data } = await doDelete(`/training_plan_trainers/${trainerToDelete.id}`, {
        payload: undefined,
        method: 'DELETE'
      })

      if (ok) {
        setTrainerToDelete(undefined)
        setTrainingPlanTrainers(data.training_plan_trainers)
      }
    }

    const errorMessage = deleteErrors.base ? (
      <div className="sw-toast error">{deleteErrors.base.join(', ')}</div>
    ) : undefined

    deleteModal = (
      <ConfirmationModal
        onConfirm={handleDelete}
        onClose={() => setTrainerToDelete(undefined)}
        message={
          <>
            {errorMessage}
            Delete coach {trainer_name} from the coaching plan?
          </>
        }
        submitting={deleting}
      />
    )
  }

  return (
    <div id="training-plan-trainers">
      <h4>Coaches</h4>
      <table className="sw-table w-full">
        <thead>
          <tr>
            <th>Coach</th>
            <th>Notes</th>
            <th></th>
            <th className="text-end">
              <button
                className="sw-btn btn-secondary btn-icon h-9 w-9"
                onClick={openAddTrainerModal}
                aria-label="Add Coach"
              >
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {trainingPlanTrainers.map(t => (
            <Trainer
              key={t.id}
              user={trainers[t.user_id]}
              onDelete={setTrainerToDelete}
              trainingPlanTrainer={t}
              onEdit={openEditTrainerModal}
              timeslots={timeslots}
              onTimeslotClick={onTimeslotClick}
            />
          ))}
        </tbody>
      </table>
      {trainerModal}
      {deleteModal}
    </div>
  )
}

export default TrainingPlanTrainers
