import React, { Fragment } from 'react'

export type ObjectError = {
  message: string
  error: string
  link_text?: string
  link_url?: string
}

export type FieldErrors = Array<string | ObjectError>

export type ModelErrors = {
  [key: string]: FieldErrors
}

// this is not an exaustive list of all errors,
// these are only the errors that we give special treatment to
export enum ErrorType {
  WorkingHours = 'working_hours',
  Snoozed = 'snooze',
  Session = 'sessions',
  SnoozeOverlapsSession = 'snooze_overlaps_sessions',
  ChangeRequest = 'change_requests'
}

const AVAILABILITY_ERRORS: string[] = [ErrorType.WorkingHours, ErrorType.Snoozed, ErrorType.Session]

const CHANGE_REQUEST_ERRORS: string[] = [ErrorType.ChangeRequest]

/*
given an activemodal style errors object of the form

{
  some_attr: ["error1", "error2"]
}

or


{
  some_attr: [{message: "error1"}, {message: "error2"}]
}

return the array ["error1", "error2"]
*/

const collectErrorMessages = (errorsForAttribute: FieldErrors, includeLinks?: boolean) => {
  return errorsForAttribute.map((messageOrObject, index) => {
    if (typeof messageOrObject === 'string') {
      return messageOrObject
    } else {
      if (includeLinks && messageOrObject.link_text && messageOrObject.link_url) {
        return (
          <span key={index}>
            {messageOrObject.message} (
            <a href={messageOrObject.link_url} target="_blank" rel="noreferrer">
              {messageOrObject.link_text}
            </a>
            )
          </span>
        )
      } else {
        return messageOrObject.message
      }
    }
  })
}

const isObjectError = (error: string | ObjectError): error is ObjectError => {
  return typeof error == 'object'
}

const partitionErrors = (errors: FieldErrors, types: string[]) => {
  const matching: ObjectError[] = []
  const notMatching: FieldErrors = []

  for (const error of errors) {
    if (isObjectError(error) && types.includes(error.error)) {
      matching.push(error)
    } else {
      notMatching.push(error)
    }
  }

  return [matching, notMatching] as const
}

const errorsOfType = (errors: ModelErrors, field: string, type: string | string[]) => {
  if (!(field in errors)) {
    return []
  }
  const types = Array.isArray(type) ? type : [type]

  const objectErrors = errors[field].filter(isObjectError)
  return objectErrors.filter(e => types.includes(e.error))
}

type Props = {
  errors: ModelErrors
  as: React.ComponentType<React.PropsWithChildren<unknown>> | string
  full?: boolean
}

const Errors = ({ errors, as: Tag, full }: Props) => {
  return (
    <>
      {Object.entries(errors).map(([attr, errorsForAttribute]) => (
        <Tag key={attr}>
          {full ? `${attr}: ` : null}
          {collectErrorMessages(errorsForAttribute, true).map((fragment, index) => (
            <Fragment key={index}>
              {index > 0 ? ', ' : null}
              {fragment}
            </Fragment>
          ))}
        </Tag>
      ))}
    </>
  )
}

export {
  collectErrorMessages,
  AVAILABILITY_ERRORS,
  CHANGE_REQUEST_ERRORS,
  isObjectError,
  errorsOfType,
  partitionErrors
}

export default Errors
