import { ChangeEventHandler, PropsWithChildren, RefObject, createRef, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import SWHorizontalFieldWithLabel from '../library/sw/sw_horizontal_field_with_label'
import { UserStub } from '../training_session_form/types'

// Unions with SWHorizontalFieldWithLabelProps causes a TS2590 error, so we explicitly define props
type TrainerSelectorProps = PropsWithChildren & {
  name: string
  label: string
  trainers: UserStub[]
  initialTrainerID?: number
  onTrainerChanged: (trainerID: number | undefined) => void
  disabled?: boolean
}

/**
 * Selection for a single coach that uses a dataset for suggested entry, and warns of invalid entries.
 * Returned inside SWHorizontalFieldWithLabel, ready for use in Formik forms, passing children to the trailingElement prop.
 */
const TrainerSelector = ({
  name,
  label,
  trainers,
  initialTrainerID,
  onTrainerChanged,
  children,
  disabled
}: TrainerSelectorProps) => {
  const trainerPickerRef = createRef<HTMLInputElement>()
  const [trainerID, setTrainerID] = useState<number | undefined | null>(null)

  const onInput: ChangeEventHandler<HTMLInputElement> = event => {
    const id = trainers.find(trainer => trainer.name === event.target.value)?.id
    setTrainerID(id)
    onTrainerChanged(id)
  }

  const dataList = (
    <datalist id="trainer_datalist">
      {trainers.map(trainer => (
        <option key={trainer.id} value={trainer.name} />
      ))}
    </datalist>
  )

  let trainerIcon = <></>
  if (trainerID)
    trainerIcon = <FontAwesomeIcon icon={['fas', 'circle-check']} size="lg" className="text-success-highlight mr-2" />
  else if (trainerID !== null)
    // null would mean the selector remains untouched
    trainerIcon = (
      <span className="sw-tooltip" data-tooltip="Coach Name Not Recognised">
        <FontAwesomeIcon icon={['fas', 'circle-question']} size="lg" className="text-warning-highlight mr-2" />
      </span>
    )

  return (
    <>
      {dataList}
      <SWHorizontalFieldWithLabel
        name={name}
        label={label}
        trailingElement={
          <>
            {trainerIcon}
            {children}
          </>
        }
        as={Picker}
        id={name}
        datalist_id="trainer_datalist"
        datalist_ref={trainerPickerRef}
        disabled={trainers.length === 0 || disabled}
        initialValue={trainers.find(trainer => trainer.id === initialTrainerID)?.name}
        onInput={onInput}
      />
    </>
  )
}

type PickerProps = {
  id: string
  datalist_id: string
  datalist_ref: RefObject<HTMLInputElement>
  onInput: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  initialValue?: string
}

const Picker = ({ id, datalist_id, datalist_ref, onInput, disabled, initialValue }: PickerProps) => (
  <input
    id={id}
    className="sw-input basis-1/2"
    list={datalist_id}
    ref={datalist_ref}
    disabled={disabled}
    onChange={onInput}
    defaultValue={initialValue}
  />
)

export default TrainerSelector
