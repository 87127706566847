import { PlannedSession, PlannedSessionState, PlannedSessionUserData } from '../types'
import { hiddenState } from './session_editor_helpers'

type HiddenSessionWarningProps = {
  plannedSessions: PlannedSession[]
  userIds: number[]
}

const HiddenSessionWarning = ({ plannedSessions, userIds }: HiddenSessionWarningProps) => {
  const sessionsWithWarning = hiddenSessionForNewlyAddedUsers(plannedSessions, userIds)

  if (sessionsWithWarning.length == 0) {
    return null
  }

  return (
    <div className="padded-content max-w-5xl mx-auto">
      <div className="sw-toast warning flex-col gap-4">
        Learners have been added to these hidden sessions (sessions are not un-hidden automatically unless they
        previously had no required learners):
        <ul>
          {sessionsWithWarning.map(session => (
            <li key={session.training_module}>{session.training_module}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const hiddenSessionForNewlyAddedUsers = (sessions: PlannedSession[], userIds: number[]) => {
  if (userIds.length == 0) {
    return []
  }

  const attendanceSet = (a?: PlannedSessionUserData) =>
    a &&
    (a.attendance_requirement == PlannedSessionState.Mandatory ||
      a.attendance_requirement == PlannedSessionState.Optional)

  const hiddenSessionsWithNewAttendances = sessions
    .filter(s => hiddenState(s.status))
    .filter(s => userIds.find(id => attendanceSet(s.attendances[id])))

  return hiddenSessionsWithNewAttendances
}

export default HiddenSessionWarning
