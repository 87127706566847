import Errors, { ModelErrors } from './errors'

type Props = { errors: ModelErrors }

const ErrorsToast = ({ errors }: Props) => {
  if (Object.keys(errors).length === 0) {
    return null
  }

  return (
    <div className="sw-toast error">
      <ul className="list-inside">
        <Errors errors={errors} as="li" />{' '}
      </ul>
    </div>
  )
}

export default ErrorsToast
