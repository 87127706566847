// these are the countries with eu_vat

import { useState } from 'react'
import { StripeTaxId } from '../generated_types/billing'

// see https://stripe.com/docs/billing/customer/tax-ids
const euVatCountries = [
  ['Austria', 'AT', '🇦🇹'],
  ['Belgium', 'BE', '🇧🇪'],
  ['Bulgaria ', 'BG', '🇧🇬'],
  ['Croatia', 'HR', '🇭🇷'],
  ['Cyprus', 'CY', '🇨🇾'],
  ['Czechia', 'CZ', '🇨🇿'],
  ['Denmark', 'DK', '🇩🇰'],
  ['Estonia', 'EE', '🇪🇪'],
  ['Finland', 'FI', '🇫🇮'],
  ['France', 'FR', '🇫🇷'],
  ['Germany', 'DE', '🇩🇪'],
  ['Greece', 'GR', '🇬🇷'],
  ['Hungary', 'HU', '🇭🇺'],
  ['Ireland', 'IE', '🇮🇪'],
  ['Italy', 'IT', '🇮🇹'],
  ['Latvia', 'LV', '🇱🇻'],
  ['Lithuania', 'LT', '🇱🇹'],
  ['Luxembourg', 'LU', '🇱🇺'],
  ['Malta', 'MT', '🇲🇹'],
  ['Netherlands', 'NL', '🇳🇱'],
  ['Poland', 'PL', '🇵🇱'],
  ['Portugal ', 'PT', '🇵🇹'],
  ['Romania', 'RO', '🇷🇴'],
  ['Slovakia', 'SK', '🇸🇰'],
  ['Slovenia', 'SI', '🇸🇮'],
  ['Spain', 'ES', '🇪🇸'],
  ['Sweden', 'SE', '🇸🇪']
]

export const EU_VAT_TYPE = 'eu_vat'

type TaxIdInputProps = {
  value: StripeTaxId
  onChange: (value: StripeTaxId) => void
}

const TaxIdInput = ({ value, onChange }: TaxIdInputProps) => {
  const [initialValue] = useState(value)

  // the display verification is either
  // the data is the same as when initially rendered: used the verification data provided
  // the data has changed: don't display anything
  const verification =
    value.type === initialValue.type && value.country === initialValue.country && value.value === initialValue.value
      ? initialValue.verification
      : ({ status: 'unavailable', verified_name: '', verified_address: '' } as const)

  const countryPicker = (
    <select
      value={value.country}
      className="sw-input"
      aria-label="vat-country"
      onChange={e => onChange({ country: e.target.value, value: value.value, type: value.type, verification })}
    >
      <option />
      {euVatCountries.map(([name, value, flag]) => (
        <option key={value} value={value}>
          {flag} {name}
        </option>
      ))}
    </select>
  )

  const idInput = (
    <input
      value={value.value}
      className="sw-input"
      aria-label="vat-id"
      onChange={e => onChange({ country: value.country, value: e.target.value, type: value.type, verification })}
    />
  )

  const verificationDisplay = <VerificationDisplay verification={verification} />

  return (
    <div className="sw-form-grp">
      {countryPicker}
      {idInput} {verificationDisplay}
    </div>
  )
}

const VerificationDisplay = ({ verification }: { verification: StripeTaxId['verification'] }) => {
  let verificationDisplay = <></>
  const verificationClasses = 'sw-tooltip tooltip-left ml-3 mr-2 cursor-pointer'
  switch (verification.status) {
    case 'verified':
      verificationDisplay = (
        <span
          className={verificationClasses}
          data-tooltip={`A matching VAT number entry was found\nfor ${verification.verified_name}\n at ${verification.verified_address}`}
        >
          ✅
        </span>
      )
      break
    case 'unverified':
      verificationDisplay = (
        <span className={verificationClasses} data-tooltip="VAT number could not be verified">
          ❌
        </span>
      )
      break
    case 'pending':
      verificationDisplay = (
        <span className={verificationClasses} data-tooltip="Verification is pending">
          ⌛
        </span>
      )
      break
    case 'unavailable':
      break
  }
  return verificationDisplay
}
export default TaxIdInput
