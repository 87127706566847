import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { DisplayComponentProps, EditableContent } from '../library/editor'

const CoachProfileTextContent = ({ formatted, startEditing }: DisplayComponentProps) => {
  return (
    <EditableContent
      formatted={formatted}
      startEditing={startEditing}
      emptyStateAction={
        <>
          <FontAwesomeIcon icon={['far', 'pen-to-square']} />
          Edit
        </>
      }
      classNames="m-2 sw-box"
    />
  )
}

export default CoachProfileTextContent
