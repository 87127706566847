import { MouseEventHandler, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { LearnerModuleAccessProps, LearnerModuleAccessData } from '../generated_types/learner_module_accesses'
import Empty from '../library/empty'
import DeleteLearnerModuleAccessModal from './delete_learner_module_access_modal'
import LearnerModuleAccess from './learner_module_access'
import LearnerModuleAccessModal from './learner_module_access_modal'
import ModulesDatalist from '../training_plan_editor/components/modules_datalist'

const LearnerModuleAccessEditor = ({
  user_id,
  user_first_name,
  learner_module_accesses: initialLearnerModuleAccesses,
  module_titles
}: LearnerModuleAccessProps) => {
  const [learnerModuleAccesses, setLearnerModuleAccesses] = useState(initialLearnerModuleAccesses)
  const [accessToDelete, setAccessToDelete] = useState<LearnerModuleAccessData | undefined>()
  const [showAddModal, setShowAddModal] = useState(false)

  const openAddModal: MouseEventHandler<HTMLButtonElement> = () => {
    setShowAddModal(true)
  }

  const content =
    learnerModuleAccesses.length > 0 ? (
      <table className="sw-table w-full bg-white">
        <thead>
          <tr>
            <th>Module</th>
            <th></th>
            <th>Shared at</th>
            <th className="text-end">
              <button onClick={openAddModal} className="sw-btn btn-secondary btn-icon" aria-label="Add Shared Module">
                <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {learnerModuleAccesses.map(learner_module_access => (
            <LearnerModuleAccess
              key={learner_module_access.id}
              learner_module_access={learner_module_access}
              onDelete={setAccessToDelete}
              module_titles={module_titles}
            />
          ))}
        </tbody>
      </table>
    ) : (
      <Empty
        title="No shared modules"
        action={
          <button onClick={openAddModal} className="sw-btn btn-primary">
            <FontAwesomeIcon icon={['fas', 'circle-plus']} />
            Add Share
          </button>
        }
        icon={<FontAwesomeIcon icon={['fas', 'share']} size="3x" />}
      />
    )

  const deleteModal = accessToDelete && (
    <DeleteLearnerModuleAccessModal
      learner_module_access={accessToDelete}
      onCancel={() => setAccessToDelete(undefined)}
      onComplete={deleted => {
        setAccessToDelete(undefined)
        setLearnerModuleAccesses(current => current.filter(access => access.id !== deleted.id))
      }}
    />
  )

  const addModal = showAddModal && (
    <LearnerModuleAccessModal
      user_id={user_id}
      user_first_name={user_first_name}
      onCancel={() => setShowAddModal(false)}
      onComplete={added => {
        setShowAddModal(false)
        setLearnerModuleAccesses(current => [added, ...current])
      }}
    />
  )

  return (
    <>
      <ModulesDatalist modules={module_titles} />
      {content}
      {deleteModal}
      {addModal}
    </>
  )
}

export default LearnerModuleAccessEditor
