import React from 'react'
import { CompanyPause } from './types'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

type Props = {
  pause: CompanyPause
  onEdit: (s: CompanyPause) => void
  onDelete: (s: CompanyPause) => void
}

const Pause = ({ pause, onEdit, onDelete }: Props) => {
  const now = Date.now()
  const historical = pause.end_date ? new Date(pause.end_date).getTime() < now : false

  const tdClasses = historical ? 'text-midgrey' : ''
  return (
    <tr className={historical ? 'bg-lightestgrey' : ''}>
      <td className={tdClasses}>{pause.start_date}</td>
      <td className={tdClasses}>{pause.end_date}</td>
      <td className={tdClasses}>{pause.description}</td>
      <td className="whitespace-nowrap text-end">
        <button onClick={() => onEdit(pause)} className="sw-btn btn-icon mr-2" aria-label="Edit">
          <FontAwesomeIcon icon={['fas', 'pencil']} fixedWidth />
        </button>
        <button onClick={() => onDelete(pause)} className="sw-btn btn-icon is-error" aria-label="Delete">
          <FontAwesomeIcon icon={['fas', 'xmark']} fixedWidth />
        </button>
      </td>
    </tr>
  )
}

export default Pause
