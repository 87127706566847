import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { assertUnreachable } from '../utils/asserts'
import { UserSessionStatus } from './session_status'

const SessionStatusChip = ({ status }: { status: UserSessionStatus }) => {
  let text = '',
    classes = '',
    icon = <></>,
    tooltip = ''

  switch (status) {
    case UserSessionStatus.NotInPlan:
      text = 'Not in your plan'
      classes = 'text-midgray border-lightergrey'
      icon = <FontAwesomeIcon icon={['fas', 'circle-xmark']} fixedWidth className="text-midgray" />
      break
    case UserSessionStatus.NotNeeded:
      text = 'Not needed'
      classes = 'text-midgray border-lightergrey'
      icon = <FontAwesomeIcon icon={['fas', 'circle-xmark']} fixedWidth className="text-midgray" />
      break
    case UserSessionStatus.Optional:
      text = 'Optional'
      classes = 'text-coralorange border-coralorange'
      icon = <FontAwesomeIcon icon={['fas', 'circle-minus']} fixedWidth className="text-coralorange" />
      break
    case UserSessionStatus.InPlan:
      text = 'In your plan'
      classes = 'text-oceanblue border-oceanblue'
      icon = <FontAwesomeIcon icon={['fas', 'circle-check']} fixedWidth className="text-oceanblue" />
      break
    case UserSessionStatus.Scheduled:
      text = 'Scheduled'
      classes = 'text-darkerseagreen border-darkerseagreen'
      icon = <FontAwesomeIcon icon={['fas', 'calendar-day']} fixedWidth className="text-darkerseagreen" />
      break
    case UserSessionStatus.Completed:
      text = 'Completed'
      classes = 'text-darkestseagreen border-darkestseagreen'
      icon = <FontAwesomeIcon icon={['fas', 'user-graduate']} fixedWidth className="text-darkestseagreen" />
      break
    case UserSessionStatus.NotesShared:
      text = 'Notes Shared'
      classes = 'text-darkestseagreen border-darkestseagreen'
      icon = <FontAwesomeIcon icon={['fas', 'book']} fixedWidth className="text-darkestseagreen" />
      break
    case UserSessionStatus.Skipped:
      text = 'Skipped'
      classes = 'text-midgray border-lightergrey sw-tooltip'
      tooltip =
        'We weren’t able to schedule this session for you,\nso it has been skipped.\nLet us know if you’d still like to attend.'
      icon = <FontAwesomeIcon icon={['fas', 'circle-xmark']} fixedWidth className="text-midgray" />
      break
    default:
      assertUnreachable(status)
  }

  return (
    <div className={`session-status min-w-0 ${classes}`} data-tooltip={tooltip || undefined}>
      {icon}
      <span className="truncate">{text}</span>
    </div>
  )
}

export default SessionStatusChip
