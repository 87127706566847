import {
  Modules,
  PlannedSession,
  PlannedSessionState,
  PlannedSessionUserData,
  TrainingSessionState,
  Users
} from '../types'
import getDisplaySession, { sessionsIsPartial } from './session_status'
import { DEFAULT_MODULE_ESTIMATED_MINUTES, CREDITS_PER_HOUR } from '../constants'
import { hiddenState } from './session_editor_helpers'
import { SessionDataForModule, Sessions, SharedNotes, SharedNotesForModule } from '../../generated_types/training_plan'

interface calculateCreditsParams {
  plannedSessions: PlannedSession[]
  modules: Modules
  available_learners: Users
  showHidden: boolean
  sharedNotes: SharedNotes
  sessions: Sessions
}

type Cost = {
  mandatory: number
  includingOptional: number
}

export type PlanCostData = Record<number, Cost>

const perLearnerPlanCost = ({
  plannedSessions,
  modules,
  available_learners,
  showHidden,
  sharedNotes,
  sessions
}: calculateCreditsParams): PlanCostData => {
  let filteredSessions = showHidden ? plannedSessions : plannedSessions.filter(s => !hiddenState(s.status))
  filteredSessions = filteredSessions.filter(session => !session.markedForDestruction)

  const included = (
    [user_id, userAttendance]: [string, PlannedSessionUserData],
    sharedNotesForModule: SharedNotesForModule | undefined,
    sessionDataForModule: SessionDataForModule | undefined,
    includeOptional: boolean
  ) => {
    const { attendance_requirement, do_not_schedule } = userAttendance
    const sessionsForUser = sessionDataForModule?.[user_id]

    const session = getDisplaySession(sessionsForUser)

    if (!available_learners[user_id]) {
      //ignore deactivated users
      return false
    }

    // include an attendance if either
    // - it is partially complete or scheduled (even if declined)
    // - it is mandatory (& not yet complete)
    // for the purpose of this code, having a learner module access with no_session_needed==true counts as being complete
    //
    // do not schedule attendances are excluded unless they have a scheduled/scheduled accepted session
    if (
      session?.status === TrainingSessionState.Scheduled ||
      session?.status === TrainingSessionState.ScheduledAccepted
    ) {
      return true
    }

    if (session?.status === TrainingSessionState.ScheduledDeclined) {
      return do_not_schedule ? false : true
    }

    if (do_not_schedule) {
      return false
    }

    // don't include if there is no session needed
    if (sharedNotesForModule && sharedNotesForModule[user_id]?.no_session_needed) {
      return false
    }

    if (sessionsIsPartial(sessionsForUser)) {
      return true
    }
    if (session?.status !== TrainingSessionState.Complete) {
      if (attendance_requirement === PlannedSessionState.Mandatory) {
        return true
      }
      if (attendance_requirement === PlannedSessionState.Optional && includeOptional) {
        return true
      }
    }
    return false
  }

  const costPerAttendance = (training_module: string, modules: Modules) => {
    const durationForSession = modules[training_module]?.estimated_minutes || DEFAULT_MODULE_ESTIMATED_MINUTES

    return (durationForSession / 60.0) * CREDITS_PER_HOUR
  }

  const costsPerLearner: Record<number, Cost> = {}

  filteredSessions.forEach(session => {
    const cost = costPerAttendance(session.training_module, modules)
    Object.entries(session.attendances).forEach(entry => {
      const [user_id] = entry
      const sharedNotesForModule = sharedNotes[session.training_module]
      const sessionDataForModule = sessions[session.training_module]
      costsPerLearner[user_id] ||= { mandatory: 0, includingOptional: 0 }
      if (included(entry, sharedNotesForModule, sessionDataForModule, false)) {
        costsPerLearner[user_id].mandatory += cost
      }
      if (included(entry, sharedNotesForModule, sessionDataForModule, true)) {
        costsPerLearner[user_id].includingOptional += cost
      }
    })
  })

  return costsPerLearner
}

type CalculateCreditsParams = {
  costPerLearner: PlanCostData
  userIdsToRemove: Set<number>
}

const totalPlanCost = ({ costPerLearner, userIdsToRemove }: CalculateCreditsParams) => {
  return Object.entries(costPerLearner).reduce(
    (total, [user_id, cost]) => {
      if (userIdsToRemove.has(parseInt(user_id))) {
        return total
      }
      return {
        mandatory: total.mandatory + cost.mandatory,
        includingOptional: total.includingOptional + cost.includingOptional
      }
    },
    { mandatory: 0, includingOptional: 0 }
  )
}

export { perLearnerPlanCost, totalPlanCost }
