import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { MouseEventHandler, useRef, useState } from 'react'

import { Transition } from 'react-transition-group'

type Props = {
  onCopy: MouseEventHandler
  className?: string
  size?: 'sm' | 'xs'
}

const CopyButton = ({ onCopy, className, size = 'sm' }: Props) => {
  const [copied, setCopied] = useState(false)
  const nodeRef = useRef(null)

  const onClick: MouseEventHandler = e => {
    setCopied(true)
    onCopy(e)
  }

  const buttonSizeClasses = size === 'sm' ? 'w-7 h-7 rounded-lg' : 'w-6 h-6 rounded-md'
  return (
    <button onClick={onClick} className={`sw-btn btn-icon ${buttonSizeClasses} ${className ?? ''}`}>
      <div>
        <Transition nodeRef={nodeRef} in={copied} exit={false} onEntered={() => setCopied(false)} timeout={2000}>
          {state => (
            <FontAwesomeIcon
              ref={nodeRef}
              icon={state === 'entering' ? ['far', 'square-check'] : ['fas', 'copy']}
              className="pointer-events-none"
              size={size}
            />
          )}
        </Transition>
      </div>
    </button>
  )
}

export default CopyButton
