import { PropsWithChildren } from 'react'
import { CalendarInvitationStatus } from '../generated_types/training_session'
import { assertUnreachable } from '../utils/asserts'
import { longDateFormat } from '../utils/date_helpers'

type Props = {
  status: CalendarInvitationStatus | null
  lastRejectedAt?: string | null
}

const CalendarInvitationStatusDisplay = ({ status, lastRejectedAt }: Props) => {
  if (status === null || status === CalendarInvitationStatus.NeedsAction) {
    return (
      <span data-tooltip="Calendar invite not responded to" className="sw-tooltip cursor-default relative">
        📅<OverlayIcon>❓</OverlayIcon>
      </span>
    )
  } else if (status === CalendarInvitationStatus.Declined) {
    let tooltip = 'Calendar invite declined'
    if (lastRejectedAt) {
      tooltip += ` (${longDateFormat.format(new Date(lastRejectedAt))})`
    }
    return (
      <span data-tooltip={tooltip} className="sw-tooltip cursor-default relative">
        📅<OverlayIcon>❌</OverlayIcon>
      </span>
    )
  } else if (status === CalendarInvitationStatus.Tentative) {
    return (
      <span data-tooltip="Calendar invite tentative" className="sw-tooltip cursor-default relative">
        📅<OverlayIcon>⚠️</OverlayIcon>
      </span>
    )
  } else if (status === CalendarInvitationStatus.Accepted) {
    return (
      <span data-tooltip="Calendar invite accepted" className="sw-tooltip cursor-default relative">
        📅<OverlayIcon>✅</OverlayIcon>
      </span>
    )
  } else {
    assertUnreachable(status)
  }
}

const OverlayIcon = ({ children }: PropsWithChildren) => (
  <span className="absolute text-xs -bottom-1 -left-1">{children}</span>
)

export default CalendarInvitationStatusDisplay
