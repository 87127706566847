import { OverlappingSessionDetail } from '../generated_types/training_plan'
import ErrorsToast from '../library/errors_toast'
import LoadingButton from '../library/loading_button'
import Modal from '../library/modal'
import { useApiSave } from '../utils/json_request'

type Props = {
  sessionDetails: OverlappingSessionDetail[]
  onClose: () => void
  snoozeId: number
}

const DeclineOverlappingCoachSessionsModal = ({ sessionDetails, onClose, snoozeId }: Props) => {
  const {
    save: decline,
    errors: declineErrors,
    saving: declining,
    resetErrors: resetDeclineErrors
  } = useApiSave('/coach_attendance_declines', 'POST')

  const {
    save: acknowledge,
    errors: ackErrors,
    saving: acknowledging,
    resetErrors: resetAckErrors
  } = useApiSave(`/snoozes/${snoozeId}/acknowledge_overlapping_sessions`, 'PUT')

  const description = sessionDetails.length === 1 ? 'one session' : `${sessionDetails.length} sessions`

  const declineAll = async () => {
    resetDeclineErrors()
    resetAckErrors()
    const result = await decline({
      payload: {
        coach_attendance_decline: {
          snooze_id: snoozeId,
          training_session_codes: sessionDetails.map(detail => detail.code)
        }
      }
    })

    if (result.ok) {
      onClose()
    }
  }

  const acknowledgeAll = async () => {
    resetDeclineErrors()
    resetAckErrors()
    const result = await acknowledge({ payload: undefined })

    if (result.ok) {
      onClose()
    }
  }

  return (
    <Modal
      options={{ active: true }}
      title="⚠️ Overlapping Sessions Found"
      onClose={onClose}
      footer={
        <>
          <LoadingButton className="sw-btn mr-auto" onClick={acknowledgeAll} loading={declining || acknowledging}>
            Yes, I&apos;m still available
          </LoadingButton>

          <LoadingButton className="sw-btn btn-primary" onClick={declineAll} loading={declining || acknowledging}>
            No (Decline all invitations)
          </LoadingButton>
        </>
      }
    >
      <>
        <ErrorsToast errors={declineErrors} />
        <ErrorsToast errors={ackErrors} />
        <h5>Pause Added</h5>

        <p>You are scheduled for {description} during this period:</p>

        <ul className="list-outside">
          {sessionDetails.map(detail => (
            <li key={detail.code}>{detail.description}</li>
          ))}
        </ul>

        {sessionDetails.length == 1 ? (
          <p className="mt-4">Will you still be able to coach this session?</p>
        ) : (
          <>
            <p className="mt-4">Will you still be able to coach them?</p>

            <p className="form-input-hint">
              If you are able to teach some, but not all of these sessions, then please accept / decline individual
              calendar events accordingly
            </p>
          </>
        )}
      </>
    </Modal>
  )
}

export default DeclineOverlappingCoachSessionsModal
