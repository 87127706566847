import { TrainingPlanContext } from '../types'
import { PlanCostData, totalPlanCost } from './plan_cost'

interface PlanCostProps {
  costPerLearner: PlanCostData
  trainingPlanContext: TrainingPlanContext
}

const PlanCostDisplay = ({ costPerLearner, trainingPlanContext }: PlanCostProps) => {
  const { userIdsToRemove } = trainingPlanContext
  const cost = totalPlanCost({
    costPerLearner,
    userIdsToRemove
  })

  return (
    <div className="my-5">
      <span
        className="font-semibold sw-tooltip tooltip-right"
        data-tooltip={`Includes any scheduled, partially complete\nor mandatory sessions`}
      >
        Estimated Total Cost: {Math.round(cost.mandatory)} credits ({Math.round(cost.includingOptional)} credits
        including optional sessions)
      </span>
    </div>
  )
}

export default PlanCostDisplay
