import { Controller } from '@hotwired/stimulus'

/*
 * Manages a tab group:
 * - a button group
 * - 1 or more panes of content
 *
 * The buttons are used to select which pane is active
 *
 * The panes are exposed to the controller via the pane target
 * the buttons are exposed via the button target
 */

export default class TabGroupController extends Controller<HTMLElement> {
  static targets = ['pane', 'button']

  declare paneTargets: HTMLElement[]
  declare buttonTargets: HTMLButtonElement[]

  showTab(event: MouseEvent) {
    event.stopPropagation()
    event.preventDefault()
    if (!event.currentTarget) return
    const button = event.currentTarget as HTMLButtonElement

    const selectedIndex = this.buttonTargets.indexOf(button)
    if (selectedIndex < 0) return

    // ensure only the selected pane is visible
    this.paneTargets.forEach((element, index) => {
      if (index === selectedIndex) {
        this.paneTargets[index].classList.remove('hidden')
      } else {
        this.paneTargets[index].classList.add('hidden')
      }
    })

    // ensure only the selected button is active
    this.buttonTargets.forEach((element, index) => {
      if (index === selectedIndex) {
        this.buttonTargets[index].classList.add('btn-active')
      } else {
        this.buttonTargets[index].classList.remove('btn-active')
      }
    })
  }
}
