import { longDateFormat } from '../utils/date_helpers'

type Props = {
  name?: string | null
  updated_at: string | null
  custom_prefix?: string
  classNames?: string
}

const UpdatedAt = ({ name, updated_at, custom_prefix, classNames = '' }: Props) => {
  if (updated_at)
    return (
      <span className={`text-sm opacity-80 ${classNames}`}>
        {custom_prefix === undefined ? 'Updated at' : custom_prefix} {longDateFormat.format(new Date(updated_at))}
        {name ? ` by ${name}` : null}
      </span>
    )

  return null
}

export default UpdatedAt
