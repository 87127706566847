import { ModelErrors } from '../library/errors'

/**
 * Turns an error response into a displayable string (for a tooltip for instance)
 */
export const textifyErrors = (errors: ModelErrors) =>
  Object.values(errors)
    .map(error => error.map(err => `- ${typeof err === 'string' ? err : err.message}`))
    .flat()
    .join('\n')
