import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { PlannedSessionUserData, SetSelectedCellFunction, TrainingSessionState, TrainingSessionStatus } from '../types'
import ChangeRequestIcon from '../../library/change_request_icon'

export type SessionStatusInfoProps = {
  userId: number
  moduleKey: string
  editable: boolean
  sessionUserData?: PlannedSessionUserData
  /*
   * the main session status (ie the one displayed in the cell)
   */
  trainingSessionStatus?: TrainingSessionStatus
  /*
   * the complete session history for this cell
   */
  sessionStatuses: TrainingSessionStatus[] | undefined
  setDisplaySessionInfoModalFor: SetSelectedCellFunction
}

const SessionStatusInfo = ({
  userId,
  moduleKey,
  editable,
  sessionUserData,
  trainingSessionStatus,
  sessionStatuses,
  setDisplaySessionInfoModalFor
}: SessionStatusInfoProps) => {
  const { change_requests, do_not_schedule } = sessionUserData || {}
  const hasChangeRequests = change_requests && change_requests.length > 0

  // Show cell calendar button and session history table in modal, if there is more than one session or if a follow up is needed
  const showSessionHistory =
    sessionStatuses &&
    (sessionStatuses.length > 1 || trainingSessionStatus?.status === TrainingSessionState.Partial || do_not_schedule)

  if (!hasChangeRequests && !showSessionHistory && !do_not_schedule) return null

  // change_requests is ordered in the BE, so change_requests[0] is the "most relevant" to the admin (See PlannedSessionsPresenter)
  // If editable is false, the user is a manager or "viewing as a manager" so find the most relevant change request that hasn't been actioned
  const relevantChangeRequest =
    hasChangeRequests && editable ? change_requests[0] : change_requests?.find(cr => !cr.response)

  const openModal = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation()
    setDisplaySessionInfoModalFor({ userId, moduleKey })
  }

  const commonInfoButtonClasses = 'sw-btn btn-icon info-button sw-tooltip tooltip-left hide-cell-tooltip'

  return (
    <>
      <div className="session-info">
        {relevantChangeRequest && (
          <button
            className={`${commonInfoButtonClasses} change-request`}
            data-tooltip={`${relevantChangeRequest.response ? 'Actioned ' : ''}Change Request`}
            onClick={event => openModal(event)}
          >
            <ChangeRequestIcon changeRequest={relevantChangeRequest} size="sm" />
          </button>
        )}
        {showSessionHistory && (
          <button
            className={`${commonInfoButtonClasses} history`}
            data-tooltip="Previously Scheduled"
            onClick={event => openModal(event)}
          >
            <FontAwesomeIcon icon={['fas', 'calendar']} size="sm" transform="shrink-1" />
          </button>
        )}
      </div>
    </>
  )
}

export default SessionStatusInfo
