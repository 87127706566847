import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { UserPlannedSessionsPageProps } from '../generated_types/user_plan_viewer'

type SessionsEmptyProps = {
  user: UserPlannedSessionsPageProps['user']
  noSessions: boolean
}

const SessionsEmpty = ({ user, noSessions }: SessionsEmptyProps) => {
  let title = ''
  let subtitle = ''

  if (noSessions) {
    title = user ? `${user.name} currently has no planned sessions` : 'You currently have no planned sessions'
    subtitle = 'Please check back later.'
  } else {
    title = 'These filters return no results'
    subtitle = 'Select others to try again.'
  }

  return (
    <div className="sw-empty">
      <div className="fa-layers fa-fw fa-5x">
        <FontAwesomeIcon icon={['fas', 'chalkboard']} />
        <FontAwesomeIcon icon={['fas', 'clock']} transform="shrink-4 right-3 down-2" />
      </div>
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </div>
  )
}

export default SessionsEmpty
