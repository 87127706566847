import React from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { SchedulerPanelProps } from './scheduler_panel'
import { objectRemove } from '../../../utils/object_helpers'
import { getIssueTooltip, getLearnerIssues, splitUnavailabilities } from './scheduler_panel_helpers'
import UnavailabilityIcons from './unavailability_icons'
import { SchedulerContextData } from './use_scheduler'
import { SelectedPlanCells } from '../../types'

type LearnerListProps = Pick<
  SchedulerPanelProps,
  'modules' | 'dependencies' | 'attendances' | 'sharedNotes' | 'sessions'
> & {
  disabled?: boolean
  uneditableCells?: SelectedPlanCells
} & Pick<SchedulerContextData, 'selectedCells' | 'setSelectedCells' | 'learnerUnavailabilities'>

/**
 * A list of learners, with warning status icons and remove button
 */
const LearnerList = ({
  selectedCells,
  setSelectedCells,
  uneditableCells,
  modules,
  dependencies,
  attendances,
  sharedNotes,
  learnerUnavailabilities,
  disabled,
  sessions
}: LearnerListProps) => {
  const removeLearner = (userId: string) => {
    if (!disabled)
      setSelectedCells(currentValue => {
        const users = objectRemove(userId, currentValue.users)
        const modules = Object.keys(users).length < 1 ? [] : currentValue.modules
        return {
          modules,
          users
        }
      })
  }

  const uneditable = (userId: string) => uneditableCells && userId in uneditableCells.users
  // partition the users so that the uneditable ones are at the top
  const users = Object.entries(selectedCells.users)
    .filter(([userId, _]) => uneditable(userId))
    .concat(Object.entries(selectedCells.users).filter(([userId, _]) => !uneditable(userId)))

  const learnerList = users.map(([userId, user]) => {
    const uneditable = uneditableCells && userId in uneditableCells.users
    const learnerIssues = getLearnerIssues({
      userId: parseInt(userId),
      selectedModules: selectedCells.modules,
      allModules: modules,
      dependencies,
      attendances,
      sharedNotes,
      sessions
    })
    const issueTooltips = learnerIssues
      ? Object.entries(learnerIssues).map(([issue, modules]) => getIssueTooltip(issue, modules.length))
      : []

    const unavailabilities = learnerUnavailabilities[userId] ?? []
    const itemClasses = 'scheduler-item' + (disabled || uneditable ? ' text-midgrey' : '')

    return (
      <div key={userId} className={itemClasses}>
        <a
          className={`sw-btn btn-close item-remove sw-tooltip tooltip-right ${uneditable ? 'disabled' : ''}`}
          data-tooltip="Remove"
          onClick={uneditable ? undefined : () => removeLearner(userId)}
        >
          <FontAwesomeIcon icon={['fas', 'xmark']} size="xs" />
        </a>
        {user.name}
        {!uneditable && (learnerIssues || !!unavailabilities.length) && (
          <div className="item-warnings">
            {learnerIssues && (
              <div className="sw-tooltip tooltip-left" data-tooltip={issueTooltips.join('\n')}>
                {'⚠️'}
              </div>
            )}
            <UnavailabilityIcons {...splitUnavailabilities(unavailabilities)} />
          </div>
        )}
      </div>
    )
  })

  return (
    <div className="mb-3">
      <div className="font-bold mb-1">Learners</div>
      {learnerList}
    </div>
  )
}

export default LearnerList
