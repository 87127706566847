import React, { MouseEventHandler, ReactNode, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import ConfirmationModal from '../library/confirmation_modal'
import Errors from '../library/errors'
import { useSave } from '../utils/json_request'
import { type Attendance, AttendanceStatus, User, UserMap, LineItem } from './types'
import { PlanMembership } from '../generated_types/training_session'
import CalendarInvitationStatusDisplay from './calendar_invitation_status_display'
import CopyButton from '../library/copy_button'
import AttendancesEmailMenu from './attendances_email_menu'
import { useMenuState } from '@szhsin/react-menu'
import { FlashAlert, FlashSuccess } from '../library/flash_message'

type AttendanceProps = {
  attendance: Attendance
  user: User
  onDelete: (a: Attendance) => void
  readonly: boolean
  feedbackUrl: string
}

const Attendance = ({ attendance, user, onDelete, readonly, feedbackUrl }: AttendanceProps) => {
  const status =
    attendance.status === AttendanceStatus.Attended ? '✅' : attendance.status === AttendanceStatus.Absent ? '❌' : '❓'

  return (
    <tr>
      <td>
        <CalendarInvitationStatusDisplay
          status={attendance.invitation_status}
          lastRejectedAt={attendance.calendar_event_last_rejected_at}
        />
      </td>
      <td>
        <a href={`/users/${user.id}`}>{user.name}</a>
      </td>
      <td>
        <a href={`mailto:${user.email}`}>{user.email}</a>
        <CopyButton size="xs" className="ml-1" onCopy={() => navigator.clipboard.writeText(user.email)} />
      </td>
      <td>{user.company.name}</td>
      <td className="sw-tooltip" data-tooltip={attendance.id}>
        <CopyButton size="xs" onCopy={() => navigator.clipboard.writeText(attendance.id)} />
      </td>
      <td>{status}</td>
      <td>{attendance.charged ? '✅' : '❌'}</td>
      <td>
        {attendance.overall_satisfaction !== null && (
          <a href={`${feedbackUrl}/${attendance.id}`} target="_blank" rel="noreferrer">
            {attendance.overall_satisfaction}/5
          </a>
        )}
      </td>
      <td>
        <button
          className="sw-btn btn-icon is-error"
          disabled={readonly}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            onDelete(attendance)
          }}
          aria-label="Delete Attendance"
        >
          <FontAwesomeIcon icon={['fas', 'xmark']} />
        </button>
      </td>
    </tr>
  )
}

type AttendancesProps = {
  attendances: Attendance[]
  users: UserMap
  setAttendances: React.Dispatch<React.SetStateAction<Attendance[]>>
  setPlanMemberships: React.Dispatch<React.SetStateAction<PlanMembership[]>>
  setLineItems: React.Dispatch<React.SetStateAction<LineItem[]>>
  setUsers: React.Dispatch<React.SetStateAction<UserMap>>
  readonly: boolean
  feedbackUrl: string
}

const Attendances = ({
  attendances,
  users,
  setAttendances,
  setPlanMemberships,
  setLineItems,
  setUsers,
  readonly,
  feedbackUrl
}: AttendancesProps) => {
  const [message, setMessage] = useState<string | undefined>()
  const [attendanceToDelete, setAttendanceToDelete] = useState<Attendance | null>(null)
  const [doDelete, deleteErrors, deleting, resetDeleteErrors] = useSave<
    undefined,
    undefined,
    { line_items: LineItem[]; message: string }
  >()

  let confirmationModal: ReactNode

  if (attendanceToDelete) {
    const user = users[attendanceToDelete.user_id]

    const handleDelete = async () => {
      const { ok, data } = await doDelete(attendanceToDelete.url, { method: 'DELETE', payload: undefined })

      if (ok) {
        setLineItems(data.line_items)
        setAttendances(current => current.filter(att => att.id !== attendanceToDelete.id))
        setAttendanceToDelete(null)
        setPlanMemberships(current => {
          // remove the deleted user from any plan membership info
          const updated = current.map(pm => ({
            ...pm,
            user_ids: pm.user_ids.filter(userId => userId !== attendanceToDelete.user_id)
          }))
          return updated.filter(pm => pm.user_ids.length > 0)
        })
        setUsers(({ [user.id]: toRemove, ...remainder }) => remainder)
        setMessage(data.message)
      }
    }
    confirmationModal = (
      <ConfirmationModal
        message={
          <>
            Are you sure you want to remove <span className="font-semibold">{user.name}</span> from the session?
          </>
        }
        onClose={() => setAttendanceToDelete(null)}
        onConfirm={handleDelete}
        submitting={deleting}
      />
    )
  }

  const openDeleteModal = (attendance: Attendance) => {
    resetDeleteErrors()
    setAttendanceToDelete(attendance)
  }

  const [menuProps, toggleMenu] = useMenuState()
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
  const showContextMenu: MouseEventHandler = e => {
    e.preventDefault()
    setAnchorPoint({ x: e.clientX, y: e.clientY })
    toggleMenu(true)
  }

  return (
    <>
      {Object.keys(deleteErrors).length > 0 && (
        <FlashAlert sticky onClose={() => resetDeleteErrors()}>
          <ul className="list-inside">
            <Errors errors={deleteErrors} as="li" />
          </ul>
        </FlashAlert>
      )}
      {message && (
        <FlashSuccess onClose={() => setMessage(undefined)} sticky>
          {message}
        </FlashSuccess>
      )}
      <table className="sw-table striped w-full attendances">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th className="cursor-context-menu" onContextMenu={showContextMenu}>
              Email
              <CopyButton size="xs" onCopy={e => showContextMenu(e)} className="ml-1" />
            </th>
            <th>Company</th>
            <th>UUID</th>
            <th>Attended</th>
            <th>Charged</th>
            <th>Rating</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {attendances.map(attendance => (
            <Attendance
              key={attendance.id}
              attendance={attendance}
              user={users[attendance.user_id]}
              onDelete={openDeleteModal}
              readonly={readonly}
              feedbackUrl={feedbackUrl}
            />
          ))}
        </tbody>
      </table>
      {confirmationModal}
      <AttendancesEmailMenu
        attendances={attendances}
        users={users}
        menuProps={menuProps}
        anchorPoint={anchorPoint}
        onClose={() => toggleMenu(false)}
      />
    </>
  )
}

export default Attendances
