import { CurriculumData } from '../../generated_types/user_plan_viewer'
import { ModuleTitles } from '../types'
import CurriculumHeader from '../curriculum_header'
import CurriculaSessionRow from './curricula_session_row'

type CurriculaCurriculumProps = {
  data: CurriculumData
  moduleTitles: ModuleTitles
}

const CurriculaCurriculum = ({ data, moduleTitles }: CurriculaCurriculumProps) => {
  const sessionHeaders = [
    { title: '', class: '' },
    { title: 'Module', class: 'col-span-8' },
    { title: 'Duration', class: 'col-span-3' }
  ]

  return (
    <>
      <CurriculumHeader title={data.curriculum_title} technology={data.technology} />
      <div className="session-layout">
        {sessionHeaders.map((header, index) => (
          <div key={index} className={`session-header ${header.class}`}>
            {header.title}
          </div>
        ))}
        {data.planned_sessions.map((session, index) => (
          <CurriculaSessionRow key={index} session={session} moduleTitles={moduleTitles} />
        ))}
      </div>
    </>
  )
}

export default CurriculaCurriculum
