import { MouseEventHandler } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { PlannedSession } from '../types'
import { hiddenState } from './session_editor_helpers'

/*
 a row can be in these states:
 - default: we show a cross icon (which means hide)
 - marked-for-hiding, hidden state: we show a 'unhide' button and a delete button
 - marked-for-deletion: undo button (which unmarks for deletion)

 if a session is both marked for deletion & marked for hiding, the deletion state wins
*/

type ToggleSession = (sessionIndex: number) => void

interface PlannedSessionVisibilityControlsProps {
  plannedSession: PlannedSession
  toggleSessionDeletion: ToggleSession
  toggleSessionVisibility: ToggleSession
  sessionIndex: number
}

const PlannedSessionVisibilityControls = ({
  plannedSession,
  toggleSessionDeletion,
  toggleSessionVisibility,
  sessionIndex
}: PlannedSessionVisibilityControlsProps) => {
  if (plannedSession.markedForDestruction) {
    return (
      <button
        onClick={wrapCallback(toggleSessionDeletion, sessionIndex)}
        className="sw-btn btn-secondary btn-icon w-9 h-9 sw-tooltip tooltip-top"
        data-tooltip="Undo Delete"
        aria-label="Undo Delete"
      >
        <FontAwesomeIcon icon={['fas', 'rotate-left']} />
      </button>
    )
  } else if (hiddenState(plannedSession.status)) {
    return (
      <>
        <button
          onClick={wrapCallback(toggleSessionVisibility, sessionIndex)}
          className="sw-btn btn-secondary btn-icon h-9 w-9 sw-tooltip tooltip-top"
          data-tooltip="Unhide"
          aria-label="Unhide"
        >
          <FontAwesomeIcon icon={['far', 'eye']} />
        </button>
        <button
          onClick={wrapCallback(toggleSessionDeletion, sessionIndex)}
          className="sw-btn btn-icon is-error w-9 h-9 sw-tooltip tooltip-top"
          data-tooltip="Delete"
          aria-label="Delete"
        >
          <FontAwesomeIcon icon={['far', 'trash-can']} />
        </button>
      </>
    )
  } else {
    return (
      <button
        onClick={wrapCallback(toggleSessionVisibility, sessionIndex)}
        className="sw-btn btn-icon h-9 w-9 sw-tooltip tooltip-top"
        data-tooltip="Hide"
        aria-label="Hide"
      >
        <FontAwesomeIcon icon={['far', 'eye-slash']} />
      </button>
    )
  }
}

export default PlannedSessionVisibilityControls

const wrapCallback = (callback: ToggleSession, sessionIndex: number): MouseEventHandler<HTMLButtonElement> => {
  return e => {
    e.preventDefault()
    e.currentTarget.blur()
    callback(sessionIndex)
  }
}
