import CoachingPlanAttributeEditor from './coaching_plan_attribute_editor'

type Props = {
  autoStartHostedEnvironments: boolean
  onChange: (newValue: boolean) => void
  trainingPlanId: number
}

const AutoStartHostedEnvironmentsEditor = ({ autoStartHostedEnvironments, onChange, trainingPlanId }: Props) => {
  return (
    <CoachingPlanAttributeEditor
      trainingPlanId={trainingPlanId}
      attribute="auto_start_hosted_environments"
      currentValue={autoStartHostedEnvironments}
      onChange={onChange}
      formattedValue={autoStartHostedEnvironments ? 'yes' : 'no'}
      saveLabel="Update Autostart"
      editLabel="Edit Autostart Hosted Environment"
    >
      {({ value, setValue }) => (
        <select
          id="autostart-hosted-env"
          className="sw-select"
          value={value ? '1' : '0'}
          onChange={e => setValue(e.target.value === '1')}
        >
          <option value="0">no</option>
          <option value="1">yes</option>
        </select>
      )}
    </CoachingPlanAttributeEditor>
  )
}

export default AutoStartHostedEnvironmentsEditor
