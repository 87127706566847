import { useState } from 'react'
import LabelledText from '../../library/labelled_text'
import Modal from '../../library/modal'
import SWFormGroup from '../../library/sw/sw_form_group'
import { Module, PlannedSessionState, User } from '../types'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

type OnConfirmParams = {
  userId: number
  moduleKey: string
  requirement: PlannedSessionState
}

type Props = {
  user: User
  moduleKey: string
  module: Module
  currentState: PlannedSessionState
  onClose: () => void
  onConfirm: (d: OnConfirmParams) => void
}

const UpdateCellWithChangeRequestModal = ({ onClose, user, module, moduleKey, currentState, onConfirm }: Props) => {
  const [requirement, setRequirement] = useState(currentState)

  return (
    <Modal
      options={{ active: true }}
      title="Create Change Request"
      onClose={onClose}
      footer={
        <>
          <button type="button" onClick={onClose} className="sw-btn mr-auto">
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            Cancel
          </button>
          <button
            type="submit"
            className={`sw-btn btn-primary`}
            disabled={currentState === requirement}
            onClick={() => onConfirm({ userId: user.id, moduleKey, requirement })}
          >
            Change and Create Change Request
            <FontAwesomeIcon icon={['fas', 'check']} />
          </button>
        </>
      }
    >
      <div className="sw-snippet flex-col p-4 mt-0 mb-8">
        <LabelledText label="Learner" text={user.name} />
        <LabelledText label="Module" text={module.title} />
      </div>
      <SWFormGroup>
        <label>Change To</label>
        <select
          className="sw-select"
          name="attendance_requirement"
          value={requirement}
          onChange={e => setRequirement(e.target.value as PlannedSessionState)}
        >
          <option value={PlannedSessionState.None}>Not Included</option>
          {currentState === PlannedSessionState.Optional && (
            <option disabled value={PlannedSessionState.Optional}>
              Optional
            </option>
          )}
          <option value={PlannedSessionState.Mandatory}>Mandatory</option>
        </select>
      </SWFormGroup>

      <p className="form-input-hint">The change request will be created when the plan is saved</p>
    </Modal>
  )
}

export default UpdateCellWithChangeRequestModal
