import React, { Dispatch, SetStateAction, useState } from 'react'
import AddPlanModule from '../session_editor/add_plan_module'
import { fetchSessionStatuses, getErrorMessage } from '../session_editor/session_editor_helpers'
import { Modules, PlannedSession, PlannedSessionStatus } from '../types'
import { moduleDoesntExistError, moduleInSessionError } from '../utils'
import { Sessions, SharedNotes } from '../../generated_types/training_plan'

type PlanModulePickerProps = {
  known_module_keys: string[]
  setModules: Dispatch<SetStateAction<Modules>>
  trainingPlanId: number
  userIdsInPlan: number[]
  plannedSessions: PlannedSession[]
  updatePlannedSessions: (u: (toUpdate: PlannedSession[]) => void) => void
  updateSharedNotes: (newData: SharedNotes) => void
  addSessions: (newData: Sessions) => void
}
const PlanModulePicker = ({
  known_module_keys,
  setModules,
  trainingPlanId,
  userIdsInPlan,
  plannedSessions,
  updatePlannedSessions,
  updateSharedNotes,
  addSessions
}: PlanModulePickerProps) => {
  const [moduleAddError, setModuleAddError] = useState<string | undefined>(undefined)

  const addPlannedSession = (moduleKey: string) => {
    const warning = moduleInSessionError(plannedSessions, moduleKey)
    if (warning) return warning
    fetchSessionStatuses({ trainingPlanId, userIds: userIdsInPlan, module: moduleKey })
      .then(({ planned_session, modules, shared_notes, sessions }) => {
        updatePlannedSessions(sessions => {
          const toUpdateIndex = sessions.findIndex(session => session.training_module === moduleKey)
          if (toUpdateIndex >= 0) {
            const session = sessions[toUpdateIndex]
            sessions[toUpdateIndex] = { ...session, attendances: planned_session.attendances }
          }
        })
        setModules(current => ({ ...current, ...modules }))
        updateSharedNotes(shared_notes)
        addSessions(sessions)
      })
      .catch((error: unknown) => setModuleAddError(getErrorMessage(error)))

    updatePlannedSessions(sessions => {
      sessions.push({
        id: null,
        training_module: moduleKey,
        attendances: {},
        dbState: null,
        status: PlannedSessionStatus.Included
      })
    })
  }

  return (
    <>
      {moduleAddError && <div className="sw-toast error">{moduleAddError}</div>}
      <AddPlanModule
        onModuleSelect={addPlannedSession}
        onModuleChange={moduleKey => moduleDoesntExistError(known_module_keys, moduleKey)}
      />
    </>
  )
}

export default PlanModulePicker
