import { ControlledMenu, MenuItem, MenuState } from '@szhsin/react-menu'
import { Attendance, UserMap } from './types'
import { AttendanceStatus, CalendarInvitationStatus } from '../generated_types/training_session'

type Props = {
  attendances: Attendance[]
  users: UserMap
  anchorPoint: { x: number; y: number }
  menuProps: {
    state?: MenuState
    endTransition: () => void
  }
  onClose: () => void
}

const AttendancesEmailMenu = ({ attendances, users, menuProps, anchorPoint, onClose }: Props) => {
  const copyEmails = (predicate: (a: Attendance) => boolean) => {
    const emails = attendances
      .filter(predicate)
      .map(a => users[a.user_id].email)
      .join(', ')
    navigator.clipboard.writeText(emails)
  }

  const copyAll = () => copyEmails(() => true)
  const copyAwaitingResponse = () =>
    copyEmails(a => a === null || a.invitation_status === CalendarInvitationStatus.NeedsAction)
  const copyDeclined = () => copyEmails(a => a.invitation_status === CalendarInvitationStatus.Declined)
  const copyNotDeclined = () => copyEmails(a => a.invitation_status !== CalendarInvitationStatus.Declined)
  const copyAbsent = () => copyEmails(a => a.status === AttendanceStatus.Absent)

  return (
    <ControlledMenu {...menuProps} anchorPoint={anchorPoint} onClose={onClose}>
      <MenuItem onClick={copyAll}>Copy All</MenuItem>
      <MenuItem onClick={copyAwaitingResponse}>Copy Awaiting Response</MenuItem>
      <MenuItem onClick={copyDeclined}>Copy Declined</MenuItem>
      <MenuItem onClick={copyNotDeclined}>Copy Not Declined</MenuItem>
      <MenuItem onClick={copyAbsent}>Copy Confirmed Absent</MenuItem>
    </ControlledMenu>
  )
}

export default AttendancesEmailMenu
