import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  AvailableTrainer,
  CoachTimeslotMapping,
  Learner,
  Modules,
  PlannedSession,
  SelectedPlanCells,
  SessionDuration,
  Unavailabilities,
  defaultSessionDuration
} from '../../types'
import { objectAdd, objectRemove, objectShallowEquals } from '../../../utils/object_helpers'
import { parseLondonDateToUTC } from '../../../utils/date_helpers'
import { UserUnavailabilitiesQuery } from '../../../utils/api/types'
import { getRequest } from '../../../utils/json_request'
import { UserUnavailabilitiesPath } from '../../../utils/api/paths'
import { generateModuleStub, isSessionSelectable, parseUnavailabilities } from '../session_editor_helpers'
import {
  Module,
  SessionDataForModule,
  SharedNotesForModule,
  TrainingPlanUsers
} from '../../../generated_types/training_plan'

export type SchedulerContextData = {
  learnerUnavailabilities: Unavailabilities
  trainerUnavailabilities: Unavailabilities
  onSchedulerDateChange: (duration: Partial<SessionDuration>) => void
  onSchedulerDateClear: () => void
  selectedSessionDuration: SessionDuration
  selectedTrainerId: number
  setSelectedTrainerId: Dispatch<SetStateAction<number>>
  selectedCells: SelectedPlanCells
  setSelectedCells: Dispatch<SetStateAction<SelectedPlanCells>>
  toggleSelectedCell: (
    userId: number,
    plannedSession: PlannedSession,
    sharedNotesForModule?: SharedNotesForModule,
    sessionsForModule?: SessionDataForModule
  ) => void
  assignedTrainers: AvailableTrainer[]
  timeslotsByCoach: CoachTimeslotMapping
  sessionCode?: string
  uneditableCells?: SelectedPlanCells
}

export const SchedulerContext = createContext<SchedulerContextData | null>(null)

type SchedulerContextProviderProps = PropsWithChildren<{
  trainingPlanIds: number[] | number | null
  learners: Learner[]
  assignedTrainers: AvailableTrainer[]
  available_learners: TrainingPlanUsers
  modules: Modules
  timeslotsByCoach: CoachTimeslotMapping
  initialSessionDuration?: SessionDuration
  initialSelectedTrainerId?: number
  initialSelectedCells?: SelectedPlanCells
  /*
   * If set learners will be added to this session rather than a new session created.
   * No other changes to the session (eg modules, coach, time etc.) are permitted
   */
  sessionCode?: string
}>

export const SchedulerContextProvider = ({
  trainingPlanIds,
  learners,
  assignedTrainers,
  available_learners,
  modules,
  timeslotsByCoach,
  initialSessionDuration,
  initialSelectedTrainerId,
  initialSelectedCells,
  sessionCode,
  children
}: SchedulerContextProviderProps) => {
  /**
   * Used to delay api calls in onSchedulerDateChange until callback calls cease for 1 second
   * (this reduces api calls during rapid element changes)
   */
  const schedulerDateChangeTimeout = useRef<number | undefined>(undefined)

  const [learnerUnavailabilities, setLearnerUnavailabilities] = useState<Unavailabilities>({})
  const [trainerUnavailabilities, setTrainerUnavailabilities] = useState<Unavailabilities>({})
  const [selectedSessionDuration, setSelectedSessionDuration] = useState<SessionDuration>(
    initialSessionDuration ?? defaultSessionDuration
  )
  const [selectedTrainerId, setSelectedTrainerId] = useState(initialSelectedTrainerId ?? -1)
  const [selectedCells, _setSelectedCells] = useState<SelectedPlanCells>(
    initialSelectedCells ?? { modules: [], users: {} }
  )

  const setSelectedCells: Dispatch<SetStateAction<SelectedPlanCells>> = useCallback(
    valueOrUpdater => {
      _setSelectedCells(current => {
        const newValue = typeof valueOrUpdater === 'function' ? valueOrUpdater(current) : valueOrUpdater

        // when editing a session, changes are restricted:
        // - the modules can't be changed at all
        // - the initial set of learners can't be removed (extra learners can be added)
        if (sessionCode && initialSelectedCells) {
          newValue.modules = initialSelectedCells.modules
          newValue.users = { ...initialSelectedCells.users, ...newValue.users }
        }
        return newValue
      })
    },
    [sessionCode, initialSelectedCells]
  )

  const updateUnavailabilities = useCallback(
    async (duration: SessionDuration) => {
      const duration_in_minutes = +duration.duration_in_minutes
      if (
        !duration.start_date ||
        !duration.start_time ||
        !Number.isInteger(duration_in_minutes) ||
        duration_in_minutes < 1
      )
        return

      const from = parseLondonDateToUTC(duration.start_date, duration.start_time)
      if (!from) return

      const upto = new Date(from.getTime() + duration_in_minutes * 60000)
      const training_plan_id = trainingPlanIds ?? undefined

      clearTimeout(schedulerDateChangeTimeout.current)

      schedulerDateChangeTimeout.current = window.setTimeout(() => {
        const learnersQuery: UserUnavailabilitiesQuery = {
          user_ids: learners.map(l => l.user_id),
          from,
          upto,
          training_plan_id
        }
        getRequest(UserUnavailabilitiesPath, learnersQuery).then(response => {
          if (response.ok) setLearnerUnavailabilities(parseUnavailabilities(response.data.unavailabilities))
        })

        const assignedTrainerIds = assignedTrainers.map(trainer => trainer.id)
        if (assignedTrainerIds.length > 0) {
          const trainersQuery: UserUnavailabilitiesQuery = {
            user_ids: assignedTrainerIds,
            from,
            upto,
            training_plan_id
          }
          getRequest(UserUnavailabilitiesPath, trainersQuery).then(response => {
            if (response.ok) setTrainerUnavailabilities(parseUnavailabilities(response.data.unavailabilities))
          })
        }
      }, 1000)
    },
    [trainingPlanIds, learners, assignedTrainers]
  )

  // if the provider was initialized with non trivial selected duration we need to
  // fetch the unavailability data on mount
  const initialUnavailabilityCheckComplete = useRef(false)
  useEffect(() => {
    if (!initialUnavailabilityCheckComplete.current) {
      initialUnavailabilityCheckComplete.current = true
      updateUnavailabilities(selectedSessionDuration)
    }
  }, [updateUnavailabilities, selectedSessionDuration])

  const onSchedulerDateChange = useCallback(
    (updates: Partial<SessionDuration>) => {
      setSelectedSessionDuration(current => {
        const duration = { ...current, ...updates }
        if (objectShallowEquals(duration, current)) return current
        updateUnavailabilities(duration)
        return duration
      })
    },
    [updateUnavailabilities]
  )

  const onSchedulerDateClear = () => {
    setSelectedSessionDuration(defaultSessionDuration)
    setTrainerUnavailabilities({})
    setLearnerUnavailabilities({})
  }

  const toggleSelectedCell = useCallback(
    (
      userId: number,
      plannedSession: PlannedSession,
      sharedNotesForModule?: SharedNotesForModule,
      sessionsForModule?: SessionDataForModule
    ) => {
      if (
        isSessionSelectable(
          plannedSession.attendances[userId],
          sharedNotesForModule?.[userId],
          sessionsForModule?.[userId]
        )
      ) {
        const user = available_learners[userId]
        const moduleKey = plannedSession.training_module
        const module = modules[moduleKey] ?? generateModuleStub(moduleKey)

        setSelectedCells(currentValue => {
          return toggleSelectedPlanCell(currentValue, moduleKey, module, user)
        })
      }
    },
    [available_learners, modules, setSelectedCells]
  )

  const data = {
    learnerUnavailabilities,
    trainerUnavailabilities,
    onSchedulerDateChange,
    onSchedulerDateClear,
    selectedSessionDuration,
    selectedTrainerId,
    setSelectedTrainerId,
    selectedCells,
    setSelectedCells,
    toggleSelectedCell,
    assignedTrainers,
    timeslotsByCoach,
    sessionCode,
    uneditableCells: sessionCode ? initialSelectedCells : undefined
  }

  return <SchedulerContext.Provider value={data}>{children}</SchedulerContext.Provider>
}

const useScheduler = () => {
  const data = useContext(SchedulerContext)
  if (!data) {
    throw new Error('useScheduler must be called from a component with a SchedulerContextProvider parent')
  }
  return data
}

const toggleSelectedPlanCell = (
  currentValue: SelectedPlanCells,
  moduleKey: string,
  module: Module,
  user: TrainingPlanUsers[number]
): SelectedPlanCells => {
  const userId = user.id
  let newModules = currentValue.modules
  let newUsers = currentValue.users

  const moduleSelected = !!currentValue.modules.find(module => module.key === moduleKey)
  const userSelected = !!currentValue.users[userId]

  if (!moduleSelected && !userSelected) {
    newModules = [...currentValue.modules, { ...module, key: moduleKey }]
    newUsers = objectAdd(userId, user, currentValue.users)
  }
  if (moduleSelected && !userSelected) newUsers = objectAdd(userId, user, currentValue.users)
  if (!moduleSelected && userSelected) newModules = [...currentValue.modules, { ...module, key: moduleKey }]
  if (moduleSelected && userSelected) {
    newUsers = objectRemove(userId, currentValue.users)
    if (Object.keys(newUsers).length < 1) newModules = []
  }

  return {
    modules: newModules,
    users: newUsers
  }
}
export { toggleSelectedPlanCell }
export default useScheduler
