import { DndProvider } from 'react-dnd'
import { MultiPlanViewerPageProps, PlanMetadata, Sessions, SharedNotes } from '../../generated_types/training_plan'
import PlannedSessionRow from '../session_editor/planned_session_row'
import {
  currentAttendance,
  determinePlanDependencies,
  hiddenState,
  parseAttendances
} from '../session_editor/session_editor_helpers'
import { UserFilter, PlannedSession, TrainingPlanContext } from '../types'
import { HTML5Backend } from 'react-dnd-html5-backend'
import MultiPlanHeader from './multi_plan_header'
import useScheduler from '../session_editor/scheduler_panel/use_scheduler'
import { useCallback, useMemo, useRef, useState } from 'react'
import SchedulerPanel from '../session_editor/scheduler_panel/scheduler_panel'
import SessionStatusInfoModal from '../session_editor/session_status_info_modal'

type Props = {
  plannedSessions: PlannedSession[]
  trainingPlans: MultiPlanViewerPageProps['training_plans']
  modules: MultiPlanViewerPageProps['modules']
  indefinitePauses: UserFilter
  showHidden: boolean
  trainingPlanContext: TrainingPlanContext
  sharedNotes: SharedNotes
  sessions: Sessions
}

const SessionsTable = ({
  plannedSessions,
  indefinitePauses,
  showHidden,
  trainingPlanContext,
  trainingPlans,
  sharedNotes: initialSharedNotes,
  sessions: initialSessions
}: Props) => {
  const cellsSelectable = useRef(true)
  const showScheduler = true
  const companyId = trainingPlans[0].company_id //a bit grubby. affects what company scheduled sessions are created in
  const autoStartHostedEnvironments = trainingPlans[0].auto_start_hosted_environments //also a bit grubby

  const { selectedSessionDuration, learnerUnavailabilities, selectedCells, toggleSelectedCell } = useScheduler()

  const [sharedNotes, setSharedNotes] = useState(initialSharedNotes)
  const [sessions, _setSessions] = useState(initialSessions)

  const [displaySessionInfoModalFor, setDisplaySessionInfoModalFor] = useState<
    { userId: number; moduleKey: string } | undefined
  >()

  const planDependencies = useMemo(
    () =>
      determinePlanDependencies({
        userIds: trainingPlanContext.learners.map(l => l.user_id),
        modules: trainingPlanContext.modules,
        plannedSessions: plannedSessions,
        selectedDuration: selectedSessionDuration,
        sharedNotes,
        sessions
      }),
    [
      trainingPlanContext.modules,
      plannedSessions,
      trainingPlanContext.learners,
      selectedSessionDuration,
      sharedNotes,
      sessions
    ]
  )

  // calculate these together because they have to stay in sync. if the order of the groups and the order of the plans
  // didn't match then column headers and the actual users wouldn't match
  const { learnerGroups, sortedTrainingPlans } = useMemo(() => {
    const sortedTrainingPlans = sortTrainingPlans(trainingPlans, indefinitePauses)

    return {
      learnerGroups: sortedTrainingPlans.map(tp => tp.learners),
      sortedTrainingPlans
    }
  }, [trainingPlans, indefinitePauses])

  const handleCellClick = useCallback(
    ({
      groupIndex,
      userIndexInGroup,
      sessionIndex
    }: {
      groupIndex: number
      userIndexInGroup: number
      sessionIndex: number
    }) => {
      const userId = sortedTrainingPlans[groupIndex].learners[userIndexInGroup].user_id
      if (cellsSelectable.current) {
        const plannedSession = plannedSessions[sessionIndex]
        toggleSelectedCell(
          userId,
          plannedSession,
          sharedNotes[plannedSession.training_module],
          sessions[plannedSession.training_module]
        )
      }
    },
    [sortedTrainingPlans, toggleSelectedCell, plannedSessions, sharedNotes, sessions]
  )

  const plannedSessionsRows = plannedSessions.map((session, sessionIndex) => {
    if (hiddenState(session.status) && !showHidden) {
      // doing this (instead of filtering plannedSessions) means that the sessionIndex for a session doesn't vary depending on hidden-ness
      return null
    }
    return (
      <PlannedSessionRow
        key={session.id || session.training_module}
        trainingPlanContext={trainingPlanContext}
        session={session}
        learnerUnavailability={learnerUnavailabilities}
        usersDependencies={planDependencies[session.training_module]}
        selectedCells={selectedCells}
        sessionIndex={sessionIndex}
        indefinitePauses={indefinitePauses}
        cellClicked={handleCellClick}
        toggleSessionVisibility={noOp}
        toggleSessionDeletion={noOp}
        updateOrdering={noOp}
        startEditing={noOp}
        editable={true}
        scheduler={showScheduler}
        changeRequestIdsToReject={[]}
        setChangeRequestIdsToReject={noOp}
        learnerGroups={learnerGroups}
        sharedNotesForModule={sharedNotes[session.training_module]}
        setSharedNotes={setSharedNotes}
        sessionsForModule={sessions[session.training_module]}
        updateDoNotSchedule={noOp}
        setDisplayEditCellModalFor={noOp}
        setDisplaySessionInfoModalFor={setDisplaySessionInfoModalFor}
      />
    )
  })

  return (
    <>
      <div className="table-wrapper">
        <div id="table-menus" /> {/* Portal target for header menus (see TrainingPlanHeader) */}
        <DndProvider backend={HTML5Backend}>
          <table className="sw-simple-table training-plan">
            <MultiPlanHeader
              trainingPlans={sortedTrainingPlans}
              available_learners={trainingPlanContext.available_learners}
              indefinitePauses={indefinitePauses}
            />
            <tbody>{plannedSessionsRows}</tbody>
          </table>
        </DndProvider>
      </div>
      <SchedulerPanel
        modules={trainingPlanContext.modules}
        dependencies={planDependencies}
        attendances={parseAttendances(plannedSessions)}
        sharedNotes={sharedNotes}
        companyId={companyId}
        setCellsSelectable={() => (cellsSelectable.current = true)}
        autoStartHostedEnvironments={autoStartHostedEnvironments}
        sessions={sessions}
      />
      {displaySessionInfoModalFor && (
        <SessionStatusInfoModal
          learnerName={trainingPlanContext.available_learners[displaySessionInfoModalFor.userId].name}
          sessionTitle={trainingPlanContext.modules[displaySessionInfoModalFor.moduleKey].title}
          sessionUserData={currentAttendance({ ...displaySessionInfoModalFor, plannedSessions })}
          sessionStatuses={sessions[displaySessionInfoModalFor.moduleKey]?.[displaySessionInfoModalFor.userId]}
          onClose={() => setDisplaySessionInfoModalFor(undefined)}
          showCRResponse={true}
        />
      )}
    </>
  )
}

const noOp = () => {}

const sortTrainingPlans = (plans: PlanMetadata[], indefinitePauses: UserFilter) => {
  const [primary, ...others] = plans

  others.sort((planA, planB) => activeUserCount(planA, indefinitePauses) - activeUserCount(planB, indefinitePauses))

  return [primary, ...others]
}

const activeUserCount = (plan: PlanMetadata, indefinitePauses: UserFilter) => {
  if (indefinitePauses.show) return plan.learners.length
  return plan.learners.filter(learner => !indefinitePauses.userIds.includes(learner.user_id)).length
}
export default SessionsTable
