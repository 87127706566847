import { FlashAlert } from '../../library/flash_message'
import { usePlanVersion } from './use_plan_version'

const CoachingPlanVersionWarning = () => {
  const { lockVersions, remoteLockVersions } = usePlanVersion()

  if (Object.keys(lockVersions).every(planId => lockVersions[planId] === remoteLockVersions[planId])) {
    return null
  }

  return (
    <FlashAlert sticky className="animate-[bounce_1s_1.5]">
      <span className="sw-tooltip text-xl">
        Another user has updated this plan. Please reload to see the latest version
      </span>
    </FlashAlert>
  )
}

export default CoachingPlanVersionWarning
