import { useState, useCallback, ReactNode } from 'react'
import { CommentCreationPath, FormattedComment, PersistedComment } from './types'
import Comment from './comment'
import Composer from './composer'
import simpleFormat from '../utils/simple_format'
import Toggle from '../library/toggle'

const formatComment = (comment: PersistedComment): FormattedComment => {
  return { ...comment, formattedBody: simpleFormat(comment.body) }
}

type Props = {
  path: CommentCreationPath
  comments: PersistedComment[]
  editable: boolean
  title?: ReactNode
}

const Comments = ({ path, comments: initialComments, editable, title }: Props) => {
  const [showHidden, setShowHidden] = useState(false)
  const [comments, setComments] = useState(() => {
    return initialComments.map(comment => formatComment(comment))
  })

  const updateComment = useCallback((comment: PersistedComment) => {
    setComments(comments => {
      const updatedIndex = comments.findIndex(c => c.id === comment.id)
      const newComments = [...comments]
      newComments[updatedIndex] = formatComment(comment)
      return newComments
    })
  }, [])

  const filteredComments = showHidden ? comments : comments.filter(comment => !comment.hidden)
  const commentComponents = filteredComments.map(comment => (
    <Comment
      key={comment.id}
      comment={comment}
      editable={editable}
      commentUpdated={comment => updateComment(comment)}
    />
  ))

  let addCommentComponent: ReactNode | null = null
  const addComment = useCallback((newComment: PersistedComment) => {
    setComments(existing => [formatComment(newComment), ...existing])
  }, [])
  if (editable) {
    addCommentComponent = <Composer onCommentPersisted={addComment} comment={{ body: '', path, hidden: false }} />
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        {title ?? <h4 className="my-3">Comments</h4>}
        <Toggle
          checked={showHidden}
          onChange={value => setShowHidden(value)}
          label="Show hidden comments"
          classNames="mr-0"
        />
      </div>
      <div className="p-4 border-2 border-lightergrey bg-lightestgrey/20 rounded-md">
        {addCommentComponent}
        {!!commentComponents.length && <div className="sw-divider" />}
        {commentComponents}
      </div>
    </div>
  )
}

export default Comments
