import { MouseEventHandler } from 'react'
import { Formik, Form } from 'formik'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import Modal from '../library/modal'
import { ModelErrors } from '../library/errors'
import { Pause, UserContext } from './types'

type Props = {
  onCancel: () => void
  errors: ModelErrors
  pause: Pause
  onSubmit: (s: Pause) => void
  user: UserContext['user']
}
const DeletePauseModal = ({ onCancel, errors, onSubmit, pause, user }: Props) => {
  const closeModal: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    onCancel()
  }

  let datesDescription = (
    <>
      from <em>{pause.start_date}</em>
    </>
  )
  if (pause.end_date) {
    datesDescription = (
      <>
        {datesDescription} to <em>{pause.end_date}</em>
      </>
    )
  }

  const prompt = user.is_self ? (
    <>Delete pause {datesDescription}?</>
  ) : (
    <>
      Delete pause {datesDescription} for <strong>{user.name}</strong>?
    </>
  )

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={pause} //react complains if the value of an input is set to null
    >
      {({ isSubmitting }) => (
        <Form>
          <Modal
            options={{ active: true }}
            title={`Delete Pause`}
            onClose={closeModal}
            footer={
              <>
                <a onClick={closeModal} className="sw-btn mr-auto">
                  <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                  Cancel
                </a>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
                >
                  Delete
                  <FontAwesomeIcon icon={['far', 'trash-can']} />
                </button>
              </>
            }
          >
            {errors.base ? <div className="sw-toast error">{errors.base.join(', ')}</div> : null}
            {prompt}
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

export default DeletePauseModal
