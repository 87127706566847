import { application } from './application'
import RevealController from '@skiller-whale/style/controllers/reveal'
import OverflowController from '@skiller-whale/style/controllers/overflow'
import UrlFragmentController from './url_fragment_controller'
import CoachMessagingModalController from './coach_messaging_modal_controller'
import ToggleController from './toggle_controller'
import TabGroupController from './tab_group_controller'
import CurriculumPermissionsController from './curriculum_permissions'
import DebouncedFormController from './debounced_form_controller'

application.register('overflow', OverflowController)
application.register('reveal', RevealController)
application.register('url-fragment', UrlFragmentController)
application.register('coach-messaging-modal', CoachMessagingModalController)
application.register('tab-group', TabGroupController)
application.register('toggle', ToggleController)
application.register('curriculum-permissions', CurriculumPermissionsController)
application.register('debounced-form', DebouncedFormController)
