import { useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import jsonRequest, { useSave } from '../utils/json_request'
import UserPicker from '../user_picker'
import Empty from '../library/empty'

import EditableDatetime from './editable_datetime'
import Warning from './warning'

import {
  DeleteRecordingSharePayload,
  NewRecordingSharePayload,
  RecordingShareResponse,
  RecordingShareUser,
  RecordingShares,
  UpdateRecordingSharePayload
} from './types'
import LoadingButton from '../library/loading_button'

type RecordingSharePanelProps = {
  users: RecordingShareUser[]
  shares: RecordingShares
  trainingSessionCode: string
}

const RecordingSharePanel = ({ users, shares: initialShares, trainingSessionCode }: RecordingSharePanelProps) => {
  const [shares, setShares] = useState(() =>
    Object.fromEntries(initialShares.map(s => [s.id, { ...s, expires_at: new Date(s.expires_at) }]))
  )
  const [deleting, setDeleting] = useState(new Set())
  const [updating, setUpdating] = useState(new Set())
  const [saveShare, _errors, savingShare, _reset] = useSave<
    NewRecordingSharePayload,
    undefined,
    RecordingShareResponse
  >()

  const sharedUsers = new Set(Object.values(shares).map(s => s.user_id))
  const addableUsers = users.filter(u => !sharedUsers.has(u.id))

  const shareWithUser = async (user: RecordingShareUser) => {
    const { ok, data } = await saveShare('/recording_shares', {
      method: 'POST',
      payload: {
        recording_share: {
          user_id: user.id,
          training_session_code: trainingSessionCode
        }
      }
    })

    if (ok) {
      const newShare = {
        id: data.recording_share.id,
        user_id: data.recording_share.user_id,
        user_name: user.name,
        accessible: data.recording_share.accessible,
        expires_at: new Date(data.recording_share.expires_at)
      }

      setShares(current => ({ ...current, [newShare.id]: newShare }))
    }
  }

  const deleteShare = async (idToDelete: number) => {
    setDeleting(current => new Set([...current]).add(idToDelete))

    const { ok } = await jsonRequest<DeleteRecordingSharePayload, undefined, null>(`/recording_shares/${idToDelete}`, {
      method: 'DELETE',
      payload: {}
    }).finally(() => {
      setDeleting(current => {
        const result = new Set([...current])
        result.delete(idToDelete)
        return result
      })
    })

    if (ok) {
      setShares(({ [idToDelete]: _, ...rest }) => rest)
    }
  }

  const setExpiresAt = async (id: number, value: Date) => {
    //invalid date entry
    if (isNaN(value.getTime())) {
      return
    }

    setUpdating(current => new Set([...current]).add(id))
    const { ok, data } = await jsonRequest<UpdateRecordingSharePayload, undefined, RecordingShareResponse>(
      `/recording_shares/${id}`,
      {
        method: 'PATCH',
        payload: {
          recording_share: {
            expires_at: value.toISOString()
          }
        }
      }
    ).finally(() => {
      setUpdating(current => {
        const result = new Set([...current])
        result.delete(id)
        return result
      })
    })

    if (ok) {
      const updates = {
        accessible: data.recording_share.accessible,
        expires_at: new Date(data.recording_share.expires_at)
      }
      setShares(({ [id]: toUpdate, ...rest }) => ({ ...rest, [id]: { ...toUpdate, ...updates } }))
    }
  }

  const sharesTable =
    Object.entries(shares).length > 0 ? (
      <table className="sw-table w-full mt-8">
        <thead>
          <tr>
            <th>User</th>
            <th>Expires At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Object.values(shares).map(share => (
            <tr key={share.id}>
              <td className="align-middle">{share.user_name}</td>
              <td className="align-middle whitespace-nowrap">
                <EditableDatetime
                  value={share.expires_at}
                  setValue={newValue => setExpiresAt(share.id, newValue)}
                  updating={updating.has(share.id)}
                />
              </td>
              <td>
                <div className="flex justify-end gap-4">
                  {share.accessible ? '' : <Warning />}
                  <LoadingButton
                    onClick={() => deleteShare(share.id)}
                    loading={deleting.has(share.id)}
                    data-share-id={share.id} // For spec testing
                    className="sw-btn btn-icon is-error"
                    aria-label="Delete Share"
                  >
                    <FontAwesomeIcon icon={['fas', 'xmark']} />
                  </LoadingButton>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <Empty
        title="Recording not shared"
        icon={<FontAwesomeIcon icon={['fas', 'file-video']} size="5x" />}
        action={<p>Share this recording with someone using the drop down above.</p>}
        classNames="mt-8"
      />
    )

  return (
    <div>
      <UserPicker users={addableUsers} userSelected={shareWithUser} label="Add" loading={savingShare} />
      {sharesTable}
    </div>
  )
}
export default RecordingSharePanel
