import { FlashWarning } from '../../library/flash_message'
import usePresence from './use_presence'

const CoachingPlanPresence = () => {
  const { viewers } = usePresence()

  if (!viewers || viewers.length === 0) {
    return null
  }

  return (
    <FlashWarning sticky className="animate-[bounce_1s_1.5]">
      <span
        className="sw-tooltip tooltip-left text-xl"
        data-tooltip={viewers.map(v => `${v.user.name}: ${v.mode}`).join('\n')}
      >
        {viewers.length} other {viewers.length === 1 ? 'viewer' : 'viewers'}
      </span>
    </FlashWarning>
  )
}

export default CoachingPlanPresence
