import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

const Warning = () => {
  const tooltip =
    "This share is not currently active.\nThis might be because it has expired,\nor because user doesn't have privileges."

  return (
    <div className="sw-tooltip tooltip-left" data-tooltip={tooltip}>
      <FontAwesomeIcon icon={['fas', 'circle-exclamation']} className="text-warning-highlight h-8 w-8" />
    </div>
  )
}

export default Warning
