import { MouseEventHandler, PropsWithChildren, ReactNode } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { PropsWithClassNames } from '../utils/types'

export type ModalOptions = {
  /**
   * Set Modal as visible
   * Defaults to true
   */
  active?: boolean
  /**
   * Displays a loading state
   */
  submitting?: boolean
  /**
   * Display Modal using the "focus" design variant
   */
  focus?: boolean
  /**
   * Display Modal with the "drawer" design modifier
   */
  drawer?: boolean
  /**
   * Shows a background overlay that closes the modal when clicked
   * Defaults to true
   */
  dismissOnClick?: boolean
  /**
   * Will call event.stopPropagation() for click events inside the modal container
   * Useful for modals rendered inside elements with onClick handlers
   */
  stopPropagation?: boolean
}

export type ModalProps = PropsWithChildren &
  PropsWithClassNames & {
    id?: string
    title?: ReactNode
    header?: ReactNode
    controls?: ReactNode
    footer?: ReactNode
    body?: ReactNode
    onClose?: MouseEventHandler<HTMLAnchorElement>
    footerClassNames?: string
    options?: ModalOptions
  }

const Modal = ({
  header,
  controls,
  footer,
  body,
  id,
  title,
  onClose,
  children,
  classNames = '',
  footerClassNames = '',
  options
}: ModalProps) => {
  const {
    active = false,
    focus = false,
    drawer = false,
    submitting = false,
    dismissOnClick = true,
    stopPropagation = false
  } = options || {}

  const headerContent = header ? (
    <div className="modal-header">{header}</div>
  ) : title ? (
    <div className="modal-header">
      <h3 className="truncate">{title}</h3>
      {!submitting && (
        <a href="#" onClick={onClose} className="sw-btn btn-close shrink-0 ml-auto" aria-label="Close">
          <FontAwesomeIcon icon={['fas', 'circle-xmark']} />
        </a>
      )}
    </div>
  ) : null

  const modalClasses = ['sw-modal', focus ? 'modal-focus' : '', active ? 'active' : '', classNames]
  const containerClasses = ['modal-container', drawer ? 'modal-drawer' : '']
  const footerClasses = ['modal-footer', footerClassNames]

  return (
    <div
      className={modalClasses.join(' ')}
      id={id}
      onContextMenu={e => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      {dismissOnClick && <a href="#" onClick={onClose} className="modal-overlay" aria-label="Close" role="dialog" />}
      {controls && <div className="modal-controls">{controls}</div>}
      <div
        className={containerClasses.join(' ')}
        onClick={stopPropagation ? event => event.stopPropagation() : undefined}
      >
        {headerContent}
        <div className="modal-body">
          {body}
          {children}
        </div>
        {footer && <div className={footerClasses.join(' ')}>{footer}</div>}
      </div>
    </div>
  )
}

export default Modal
