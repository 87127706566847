import React, { ComponentType, MouseEventHandler, ReactNode, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import Errors from './errors'
import { useApiSave } from '../utils/json_request'
import Empty from './empty'
import API from '../utils/api/api'
import { UserStub } from '../generated_types/training_plan'

const numberOfLines = (string: string) => {
  return string.split('\n').length
}

type EditorProps<T extends keyof API> = {
  source: string
  url: T
  serialize: (body: string) => API[T]['PATCH']['request']
  onUpdate: (e: API[T]['PATCH']['response']) => void
  onCancel: () => void
  rows?: number
}

const Editor = <T extends keyof API>({ source, url, onUpdate, onCancel, serialize, rows }: EditorProps<T>) => {
  const { save, errors, saving, resetErrors } = useApiSave(url, 'PATCH')
  const [body, setBody] = useState(source)
  rows ||= Math.max(4, numberOfLines(body || '') + 1)

  const handleSave: MouseEventHandler = async e => {
    resetErrors()
    e.stopPropagation()
    e.preventDefault()

    const { ok, data } = await save({ payload: serialize(body) })

    if (ok) {
      onUpdate(data)
    }
  }

  let buttonClasses = 'sw-btn btn-primary btn-sm ml-auto'

  if (saving) {
    buttonClasses = buttonClasses.concat(' sw-loading')
  }

  return (
    <>
      <Errors errors={errors} as="p" />
      <textarea className="sw-input w-full my-2" rows={rows} value={body} onChange={e => setBody(e.target.value)} />
      <div className="flex">
        <button disabled={saving} onClick={onCancel} className="sw-btn btn-sm">
          <FontAwesomeIcon icon={['far', 'rectangle-xmark']} />
          Cancel
        </button>
        <button disabled={saving} onClick={handleSave} className={buttonClasses}>
          Save
          <FontAwesomeIcon icon={['far', 'floppy-disk']} />
        </button>
      </div>
    </>
  )
}

type EditorContent = {
  source: string
  formatted: string
  updated_at?: string | null
  updated_by?: UserStub | null
}

type UseEditorProps<T extends keyof API> = {
  source: string
  formatted: string
  url: T
  serialize: (body: string) => API[T]['PATCH']['request']
  parse: (data: API[T]['PATCH']['response']) => EditorContent
  rows?: number
  onUpdate?: (e: EditorContent) => void
  updated_at?: string | null
  updated_by?: UserStub | null
}

export type DisplayComponentProps = {
  source: string
  formatted: string
  startEditing: () => void
}

const useEditor = <T extends keyof API>(
  DisplayComponent: ComponentType<DisplayComponentProps>,
  {
    source: initialSource,
    formatted: initialFormatted,
    parse,
    serialize,
    url,
    rows,
    onUpdate,
    updated_at: initial_updated_at,
    updated_by: initial_updated_by
  }: UseEditorProps<T>
) => {
  const [editing, setEditing] = useState(false)
  const [source, setSource] = useState(initialSource)
  const [formatted, setFormatted] = useState(initialFormatted)
  const [updatedAt, setUpdatedAt] = useState(initial_updated_at || null)
  const [updatedBy, setUpdatedBy] = useState(initial_updated_by || null)

  const startEditing = () => {
    setEditing(true)
  }
  const editCancelled = () => {
    setEditing(false)
  }

  let content: ReactNode

  if (editing) {
    const handleUpdate = (data: API[T]['PATCH']['response']) => {
      setEditing(false)
      const newData = parse(data)
      setSource(newData.source)
      setFormatted(newData.formatted)
      setUpdatedAt(newData.updated_at || null)
      setUpdatedBy(newData.updated_by || null)
      if (onUpdate) {
        onUpdate({
          source: newData.source,
          formatted: newData.formatted,
          updated_at: newData.updated_at,
          updated_by: newData.updated_by
        })
      }
    }
    content = (
      <Editor
        url={url}
        rows={rows}
        serialize={serialize}
        source={source}
        onUpdate={handleUpdate}
        onCancel={editCancelled}
      />
    )
  } else {
    content = <DisplayComponent source={source} formatted={formatted} startEditing={startEditing} />
  }

  return { content, updatedAt, updatedBy }
}

type EditableContentProps = {
  formatted: string
  startEditing: () => void
  emptyStateTitle?: string
  emptyStateAction: ReactNode
  classNames?: string
}
const EditableContent = ({
  formatted,
  startEditing,
  emptyStateTitle,
  emptyStateAction,
  classNames = ''
}: EditableContentProps) => {
  let content: ReactNode = null
  if (formatted) {
    content = (
      <div className={`editable-content ${classNames}`}>
        <div dangerouslySetInnerHTML={{ __html: formatted }} />
        <div className="flex mt-4">
          <button className="sw-btn btn-primary btn-sm ml-auto" onClick={startEditing}>
            <FontAwesomeIcon icon={['far', 'pen-to-square']} />
            Edit
          </button>
        </div>
      </div>
    )
  } else {
    content = (
      <Empty
        icon={<FontAwesomeIcon icon={['far', 'rectangle-xmark']} size="3x" />}
        title={emptyStateTitle}
        action={
          <button className="sw-btn btn-primary" onClick={startEditing}>
            {emptyStateAction}
          </button>
        }
        classNames="mt-2"
      />
    )
  }
  return content
}

export default Editor

export { useEditor, EditableContent }
