import CurriculumIcon from '../library/curriculum_icon'

type CurriculumHeaderProps = {
  title: string
  technology: string
}

const CurriculumHeader = ({ title, technology }: CurriculumHeaderProps) => {
  return (
    <div className="curriculum-header-wrapper">
      <div className="sw-header header-waveball curriculum-header">
        <h2 className="m-0 leading-tight">{title}</h2>
        <div className="curriculum-icon">
          <CurriculumIcon technologyKey={technology} />
        </div>
      </div>
    </div>
  )
}

export default CurriculumHeader
