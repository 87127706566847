import { InputHTMLAttributes } from 'react'

interface SWCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode
  classNames?: string
}

/**
 * Version of library/checkbox using sw-* classes
 */
const SWCheckbox = ({ label, classNames = '', className = '', ...props }: SWCheckboxProps) => {
  return (
    <label className={`sw-label ${classNames}`}>
      <input type="checkbox" className={`sw-input ${className}`} {...props} />
      {label}
    </label>
  )
}

export default SWCheckbox
