import { TrainingSessionState, TrainingSessionStatus } from '../types'
import { isAfter as fnsIsAfter } from 'date-fns/isAfter'

/**
 * Determines from a sessions array the appropriate session to display in training plan cell
 * Returns undefined when no session determined
 */
const getDisplaySession = (sessions?: TrainingSessionStatus[]) => {
  if (!sessions?.length) return undefined
  const now = Date.now() // Kept within function for testing

  // Assume sessions already in ascending order from BE
  const futureSessions = sessions.filter(session => fnsIsAfter(new Date(session.finish), now))

  const scheduledAcceptedSession = futureSessions.find(
    session => session.status === TrainingSessionState.ScheduledAccepted
  )
  if (scheduledAcceptedSession) return scheduledAcceptedSession

  const scheduledSession = futureSessions.find(session => session.status === TrainingSessionState.Scheduled)
  if (scheduledSession) return scheduledSession

  const pastSessionsDesc = sessions.filter(session => fnsIsAfter(now, new Date(session.finish))).reverse()

  const completedSession = pastSessionsDesc.find(session => session.status === TrainingSessionState.Complete)
  if (completedSession) return completedSession

  const unconfirmedSession = pastSessionsDesc.find(session => session.status === TrainingSessionState.Unconfirmed)
  if (unconfirmedSession) return unconfirmedSession

  const partialSession = pastSessionsDesc.find(session => session.status === TrainingSessionState.Partial)
  if (partialSession) return partialSession

  const scheduledDeclinedSession = futureSessions.find(
    session => session.status === TrainingSessionState.ScheduledDeclined
  )
  if (scheduledDeclinedSession) return scheduledDeclinedSession

  const pastSessionDeclinedSession = pastSessionsDesc.find(session => session.status === TrainingSessionState.Declined)
  if (pastSessionDeclinedSession) return pastSessionDeclinedSession

  const pastSessionAbsentSession = pastSessionsDesc.find(session => session.status === TrainingSessionState.Absent)
  if (pastSessionAbsentSession) return pastSessionAbsentSession

  return undefined
}

export default getDisplaySession

/**
 * Determines if a sessions array contains a previous partially complete session
 */
export const sessionsIsPartial = (sessions?: TrainingSessionStatus[]) => {
  if (!sessions?.length) return false
  let returnValue = false
  // Assume sessions already in ascending order from BE
  for (const session of Array.from(sessions).reverse()) {
    // If it was completed at some point, stop checking
    if (session.status === TrainingSessionState.Complete) break
    if (session.status === TrainingSessionState.Partial) {
      returnValue = true
      break
    }
  }
  return returnValue
}
