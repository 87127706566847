import { FormEventHandler, MouseEventHandler } from 'react'
import { Formik, Field, useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { errorsOfType, ErrorType, ModelErrors } from '../library/errors'
import SWFieldWithLabel from '../library/sw/sw_field_with_label'
import SWFormGroup from '../library/sw/sw_form_group'
import SWCheckbox from '../library/sw/sw_checkbox'
import Modal from '../library/modal'

import TrainingPlanPicker from './training_plan_picker'
import { EditablePause, PauseFormData } from './types'
import { TrainingPlanContext, UserContext } from './types'
import { SnoozeOverlapError } from '../generated_types/training_plan'

const formatPauseForFormik = ({ training_plan_ids, ...pause }: EditablePause): PauseFormData => {
  return {
    ...pause,
    end_date: pause.end_date || '',
    //field input values are strings, - if we leave as integers it confuses formiks checkbox code
    training_plan_ids: (training_plan_ids || []).map(id => id.toString())
  }
}

type Props = {
  onCancel: () => void
  errors: ModelErrors
  onSubmit: (f: PauseFormData) => void
  pause: EditablePause
  context: TrainingPlanContext | UserContext
  showPlanPicker: boolean
}

const PauseFormModal = (props: Props) => {
  const { onSubmit, pause, ...pauseFormProps } = props
  const { errors, onCancel, context } = pauseFormProps

  const sessionErrors = errorsOfType(errors, 'start_date', ErrorType.SnoozeOverlapsSession) as SnoozeOverlapError[]

  return (
    <Formik<PauseFormData> onSubmit={onSubmit} initialValues={formatPauseForFormik(pause)}>
      {({ isSubmitting, setFieldValue, handleSubmit, values }) => {
        const clearOverlapFormValuesAndSubmit: FormEventHandler = e => {
          e.preventDefault()
          e.stopPropagation()
          setFieldValue('decline_overlapping_attendance_ids', undefined)
          setFieldValue('allow_overlapping_attendance_ids', undefined)
          handleSubmit()
        }

        return values.user_id && sessionErrors.length > 0 ? (
          <ConfirmSessionClash
            sessionErrors={sessionErrors}
            onCancel={onCancel}
            submitting={isSubmitting}
            user={'user' in context ? context.user : { is_self: false, ...context.users[values.user_id] }}
            onConfirm={action => {
              setFieldValue('decline_overlapping_attendance_ids', undefined)
              setFieldValue('allow_overlapping_attendance_ids', undefined)
              if (action === 'decline') {
                setFieldValue(
                  'decline_overlapping_attendance_ids',
                  sessionErrors.map(s => s.attendance_id)
                )
              } else {
                setFieldValue(
                  'allow_overlapping_attendance_ids',
                  sessionErrors.map(s => s.attendance_id)
                )
              }
              handleSubmit()
            }}
          />
        ) : (
          <PauseForm {...pauseFormProps} onSubmit={clearOverlapFormValuesAndSubmit} />
        )
      }}
    </Formik>
  )
}

type FormProps = Pick<Props, 'onCancel' | 'errors' | 'context' | 'showPlanPicker'> & {
  onSubmit: FormEventHandler<HTMLFormElement>
}

const PauseForm = ({ onCancel, errors, context, showPlanPicker, onSubmit }: FormProps) => {
  const { values, isSubmitting } = useFormikContext<PauseFormData>()
  const newPause = !values.id
  const { companyId } = context

  const action = newPause ? (
    <>
      Create
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={['far', 'circle-pause']} />
        <FontAwesomeIcon icon={['fas', 'circle-plus']} transform="shrink-3 right-9 down-6" />
      </span>
    </>
  ) : (
    <>
      Save
      <FontAwesomeIcon icon={['far', 'floppy-disk']} />
    </>
  )
  const title = newPause ? 'Create Pause' : 'Update Pause'
  const forSingleUser = 'user' in context
  const forSelf = forSingleUser && context.user.is_self
  // if editing for a single user then the only eligible user is that user
  const eligibleUsers = forSingleUser
    ? [context.user]
    : context.userIds
        .map(id => context.users[id])
        .filter(u => u) //ignore deactivated users
        .sort((userA, userB) => userA.name.localeCompare(userB.name))

  const closeModal: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    onCancel()
  }

  const disableSubmission =
    isSubmitting || !values.user_id || !values.start_date || !(values.training_plan_ids.length || values.global)

  return (
    <form onSubmit={onSubmit}>
      <Modal
        options={{ active: true }}
        title={title}
        onClose={closeModal}
        footer={
          <>
            <a onClick={closeModal} className="sw-btn mr-auto">
              <FontAwesomeIcon icon={['fas', 'chevron-left']} />
              Cancel
            </a>
            <button
              type="submit"
              disabled={disableSubmission}
              className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
            >
              {action}
            </button>
          </>
        }
      >
        {errors.base ? <div className="sw-toast error">{errors.base.join(', ')}</div> : null}

        <div className="flex flex-col gap-4">
          {!forSelf && (
            <SWFormGroup errors={errors['user']}>
              <SWFieldWithLabel
                label="Learner"
                id="snooze_user_id"
                name="user_id"
                className="sw-select"
                as="select"
                disabled={forSingleUser || !newPause}
              >
                <option value="" />
                {eligibleUsers.map(u => (
                  <option key={u.id} value={u.id}>
                    {u.name}
                  </option>
                ))}
              </SWFieldWithLabel>
            </SWFormGroup>
          )}

          <SWFormGroup errors={errors['start_date']}>
            <SWFieldWithLabel
              label="Start Date"
              type="date"
              id="snooze_start_date"
              name="start_date"
              className="sw-input"
            />
          </SWFormGroup>

          <SWFormGroup errors={errors['end_date']}>
            <SWFieldWithLabel label="End Date" type="date" id="snooze_end_date" name="end_date" className="sw-input" />
            <p className="form-input-hint">Leave this as &apos;dd/mm/yyyy&apos; for indefinite pauses</p>
          </SWFormGroup>

          <SWFormGroup errors={errors['description']}>
            <SWFieldWithLabel
              label="Description"
              as="textarea"
              id="snooze_description"
              name="description"
              className="sw-input"
            />
            <p className="form-input-hint">
              Any relevant information that explains why this pause exists.
              {!forSelf && ' This information will be visible to the user'}
            </p>
          </SWFormGroup>

          {showPlanPicker && companyId && (
            <div>
              <SWFormGroup errors={errors['global']}>
                <h4 className="mb-2">Pause applies to...</h4>
                <Field label="All plans" type="checkbox" id="snooze_global" name="global" as={SWCheckbox} />
                <p className="form-input-hint">This pause will also apply to any future coaching plans</p>
              </SWFormGroup>
              <SWFormGroup errors={errors['training_plan_ids']}>
                <TrainingPlanPicker
                  userId={values.user_id}
                  companyId={companyId}
                  fieldName="training_plan_ids"
                  disabled={values.global}
                />
              </SWFormGroup>
            </div>
          )}
        </div>
      </Modal>
    </form>
  )
}

type ConfirmSessionClashProps = {
  sessionErrors: SnoozeOverlapError[]
  onCancel: () => void
  onConfirm: (action: 'decline' | 'ignore') => void
  submitting: boolean
  user: UserContext['user']
}

const ConfirmSessionClash = ({ sessionErrors, onCancel, onConfirm, submitting, user }: ConfirmSessionClashProps) => {
  const sessionList = (
    <ul className="my-2 list-inside">
      {sessionErrors.map((error, index) => (
        <li key={index}>
          {error.message}
          {error.session_edit_url && (
            <span className="ml-2">
              (
              <a href={error.session_edit_url} target="_blank" rel="noreferrer">
                Edit
              </a>
              )
            </span>
          )}
        </li>
      ))}
    </ul>
  )
  const description = sessionErrors.length > 1 ? 'some sessions' : 'a session'
  const events = sessionErrors.length > 1 ? 'session invitations' : 'session invitation'
  const invitations = sessionErrors.length > 1 ? 'Invitations' : 'Invitation'

  const message = user.is_self ? (
    <>
      <p>Just so you know, you&apos;re already invited to {description} during that time:</p>
      {sessionList}
      <p>Do you want to decline the {events}?</p>
    </>
  ) : (
    <>
      <p>
        {user.name} is already invited to {description} during that time:
      </p>
      {sessionList}
      <p>Do you want to decline the {events}?</p>
    </>
  )

  return (
    <Modal
      body={message}
      title="Confirmation Required"
      onClose={onCancel}
      options={{ active: true, submitting }}
      footer=<>
        <button type="button" disabled={submitting} onClick={onCancel} className="sw-btn mr-auto">
          <FontAwesomeIcon icon={['fas', 'chevron-left']} />
          Cancel Pause
        </button>
        <button
          disabled={submitting}
          type="submit"
          onClick={() => onConfirm('ignore')}
          className={`sw-btn mr-2 ${submitting ? ' sw-loading' : ''}`}
        >
          Pause But Don&apos;t Decline {invitations}
          <FontAwesomeIcon icon={['fas', 'check']} />
        </button>
        <button
          disabled={submitting}
          type="submit"
          onClick={() => onConfirm('decline')}
          className={`sw-btn btn-primary${submitting ? ' sw-loading' : ''}`}
        >
          Pause And Decline {invitations}
          <FontAwesomeIcon icon={['fas', 'check']} />
        </button>
      </>
    />
  )
}
export default PauseFormModal
