import React, { useState } from 'react'

import Errors, { ModelErrors } from '../library/errors'

import LineItem from './line_item'
import AddUserButton from './add_user_button'
import { ExistingTrainingSession, LineItem as LineItemData, UserMap, UserStub, AttendanceUpdate } from './types'
import { FlashAlert, FlashSuccess } from '../library/flash_message'

const removeUsersAlreadyInSession = (candidates: Array<UserStub>, usersInSession: UserMap) => {
  return candidates.filter(u => !usersInSession[u.id])
}

const formatErrors = (errors: ModelErrors) => (
  <ul className="list-inside">
    <Errors errors={errors} as="li" />
  </ul>
)

export type LineItemsProps = {
  users: UserMap
  lineItems: Array<LineItemData>
  setLineItems: React.Dispatch<React.SetStateAction<Array<LineItemData>>>
  trainingSession: ExistingTrainingSession
  availableInternalUsers: Array<UserStub>
  attendanceAdded: (data: AttendanceUpdate) => void
  allowAdd: boolean
}

const LineItems = ({
  users,
  lineItems,
  setLineItems,
  trainingSession,
  availableInternalUsers,
  attendanceAdded,
  allowAdd
}: LineItemsProps) => {
  const [message, setMessage] = useState<string | null>(null)
  const [errors, setErrors] = useState<ModelErrors | null>(null)
  const internalUsersInSession = Object.values(users).filter(u => u.internal)
  const addableInternalUsers = removeUsersAlreadyInSession(availableInternalUsers, users)

  const ConfiguredAddUserButton = React.useCallback(
    ({ users }) =>
      allowAdd ? (
        <AddUserButton
          trainingSession={trainingSession}
          users={users}
          attendanceAdded={attendanceAdded}
          setMessage={setMessage}
          setErrors={setErrors}
        />
      ) : null,
    [trainingSession, attendanceAdded, allowAdd]
  )

  return (
    <>
      <table className="sw-table striped w-full">
        <thead>
          <tr>
            <th>Company</th>
            <th>Credits Used</th>
            <th>Auto-calculate</th>
            <th>Learners</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {lineItems.map(lineItem => (
            <LineItem
              sessionCode={trainingSession.code}
              key={lineItem.id}
              lineItem={lineItem}
              setLineItems={setLineItems}
              addUserButton={
                <ConfiguredAddUserButton users={removeUsersAlreadyInSession(lineItem.company.active_users, users)} />
              }
            />
          ))}
          <tr>
            <td className="bg-[url('skiller_whale_style/SW_horizontal_colour.png')] bg-contain bg-left bg-no-repeat p-0" />
            <td></td>
            <td></td>
            <td className="align-middle">
              {internalUsersInSession.length}
              {internalUsersInSession.length === 1 ? ' person' : ' people'}
            </td>
            <td className="align-middle">
              <ConfiguredAddUserButton users={addableInternalUsers} />
            </td>
          </tr>
        </tbody>
      </table>
      {message && (
        <FlashSuccess sticky onClose={() => setMessage(null)}>
          {message}
        </FlashSuccess>
      )}
      {errors && (
        <FlashAlert sticky onClose={() => setErrors(null)}>
          {formatErrors(errors)}
        </FlashAlert>
      )}
    </>
  )
}

export default LineItems
