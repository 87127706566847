import { useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { PlannedSession } from '../generated_types/user_plan_viewer'
import { UserSessionStatus, getStatus } from './session_status'
import { ModuleTitles } from './types'
import SessionStatusChip from './session_status_chip'
import ChangeRequestDropdown from './change_request_dropdown'
import SessionDetails from './session_details'

type SessionRowPops = {
  session: PlannedSession
  moduleTitles: ModuleTitles
  readonly: boolean
}

const SessionRow = ({ session, moduleTitles, readonly }: SessionRowPops) => {
  const [detailsOpen, setDetailsOpen] = useState(false)

  const sessionStatus = getStatus(session)
  const rowWrapperClasses = `session-row-wrapper${
    sessionStatus === UserSessionStatus.NotInPlan || sessionStatus === UserSessionStatus.NotNeeded
      ? ' session-hidden'
      : ''
  }`

  const rowTitle = (
    <>
      <h4 className="title" dangerouslySetInnerHTML={{ __html: session.module_title_html }} />
      <span
        className={`subtitle${detailsOpen ? ' opacity-0' : ''}`}
        dangerouslySetInnerHTML={{ __html: session.overview_html }}
      />
    </>
  )

  const hasDetailsContent = !!session.overview_html || session.learnings_html.length > 0

  const commonCells = (
    <>
      <div className="session-cell gap-4 col-span-5">{rowTitle}</div>
      <div className="session-cell overflow-hidden whitespace-nowrap col-span-1">{session.estimated_minutes} mins</div>
      <div className="session-cell gap-2 col-span-2">
        <SessionStatusChip status={sessionStatus} />
      </div>
      <div className="session-cell gap-2 col-span-3 rounded-r-md">
        <ChangeRequestDropdown session={session} sessionStatus={sessionStatus} readonly={readonly} />
      </div>
    </>
  )

  const rowContent = hasDetailsContent ? (
    <div onClick={() => setDetailsOpen(open => !open)} className={rowWrapperClasses}>
      <div className="session-cell justify-center px-0 rounded-l-md">
        <FontAwesomeIcon
          icon={['fas', 'chevron-right']}
          className={`transition-transform text-orcablue${detailsOpen ? ' rotate-90' : ''}`}
        />
      </div>
      {commonCells}
    </div>
  ) : (
    <div className={rowWrapperClasses}>
      <div className="session-cell rounded-l-md" />
      {commonCells}
    </div>
  )

  return (
    <>
      {rowContent}
      {hasDetailsContent && <SessionDetails session={session} moduleTitles={moduleTitles} open={detailsOpen} />}
    </>
  )
}

export default SessionRow
