import { TrainingModules } from './types'

type ModuleWarningProps = {
  modules: string[]
  moduleData: TrainingModules
}

const ModuleWarnings = ({ modules, moduleData }: ModuleWarningProps) => {
  const warnings = modules
    .map(key => {
      const data = moduleData[key]
      if (!data || !data.exists) {
        return [key, `⛔️ ${key} does not currently exist`]
      }
      if (data.deprecated) {
        // If a module is both deprecated AND unpublished, just show the
        // deprecated warning, since this is more important.
        return [key, `⛔️ ${key} has been deprecated`]
      }
      if (!data.published) {
        return [key, `⚠️ ${key} is not published`]
      }
    })
    .filter(x => x !== undefined)

  if (warnings.length == 0) {
    return null
  }

  return (
    <p className="form-input-hint flex flex-col gap-2 mb-4">
      {warnings.map(([key, message]) => (
        <span key={key}>
          {message}
          <br />
        </span>
      ))}
    </p>
  )
}

export default ModuleWarnings
