import React from 'react'

type Props = {
  updateFrozen: boolean
  setUpdateFrozen: React.Dispatch<React.SetStateAction<boolean>>
  extraConfirmationRequired: boolean
}

const ConfirmEditsToFrozen = ({ updateFrozen, setUpdateFrozen, extraConfirmationRequired }: Props) =>
  extraConfirmationRequired ? (
    <div className="sw-snippet sw-group">
      <label className="sw-label">
        <input
          type="checkbox"
          className="sw-input"
          checked={updateFrozen}
          onChange={e => setUpdateFrozen(e.target.checked)}
        />
        I&apos;m aware that this invoice is frozen, and still want to update it.
      </label>
    </div>
  ) : null

export default ConfirmEditsToFrozen
