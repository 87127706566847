import { Dispatch, SetStateAction } from 'react'
import { Schedulers } from '../../generated_types/training_plan'
import SchedulerEditor from './scheduler_editor'

type Props = {
  trainingPlanId: number
  schedulerId: number | null
  setSchedulerId: Dispatch<SetStateAction<number | null>>
  schedulers: Schedulers
}

const Scheduler = ({ schedulerId, schedulers, setSchedulerId, trainingPlanId }: Props) => {
  return (
    <div className="flex flex-wrap items-center justify-end gap-x-2 gap-y-1">
      <label className="basis-36 text-base font-semibold mr-auto" htmlFor="scheduler-select">
        Scheduler:
      </label>
      <SchedulerEditor
        schedulers={schedulers}
        schedulerId={schedulerId}
        setSchedulerId={setSchedulerId}
        trainingPlanId={trainingPlanId}
      />
    </div>
  )
}

export default Scheduler
