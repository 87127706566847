import { InputHTMLAttributes, ReactNode } from 'react'

interface SWRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode
  /**
   * Classes to apply to the label around the radio
   */
  classNames?: string
}

/**
 * Version of library/radio using sw-* classes
 */
const SWRadio = ({ label, classNames = '', className = '', ...props }: SWRadioProps) => {
  return (
    <label className={`sw-label ${classNames}`}>
      <input type="radio" className={`sw-input ${className}`} {...props} />
      {label}
    </label>
  )
}

export default SWRadio
