import { CoachProfileProps } from '../generated_types/components'
import { useEditor } from '../library/editor'
import CoachProfileTextContent from './coach_profile_text_content'

const CoachProfile = ({ coach_profile }: CoachProfileProps) => {
  const { content: biographyContent } = useEditor(CoachProfileTextContent, {
    source: coach_profile.biography,
    formatted: coach_profile.formatted_biography,
    url: `/users/${coach_profile.user_id}/coach_profile`,
    serialize: biography => ({ coach_profile: { biography } }),
    parse: data => {
      return {
        source: data.coach_profile.biography,
        formatted: data.coach_profile.formatted_biography
      }
    }
  })

  const { content: summaryContent } = useEditor(CoachProfileTextContent, {
    source: coach_profile.summary,
    formatted: coach_profile.formatted_summary,
    url: `/users/${coach_profile.user_id}/coach_profile`,
    serialize: summary => ({ coach_profile: { summary } }),
    parse: data => {
      return {
        source: data.coach_profile.summary,
        formatted: data.coach_profile.formatted_summary
      }
    }
  })

  return (
    <div className="flex flex-wrap gap-x-4">
      <div className="sw-card my-8 basis-full 2xl:basis-5/12 grow">
        <h3>Coach Summary</h3>
        {summaryContent}
        <p className="form-input-hint">Internal notes about the coach</p>
      </div>
      <div className="sw-card my-8 basis-full 2xl:basis-5/12 grow">
        <h3>Coach biography</h3>
        {biographyContent}
        <p className="form-input-hint">A biography of the coach. May be shared externally</p>
      </div>
    </div>
  )
}

export default CoachProfile
