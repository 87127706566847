import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import Modal from '../library/modal'

type AnswersIframeModalProps = {
  url: string
  active: boolean
  onClose: () => void
}

const AnswersIframeModal = ({ url, active, onClose }: AnswersIframeModalProps) => {
  const controls = (
    <>
      <h2 className="mr-auto">Learner Answers</h2>
      <button className="sw-btn btn-close-lg" onClick={onClose}>
        <FontAwesomeIcon icon={['fas', 'xmark']} />
      </button>
    </>
  )

  return (
    <Modal
      id="answers-iframe-modal"
      controls={controls}
      classNames="answers-iframe-modal"
      onClose={onClose}
      options={{
        active,
        focus: true,
        drawer: true
      }}
    >
      <div className="sw-loading loading-lg absolute right-1/2 top-1/2 z-10"></div>
      {active && !!url && (
        <iframe
          name="answers-iframe"
          referrerPolicy="origin"
          loading="lazy"
          className="grow z-20 rounded-md"
          src={url}
        />
      )}
    </Modal>
  )
}
export default AnswersIframeModal
