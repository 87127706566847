import { ChangeEventHandler } from 'react'

type TechnologyDropdownProps = {
  value: string
  valuesAndOptions: [string, string][]
  onChangeHandler: ChangeEventHandler<HTMLSelectElement>
}
const TechnologyDropdown = ({ value, valuesAndOptions, onChangeHandler }: TechnologyDropdownProps) => (
  <div className="flex ml-auto gap-3 items-center">
    <label htmlFor="technology-filter" className="sw-label font-semibold ml-auto">
      Technology:
    </label>
    <select
      id="technology-filter"
      className="sw-select"
      value={value}
      onChange={onChangeHandler}
      disabled={valuesAndOptions.length < 2}
    >
      <option value="">All</option>
      {valuesAndOptions.map(([value, option]) => (
        <option key={value} value={value}>
          {option}
        </option>
      ))}
    </select>
  </div>
)

export default TechnologyDropdown
