import React, { useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { longDateFormat } from '../utils/date_helpers'

type EditableDatetimeProps = {
  value: Date
  setValue: (newValue: Date) => Promise<void>
  updating: boolean
}

const EditableDatetime = ({ value, setValue, updating }: EditableDatetimeProps) => {
  const [editing, setEditing] = useState(false)
  const dateInputRef = React.createRef<HTMLInputElement>()

  const saveEdit = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const valueAsString = dateInputRef.current?.value || ''

      setValue(new Date(valueAsString))
      e.preventDefault()
      setEditing(false)
    },
    [dateInputRef, setValue]
  )

  const cancelEdit = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setEditing(false)
  }, [])

  if (editing) {
    const formattedValue = dateToInputFormat(value)
    //use an uncontrolled component - controlled components and datetime-local don't seem to play very nicely
    return (
      <>
        <input ref={dateInputRef} type="datetime-local" defaultValue={formattedValue} className="sw-input mr-4" />
        <button onClick={cancelEdit} className="sw-btn btn-icon mr-2" aria-label="Cancel Edit">
          <FontAwesomeIcon icon={['fas', 'xmark']} />
        </button>
        <button onClick={saveEdit} className="sw-btn btn-primary btn-icon" aria-label="Confirm Expiry">
          <FontAwesomeIcon icon={['fas', 'check']} />
        </button>
      </>
    )
  } else {
    return (
      <>
        <span className="mr-2">{formatDatetime(value)}</span>
        {!updating && (
          <button onClick={() => setEditing(true)} className="sw-btn btn-icon" aria-label="Edit Expiry">
            <FontAwesomeIcon icon={['fas', 'pencil']} />
          </button>
        )}
        {updating && <span className="sw-loading" />}
      </>
    )
  }
}

export default EditableDatetime

// date.toISOString() is nearly what we want, but
// - it is documented as outputting utc
// - it outputs seconds + timezone, which we don't want
const dateToInputFormat = (date: Date) => {
  return `${date.getFullYear()}-${padDateComponent(date.getMonth() + 1)}-${padDateComponent(
    date.getDate()
  )}T${padDateComponent(date.getHours())}:${padDateComponent(date.getMinutes())}`
}

const padDateComponent = (n: number) => {
  return n.toString().padStart(2, '0')
}

const formatDatetime = (date: Date) => {
  return longDateFormat.format(date)
}
