import { ChangeEventHandler } from 'react'
import { CurriculaPageProps } from '../../generated_types/user_plan_viewer'
import { curriculaToModuleTitles } from '../helpers'
import TechnologyDropdown from '../technology_dropdown'
import useUrlParam from '../use_url_param'
import CurriculaCurriculum from './curricula_curriculum'

const CurriculaViewer = ({ curricula }: CurriculaPageProps) => {
  const technologiesAndTitles = Object.entries(
    Object.fromEntries(curricula.map(curriculum => [curriculum.technology, curriculum.technology_title] as const))
  )

  const checkTechParam = (param: string) => {
    if (param && technologiesAndTitles.some(([key, _]) => key === param)) return param
    return ''
  }
  const [technologyFilter, setTechnologyFilter] = useUrlParam<string>('tech', checkTechParam)

  const handleSelectChange: ChangeEventHandler<HTMLSelectElement> = event => setTechnologyFilter(event.target.value)

  const filteredCurricula =
    technologyFilter === '' ? curricula : curricula.filter(c => c.technology === technologyFilter)
  const moduleTitles = curriculaToModuleTitles(curricula)

  return (
    <>
      <fieldset className="controls-panel">
        <legend>Filter</legend>
        <TechnologyDropdown
          value={technologyFilter}
          valuesAndOptions={technologiesAndTitles}
          onChangeHandler={handleSelectChange}
        />
      </fieldset>

      <div className="curriculum-header-topper" />
      {filteredCurricula.map((data, index) => (
        <CurriculaCurriculum key={index} data={data} moduleTitles={moduleTitles} />
      ))}
    </>
  )
}

export default CurriculaViewer
