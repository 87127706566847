import React, { useEffect, useState } from 'react'
import { assertUnreachable } from '../../../utils/asserts'
import { SessionDuration, Timeslot } from '../../types'
import { getTimeslotError, TimeslotError } from '../../utils'

const DEFAULT_MINS = '60'

interface TimePeriodSelectorProps {
  timePeriod: SessionDuration
  onChange: (duration: SessionDuration) => void
  totalEstimatedDuration: number
  disabled?: boolean
  timeslots?: Timeslot[]
}

/**
 * Allows user input of a date-time and minute duration
 */
const TimePeriodSelector = ({
  timePeriod,
  onChange,
  disabled,
  totalEstimatedDuration,
  timeslots
}: TimePeriodSelectorProps) => {
  // Use a state for estimated duration, so it might be compared in useEffect below
  const [estimatedDuration, setEstimatedDuration] = useState(0)

  /**
   * This useEffect is designed to update duration_in_minutes ONLY when totalEstimatedDuration changes.
   * This allows us to "suggest" the total estimated duration when the module selection changes, but still allow
   * end-users to change it to what they please (though this will display a warning).
   */
  useEffect(() => {
    if (totalEstimatedDuration < 1) setEstimatedDuration(0)
    if (totalEstimatedDuration < 1 || totalEstimatedDuration === estimatedDuration) return
    onChange({
      ...timePeriod,
      duration_in_minutes: totalEstimatedDuration.toString()
    })
    setEstimatedDuration(totalEstimatedDuration)
  }, [estimatedDuration, onChange, timePeriod, totalEstimatedDuration])

  const minsDurationMismatch =
    timePeriod.duration_in_minutes !== '' &&
    estimatedDuration > 0 &&
    timePeriod.duration_in_minutes !== estimatedDuration.toString()

  const timeslotError = timeslots && getTimeslotErrorMessage(timeslots, timePeriod)
  return (
    <div className="mb-3">
      <strong>Time (London)</strong>
      <div className="flex items-center my-1">
        <div className="basis-1/6 text-right mr-2">
          <label htmlFor="from_date">On:</label>
        </div>
        <input
          id="from_date"
          type="date"
          value={timePeriod?.start_date}
          className="sw-input"
          onChange={event => {
            const duration_in_minutes =
              timePeriod.duration_in_minutes === '' ? DEFAULT_MINS : timePeriod.duration_in_minutes
            onChange({ start_date: event.target.value, start_time: timePeriod.start_time, duration_in_minutes })
          }}
          disabled={disabled}
        />
      </div>
      <div className="flex items-center my-1">
        <div className="basis-1/6 text-right mr-2">
          <label htmlFor="from_time">At:</label>
        </div>
        <input
          id="from_time"
          type="time"
          value={timePeriod?.start_time}
          className="sw-input"
          onChange={event => onChange({ ...timePeriod, start_time: event.target.value })}
          disabled={disabled}
        />
      </div>
      <div className="flex items-center my-1">
        <div className="basis-1/6 text-right mr-2">
          <label htmlFor="mins_duration">For:</label>
        </div>
        <input
          id="mins_duration"
          type="number"
          min="0"
          step="5"
          value={timePeriod?.duration_in_minutes}
          className="sw-input w-24 mr-2"
          onChange={event => onChange({ ...timePeriod, duration_in_minutes: event.target.value })}
          disabled={disabled}
        />
        <span>minutes</span>
      </div>
      {minsDurationMismatch && (
        <p className="form-input-hint text-warning-text">
          ⚠️ The estimated duration for this module selection is {estimatedDuration} minutes
        </p>
      )}
      {timeslotError && <p className="form-input-hint text-warning-text">⚠️ {timeslotError}</p>}
    </div>
  )
}

export default TimePeriodSelector

const getTimeslotErrorMessage = (timeslots: Timeslot[], timePeriod: SessionDuration): string | null => {
  const error = getTimeslotError(timeslots, timePeriod)
  switch (error) {
    case null:
      return null
    case TimeslotError.NO_MATCHING_TIMESLOT:
      return 'There is no timeslot for this coach and time'
    case TimeslotError.TIMESLOT_TOO_SHORT:
      return 'The timeslot is too short for this duration'
    default:
      assertUnreachable(error)
  }
}
