import { SessionType, TrainingModules } from './types'

type Props = {
  session_type: SessionType | ''
  selectedModules: string[]
  moduleData: TrainingModules
}
const SessionTypeWarning = ({ session_type, selectedModules, moduleData }: Props) => {
  if (session_type === '') return null
  if (selectedModules.length === 0) return null

  const clashingModule = selectedModules.find(moduleKey => {
    const defaultSessionType = moduleData[moduleKey]?.default_session_type
    return defaultSessionType && defaultSessionType !== session_type
  })

  if (clashingModule) {
    const clashingModuleData = moduleData[clashingModule]
    return (
      <span className="form-input-hint">
        ⚠️ The default session type for {clashingModule} is {clashingModuleData.default_session_type}
      </span>
    )
  }

  return null
}

export default SessionTypeWarning
