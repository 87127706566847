import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { nextMonday, startOfDay, parse } from 'date-fns'
import { Timeslot } from '../../generated_types/training_plan'
import { nextOccurrences } from '../../utils/date_helpers'
import { timeslotDescription } from '../utils'
import { toZonedTime, fromZonedTime } from 'date-fns-tz'

type Props = {
  timeslot: Timeslot
  onClick?: (starts_at: Date, timezone: string) => void
}
const TimeslotChip = ({ timeslot, onClick }: Props) => {
  const hasAction = !!onClick

  const timezone = 'Europe/London'

  const dateFormat = new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'long',
    timeZone: timezone
  })

  // this sets base to the *local* time with the same start time as the timeslot (where local means the local time of the user accessing the site)
  // ie if a user in new york visits site in the summer and the time slot is 09:00
  // then this gets set to 2023-06-01 09:00 EST (or similar)
  //
  // we want the current date (as in yyyy-mm-dd) to be from the current output tz, so this isn't quite
  const currentTimeInTimezone = toZonedTime(new Date(), timezone)
  const base = parse(timeslot.starts_at, 'HH:mm', currentTimeInTimezone)
  const nextMondayInTz = fromZonedTime(startOfDay(nextMonday(currentTimeInTimezone)), timezone)

  const dates = nextOccurrences(timeslot.day_of_week, base, 4, timezone)
  const labels = labelsForDates(dates, nextMondayInTz, dateFormat)

  return hasAction ? (
    <div className="sw-dropdown">
      <a className="sw-chip dropdown-trigger whitespace-nowrap text-orcablue" href="#">
        {timeslotDescription(timeslot)}
        <FontAwesomeIcon icon={['fas', 'chevron-down']} size="sm" />
      </a>
      <ul className="sw-menu">
        <li>
          <span>Autofill Scheduler With:</span>
        </li>
        <li className="sw-divider"></li>
        {labels.map((label, index) => (
          <li key={index}>
            <a href="#" onClick={() => onClick(dates[index], timezone)}>
              {label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <div className="sw-chip">{timeslotDescription(timeslot)}</div>
  )
}

export default TimeslotChip

// We make a small distinction between results that are 'this week' and next week, where week is defined as starting on the sunday to monday boundary
const labelsForDates = (dates: Date[], nextMondayInTz: Date, dateFormat: Intl.DateTimeFormat): string[] => {
  let ordinals: string[]
  if (dates[0] < nextMondayInTz) {
    ordinals = ['This Week', 'Next Week']
  } else {
    ordinals = ['Next Week']
  }

  ordinals = [
    ...ordinals,
    ...Array.from({ length: dates.length - ordinals.length }, (_, index) => `${index + 2} Weeks`)
  ]
  return ordinals.map((o, index) => `${o} (${dateFormat.format(dates[index])})`)
}
