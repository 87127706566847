import { useState } from 'react'
import { TaughtModulesPageProps, TaughtModule as TaughtModuleData } from '../generated_types/home'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import Pagination from '../library/pagination'
import { apiRequest } from '../utils/json_request'
import CurriculumIcon from '../library/curriculum_icon'

const TaughtModules = ({
  taught_modules: initialTaughtModules,
  pagination: initialPagination
}: TaughtModulesPageProps) => {
  const [taughtModules, setTaughtModules] = useState(initialTaughtModules)
  const [pagination, setPagination] = useState(initialPagination)

  const fetchPage = async (newPageNumber: number) => {
    const response = await apiRequest('/taught_modules', {
      method: 'GET',
      payload: undefined,
      query: { per_page: pagination.per_page, page: newPageNumber }
    })

    if (response.ok) {
      setPagination(response.data.pagination)
      setTaughtModules(response.data.taught_modules)
    }
  }

  return (
    <>
      <Pagination
        {...pagination}
        onPageChange={(newNumber: number) => fetchPage(newNumber)}
        classNames="justify-center gap-6"
      />
      {taughtModules.map(tm => (
        <TaughtModule key={tm.key} taught_module={tm} />
      ))}
    </>
  )
}

const TaughtModule = ({ taught_module }: { taught_module: TaughtModuleData }) => (
  <div className="flex flex-wrap gap-x-5 gap-y-2 items-center justify-end bg-gradient2 rounded-lg py-2.5 px-5">
    <div className="flex grow gap-3 max-w-full items-center">
      <div
        className="sw-tooltip flex shrink-0 items-center justify-center rounded-full h-12 w-12 bg-white"
        data-tooltip={taught_module.curriculum_title}
      >
        <CurriculumIcon
          technologyKey={taught_module.technology}
          iconClasses="text-orcablue"
          transparentClasses="text-white"
        />
      </div>
      <div className="overflow-hidden">
        <div className="font-semibold text-orcablue truncate">{taught_module.title}</div>
      </div>
    </div>
    {taught_module.reference_url && (
      <a href={taught_module.reference_url} className="sw-btn btn-secondary font-medium text-base">
        Reference
      </a>
    )}
    {taught_module.session_archive_url && (
      <a
        href={taught_module.session_archive_url}
        className="sw-btn btn-secondary font-medium text-base sw-tooltip"
        data-tooltip="Download your files from this session"
      >
        <FontAwesomeIcon icon={['fas', 'file-arrow-down']} />
      </a>
    )}
    {taught_module.summary_url && (
      <a href={taught_module.summary_url} className="sw-btn btn-secondary font-medium text-base">
        Session Content
      </a>
    )}
  </div>
)

export default TaughtModules
