import { Dispatch, SetStateAction } from 'react'
import { Schedulers } from '../../generated_types/training_plan'
import CoachingPlanAttributeEditor from './coaching_plan_attribute_editor'

type Props = {
  trainingPlanId: number
  schedulerId: number | null
  setSchedulerId: Dispatch<SetStateAction<number | null>>
  schedulers: Schedulers
}

const SchedulerEditor = ({ schedulerId, setSchedulerId, schedulers, trainingPlanId }: Props) => {
  const scheduler =
    schedulerId &&
    (schedulers[schedulerId] ?? {
      id: schedulerId,
      name: `id: ${schedulerId} (inactive)`
    })

  return (
    <CoachingPlanAttributeEditor
      trainingPlanId={trainingPlanId}
      attribute="scheduler_id"
      currentValue={schedulerId}
      onChange={setSchedulerId}
      formattedValue={scheduler ? scheduler.name : '<none>'}
      saveLabel="Confirm Scheduler"
      editLabel="Edit Scheduler"
    >
      {({ value, setValue }) => (
        <select
          id="scheduler-select"
          className="sw-select"
          value={value || ''}
          onChange={e => setValue(parseInt(e.target.value) || null)}
        >
          <option value="">&lt;none&gt;</option>
          {Object.values(schedulers).map(s => (
            <option key={s.id} value={s.id}>
              {s.name}
            </option>
          ))}
        </select>
      )}
    </CoachingPlanAttributeEditor>
  )
}
export default SchedulerEditor
