import ConfirmationWithReasonModal from '../library/confirmation_with_reason_modal'
import ErrorsToast from '../library/errors_toast'
import { useApiSave } from '../utils/json_request'
import { ExistingTrainingSession, LineItem } from './types'

type Props = {
  close: (message?: string) => void
  trainingSession: ExistingTrainingSession
  setTrainingSession: (v: ExistingTrainingSession) => void
  setLineItems: (v: LineItem[]) => void
}

const UncancelSessionModal = ({ trainingSession, setTrainingSession, setLineItems, close }: Props) => {
  const description =
    'Uncancelling a coaching session item will restore calendar invitations and reenable the session. It will not adjust credits or coach pay.'

  const { errors, save, saving, resetErrors } = useApiSave(
    `/coaching_sessions/${trainingSession.code}/cancellation`,
    'DELETE'
  )

  const onConfirm = async (reason: string) => {
    resetErrors()
    const response = await save({ payload: { training_session_uncancellation: { reason } } })
    if (response.ok) {
      setTrainingSession(response.data.training_session)
      setLineItems(response.data.line_items)
      close(response.data.message)
    }
  }

  const message = (
    <>
      <ErrorsToast errors={errors} />
      <p>{description}</p>
      <p>Please provide a reason for uncancelling this session</p>
    </>
  )

  return (
    <ConfirmationWithReasonModal
      message={message}
      title="Uncancel Coaching Session"
      onClose={() => close()}
      submitting={saving}
      onConfirm={onConfirm}
    />
  )
}

export default UncancelSessionModal
