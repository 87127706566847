import { Dispatch, SetStateAction } from 'react'
import Modal from '../../library/modal'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { Field, Form, Formik } from 'formik'
import SWRadio from '../../library/sw/sw_radio'
import { usePlanVersion } from './use_plan_version'
import { useApiSave } from '../../utils/json_request'
import ErrorsToast from '../../library/errors_toast'
import { addDays, formatISO } from 'date-fns'

type Props = {
  onClose: () => void
  trainingPlanId: number
  scheduledUntilDate: Date | null
  setScheduledUntilDate: Dispatch<SetStateAction<Date | null>>
}

const enum Mode {
  Custom = 'custom',
  None = 'none',
  OneWeek = 'one-week',
  TwoWeeks = 'two-weeks'
}

type FormData = {
  mode: Mode | ''
  date: string
}

const ScheduledUntilModal = ({ onClose, trainingPlanId, scheduledUntilDate, setScheduledUntilDate }: Props) => {
  const title = scheduledUntilDate ? 'Edit Plan Resolved Until' : 'Mark Plan As Resolved'
  const initial: FormData = {
    mode: scheduledUntilDate ? Mode.Custom : '',
    date: scheduledUntilDate ? formatDateForInput(scheduledUntilDate) : ''
  }
  const { lockVersions, planUpdated } = usePlanVersion()
  const { save, errors } = useApiSave(`/plans/${trainingPlanId}`, 'PATCH')

  const onSubmit = async (data: FormData) => {
    let dateToSet: string | null
    switch (data.mode) {
      case Mode.None:
        dateToSet = null
        break
      case Mode.Custom:
        dateToSet = data.date
        break
      case Mode.OneWeek:
        dateToSet = formatDateForInput(addDays(new Date(), 7))
        break
      case Mode.TwoWeeks:
        dateToSet = formatDateForInput(addDays(new Date(), 14))
        break
      case '':
        return
    }

    const result = await save({
      payload: {
        training_plan: {
          scheduled_until: dateToSet,
          lock_version: lockVersions[trainingPlanId]
        }
      }
    })

    if (result.ok) {
      const updatedPlan = result.data.training_plan
      const newDate = updatedPlan.scheduled_until ? new Date(updatedPlan.scheduled_until) : null
      planUpdated({ newVersion: updatedPlan.lock_version, planId: updatedPlan.id })
      setScheduledUntilDate(newDate)

      onClose()
    }
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initial}>
      {({ isSubmitting, values }) => (
        <Form>
          <Modal
            onClose={onClose}
            options={{ active: true, dismissOnClick: true }}
            title={title}
            body={
              <>
                <ErrorsToast errors={errors} />
                Mark plan as resolved until
                <div className="flex flex-col my-4 ">
                  <Field type="radio" as={SWRadio} name="mode" value={Mode.OneWeek} label="1 week from now" />
                  <Field type="radio" as={SWRadio} name="mode" value={Mode.TwoWeeks} label="2 weeks from now" />
                  <div className="flex ">
                    <Field
                      type="radio"
                      as={SWRadio}
                      name="mode"
                      value={Mode.Custom}
                      label="Custom"
                      classNames="text-current mr-4"
                    />
                    <Field
                      label="on"
                      name="date"
                      type="date"
                      className="sw-input"
                      disabled={values.mode !== Mode.Custom}
                    />
                  </div>
                </div>
                {scheduledUntilDate && (
                  <>
                    <div className="sw-divider" />
                    <Field type="radio" as={SWRadio} name="mode" value={Mode.None} label="Reset Resolved Until" />
                  </>
                )}
              </>
            }
            footer={
              <>
                <a onClick={onClose} className="sw-btn mr-auto">
                  <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                  Cancel
                </a>
                <button
                  type="submit"
                  disabled={isSubmitting || values.mode === ''}
                  className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
                >
                  Save
                  <FontAwesomeIcon icon={['far', 'floppy-disk']} />
                </button>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  )
}

const formatDateForInput = (date: Date) => formatISO(date, { representation: 'date' })

export default ScheduledUntilModal
