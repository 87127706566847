import { ReactNode } from 'react'
import { Field } from 'formik'
import { SWFieldWithLabelProps } from './sw_field_with_label'

type SWHorizontalFieldWithLabelProps<T> = SWFieldWithLabelProps<T> & {
  trailingElement?: ReactNode
}

/**
 * Similar to SWFieldWithLabel, but with a horizontal layout
 */
const SWHorizontalFieldWithLabel = <T,>({
  id,
  name,
  label,
  trailingElement,
  ...fieldProps
}: SWHorizontalFieldWithLabelProps<T>) => (
  <div className="flex items-center gap-2">
    <label htmlFor={id || name} className="sw-label shrink-0 basis-20">
      {label}
    </label>
    <Field id={id || name} name={name} {...fieldProps} />
    {trailingElement && <span>{trailingElement}</span>}
  </div>
)

export default SWHorizontalFieldWithLabel
