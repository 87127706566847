import { Dispatch, ReactNode, SetStateAction, useState } from 'react'

import { useApiSave } from '../utils/json_request'
import {
  collectErrorMessages,
  AVAILABILITY_ERRORS,
  ModelErrors,
  errorsOfType,
  CHANGE_REQUEST_ERRORS
} from '../library/errors'
import ConfirmationModal from '../library/confirmation_modal'
import { CreateAttendancePayload } from '../generated_types/training_session'

import { LineItemsProps } from './line_items'
import { UserStub } from './types'

type AddUserButtonProps = Pick<LineItemsProps, 'trainingSession' | 'attendanceAdded'> & {
  users: UserStub[]
  setMessage: Dispatch<SetStateAction<string | null>>
  setErrors: Dispatch<SetStateAction<ModelErrors | null>>
}

const AddUserButton = ({ users, trainingSession, setMessage, setErrors, attendanceAdded }: AddUserButtonProps) => {
  const [selectedId, setSelectedId] = useState('')
  const [confirmationRequired, setConfirmationRequired] = useState<Confirmation | null>(null)

  const { save, saving } = useApiSave(`/coaching_sessions/${trainingSession.code}/attendances`, 'POST')

  const addUser = async (user_id: string, permit_availability_clash = false, permit_change_request_clash = false) => {
    // Check the string only contains numbers
    if (!/^\d+$/.test(user_id)) return

    setMessage(null)
    setErrors(null)
    setConfirmationRequired(null)

    const payload: CreateAttendancePayload = {
      user_id: +user_id,
      permit_availability_clash,
      permit_change_request_clash
    }
    const { ok, data } = await save({ payload })

    if (ok) {
      setMessage(data.message)
      attendanceAdded(data)
    } else {
      const errors = data?.errors || {}
      const availabilityErrors = errorsOfType(errors, 'base', AVAILABILITY_ERRORS)
      const changeReqestErrors = errorsOfType(errors, 'base', CHANGE_REQUEST_ERRORS)

      if (availabilityErrors.length > 0 || changeReqestErrors.length > 0) {
        setConfirmationRequired({
          messages: [
            collectErrorMessages(availabilityErrors).join(', '),
            collectErrorMessages(changeReqestErrors).join(', ')
          ].filter(msg => msg.length > 0),
          user_id,
          permit_availability_clash: availabilityErrors.length > 0,
          permit_change_request_clash: changeReqestErrors.length > 0
        })
      } else {
        setErrors(errors)
      }
    }
  }

  let confirmationModal: ReactNode
  if (confirmationRequired) {
    confirmationModal = (
      <ConfirmationModal
        message={formatMessages(confirmationRequired.messages)}
        submitting={saving}
        onClose={() => setConfirmationRequired(null)}
        onConfirm={() =>
          addUser(
            confirmationRequired.user_id,
            confirmationRequired.permit_availability_clash,
            confirmationRequired.permit_change_request_clash
          )
        }
      />
    )
  }

  return (
    <>
      {confirmationModal}
      <div className="sw-form-grp inline-flex">
        <select name="user_id" className="sw-select" defaultValue="" onChange={e => setSelectedId(e.target.value)}>
          <option disabled value="">
            - Select Employee -
          </option>
          {users.map(u => (
            <option key={u.id} value={u.id}>
              {u.name}
            </option>
          ))}
        </select>
        <button
          className={`sw-btn btn-primary ${saving ? 'sw-loading' : ''}`}
          disabled={!selectedId}
          onClick={e => {
            addUser(selectedId)
            e.preventDefault()
          }}
        >
          Add
        </button>
      </div>
    </>
  )
}

export default AddUserButton

type Confirmation = {
  messages: string[]
  user_id: string
  permit_availability_clash?: boolean
  permit_change_request_clash?: boolean
}

const formatMessages = (messages: string[]) => {
  if (messages.length == 1) return messages[0]
  else
    return (
      <ul>
        {messages.map((m, i) => (
          <li key={i}>{m}</li>
        ))}
      </ul>
    )
}
