import React, { ChangeEventHandler, Dispatch, SetStateAction } from 'react'

interface ModulePickerProps {
  id?: string
  moduleKey: string
  setModuleKey: Dispatch<SetStateAction<string>>
  onModuleKeyChange: (moduleKey: string) => void
}

const ModulePicker = ({ id, moduleKey, setModuleKey, onModuleKeyChange }: ModulePickerProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    setModuleKey(event.target.value)
    onModuleKeyChange(event.target.value)
  }

  return (
    <input
      id={id}
      name="training_module"
      list="available-modules"
      value={moduleKey}
      onChange={handleChange}
      className="sw-input"
    />
  )
}

export default ModulePicker
