import { useState, useEffect } from 'react'
import SWCheckbox from '../library/sw/sw_checkbox'
import { Field } from 'formik'
import { apiRequest } from '../utils/json_request'
import { TrainingPlan } from './types'

type Props = {
  userId?: number
  fieldName: string
  companyId: number
  disabled?: boolean
}
const TrainingPlanPicker = ({ userId, fieldName, companyId, disabled }: Props) => {
  const [trainingPlans, setTrainingPlans] = useState<TrainingPlan[]>()
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTrainingPlans = async () => {
      if (userId) {
        setLoading(true)
        const { ok, data, status } = await apiRequest(`/companies/${companyId}/plans`, {
          method: 'GET',
          payload: undefined,
          query: { user_id: userId }
        })

        if (ok) {
          setTrainingPlans(data.training_plans)
        } else {
          setError(`Could not fetch coaching plans: ${status}`)
        }
      }
      setLoading(false)
    }
    fetchTrainingPlans() // effects can't be async functions - you need to create & call an async function within the effect
  }, [companyId, userId])

  if (!trainingPlans) {
    if (error) {
      return <span>Failed to fetch coaching plans: {error}</span>
    }
    if (!userId) {
      return null
    }
  }

  if (loading || !trainingPlans) return <span className="sw-loading loading-lg my-4" />

  return (
    <fieldset disabled={disabled}>
      <div className="sw-divider text-center" data-content="OR"></div>
      <div className="flex flex-col gap-4">
        {trainingPlans.map(trainingPlan => (
          <div key={trainingPlan.id} className="flex items-center gap-2 pb-5 border-b border-lightergrey">
            <div className="basis-40 shrink-0 grow-0">
              <Field
                disabled={disabled}
                as={SWCheckbox}
                type="checkbox"
                name={fieldName}
                label={trainingPlan.name}
                value={trainingPlan.id.toString()}
              />
            </div>
            <div className="grow p-1.5">{trainingPlan.title}</div>
          </div>
        ))}
      </div>
    </fieldset>
  )
}

export default TrainingPlanPicker
