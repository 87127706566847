import React from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { getModuleIssueContent, toFriendlyName } from '../session_editor_helpers'
import { PlanDependencies, SelectedPlanCells } from '../../types'
import { SchedulerPanelProps } from './scheduler_panel'
import { isScheduledAhead } from './scheduler_panel_helpers'
import { SchedulerContextData } from './use_scheduler'

type ModuleListProps = Pick<SchedulerPanelProps, 'dependencies'> & {
  disabled?: boolean
  uneditableCells?: SelectedPlanCells
} & Pick<SchedulerContextData, 'selectedCells' | 'setSelectedCells'>

/**
 * A list of module titles, with warning status icons and remove button
 */
const ModuleList = ({ selectedCells, setSelectedCells, uneditableCells, dependencies, disabled }: ModuleListProps) => {
  const removeModule = (moduleKey: string) => {
    if (!disabled)
      setSelectedCells(currentValue => {
        const modules = currentValue.modules.filter(module => module.key !== moduleKey)
        const users = modules.length < 1 ? {} : currentValue.users
        return {
          modules,
          users
        }
      })
  }

  const moduleList = selectedCells.modules.map(moduleWithKey => {
    const { key: moduleKey, ...module } = moduleWithKey
    const moduleName = module.title ? module.title : toFriendlyName(moduleKey)
    const { moduleTooltip, moduleIcon } = getModuleIssueContent(module)
    const dependantModulePresent = moduleDependencyInList(moduleKey, dependencies, selectedCells)

    const uneditable =
      uneditableCells && uneditableCells.modules.find(uneditableModule => uneditableModule.key == moduleWithKey.key)

    const itemClasses = 'scheduler-item' + (disabled || uneditable ? ' text-midgrey' : '')

    return (
      <div key={moduleKey} className={itemClasses}>
        <a
          className={`sw-btn btn-close item-remove sw-tooltip tooltip-right ${uneditable ? 'disabled' : ''}`}
          data-tooltip="Remove"
          onClick={uneditable ? undefined : () => removeModule(moduleKey)}
        >
          <FontAwesomeIcon icon={['fas', 'xmark']} size="xs" />
        </a>
        {moduleName}
        {moduleTooltip && moduleIcon && (
          <div className="sw-tooltip tooltip-left item-warnings" data-tooltip={moduleTooltip}>
            {moduleIcon}
          </div>
        )}
        {dependantModulePresent && (
          <div className="sw-tooltip tooltip-left item-warnings" data-tooltip="Module should go later!">
            {'🧐'}
          </div>
        )}
      </div>
    )
  })

  return (
    <div className="mb-3">
      <div className="font-bold mb-1">Modules</div>
      {moduleList}
    </div>
  )
}

export default ModuleList

/**
 * Determines if a module's dependency is selected, but scheduled after it
 * (A dependency could be avoided by scheduling modules in a different order)
 */
const moduleDependencyInList = (
  moduleKey: string,
  dependencies: PlanDependencies,
  selectedCells: SelectedPlanCells
) => {
  const userIds = Object.keys(selectedCells.users)
  const selectedModuleKeys = selectedCells.modules.map(module => module.key)
  const moduleDependencies = dependencies[moduleKey]
  let inList = false

  if (moduleDependencies) {
    const allModuleDependencyKeys = Object.entries(moduleDependencies).reduce((previous, [userId, current]) => {
      if (userIds.includes(userId)) return previous.concat(current)
      return previous
    }, [] as string[])

    allModuleDependencyKeys.forEach(depModuleKey => {
      if (isScheduledAhead(depModuleKey, moduleKey, selectedModuleKeys)) inList = true
    })
  }
  return inList
}
