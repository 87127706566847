import { UserPlannedSessionsPageProps } from '../generated_types/user_plan_viewer'
import { getLongDateOnly } from '../utils/date_helpers'

type Props = {
  confirmed_at: Date | null
  user: Required<UserPlannedSessionsPageProps>['user']
}

const PlanConfirmationStatus = ({ confirmed_at, user }: Props) => {
  if (confirmed_at) {
    return (
      <div className="sw-toast success">
        ✅ {user.name} confirmed their plan on
        <time dateTime={confirmed_at.toISOString()}>{getLongDateOnly(confirmed_at)}</time>
      </div>
    )
  } else {
    return <div className="sw-toast warning">⚠️ {user.name} has not yet confirmed their plan</div>
  }
}

export default PlanConfirmationStatus
