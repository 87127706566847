import sanitizeHtml from 'sanitize-html'

const simpleFormat = (text: string) => {
  const sanitizedText = sanitizeHtml(text, { allowedTags: [], allowedAttributes: {} })

  const formattedText = sanitizedText
    .replaceAll(/\r\n?/g, '\n') // normalize line endings
    .split(/\n\n+/) // split multiple \n in a row (these will be paragraphs)
    .map(text => text.replaceAll('\n', '\n<br/>')) // line endings -> break
    .map(paragraph => `<p>${paragraph}</p>`)
    .join('\n')
  return formattedText
}

export default simpleFormat
