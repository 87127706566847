import { Dispatch, ReactNode, SetStateAction } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { TrainerInvoice, TrainerInvoiceStatus as Status } from './constants'
import InvoiceStatusModal from './invoice_status_modal'

type TrainerInvoiceStatusProps = {
  invoice: TrainerInvoice
  setInvoice: Dispatch<SetStateAction<TrainerInvoice>>
  readOnly: boolean
}

const TrainerInvoiceStatus = ({ invoice, setInvoice, readOnly }: TrainerInvoiceStatusProps) => {
  let actions: ReactNode = null
  if (invoice.status === Status.Open) {
    actions = (
      <>
        <a href="#freeze-invoice-modal" className="sw-btn ml-auto">
          <FontAwesomeIcon icon={['far', 'snowflake']} />
          Freeze Invoice
        </a>
        <InvoiceStatusModal
          invoice={invoice}
          setInvoice={setInvoice}
          title="Freeze Invoice"
          prompt="Mark this invoice as frozen? This indicates a 'complete' state, where no further changes are expected"
          action={
            <>
              Freeze Invoice
              <FontAwesomeIcon icon={['far', 'snowflake']} />
            </>
          }
          id="freeze-invoice-modal"
          newStatus={Status.Frozen}
        />
      </>
    )
  } else if (invoice.status === Status.Frozen) {
    actions = (
      <>
        <a href="#unfreeze-invoice-modal" className="sw-btn ml-auto">
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={['far', 'snowflake']} />
            <FontAwesomeIcon icon={['fas', 'circle-xmark']} transform="shrink-2 right-8 down-7" />
          </span>
          Unfreeze Invoice
        </a>
        <a href="#complete-invoice-modal" className="sw-btn btn-primary">
          <FontAwesomeIcon icon={['far', 'square-check']} />
          Complete Invoice
        </a>
        <InvoiceStatusModal
          invoice={invoice}
          setInvoice={setInvoice}
          title="Unfreeze Invoice"
          prompt="Reopen this invoice to allow further changes?"
          action={
            <>
              Unfreeze Invoice
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={['far', 'snowflake']} />
                <FontAwesomeIcon icon={['fas', 'circle-xmark']} transform="shrink-2 right-8 down-7" />
              </span>
            </>
          }
          id="unfreeze-invoice-modal"
          newStatus={Status.Open}
        />
        <InvoiceStatusModal
          invoice={invoice}
          setInvoice={setInvoice}
          title="Complete Invoice"
          prompt="Complete this invoice? Once an invoice is complete it can no longer be re-opened or edited. This should be only be done once a coach has submitted an invoice to us, or agreed to the amounts on this draft invoice"
          action={
            <>
              Complete <FontAwesomeIcon icon={['far', 'square-check']} />
            </>
          }
          id="complete-invoice-modal"
          newStatus={Status.Complete}
        />
      </>
    )
  }

  return (
    <div className="flex flex-wrap items-center justify-end gap-2 my-2">
      {invoice.status === Status.Open && (
        <span className="font-semibold">This data is provisional and subject to adjustment</span>
      )}
      {!readOnly && actions}
    </div>
  )
}

export default TrainerInvoiceStatus
