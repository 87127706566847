import CoachingPlanAttributeEditor from './coaching_plan_attribute_editor'

type Props = {
  frequencyInWeeks: number | null
  frequencyChanged: (value: number | null) => void
  trainingPlanId: number
}

const PlanFrequencyEditor = ({ frequencyInWeeks, frequencyChanged, trainingPlanId }: Props) => {
  return (
    <CoachingPlanAttributeEditor
      trainingPlanId={trainingPlanId}
      attribute="frequency_in_weeks"
      currentValue={frequencyInWeeks}
      onChange={frequencyChanged}
      saveLabel="Confirm Frequency"
      editLabel="Edit Session Frequency"
      formattedValue={frequencyLabel(frequencyInWeeks)}
    >
      {({ value, setValue }) => (
        <select
          id="frequency-select"
          className="sw-select"
          value={value || ''}
          onChange={e => setValue(e.target.value === '' ? null : parseInt(e.target.value))}
        >
          <option value="">&lt;none&gt;</option>
          <option value="1">{frequencyLabel(1)}</option>
          <option value="2">{frequencyLabel(2)}</option>
          <option value="3">{frequencyLabel(3)}</option>
          <option value="4">{frequencyLabel(4)}</option>
        </select>
      )}
    </CoachingPlanAttributeEditor>
  )
}

export const frequencyLabel = (n: number | null): string => {
  switch (n) {
    case 1:
      return 'weekly'
    case 2:
      return 'fortnightly'
    case null:
      return '<none>'
    default:
      return `${n} weekly`
  }
}
export default PlanFrequencyEditor
