import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

interface FlashProps {
  children: React.ReactNode
  onClose?: () => void
  sticky?: boolean
  className?: string
}

const FlashMessage = ({ children, sticky }: FlashProps) => {
  const container = useRef(document.createElement('div'))
  const parent = sticky ? 'sticky-flashes' : 'flashes'

  useEffect(() => {
    const flashes = document.getElementById(parent)
    const containerElement = container.current
    if (sticky) {
      containerElement.className = 'basis-full flex'
    }
    flashes && flashes.appendChild(containerElement)
    return () => {
      flashes && flashes.removeChild(containerElement)
    }
  }, [parent, sticky])

  return ReactDOM.createPortal(children, container.current)
}

const CloseButton = ({ onClose }: Pick<FlashProps, 'onClose'>) =>
  onClose ? (
    <button onClick={onClose} className="sw-btn btn-close py-2 ml-auto">
      <FontAwesomeIcon icon={['fas', 'circle-xmark']} className="hover:text-white" />
    </button>
  ) : null

const stickyClasses = 'mx-auto drop-shadow-xl mb-0'

const FlashAlert = ({ children, sticky, onClose, className }: FlashProps) => (
  <FlashMessage sticky={sticky}>
    <div className={`sw-toast error ${sticky ? stickyClasses : ''} ${className ? className : ''}`}>
      {children}
      <CloseButton onClose={onClose} />
    </div>
  </FlashMessage>
)

const FlashSuccess = ({ children, sticky, onClose, className }: FlashProps) => (
  <FlashMessage sticky={sticky}>
    <div className={`sw-toast success ${sticky ? stickyClasses : ''} ${className ? className : ''}`}>
      {children}
      <CloseButton onClose={onClose} />
    </div>
  </FlashMessage>
)

const FlashWarning = ({ children, sticky, onClose, className }: FlashProps) => (
  <FlashMessage sticky={sticky}>
    <div className={`sw-toast warning ${sticky ? stickyClasses : ''} ${className ? className : ''}`}>
      {children}
      <CloseButton onClose={onClose} />
    </div>
  </FlashMessage>
)

export { FlashAlert, FlashSuccess, FlashWarning }
