import React, { MouseEventHandler, PropsWithChildren } from 'react'

type ListPickerProps = PropsWithChildren<{
  id: string
  onAdd: (key: string) => void
}>

const ListPicker = ({ id, onAdd, children }: ListPickerProps) => {
  const datalistId = `${id}-datalist`

  const listRef = React.createRef<HTMLInputElement>()

  const addToList: MouseEventHandler = function (e) {
    const newValue = listRef.current?.value
    if (newValue) {
      onAdd(newValue)
      listRef.current.value = ''
    }
    e.preventDefault()
  }

  return (
    <>
      <datalist id={datalistId}>{children}</datalist>
      <div className="sw-form-grp">
        <input list={datalistId} ref={listRef} id={id} className="sw-input" />
        <button aria-label="Add" onClick={addToList} className="sw-btn btn-primary">
          Add
        </button>
      </div>
    </>
  )
}

export default ListPicker
