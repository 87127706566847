import { format } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'

const formatDate = (isoDate: string): string => {
  const date = new Date(isoDate)
  const localDate = toZonedTime(date, 'Europe/London')
  return format(localDate, "d MMMM yyyy 'at' HH':'mm")
}

export { formatDate }
