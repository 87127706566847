import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

const InternalCreditValueWarning = () => (
  <div className="sw-toast">
    <FontAwesomeIcon icon={['fas', 'circle-info']} size="lg" />
    Remember: credits are now each equivalent to 1 learner hour (typically £110)
  </div>
)

export default InternalCreditValueWarning
