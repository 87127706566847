import { useState } from 'react'
import { apiRequest } from '../utils/json_request'
import { CommentData, PersistedComment, UpdatedableCommentProperties } from './types'

type Props = {
  comment: CommentData
  onUpdate: (c: PersistedComment) => void
}

type ResultType = {
  updateComment: (data: UpdatedableCommentProperties) => void
  submitting: boolean
}

const useComment = ({ comment, onUpdate }: Props): ResultType => {
  const [submitting, setSubmitting] = useState(false)

  const updateComment = async (updates: UpdatedableCommentProperties) => {
    setSubmitting(true)
    const payload = { comment: updates }

    const { ok, data } = await ('id' in comment
      ? apiRequest(`/comments/${comment.id}`, { method: 'PATCH', payload })
      : apiRequest(comment.path, { method: 'POST', payload }))

    setSubmitting(false)

    if (ok) {
      onUpdate(data.comment)
    }
  }

  return { updateComment, submitting }
}

export default useComment
