import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { LineItem } from './types'
import Form from '../library/form'
import Modal from '../library/modal'

type Props = {
  lineItem: LineItem
  closeModal: () => void
}

const AutoCalculateModal = ({ lineItem, closeModal }: Props) => (
  <Form action={lineItem.url} method="PATCH">
    <Modal
      title={
        <>
          Auto Calculate Credits for <span className="text-primary">{lineItem.company.name}</span>
        </>
      }
      onClose={closeModal}
      options={{ active: true }}
      body={
        <p className="whitespace-normal">
          {/* 👆 To counteract the cell's whitespace value */}
          Re-enable auto-calculation? This will automatically adjust the number of credits, removing any manually
          entered credits and explanation.
          <input type="hidden" name="line_item[content_attributes][manually_edited_credits]" value="0" />
          <input type="hidden" name="line_item[content_attributes][manual_credits_reason]" value="" />
        </p>
      }
      footer={
        <button type="submit" className="sw-btn btn-primary">
          <FontAwesomeIcon icon={['far', 'floppy-disk']} />
          Re-Calculate
        </button>
      }
    />
  </Form>
)

export default AutoCalculateModal
