import { CurriculumData, PlannedSession } from '../generated_types/user_plan_viewer'
import { UserSessionStatus, getStatus } from './session_status'
import { ModuleTitles, StatusFilterKey } from './types'

/**
 * Translates an array of curriculum data into the module titles of the associated planned sessions
 */
export const curriculaToModuleTitles = (curricula: CurriculumData[]) => {
  const modules: ModuleTitles = {}
  curricula.forEach(curriculum =>
    curriculum.planned_sessions.forEach(session => {
      if (!modules[session.training_module]) {
        modules[session.training_module] = session.module_title_html
      }
    })
  )
  return modules
}

/**
 * Returns user friendly text for a given status filter key
 */
export const statusFilterTitle = (key: StatusFilterKey) => {
  switch (key) {
    case 'all_sessions':
      return 'All Sessions'
    case 'in_your_plan':
      return 'In Plan'
    case 'optional':
      return 'Optional Sessions'
    case 'not_in_your_plan':
      return 'Not In Plan'
  }
}

/**
 * Returns a filtered list of curricula by technology, containing a filtered list of planned sessions by session status
 * (status is determined by getStatus() in session_status.tsx )
 */
export const filterCurricula = (curricula: CurriculumData[], techFilter: string, statusFilter: StatusFilterKey) => {
  const filteredCurricula: CurriculumData[] = []

  curricula.forEach(curriculum => {
    // Only check curricula that match the tech filter
    if (!techFilter || curriculum.technology === techFilter) {
      // If the status filter is all, include all sessions
      const filteredPlannedSessions =
        statusFilter === 'all_sessions'
          ? curriculum.planned_sessions
          : curriculum.planned_sessions.filter(session => {
              // Otherwise filter on session status
              const sessionStatus = getStatus(session)
              switch (statusFilter) {
                case 'in_your_plan':
                  return [
                    UserSessionStatus.InPlan,
                    UserSessionStatus.Optional,
                    UserSessionStatus.Scheduled,
                    UserSessionStatus.Completed,
                    UserSessionStatus.NotesShared
                  ].includes(sessionStatus)
                case 'optional':
                  return sessionStatus === UserSessionStatus.Optional
                case 'not_in_your_plan':
                  return (
                    sessionStatus === UserSessionStatus.NotInPlan ||
                    sessionStatus === UserSessionStatus.NotNeeded ||
                    sessionStatus === UserSessionStatus.Skipped
                  )
                default:
                  return false
              }
            })

      if (filteredPlannedSessions.length)
        filteredCurricula.push({ ...curriculum, planned_sessions: filteredPlannedSessions })
    }
  })
  return filteredCurricula
}

/**
 * Given a session, returns an error message if marked as un-published or non-existant
 */
export const getModuleWarning = (session: PlannedSession) => {
  if (session.exists === false) {
    return 'Module does not exist'
  } else if (session.published === false) {
    return 'Module is not published'
  }
  return undefined
}
