import { FC, MouseEventHandler, ReactNode } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { formatAmount, formatDate, formatDescription } from './formatters'
import { LineItemType, LineItem } from './constants'

const trainingSessionLink = code => {
  const href = `/coaching_sessions/${code}/edit`
  return <a href={href}>{code}</a>
}

const descriptionWithDetails = (lineItem: LineItem) => {
  const text = formatDescription(lineItem)
  if (lineItem.type === LineItemType.Adjustment && lineItem.adjusted_line_item.invoice_id !== lineItem.invoice_id) {
    const { training_session_code, date, description, invoice_id } = lineItem.adjusted_line_item

    const invoiceLink = (
      <a className="ml-2" href={`/invoices/${invoice_id}`} target="blank">
        (Original Invoice)
      </a>
    )
    let tooltipContent = ''
    if (training_session_code) {
      tooltipContent = tooltipContent.concat(`${training_session_code}\n`)
    }
    tooltipContent = tooltipContent.concat(`${formatDate(date)}\n${description}`)

    return (
      <>
        <span className="sw-tooltip" data-tooltip={tooltipContent}>
          {text}
        </span>
        {invoiceLink}
      </>
    )
  } else {
    return text
  }
}

type LineItemAction = (item: LineItem) => void

type Props = {
  lineItem: LineItem
  onAdjust?: LineItemAction
  onEdit?: LineItemAction
  onDelete?: LineItemAction
  onMoveToOpen?: LineItemAction
}

const LineItemComponent: FC<Props> = ({ lineItem, onAdjust, onEdit, onDelete, onMoveToOpen }) => {
  const { date, training_session_code, amount } = lineItem

  let adjustControls: ReactNode

  if (onAdjust && lineItem.adjustable) {
    const handleAdjustmentClick: MouseEventHandler<HTMLButtonElement> = event => {
      event.stopPropagation()
      onAdjust(lineItem)
    }
    adjustControls = (
      <button onClick={handleAdjustmentClick} className="sw-btn btn-primary btn-sm whitespace-nowrap">
        <FontAwesomeIcon icon={['fas', 'circle-plus']} />
        Add Adjustment
      </button>
    )
  }

  let editControls: ReactNode

  if (onEdit) {
    const handleEditClick: MouseEventHandler<HTMLButtonElement> = event => {
      event.stopPropagation()
      onEdit(lineItem)
    }
    editControls = (
      <button onClick={handleEditClick} className="sw-btn btn-icon" aria-label="Edit Line Item">
        <FontAwesomeIcon icon={['fas', 'pencil']} />
      </button>
    )
  }

  let deleteControls: ReactNode

  if (onDelete) {
    const handleDeleteClick: MouseEventHandler<HTMLButtonElement> = event => {
      event.stopPropagation()
      onDelete(lineItem)
    }

    deleteControls = (
      <button onClick={handleDeleteClick} className="sw-btn btn-icon is-error" aria-label="Delete Line Item">
        <FontAwesomeIcon icon={['far', 'trash-can']} />
      </button>
    )
  }

  let moveControls: ReactNode
  if (onMoveToOpen) {
    const handleMoveClick: MouseEventHandler<HTMLButtonElement> = event => {
      event.stopPropagation()
      onMoveToOpen(lineItem)
    }
    moveControls = (
      <button
        onClick={handleMoveClick}
        className="sw-btn btn-icon sw-tooltip"
        aria-label="Move to Open Invoice"
        data-tooltip="Move to Open Invoice"
      >
        <FontAwesomeIcon icon={['fas', 'share']} />
      </button>
    )
  }

  let indent: ReactNode
  if (lineItem.type == LineItemType.Adjustment) {
    if (lineItem.adjusted_line_item.invoice_id === lineItem.invoice_id) {
      indent = <span className="px-2"></span>
    }
  }

  const controls = (
    <span className="flex flex-wrap items-center justify-end gap-2">
      {adjustControls}
      <span className="flex items-center justify-end gap-2">
        {editControls}
        {deleteControls}
        {moveControls}
      </span>
    </span>
  )

  let warning: ReactNode
  if (lineItem.warnings) {
    warning = (
      <span className="sw-tooltip" data-tooltip={lineItem.warnings.join('\n')}>
        ⚠️
      </span>
    )
  }
  return (
    <tr>
      <td>{training_session_code ? trainingSessionLink(training_session_code) : null}</td>
      <td>
        {indent}
        {formatDate(date)}
      </td>
      <td>
        {indent}
        {descriptionWithDetails(lineItem)}
        {warning}
      </td>
      <td>{formatAmount(amount)}</td>
      <td>{controls}</td>
    </tr>
  )
}

export default LineItemComponent
