import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type ChangeRequestResponseIconProps = {
  response: 'approved' | 'rejected' | null
  includeTooltip?: boolean
}

const ChangeRequestResponseIcon = ({ response, includeTooltip }: ChangeRequestResponseIconProps) => {
  let icon: IconProp, iconColor: string, iconBGColor: string, tooltipText: string

  switch (response) {
    case 'approved':
      icon = ['fas', 'check']
      iconColor = 'text-darkestseagreen'
      iconBGColor = ' bg-darkestseagreen/20'
      tooltipText = 'Approved by Admin'
      break
    case 'rejected':
      icon = ['fas', 'xmark']
      iconColor = 'text-snapperred'
      iconBGColor = ' bg-snapperred/20'
      tooltipText = 'Rejected by Admin'
      break
    default:
      icon = ['fas', 'minus']
      iconColor = 'text-midgray'
      iconBGColor = ' bg-midgray/40'
      tooltipText = 'Request Pending'
  }

  return (
    <div
      className={`inline rounded-md p-2${iconBGColor}${includeTooltip ? ' sw-tooltip tooltip-left' : ''}`}
      data-tooltip={includeTooltip ? tooltipText : undefined}
    >
      <FontAwesomeIcon icon={icon} className={iconColor} size="xl" />
    </div>
  )
}

export default ChangeRequestResponseIcon
