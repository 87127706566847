import { statusFilterTitle } from './helpers'
import { StatusFilterKey } from './types'

type StatusFilterButtonProps = {
  status: StatusFilterKey
  active: boolean
  onFilter: (status: StatusFilterKey) => void
}
const StatusFilterButton = ({ status, active, onFilter }: StatusFilterButtonProps) => {
  return (
    <button
      className={`sw-btn${active ? '  btn-active cursor-default' : ''}`}
      onClick={() => onFilter(status)}
      aria-pressed={active}
    >
      {statusFilterTitle(status)}
    </button>
  )
}

export default StatusFilterButton
