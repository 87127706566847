import { useState } from 'react'
import { CellContentProps } from './types'
import ModuleDetailsModal from './module_details_modal'

const ModuleCellContent = (props: CellContentProps) => {
  const { changeRequest, moduleData } = props
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)

  const title = moduleData?.title_html ? (
    <h4 dangerouslySetInnerHTML={{ __html: moduleData.title_html }} className="module-title" />
  ) : (
    <span className="truncate">{changeRequest.module_key}</span>
  )
  const overview = !!moduleData?.overview_html && (
    <span
      dangerouslySetInnerHTML={{ __html: moduleData.overview_html }}
      className="text-midgrey flex-1 py-0.5 truncate"
    />
  )

  // We want at least a title and either the overview or learning content before showing "more" link
  const moreDetails = moduleData?.title_html && !!(moduleData?.overview_html || moduleData?.learnings_html?.length) && (
    <>
      <a
        className="text-midgray  underline cursor-pointer ml-0.5 hover:font-medium"
        onClick={() => setDetailsModalOpen(true)}
      >
        more
      </a>
      <ModuleDetailsModal
        moduleData={moduleData}
        changeRequest={changeRequest}
        active={detailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
      />
    </>
  )

  return (
    <>
      {title}
      {overview}
      {moreDetails}
    </>
  )
}

export default ModuleCellContent
