import { Dispatch, SetStateAction } from 'react'
import { PastSessionsUserPath } from '../utils/api/paths'
import {
  CoachTrainingSessionSummary,
  TrainingSessionSummary,
  type Pagination as PaginationData
} from '../generated_types/components'
import { apiRequest } from '../utils/json_request'
import Pagination from '../library/pagination'

type PaginationProps<T extends TrainingSessionSummary | CoachTrainingSessionSummary> = {
  pagination: PaginationData
  setTrainingSessions: Dispatch<SetStateAction<T[]>>
  setPagination: Dispatch<SetStateAction<PaginationData | undefined>>
  url: PastSessionsUserPath
  asCoach?: boolean
}

const TrainingSessionPagination = <T extends TrainingSessionSummary | CoachTrainingSessionSummary>({
  setTrainingSessions,
  setPagination,
  pagination,
  url,
  asCoach
}: PaginationProps<T>) => {
  const showPagination = pagination.total_entries > pagination.per_page

  const fetchPage = async (newPageNumber: number) => {
    const response = await apiRequest(url, {
      method: 'GET',
      payload: undefined,
      query: { per_page: pagination.per_page, page: newPageNumber, as_coach: asCoach ? 1 : 0 }
    })

    if (response.ok) {
      setPagination(response.data.pagination)
      setTrainingSessions(response.data.training_sessions as T[])
    }
  }

  return showPagination ? (
    <Pagination {...pagination} onPageChange={page => fetchPage(page)} classNames="justify-center gap-6" />
  ) : null
}

export default TrainingSessionPagination
