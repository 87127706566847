import React from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { AdminTrainingPlan } from './generated_types/training_plan'
import { useEditor, EditableContent } from './library/editor'
import UpdatedAt from './library/updated_at'
import { usePlanVersion } from './training_plan_editor/components/use_plan_version'

type Props = {
  trainingPlan: AdminTrainingPlan
}

const TrainingPlanDescription = ({
  trainingPlan: {
    description,
    id: trainingPlanId,
    formatted_description: formattedDescription,
    description_updated_by,
    description_updated_at
  }
}: Props) => {
  const { lockVersions, planUpdated } = usePlanVersion()
  const { updatedAt, updatedBy, content } = useEditor(TrainingPlanDescriptionContent, {
    source: description,
    formatted: formattedDescription,
    updated_at: description_updated_at,
    updated_by: description_updated_by,
    url: `/plans/${trainingPlanId}`,
    serialize: description => ({ training_plan: { description, lock_version: lockVersions[trainingPlanId] } }),
    parse: ({ training_plan }) => {
      planUpdated({ newVersion: training_plan.lock_version, planId: training_plan.id })
      return {
        source: training_plan.description,
        formatted: training_plan.formatted_description,
        updated_at: training_plan.description_updated_at,
        updated_by: training_plan.description_updated_by
      }
    }
  })

  return (
    <>
      <h4 className="mb-0">Description</h4>
      <UpdatedAt name={updatedBy?.name} updated_at={updatedAt} classNames="mx-2" />
      {content}
    </>
  )
}

export default TrainingPlanDescription

type ContentProps = {
  formatted: string
  startEditing: () => void
}

const TrainingPlanDescriptionContent = ({ formatted, startEditing }: ContentProps) => {
  return (
    <EditableContent
      formatted={formatted}
      startEditing={startEditing}
      emptyStateTitle="No description"
      emptyStateAction={
        <>
          <FontAwesomeIcon icon={['far', 'pen-to-square']} />
          Add description
        </>
      }
      classNames="m-2 sw-box"
    />
  )
}
