import React, { ReactNode } from 'react'

interface EmptyProps {
  title?: string
  subtitle?: ReactNode
  icon?: ReactNode
  action?: ReactNode
  classNames?: string
}

const Empty = ({ icon, title, action, subtitle, classNames = '' }: EmptyProps) => {
  return (
    <div className={`sw-empty ${classNames}`}>
      {icon}
      <h3>{title ?? `There's nothing here`}</h3>
      {subtitle && <p>{subtitle}</p>}
      {action && <div>{action}</div>}
    </div>
  )
}

export default Empty
