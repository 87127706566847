import React from 'react'
import { TrainingPlanContext } from '../types'
import { newlyAddedClass } from '../utils'

const TrainingPlanFooter = ({
  learners,
  newAddedUserIds,
  userIdsToRemove,
  editable
}: Pick<TrainingPlanContext, 'learners' | 'newAddedUserIds' | 'userIdsToRemove' | 'editable'>) => {
  const userCells = learners.map(({ user_id: userId }) => {
    const classNames: Array<string> = []
    if (userIdsToRemove.has(userId)) {
      classNames.push('marked-for-destruction')
    }

    const newUserClass = newlyAddedClass(newAddedUserIds, userId)
    if (newUserClass) {
      classNames.push(newUserClass)
    }

    return <td key={userId} className={classNames.join(' ')} />
  })

  return (
    <tfoot>
      <tr>
        <th />
        {editable ? <th /> : null}
        {userCells}
      </tr>
    </tfoot>
  )
}

export default TrainingPlanFooter
