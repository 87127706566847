import React, { useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import SessionDetails from './training_session_form/session_details'
import LineItems from './training_session_form/line_items'
import Attendances from './training_session_form/attendances'
import Modal from './library/modal'

import {
  Attendance,
  LineItem,
  ExistingTrainingSession,
  UserMap,
  AttendanceUpdate,
  TrainingModules,
  UserStub
} from './training_session_form/types'
import { ModuleDurationOverrides, PlanMembership, SessionType } from './generated_types/training_session'
import LineItemAuditTrail from './training_session_form/line_item_audit_trail'
import Title, { TitleContainer } from './training_session_form/title'
import CancelSessionModal from './training_session_form/cancel_session_modal'
import UncancelSessionModal from './training_session_form/uncancel_session_modal'
import { FlashSuccess } from './library/flash_message'
import PlanMemberships from './training_session_form/plan_memberships'

type Props = {
  trainingSession: ExistingTrainingSession
  users: UserMap
  lineItems: LineItem[]
  attendances: Attendance[]
  modules: TrainingModules
  availableInternalUsers: UserStub[]
  url: string
  trainers: UserStub[]
  moduleDurationOverrides: ModuleDurationOverrides
  planMemberships: PlanMembership[]
  coachMessagingEnabled: boolean
}

const externalChargeableAttendances = (attendances: Attendance[], internal: UserStub[]): Attendance[] =>
  attendances.filter(a => internal.findIndex(user => user.id == a.user_id) === -1).filter(a => a.charged)

const TrainingSessionEditForm = ({
  url,
  trainers,
  availableInternalUsers,
  modules,
  trainingSession: initialTrainingSession,
  lineItems: initialLineItems,
  users: initialUsers,
  attendances: initialAttendances,
  planMemberships: initialPlanMemberships,
  moduleDurationOverrides,
  coachMessagingEnabled
}: Props) => {
  const [trainingSession, setTrainingSession] = useState(initialTrainingSession)
  const [users, setUsers] = useState(initialUsers)
  const [lineItems, setLineItems] = useState(initialLineItems)
  const [attendances, setAttendances] = useState(initialAttendances)
  const [planMemberships, setPlanMemberships] = useState(initialPlanMemberships)
  const [displayManualCreditsWarning, setDisplayManualCreditsWarning] = useState(false)
  const [changeCancellationStatus, setChangeCancellationStatus] = useState(false)
  const [successMessage, setSuccessMessage] = useState<undefined | string>()

  const updateTrainingSession = (updatedTrainingSession: ExistingTrainingSession) => {
    setTrainingSession(oldTrainingSession => {
      const durationChanged = oldTrainingSession.duration_in_minutes != updatedTrainingSession.duration_in_minutes
      const manuallyEditedLineItems = lineItems.filter(lineItem => lineItem.manually_edited_credits)
      if (durationChanged && manuallyEditedLineItems.length > 0) {
        setDisplayManualCreditsWarning(true)
      }
      return updatedTrainingSession
    })
  }

  const updateAttendances: React.Dispatch<React.SetStateAction<Attendance[]>> = newAttendances => {
    setAttendances(oldAttendances => {
      const updatedAttendances = typeof newAttendances == 'function' ? newAttendances(oldAttendances) : newAttendances

      const amountChanged =
        externalChargeableAttendances(updatedAttendances, availableInternalUsers).length !=
        externalChargeableAttendances(oldAttendances, availableInternalUsers).length
      const manuallyEditedLineItems = lineItems.filter(lineItem => lineItem.manually_edited_credits)
      if (amountChanged && manuallyEditedLineItems.length > 0) {
        setDisplayManualCreditsWarning(true)
      }
      return updatedAttendances
    })
  }

  const attendanceAdded = (data: AttendanceUpdate) => {
    const {
      attendances: newAttendances,
      line_items: newLineItems,
      users: newUsers,
      plan_memberships: newPlanMemberships
    } = data
    setUsers(newUsers)
    updateAttendances(newAttendances)
    setPlanMemberships(newPlanMemberships)
    setLineItems(newLineItems)
  }

  const onSessionCancellationStatusModalClose = (message?: string) => {
    setChangeCancellationStatus(false)
    if (message) {
      setSuccessMessage(message)
    }
  }

  return (
    <>
      {successMessage && (
        <FlashSuccess sticky onClose={() => setSuccessMessage(undefined)}>
          {successMessage}
        </FlashSuccess>
      )}
      <div id="session-actions" className="justify-end mb-4 gap-4 flex flex-row">
        {
          <button
            className={`sw-btn ${trainingSession.cancelled ? 'is-success' : 'is-warning'}`}
            disabled={trainingSession.session_type === SessionType.Fake}
            onClick={() => setChangeCancellationStatus(true)}
          >
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={['fas', 'chalkboard-user']} />
              <FontAwesomeIcon
                icon={['fas', trainingSession.cancelled ? 'circle-check' : 'circle-xmark']}
                transform="shrink-2 right-11 down-7"
              />
            </span>
            {trainingSession.cancelled ? 'Uncancel Session' : 'Cancel Session'}
          </button>
        }
        {!trainingSession.cancelled && (
          <a href={trainingSession.session_url} className="sw-btn btn-primary">
            Go To Session
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </a>
        )}
      </div>

      <TitleContainer>
        <Title trainingSession={trainingSession} />
      </TitleContainer>

      <SessionDetails
        url={url}
        trainingSession={trainingSession}
        setTrainingSession={updateTrainingSession}
        setLineItems={setLineItems}
        trainers={trainers}
        modules={modules}
        attendances={attendances}
        setAttendances={updateAttendances}
        setPlanMemberships={setPlanMemberships}
        moduleDurationOverrides={moduleDurationOverrides}
        coachMessagingEnabled={coachMessagingEnabled}
      />

      <h3>Add Attendees</h3>

      <LineItems
        attendanceAdded={attendanceAdded}
        users={users}
        lineItems={lineItems}
        setLineItems={setLineItems}
        trainingSession={trainingSession}
        availableInternalUsers={availableInternalUsers}
        allowAdd={!(trainingSession.cancelled || trainingSession.session_type === SessionType.Fake)}
      />

      <h3 className="mt-12">Current Attendees</h3>

      <Attendances
        attendances={attendances}
        users={users}
        setUsers={setUsers}
        setAttendances={updateAttendances}
        setPlanMemberships={setPlanMemberships}
        setLineItems={setLineItems}
        readonly={trainingSession.cancelled || trainingSession.session_type === SessionType.Fake}
        feedbackUrl={trainingSession.feedback_url}
      />

      <h3 className="mt-12">Coaching Plans</h3>
      <PlanMemberships
        planMemberships={planMemberships}
        users={users}
        attendances={attendances}
        sessionCode={trainingSession.code}
      />

      <LineItemAuditTrail lineItems={lineItems} />

      {changeCancellationStatus &&
        (trainingSession.cancelled ? (
          <UncancelSessionModal
            trainingSession={trainingSession}
            setLineItems={setLineItems}
            setTrainingSession={setTrainingSession}
            close={onSessionCancellationStatusModalClose}
          />
        ) : (
          <CancelSessionModal
            trainingSession={trainingSession}
            setLineItems={setLineItems}
            setTrainingSession={setTrainingSession}
            close={onSessionCancellationStatusModalClose}
            lineItems={lineItems}
          />
        ))}

      {displayManualCreditsWarning ? (
        <Modal
          options={{ active: true }}
          title="Manual Credit Check Required!"
          footer={
            <button className="sw-btn btn-primary" onClick={() => setDisplayManualCreditsWarning(false)}>
              OK
              <FontAwesomeIcon icon={['fas', 'check']} />
            </button>
          }
          onClose={() => setDisplayManualCreditsWarning(false)}
        >
          Your change would normally edit the credits used for this session, but we haven&apos;t recalculated anything
          because the credits have previously been manually edited. Please check that no updates are required
        </Modal>
      ) : null}
    </>
  )
}

export default TrainingSessionEditForm
