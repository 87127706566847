import { useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { CellContentProps } from './types'
import AnswersIframeModal from './answers_iframe_modal'

const AnswerLinksCellContent = ({ changeRequest }: CellContentProps) => {
  const { participations } = changeRequest

  if (!participations.length) return <></>
  if (participations.length === 1) return <AnswerLink answerLink={participations[0].answers_url} />

  const mostRecentAnswersUrl = participations
    .map(participation => ({ answersUrl: participation.answers_url, date: new Date(participation.updated_at) }))
    .reduce((previous, current) => (previous.date.getTime() > current.date.getTime() ? previous : current)).answersUrl

  return <AnswerLink answerLink={mostRecentAnswersUrl} />
}

export default AnswerLinksCellContent

const AnswerLink = ({ answerLink }: { answerLink: string }) => {
  const [modalActive, setModalActive] = useState(false)

  return (
    <>
      <button
        title="Answers"
        className="sw-btn btn-sm btn-primary hover:bg-oceanblue/60 focus:bg-oceanblue/60 whitespace-nowrap mt-1"
        onClick={() => setModalActive(true)}
      >
        Answers
        <FontAwesomeIcon icon={['fas', 'window-restore']} />
      </button>
      <AnswersIframeModal url={answerLink} active={modalActive} onClose={() => setModalActive(false)} />
    </>
  )
}
