import React, { MouseEventHandler, RefObject } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import SWHorizontalFieldWithLabel from '../library/sw/sw_horizontal_field_with_label'
import ModuleWarnings from './module_warnings'
import { TrainingModules } from './types'

export type ModuleSelectorProps = {
  name: string
  datalist_id: string
  moduleData: TrainingModules
  modules: string[]
  setModules: (updater: (current: string[]) => string[]) => void
  multiple: boolean
  readonly: boolean
}

const ModuleSelector = ({
  name,
  datalist_id,
  moduleData,
  modules,
  setModules,
  multiple,
  readonly
}: ModuleSelectorProps) => {
  const listRef = React.createRef<HTMLInputElement>()

  const addToList: MouseEventHandler = function (e) {
    const newValue = listRef.current?.value
    if (newValue) {
      setModules(current => [...new Set(current.concat(newValue))])
      listRef.current.value = ''
    }
    e.preventDefault()
  }

  const removeFromList: MouseEventHandler<HTMLElement> = function (e) {
    const toRemove = e.currentTarget.dataset.key
    if (!toRemove) {
      return
    }

    setModules(current => {
      const index = current.indexOf(toRemove)
      if (index < 0) {
        return current
      }

      const newModules = [...current]
      newModules.splice(index, 1)
      return newModules
    })
    e.preventDefault()
  }

  const chips = modules.map(key => {
    const removeLink = readonly ? null : (
      <a href="#" className="sw-btn btn-close-sm" onClick={removeFromList} data-key={key}>
        <FontAwesomeIcon icon={['fas', 'xmark']} size="sm" />
      </a>
    )
    const data = moduleData[key]
    return (
      <span key={key} className="sw-chip sw-tooltip" data-tooltip={key}>
        {data ? `${data.curriculum_title}: ${data.module_title}` : key}
        {removeLink}
      </span>
    )
  })

  const canAddModule = !readonly && (multiple || modules.length === 0)

  return (
    <>
      <SWHorizontalFieldWithLabel
        name={name}
        label="Modules"
        as={Picker}
        datalist_id={datalist_id}
        addToList={addToList}
        listRef={listRef}
        disabled={!canAddModule}
      />
      <div className="flex flex-wrap gap-1 m-2">{chips}</div>
      <ModuleWarnings modules={modules} moduleData={moduleData} />
    </>
  )
}

export default ModuleSelector

type PickerProps = {
  id: string
  datalist_id: string
  listRef: RefObject<HTMLInputElement>
  addToList: MouseEventHandler
  disabled: boolean
}

const Picker = ({ id, datalist_id, listRef, addToList, disabled }: PickerProps) => (
  <div className="sw-form-grp max-w-xl grow">
    <input list={datalist_id} ref={listRef} id={id} className="sw-input" disabled={disabled} />
    <button onClick={addToList} aria-label="Add Module" className="sw-btn" disabled={disabled}>
      Add
    </button>
  </div>
)
