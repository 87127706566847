import React, { SetStateAction, useState } from 'react'
import UserPicker from '../../user_picker'
import { fetchUserSessionStatuses, getErrorMessage } from '../session_editor/session_editor_helpers'
import { PlannedSession, User, Learner } from '../types'
import { Sessions, SharedNotes, TrainingPlanUserStatusResponse } from '../../generated_types/training_plan'

type UpdateInPlace<A> = (updater: (value: A) => void) => void

type Props = {
  updatePlannedSessions: UpdateInPlace<PlannedSession[]>
  setLearners: React.Dispatch<SetStateAction<Learner[]>>
  availableUsers: User[]
  moduleKeys: string[]
  trainingPlanId: number
  updateSharedNotes: (newData: SharedNotes) => void
  addSessions: (newData: Sessions) => void
}

const AddUser = ({
  updatePlannedSessions,
  setLearners,
  availableUsers,
  trainingPlanId,
  moduleKeys,
  updateSharedNotes,
  addSessions
}: Props) => {
  const [userAddError, setUserAddError] = useState<string | undefined>(undefined)

  const onUserIdSelected = async (id: number) => {
    let response: TrainingPlanUserStatusResponse
    try {
      response = await fetchUserSessionStatuses({ trainingPlanId, userId: id, moduleKeys: moduleKeys })
      setUserAddError(undefined)
    } catch (error: unknown) {
      setUserAddError(getErrorMessage(error))
      return
    }
    updatePlannedSessions(sessions => {
      sessions.forEach((session, index) => {
        const statusForSession = response.attendances.find(status => status.training_module === session.training_module)
        if (statusForSession) {
          const { training_module, ...attendanceData } = statusForSession

          sessions[index] = { ...session, attendances: { ...session.attendances, [id]: attendanceData } }
        }
      })
    })

    setLearners(existingLearners => {
      if (existingLearners.find(l => l.user_id === id)) {
        return existingLearners
      }
      return existingLearners.concat({ user_id: id, assessment_id: null, id: null })
    })

    updateSharedNotes(response.shared_notes)
    addSessions(response.sessions)
  }

  return (
    <div className="grow">
      {userAddError && <div className="sw-toast error">{userAddError}</div>}
      <UserPicker users={availableUsers} userIdSelected={onUserIdSelected} label="Add User to Plan" />
    </div>
  )
}

export default AddUser
