import { FlashSuccess, FlashAlert } from '../library/flash_message'
import Errors, { collectErrorMessages } from '../library/errors'
import { SessionDetailsFormikStatus } from './types'

type StatusDisplayProps = {
  status: SessionDetailsFormikStatus
  resetStatus: () => void
}

const StatusDisplay = ({ status, resetStatus }: StatusDisplayProps) => {
  if (!status) {
    return null
  }

  if (status.message) {
    return (
      <FlashSuccess sticky onClose={resetStatus}>
        {status.message}
      </FlashSuccess>
    )
  }

  if (status.errors) {
    if (status.errors.base) {
      //these are api level errors
      return (
        <FlashAlert sticky onClose={resetStatus}>
          {collectErrorMessages(status.errors.base)}
        </FlashAlert>
      )
    } else {
      return <ul>{<Errors errors={status.errors} full={true} as="li" />}</ul>
    }
  }
  return null
}

export default StatusDisplay
