import React from 'react'
import CompanyDescription from './company_description'
import Comments from '../comments/comments'
import { CompanyContextPageProps } from '../generated_types/company_context'
import CompanyPauses from './company_pauses'

const CompanyContext = ({ company, comments, pauses }: CompanyContextPageProps) => (
  <>
    <CompanyDescription company={company} />
    <CompanyPauses company={company} pauses={pauses} />
    <Comments editable comments={comments} path={`/companies/${company.id}/comments`} />
  </>
)

export default CompanyContext
