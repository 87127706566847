import { type LineItem, LineItemType } from './constants'

const currencyFormat = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const dateFormat = Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: 'numeric' })

const formatDate = (timestamp: string) => dateFormat.format(new Date(timestamp))

const formatAmount = (amountInPence: number) => currencyFormat.format(amountInPence / 100)

const descriptionPrefix = (lineItem: LineItem) => (lineItem.type === LineItemType.Adjustment ? 'Adjustment: ' : '')

const formatDescription = (lineItem: LineItem) => `${descriptionPrefix(lineItem)}${lineItem.description}`

export { formatAmount, formatDate, formatDescription }
