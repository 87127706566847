import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { CurriculumStatus, ModuleGrouping } from '../generated_types/curriculum_status'
import { MouseEventHandler } from 'react'

type Props = {
  groupings: ModuleGrouping[]
  statuses: Record<string, CurriculumStatus | null>
  handleChipClick: MouseEventHandler<HTMLAnchorElement>
}
const ChipList = ({ statuses, groupings, handleChipClick }: Props) => {
  return (
    <div className="sw-grp mt-4 flex flex-row gap-2">
      {groupings.map(c => (
        <div key={c.key} className="sw-chip">
          {c.title}{' '}
          {statuses[c.key] ? (
            <a
              onClick={handleChipClick}
              data-key={c.key}
              aria-label={`Remove ${c.title}`}
              className="rounded-full hover:bg-orcablue"
              href="#"
            >
              <FontAwesomeIcon icon={['fas', 'xmark']} fixedWidth className="hover:text-white" />
            </a>
          ) : (
            <span className="px-2 loading" />
          )}
        </div>
      ))}
    </div>
  )
}

export default ChipList
