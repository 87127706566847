import { CurriculumData } from '../generated_types/user_plan_viewer'
import { ModuleTitles } from './types'
import CurriculumHeader from './curriculum_header'
import SessionRow from './session_row'

type CurriculumProps = {
  data: CurriculumData
  moduleTitles: ModuleTitles
  readonly: boolean
}

const Curriculum = ({ data, moduleTitles, readonly }: CurriculumProps) => {
  const sessionHeaders = [
    { title: '', class: '' },
    { title: 'Module', class: 'col-span-5' },
    { title: 'Duration', class: 'col-span-1' },
    { title: 'Status', class: 'col-span-2' },
    { title: 'Feedback', class: 'col-span-3' }
  ]

  return (
    <>
      <CurriculumHeader title={data.curriculum_title} technology={data.technology} />
      <div className="session-layout">
        {sessionHeaders.map((header, index) => (
          <div key={index} className={`session-header ${header.class}`}>
            {header.title}
          </div>
        ))}
        {data.planned_sessions.map(session => (
          <SessionRow
            key={`session-row-${session.training_module}`}
            session={session}
            moduleTitles={moduleTitles}
            readonly={readonly}
          />
        ))}
      </div>
    </>
  )
}

export default Curriculum
