import { Dispatch, MouseEventHandler, ReactNode, SetStateAction } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { TrainerInvoice, TrainerInvoiceStatus } from './constants'
import { useApiSave } from '../utils/json_request'
import Modal from '../library/modal'

type InvoiceStatusModalProps = {
  id: string
  title: string
  prompt: string
  action: ReactNode
  invoice: TrainerInvoice
  setInvoice: Dispatch<SetStateAction<TrainerInvoice>>
  newStatus: TrainerInvoiceStatus
}

const InvoiceStatusModal = ({ invoice, setInvoice, title, prompt, action, id, newStatus }: InvoiceStatusModalProps) => {
  const { save, errors, saving, resetErrors } = useApiSave(`/invoices/${invoice.id}`, 'PATCH')

  const handleSave: MouseEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault()
    const { ok, data } = await save({ payload: { invoice: { status: newStatus } } })

    if (ok) {
      close()
      setInvoice(data.invoice)
    }
  }

  const close = () => {
    resetErrors()
    window.location.hash = ''
  }

  const body = (
    <>
      {errors.base ? <div className="sw-toast error">{errors.base.join(', ')}</div> : null}
      {errors.status ? <div className="sw-toast error">{errors.status.join(', ')}</div> : null}
      <p>{prompt}</p>
    </>
  )

  return (
    <Modal
      id={id}
      title={title}
      body={body}
      onClose={close}
      footer={
        <>
          <button onClick={close} className="sw-btn mr-auto">
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            Cancel
          </button>
          <button disabled={saving} className={`sw-btn btn-primary${saving ? ' sw-loading' : ''}`} onClick={handleSave}>
            {action}
          </button>
        </>
      }
    />
  )
}

export default InvoiceStatusModal
