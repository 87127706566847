import React, { Dispatch, SetStateAction } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { Timeslot } from '../../generated_types/training_plan'
import { TimeslotClickedFunction } from '../types'
import TimeslotChip from './timeslot_chip'
import { AvailableTrainer, TrainingPlanTrainer } from './training_plan_trainer.types'
import { UserPath } from '../../utils/api/paths'

interface TrainerProps {
  user: AvailableTrainer
  trainingPlanTrainer: TrainingPlanTrainer
  onEdit: Dispatch<SetStateAction<TrainingPlanTrainer>>
  onDelete: Dispatch<SetStateAction<TrainingPlanTrainer>>
  timeslots: Timeslot[]
  onTimeslotClick?: TimeslotClickedFunction
}

const Trainer = ({ user, trainingPlanTrainer, onEdit, onDelete, timeslots, onTimeslotClick }: TrainerProps) => {
  const userPath: UserPath = `/users/${trainingPlanTrainer.user_id}`
  const timeslotIds = trainingPlanTrainer.coach_timeslots.map(t => t.timeslot_id)

  const assignedTimeslots = timeslots.filter(slot => timeslotIds.includes(slot.id))
  const handleChipClick =
    onTimeslotClick &&
    ((starts_at: Date, timezone: string) =>
      onTimeslotClick({ starts_at, timezone, coach_id: trainingPlanTrainer.user_id }))
  return (
    <tr>
      <td>
        <a href={userPath} target="_blank" rel="noreferrer">
          {user ? user.name : trainingPlanTrainer.user_id}
        </a>
      </td>
      <td>{trainingPlanTrainer.notes}</td>
      <td className="px-0">
        <div className="gap-1 flex flex-wrap">
          {assignedTimeslots.map(timeslot => (
            <TimeslotChip timeslot={timeslot} key={timeslot.id} onClick={handleChipClick} />
          ))}
        </div>
      </td>
      <td className="whitespace-nowrap text-end">
        <button
          className="sw-btn btn-icon h-9 w-9 mr-2"
          onClick={() => onEdit(trainingPlanTrainer)}
          aria-label="Edit Coach"
        >
          <FontAwesomeIcon icon={['fas', 'pencil']} />
        </button>
        <button
          className="sw-btn btn-icon is-error h-9 w-9"
          onClick={() => onDelete(trainingPlanTrainer)}
          aria-label="Delete Coach"
        >
          <FontAwesomeIcon icon={['fas', 'xmark']} />
        </button>
      </td>
    </tr>
  )
}

export default Trainer
