import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { longDateFormat } from '../../utils/date_helpers'
import Modal from '../../library/modal'
import { ChangeRequestWithReview } from '../types'
import { MouseEventHandler, PropsWithChildren } from 'react'
import { currentChangeRequest, prettifyRequestState } from './session_editor_helpers'
import ChangeRequestResponseIcon from '../../library/change_request_response_icon'
import LabelledText from '../../library/labelled_text'
import ChangeRequestReviewIcon from '../../library/change_request_review_icon'

type ChangeRequestConflictModalProps = {
  changeRequests: ChangeRequestWithReview[]
  onCancelDelete: MouseEventHandler<Element>
  onDeleteRequest: (requestId: number, event: React.MouseEvent<Element, MouseEvent>) => void
}

const ChangeRequestConflictModal = ({
  changeRequests,
  onDeleteRequest,
  onCancelDelete
}: ChangeRequestConflictModalProps) => {
  const changeRequest = currentChangeRequest(changeRequests)
  if (!changeRequest) return null

  const footer = (
    <>
      <button onClick={onCancelDelete} className="sw-btn mr-auto">
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        Cancel
      </button>
      <button type="submit" onClick={event => onDeleteRequest(changeRequest.id, event)} className="sw-btn is-error">
        Reject Change Request
        <FontAwesomeIcon icon={['fas', 'xmark']} />
      </button>
    </>
  )

  return (
    <Modal title="Override Learner Change Request?" footer={footer} options={{ active: true }} onClose={onCancelDelete}>
      <p className="text-left">
        Changing this planned session is contrary to the learner&apos;s most recently submitted change request:
      </p>
      <ChangeRequestDetails changeRequest={changeRequest} />
      <div className="sw-toast warning">
        <FontAwesomeIcon icon={['fas', 'circle-exclamation']} />
        Proceeding will reject this learner&apos;s request when the plan is saved.
      </div>
    </Modal>
  )
}

export default ChangeRequestConflictModal

const ChangeRequestDetails = ({ changeRequest }: { changeRequest: ChangeRequestWithReview }) => {
  const { original_state, requested_state, created_at, review, reviewed_at, response, responded_at } = changeRequest
  const responseText =
    response === 'approved' ? 'Admin Approved' : response === 'rejected' ? 'Admin Rejected' : 'No Admin Response'
  const reviewText =
    review === 'approved' ? 'Manager Approved' : review === 'rejected' ? 'Manager Rejected' : 'No Manager Review'

  return (
    <div className="flex flex-wrap gap-3 items-start py-3 border-l-4 border-lightergrey">
      <div className="flex flex-col basis-52 grow gap-3 ml-4 mb-4">
        <LabelledText label="Original Suggestion" text={prettifyRequestState(original_state)} />
        <LabelledText label="Change Request" text={prettifyRequestState(requested_state)} />
        <LabelledText label="Request Made" text={longDateFormat.format(new Date(created_at))} />
        {/* TODO: Include link to assessment */}
      </div>
      <Response responded_at={reviewed_at}>
        <ChangeRequestReviewIcon response={review} />
        <h4 className="m-0">{reviewText}</h4>
      </Response>
      <Response responded_at={responded_at}>
        <ChangeRequestResponseIcon response={response} />
        <h4 className="m-0">{responseText}</h4>
      </Response>
    </div>
  )
}

const Response = ({ responded_at, children }: Pick<ChangeRequestWithReview, 'responded_at'> & PropsWithChildren) => (
  <div className="sw-snippet flex-col basis-52 grow mt-0 mb-auto ml-4">
    <div className="flex gap-6 items-center">{children}</div>
    {responded_at && <LabelledText label="On" text={longDateFormat.format(new Date(responded_at))} />}
  </div>
)
