import { Dispatch, SetStateAction } from 'react'
import AutoStartHostedEnvironmentsEditor from './auto_start_hosted_environments_editor'

type Props = {
  trainingPlanId: number
  autoStartHostedEnvironments: boolean
  setAutoStartHostedEnvironments: Dispatch<SetStateAction<boolean>>
}

const AutoStartHostedEnvironments = ({
  trainingPlanId,
  autoStartHostedEnvironments,
  setAutoStartHostedEnvironments
}: Props) => {
  return (
    <div className="flex flex-wrap items-center justify-end gap-x-2 gap-y-1">
      <label className="basis-72 text-base font-semibold mr-auto" htmlFor="autostart-hosted-env">
        Auto Start Hosted Environments:
      </label>

      <AutoStartHostedEnvironmentsEditor
        trainingPlanId={trainingPlanId}
        onChange={setAutoStartHostedEnvironments}
        autoStartHostedEnvironments={autoStartHostedEnvironments}
      />
    </div>
  )
}

export default AutoStartHostedEnvironments
