import React from 'react'
import { Unavailability } from '../../types'

interface UnavailabilityIconsProps {
  sessionUnavailabilities: Unavailability[]
  otherUnavailabilities: Unavailability[]
}

const UnavailabilityIcons = ({ sessionUnavailabilities, otherUnavailabilities }: UnavailabilityIconsProps) => {
  const otherUnavailabilitiesCount = otherUnavailabilities.length
  const otherUnavailabilitiesTooltip = otherUnavailabilitiesCount
    ? `Time blockers - ${otherUnavailabilitiesCount}`
    : undefined

  const sessionUnavailabilitiesCount = sessionUnavailabilities.length
  const sessionUnavailabilitiesTooltip = sessionUnavailabilitiesCount
    ? `Session Conflicts - ${sessionUnavailabilitiesCount}`
    : undefined

  return (
    <>
      {!!sessionUnavailabilitiesCount && (
        <div className="sw-tooltip tooltip-left" data-tooltip={sessionUnavailabilitiesTooltip}>
          ⛔️
        </div>
      )}
      {!!otherUnavailabilitiesCount && (
        <div className="sw-tooltip tooltip-left" data-tooltip={otherUnavailabilitiesTooltip}>
          ⏰
        </div>
      )}
    </>
  )
}

export default UnavailabilityIcons
