import { MouseEventHandler, PropsWithChildren, ReactNode } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import Modal from './modal'

export type ConfirmationModalProps = PropsWithChildren<{
  id?: string
  title?: string
  message?: ReactNode
  confirmLabel?: string
  submitting: boolean
  disabled?: boolean
  onClose: MouseEventHandler<HTMLElement>
  onConfirm: MouseEventHandler<HTMLButtonElement>
}>

/**
 * A simple confirmation modal
 * Modal state management is left in the hands of the parent
 */
const ConfirmationModal = ({
  id,
  title,
  message,
  disabled,
  submitting,
  onClose,
  onConfirm,
  confirmLabel,
  children
}: ConfirmationModalProps) => {
  const footer = (
    <>
      <button type="button" disabled={submitting} onClick={onClose} className="sw-btn mr-auto">
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        Cancel
      </button>
      <button
        disabled={submitting || disabled}
        type="submit"
        onClick={onConfirm}
        className={`sw-btn btn-primary${submitting ? ' sw-loading' : ''}`}
      >
        {confirmLabel ?? 'Confirm'}
        <FontAwesomeIcon icon={['fas', 'check']} />
      </button>
    </>
  )

  return (
    <Modal
      id={id}
      options={{ active: true, submitting }}
      title={title ?? 'Confirmation Required'}
      footer={footer}
      onClose={onClose}
    >
      {message}
      {children}
    </Modal>
  )
}

export default ConfirmationModal
