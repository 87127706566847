import { useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { type LineItem } from './constants'
import Modal from '../library/modal'
import ConfirmEditsToFrozen from './confirm_edits_to_frozen'
import { formatDate, formatDescription } from './formatters'
import { ModelErrors } from '../library/errors'

type Props = {
  lineItem: LineItem
  onClose: () => void
  onDelete: OnDeleteCallback
  deleting: boolean
  errors: ModelErrors
  extraConfirmationRequired: boolean
}

export type OnDeleteCallback = (id: number, permit_updates_to_frozen_invoice: boolean) => Promise<void>

const DeleteLineItemModal = ({ lineItem, onClose, onDelete, deleting, errors, extraConfirmationRequired }: Props) => {
  const [updateFrozen, setUpdateFrozen] = useState(false)

  const body = (
    <>
      {errors.base ? <div className="sw-toast error">{errors.base.join(', ')}</div> : null}
      Are you sure you want to delete <strong>{formatDescription(lineItem)}</strong> from{' '}
      <i>{formatDate(lineItem.date)}</i>? This will not be included in later invoices.
      <div className="form-input-hint">If you just want to exclude it from this invoice, use Move instead.</div>
      <ConfirmEditsToFrozen
        extraConfirmationRequired={extraConfirmationRequired}
        updateFrozen={updateFrozen}
        setUpdateFrozen={setUpdateFrozen}
      />
    </>
  )

  const deleteDisabled = deleting || (extraConfirmationRequired && !updateFrozen)

  return (
    <Modal
      title="Delete Line Item"
      onClose={onClose}
      options={{ active: true }}
      body={body}
      footer={
        <>
          <button onClick={onClose} className="sw-btn mr-auto">
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            Cancel
          </button>
          <button
            disabled={deleteDisabled}
            onClick={() => onDelete(lineItem.id, updateFrozen)}
            className={`sw-btn btn-primary is-error${deleting ? ' sw-loading' : ''}`}
          >
            Delete
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={['fas', 'file-invoice-dollar']} />
              <FontAwesomeIcon icon={['fas', 'circle-xmark']} transform="shrink-2 right-9 down-6" />
            </span>
          </button>
        </>
      }
    />
  )
}

export default DeleteLineItemModal
