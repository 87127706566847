import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import SWLogoUrl from '../../images/sw_logo_colour.svg'

type CurriculumIconProps = {
  technologyKey: string
  /**
   * Those applied to the foreground icon.
   * Defaults to white.
   */
  iconClasses?: string
  /**
   * If an icon is composed from layers, the classes for the seemingly transparent sections of the image.
   * Default to oceanblue.
   */
  transparentClasses?: string
}

// TODO: In future this will be provided by the curriculum API, simplifying things.
const CurriculumIcon = ({
  technologyKey,
  iconClasses = 'text-white',
  transparentClasses = 'text-oceanblue'
}: CurriculumIconProps) => {
  switch (technologyKey) {
    case 'api_design':
      return <FontAwesomeIcon icon={['fas', 'diagram-project']} fixedWidth size="lg" className={iconClasses} />
    case 'aws':
      return <FontAwesomeIcon icon={['fab', 'aws']} fixedWidth size="lg" className={iconClasses} />
    case 'docker':
      return <FontAwesomeIcon icon={['fab', 'docker']} fixedWidth size="lg" className={iconClasses} />
    case 'go':
      return <FontAwesomeIcon icon={['fab', 'golang']} fixedWidth size="xl" className={iconClasses} />
    case 'java':
      return <FontAwesomeIcon icon={['fab', 'java']} fixedWidth size="xl" className={iconClasses} />
    case 'javascript':
      return <FontAwesomeIcon icon={['fab', 'square-js']} fixedWidth size="xl" className={iconClasses} />
    case 'kotlin':
      return (
        // Constituent shapes exceed the icon's width, so hide any overflow
        <span className="fa-layers fa-fw fa-xl overflow-hidden">
          <FontAwesomeIcon icon={['fas', 'square']} className={iconClasses} />
          <FontAwesomeIcon icon={['fas', 'square']} transform="rotate-45 right-10" className={transparentClasses} />
        </span>
      )
    case 'kubernetes':
      return (
        <span className="fa-layers fa-fw fa-xl">
          <FontAwesomeIcon icon={['fas', 'circle']} className={iconClasses} />
          <FontAwesomeIcon icon={['fas', 'dharmachakra']} transform="shrink-3" className={transparentClasses} />
        </span>
      )
    case 'python':
      return <FontAwesomeIcon icon={['fab', 'python']} fixedWidth size="xl" className={iconClasses} />
    case 'power_skills':
      return <FontAwesomeIcon icon={['fas', 'bolt']} fixedWidth size="xl" className={iconClasses} />
    case 'react':
      return <FontAwesomeIcon icon={['fab', 'react']} fixedWidth size="xl" className={iconClasses} />
    case 'react_native':
      return (
        <span className="fa-layers fa-fw fa-xl">
          <FontAwesomeIcon icon={['fas', 'circle']} className={iconClasses} />
          <FontAwesomeIcon icon={['fab', 'react']} className={transparentClasses} />
        </span>
      )
    case 'ruby':
      return (
        <FontAwesomeIcon
          icon={['fab', 'sketch']}
          fixedWidth
          size="xl"
          transform={{ rotate: 45 }}
          className={iconClasses}
        />
      )
    case 'sql':
    case 'database_engineering':
      return <FontAwesomeIcon icon={['fas', 'database']} fixedWidth size="xl" className={iconClasses} />
    case 'testing':
      return <FontAwesomeIcon icon={['fas', 'flask-vial']} fixedWidth size="lg" className={iconClasses} />
    case 'typescript':
      return (
        <span className="fa-layers fa-fw fa-xl">
          <FontAwesomeIcon icon={['fas', 'square']} className={iconClasses} />
          <span
            className={`fa-layers-text ${transparentClasses}`}
            data-fa-transform="shrink-8 right-0.75 down-2.5"
            style={{ fontWeight: 600 }}
          >
            TS
          </span>
        </span>
      )
    case 'internal':
      return <FontAwesomeIcon icon={['fas', 'person-shelter']} fixedWidth size="xl" className={iconClasses} />
    case 'skiller_whale':
      return <img src={SWLogoUrl} className={iconClasses} />
    default:
      return <FontAwesomeIcon icon={['fas', 'gears']} fixedWidth size="xl" className={iconClasses} />
  }
}

export default CurriculumIcon
