import {
  AttendanceRequirement,
  PlannedSession,
  PlannedSessionStatus,
  TrainingSessionState
} from '../generated_types/user_plan_viewer'
import { assertUnreachable } from '../utils/asserts'

export enum UserSessionStatus {
  NotInPlan = 'not-in-plan', // Explicitly Hidden
  NotNeeded = 'not-needed', // Implicitly Hidden or AttendanceRequirement = None
  Optional = 'optional',
  InPlan = 'in-plan',
  Scheduled = 'scheduled',
  Completed = 'completed',
  NotesShared = 'notes-shared',
  Skipped = 'skipped'
}

const ScheduledStates = [TrainingSessionState.Scheduled, TrainingSessionState.ScheduledAccepted]
/**
 * Given a PlannedSession, returns the appropriate UserSessionStatus
 */
export const getStatus = (plannedSession: PlannedSession) => {
  if (plannedSession.sessions.length) {
    const completedSession = plannedSession.sessions.find(session => session.status === TrainingSessionState.Complete)
    if (completedSession) return UserSessionStatus.Completed
    const scheduledSession = plannedSession.sessions.find(session => ScheduledStates.includes(session.status))
    if (scheduledSession) return UserSessionStatus.Scheduled
  }
  if (plannedSession.status === PlannedSessionStatus.Included) {
    if (plannedSession.shared_notes?.no_session_needed) {
      return UserSessionStatus.NotesShared
    }
    if (plannedSession.do_not_schedule) {
      return UserSessionStatus.Skipped
    }
    switch (plannedSession.attendance_requirement) {
      case AttendanceRequirement.None:
        return UserSessionStatus.NotNeeded
      case AttendanceRequirement.Optional:
        return UserSessionStatus.Optional
      case AttendanceRequirement.Mandatory:
        return UserSessionStatus.InPlan
      default:
        assertUnreachable(plannedSession.attendance_requirement)
    }
  } else if (
    plannedSession.status === PlannedSessionStatus.ImplicitlyHidden &&
    plannedSession.attendance_requirement === AttendanceRequirement.None
  ) {
    return UserSessionStatus.NotNeeded
  } else {
    return UserSessionStatus.NotInPlan
  }
}
