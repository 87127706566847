import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { ModelErrors } from '../library/errors'
import { textifyErrors } from '../utils/error_helpers'

type ErrorIconProps = {
  errors: ModelErrors
  message?: string
}

const ErrorIcon = ({ errors, message }: ErrorIconProps) => {
  if (Object.keys(errors).length === 0) return null

  const messageText = message ?? 'Please refresh the page and try again.'

  return (
    <div className="sw-tooltip" data-tooltip={`Something has gone wrong:\n${textifyErrors(errors)}\n\n${messageText}`}>
      <FontAwesomeIcon icon={['fas', 'circle-exclamation']} size="xl" className="text-snapperred" />
    </div>
  )
}

export default ErrorIcon
