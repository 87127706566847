import { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react'

import { ChangeRequestFilterParams, ChangeRequestReviewSection } from '../generated_types/change_requests'
import Pagination from '../library/pagination'
import { ModelErrors } from '../library/errors'
import { apiRequest } from '../utils/json_request'

import ChangeRequestsEmpty from './change_requests_empty'
import ChangeRequestsGrid from './change_requests_grid'
import { GridColumnConfig, UpdateChangeRequest } from './types'
import ModuleCellContent from './module_cell_content'
import ReviewButtonsCellContent from './review_buttons_cell_content'
import ErrorIcon from './error_icon'
import AnswerLinksCellContent from './answer_links_cell_content'

type ChangeRequestsLayoutProps = PropsWithChildren & {
  sectionData: ChangeRequestReviewSection
  updateSectionData: Dispatch<SetStateAction<ChangeRequestReviewSection>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  dataType: 'would_like' | 'dont_need'
  includeReviewed: boolean
  updateSingleRequest: UpdateChangeRequest
  filterParams?: ChangeRequestFilterParams
}

const ChangeRequestsLayout = ({
  sectionData,
  updateSectionData,
  loading,
  setLoading,
  dataType,
  includeReviewed,
  updateSingleRequest,
  filterParams,
  children
}: ChangeRequestsLayoutProps) => {
  const [errors, setErrors] = useState<ModelErrors | undefined>()

  if (sectionData.pagination.total_entries < 1) return <ChangeRequestsEmpty title="No Applicable Requests" />

  const onPaginationPageChange = async (page: number) => {
    setLoading(true)
    const requestKey = `${dataType}_page`
    const results = await apiRequest(`/change_requests/reviewable`, {
      query: {
        [requestKey]: page,
        include_reviewed: includeReviewed,
        filters: filterParams
      },
      payload: undefined,
      method: 'GET'
    })

    if (results.ok) {
      setErrors(undefined)
      const sectionData = results.data[dataType]

      if (sectionData) updateSectionData(sectionData)
      else setErrors({ base: ['Could not load requested page data'] })
    } else setErrors(results.errors)

    setLoading(false)
  }

  const loadingClasses = `absolute z-30 top-0 left-0 right-0 bottom-0 bg-white/80${
    loading ? ' animate-pulse' : ' hidden'
  }`

  const paginationContent = (
    <div className="flex items-center justify-center gap-10">
      <Pagination classNames="gap-6 my-2" onPageChange={onPaginationPageChange} {...sectionData.pagination} />
      {errors && <ErrorIcon errors={errors} />}
    </div>
  )

  return (
    <div className="flex flex-col mb-20">
      {children && <p className="text-lg mx-6 mb-8">{children}</p>}
      <div className="relative flex flex-col">
        <div className={loadingClasses} />
        {paginationContent}
        <ChangeRequestsGrid
          changeRequests={sectionData.change_requests}
          columnConfig={columnConfig}
          modules={sectionData.module_data}
          updateSingleRequest={updateSingleRequest}
        />
        {paginationContent}
      </div>
    </div>
  )
}

export default ChangeRequestsLayout

const columnConfig: GridColumnConfig = [
  {
    header: 'Learner',
    classNames: 'col-span-2 xl:col-span-1',
    CellContent: ({ changeRequest }) => <span className="learner-name">{changeRequest.user.name}</span>
  },
  {
    header: 'Module',
    classNames: 'col-span-4 xl:col-span-1',
    CellContent: ModuleCellContent
  },
  {
    header: 'Questionnaire',
    classNames: 'wrapping-cell col-span-4 xl:col-span-1',
    CellContent: AnswerLinksCellContent
  },
  {
    header: '',
    classNames: 'wrapping-cell col-span-2 xl:col-span-1',
    CellContent: ReviewButtonsCellContent
  }
]
