import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useCable } from '../../utils/cable'
import { Subscription } from '@rails/actioncable'
import { CoachingPlanChannelData } from '../../generated_types/channels'

type ChannelProviderProps = PropsWithChildren<{
  planIds: number[] | null
}>

type ChannelContextData = PropsWithChildren<{
  addListener: (handler: ChannelEventHandler) => void
  removeListener: (handler: ChannelEventHandler) => void
  channel: Subscription | undefined
}>

type ChannelEventHandler = (event: CoachingPlanChannelData) => void

const CoachingPlanChannelContext = createContext<ChannelContextData | null>(null)

const CoachingPlanChannelProvider = ({ planIds, children }: ChannelProviderProps) => {
  const { cable } = useCable()
  const [channel, setChannel] = useState<Subscription | undefined>()
  const handlersRef = useRef<ChannelEventHandler[]>([])

  const addListener = useCallback((handler: ChannelEventHandler) => {
    handlersRef.current.push(handler)
  }, [])

  const removeListener = useCallback((handler: ChannelEventHandler) => {
    let index: number

    while ((index = handlersRef.current.indexOf(handler)) >= 0) {
      handlersRef.current.splice(index, 1)
    }
  }, [])

  useEffect(() => {
    if (!planIds) {
      return
    }
    if (!cable) {
      return
    }

    const channel = cable.subscriptions.create(
      { channel: 'CoachingPlanChannel', plan_ids: planIds },
      {
        received: (event: CoachingPlanChannelData) => {
          handlersRef.current.forEach(handler => handler(event))
        }
      }
    )
    setChannel(channel)

    return () => {
      channel.unsubscribe()
      setChannel(undefined)
    }
  }, [cable, planIds])

  return (
    <CoachingPlanChannelContext.Provider value={{ addListener, removeListener, channel }}>
      {children}
    </CoachingPlanChannelContext.Provider>
  )
}

export default CoachingPlanChannelProvider

const useCoachingPlanChannel = () => {
  const data = useContext(CoachingPlanChannelContext)
  if (!data) {
    throw new Error('useCoachingPlanChannel must be called with a CoachingPlanChannelContext.Provider')
  }
  return data
}
export { useCoachingPlanChannel }
