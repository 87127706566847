import React from 'react'

function CsrfInput() {
  const field_name = document.querySelector('meta[name="csrf-param"]')?.getAttribute('content')
  const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
  if (field_name) {
    return <input type="hidden" name={field_name} value={token} readOnly />
  } else {
    return null
  }
}

export default CsrfInput
