import Comments from '../../comments/comments'
import TrainingPlanDescription from '../../training_plan_description'
import { TrainingPlanPageProps } from '../../generated_types/training_plan'
import { TrainingPlan } from '../types'

type PlanContextProps = {
  trainingPlanId: number
  trainingPlan: TrainingPlan
  comments: TrainingPlanPageProps['comments']
}

/**
 * Encompassing Plan Description and Comments
 */
const PlanContext = ({ trainingPlanId, trainingPlan, comments }: PlanContextProps) => {
  return (
    <div className="sw-card mt-4 mb-10">
      <h3 className="sw-header header-wavylines h-16 mt-0 text-2xl">Context</h3>
      <div className="flex flex-wrap gap-y-4">
        <div className="training-plan-description">
          {'description' in trainingPlan && <TrainingPlanDescription trainingPlan={trainingPlan} />}
        </div>
        <div className="sw-divider-vert hidden xl:block" />
        <div className="training-plan-comments">
          <div className="sw-divider xl:hidden" />
          {comments && (
            <Comments
              comments={comments.comments}
              editable={comments.editable}
              path={`/plans/${trainingPlanId}/comments`}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PlanContext
