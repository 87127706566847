import { MouseEventHandler } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import { ModelErrors } from '../library/errors'
import SWFieldWithLabel from '../library/sw/sw_field_with_label'
import SWFormGroup from '../library/sw/sw_form_group'
import Modal from '../library/modal'

import { EditableCompanyPause, PauseFormData } from './types'

const formatPauseForFormik = ({ end_date, ...pause }: EditableCompanyPause): PauseFormData => {
  return {
    ...pause,
    end_date: end_date || ''
    //field input values are strings - convert nulls to strings
  }
}

type Props = {
  onCancel: () => void
  errors: ModelErrors
  onSubmit: (f: PauseFormData) => void
  pause: EditableCompanyPause
}

const PauseFormModal = (props: Props) => {
  const { onSubmit, pause, ...pauseFormProps } = props

  return (
    <Formik<PauseFormData> onSubmit={onSubmit} initialValues={formatPauseForFormik(pause)}>
      {() => <PauseForm {...pauseFormProps} />}
    </Formik>
  )
}

type FormProps = Pick<Props, 'onCancel' | 'errors'>

const PauseForm = ({ onCancel, errors }: FormProps) => {
  const { values, isSubmitting } = useFormikContext<PauseFormData>()
  const newPause = !values.id

  const action = newPause ? (
    <>
      Create
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={['far', 'circle-pause']} />
        <FontAwesomeIcon icon={['fas', 'circle-plus']} transform="shrink-3 right-9 down-6" />
      </span>
    </>
  ) : (
    <>
      Save
      <FontAwesomeIcon icon={['far', 'floppy-disk']} />
    </>
  )
  const title = newPause ? 'Create Pause' : 'Update Pause'

  const closeModal: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    onCancel()
  }

  const disableSubmission = isSubmitting || !values.start_date

  return (
    <Form>
      <Modal
        options={{ active: true }}
        title={title}
        onClose={closeModal}
        footer={
          <>
            <a onClick={closeModal} className="sw-btn mr-auto">
              <FontAwesomeIcon icon={['fas', 'chevron-left']} />
              Cancel
            </a>
            <button
              type="submit"
              disabled={disableSubmission}
              className={`sw-btn btn-primary${isSubmitting ? ' sw-loading' : ''}`}
            >
              {action}
            </button>
          </>
        }
      >
        {errors.base ? <div className="sw-toast error">{errors.base.join(', ')}</div> : null}

        <div className="flex flex-col gap-4">
          <SWFormGroup errors={errors['start_date']}>
            <SWFieldWithLabel
              label="Start Date"
              type="date"
              id="pause_start_date"
              name="start_date"
              className="sw-input"
            />
          </SWFormGroup>

          <SWFormGroup errors={errors['end_date']}>
            <SWFieldWithLabel label="End Date" type="date" id="pause_end_date" name="end_date" className="sw-input" />
            <p className="form-input-hint">Leave this as &apos;dd/mm/yyyy&apos; for indefinite pauses</p>
          </SWFormGroup>

          <SWFormGroup errors={errors['description']}>
            <SWFieldWithLabel
              label="Description"
              as="textarea"
              id="pause_description"
              name="description"
              className="sw-input"
            />
            <p className="form-input-hint">Any relevant information that explains why this pause exists</p>
          </SWFormGroup>
        </div>
      </Modal>
    </Form>
  )
}
export default PauseFormModal
