import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import Form from '../library/form'
import Modal from '../library/modal'
import { LineItem } from './types'
import InternalCreditValueWarning from '../library/internal_credit_value_warning'

type Props = {
  lineItem: LineItem
  closeModal: () => void
}

const EditCreditsForm = ({ lineItem, closeModal }: Props) => (
  <Form action={lineItem.url} method="PATCH">
    <Modal
      title={
        <>
          Edit Credits for <span className="text-primary">{lineItem.company.name}</span>
        </>
      }
      onClose={closeModal}
      options={{ active: true }}
      body={
        <>
          <div className="sw-group mb-4">
            <label htmlFor="credits_used" className="sw-label">
              Credits
            </label>
            <input
              type="number"
              min="0"
              step="0.01"
              id="credits_used"
              className="sw-input"
              name="line_item[credits_used]"
              defaultValue={lineItem.credits_used}
            />
            <InternalCreditValueWarning />
          </div>
          <div className="sw-group">
            <label htmlFor="manual_credits_reason" className="sw-label">
              Reason
            </label>
            <input
              id="manual_credits_reason"
              className="sw-input"
              name="line_item[content_attributes][manual_credits_reason]"
              defaultValue={lineItem.manual_credits_reason || ''}
            />
            <p className="form-input-hint">Explain why credits are being calculated manually</p>

            <input type="hidden" name="line_item[content_attributes][manually_edited_credits]" value="1" />
          </div>
        </>
      }
      footer={
        <button type="submit" className="sw-btn btn-primary">
          <FontAwesomeIcon icon={['far', 'floppy-disk']} />
          Update
        </button>
      }
    />
  </Form>
)

export default EditCreditsForm
