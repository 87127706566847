import { PropsWithChildren } from 'react'

type Props = PropsWithChildren<{ loading: boolean }> & React.ButtonHTMLAttributes<HTMLButtonElement>

const LoadingButton = ({ children, loading, disabled, className: initialClassName, ...props }: Props) => {
  const loadingClass = loading ? 'sw-loading' : ''
  const className = [initialClassName ?? '', loadingClass].join(' ')

  return (
    <button className={className} disabled={disabled || loading} {...props}>
      {children}
    </button>
  )
}

export default LoadingButton
