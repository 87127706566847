import { Field } from 'formik'

import SWCheckbox from '../library/sw/sw_checkbox'
import SWFormGroup from '../library/sw/sw_form_group'

const TrainerInvoiceWarning = () => {
  return (
    <SWFormGroup classNames="sw-toast mt-1">
      <Field
        as={SWCheckbox}
        label="Update the coach invoice item with the new duration."
        name="adjust_trainer_invoice"
        type="checkbox"
      />
    </SWFormGroup>
  )
}
export default TrainerInvoiceWarning
