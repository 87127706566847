import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { PlannedSession } from '../generated_types/user_plan_viewer'
import { ModuleTitles } from './types'

type SessionDetailsProps = {
  session: PlannedSession
  moduleTitles: ModuleTitles
  open: boolean
}

const SessionDetails = ({ session, moduleTitles, open }: SessionDetailsProps) => {
  const overview = session.overview_html && (
    <>
      <h5 className="details-title">OVERVIEW</h5>
      <p className="mb-10" dangerouslySetInnerHTML={{ __html: session?.overview_html }} />
    </>
  )

  const learnings = session.learnings_html.length > 0 && (
    <>
      <h5 className="details-title">WHAT&apos;S COVERED?</h5>
      <ul className="ml-8">
        {session.learnings_html.map((learningHTML, index) => (
          <li key={index} className="mb-2.5" dangerouslySetInnerHTML={{ __html: learningHTML }} />
        ))}
      </ul>
    </>
  )

  const dependencies = session.module_dependencies.length > 0 && (
    <div className="basis-1/3">
      <h5 className="details-title">DEPENDS ON</h5>
      {session.module_dependencies.map((dependency, index) => {
        const moduleTitle = moduleTitles[dependency]
        if (moduleTitle)
          return <p key={index} className="mb-1.5 font-semibold" dangerouslySetInnerHTML={{ __html: moduleTitle }} />
      })}
    </div>
  )

  // module_summary_url will be blank if there is no summary or if
  // the user does not have permission to access the module summary.
  const summaryLink = session.module_summary_url ? (
    <a href={session.module_summary_url} className="sw-btn btn-primary ml-auto" target="blank">
      View Summary
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </a>
  ) : undefined

  return (
    <div className={`details-content${open ? ' open' : ''}`}>
      <div className="session-line-cell" />
      <div className="session-details-cell col-span-11">
        <div className="session-details-inner">
          <div className="basis-2/3 grow">
            {overview}
            {learnings}
          </div>
          {dependencies}
        </div>
        {summaryLink}
      </div>
    </div>
  )
}

export default SessionDetails
