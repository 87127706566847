import { LineItem, LineItemChange, LineItemChangeType } from './types'
import { longDateFormat } from '../utils/date_helpers'
type Props = {
  lineItems: LineItem[]
}

const LineItemAuditTrail = ({ lineItems }: Props) => {
  const auditData = lineItems.flatMap(item => item.change_batches)

  const cancellationRelated = auditData.filter(change_batch =>
    change_batch.changes.find(change => change.type === 'cancelled' || change.type === 'uncancelled')
  )

  if (cancellationRelated.length == 0) {
    return null
  }

  return (
    <>
      <h3 className="text-primary mt-12">Change History</h3>

      <table className="sw-table striped w-full">
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Type</th>
            <th>Notes</th>
          </tr>
        </thead>

        <tbody>
          {cancellationRelated.map(batch =>
            batch.changes.map(change => (
              <tr key={change.id}>
                <td>{longDateFormat.format(new Date(batch.created_at))}</td>
                <td>{batch.change_made_by?.name ?? 'System'}</td>
                <td>{formatType(change.type)}</td>
                <td>{formatNotes(change)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  )
}

const formatType = (type: LineItemChangeType) => {
  switch (type) {
    case 'cancelled':
      return 'Session cancelled'
    case 'uncancelled':
      return 'Session uncancelled'
    default:
      return type
  }
}

const formatNotes = (change: LineItemChange) => {
  switch (change.type) {
    case 'cancelled':
    case 'uncancelled':
      return change.metadata.reason
    default:
      return ''
  }
}

export default LineItemAuditTrail
