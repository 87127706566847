import { MouseEventHandler, useCallback } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

type Props = {
  current_page: number
  per_page: number
  total_entries: number
  onPageChange: (page: number) => void
  classNames?: string
}

const Pagination = ({
  current_page: currentPage,
  per_page: perPage,
  total_entries: totalEntries,
  onPageChange,
  classNames = ''
}: Props) => {
  const totalPages = Math.ceil(totalEntries / perPage)
  const onFirstPage = currentPage === 1
  const onLastPage = currentPage === totalPages

  const pageClicked: MouseEventHandler<HTMLAnchorElement> = useCallback(
    e => {
      const page = parseInt(e.currentTarget.dataset.page || '')
      e.preventDefault()
      e.stopPropagation()
      onPageChange(page)
    },
    [onPageChange]
  )

  if (totalEntries <= perPage) {
    return null
  }

  const previousElement = (
    <>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} /> Previous
    </>
  )
  const nextElement = (
    <>
      Next <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </>
  )

  return (
    <ol role="navigation" aria-label="Pagination" className={`sw-pagination ${classNames}`}>
      <li className={`page ${onFirstPage ? 'disabled' : ''}`} aria-disabled={onFirstPage ? true : undefined}>
        <a href="#" rel="prev" data-page={currentPage - 1} aria-label="Previous Page" onClick={pageClicked}>
          {previousElement}
        </a>
      </li>

      {pageNumbersToLink({ currentPage, totalPages }).map((pageNumber, index) =>
        pageNumber === 'gap' ? (
          <li key={`gap-${index}`}>&hellip;</li>
        ) : (
          <Page key={pageNumber} page={pageNumber} currentPage={currentPage} pageClicked={pageClicked} />
        )
      )}

      <li className={`page ${onLastPage ? 'disabled' : ''}`} aria-disabled={onLastPage ? true : undefined}>
        <a href="#" rel="next" data-page={currentPage + 1} aria-label="Next Page" onClick={pageClicked}>
          {nextElement}
        </a>
      </li>
    </ol>
  )
}

type PageProps = {
  page: number
  currentPage: number
  pageClicked: React.MouseEventHandler
}
const Page = ({ page, currentPage, pageClicked }: PageProps) => {
  const onCurrentPage = page === currentPage

  let rel: string | undefined = undefined
  if (page === currentPage - 1) rel = 'prev'
  else if (page === currentPage + 1) rel = 'next'

  return (
    <li className={`page ${onCurrentPage ? 'active' : ''}`} aria-current={onCurrentPage ? 'page' : undefined}>
      <a href="#" rel={rel} aria-label={`Page ${page}`} data-page={page} onClick={pageClicked}>
        {page}
      </a>
    </li>
  )
}

type PageNumber = number | 'gap'
export const pageNumbersToLink = ({
  currentPage,
  totalPages,
  windowSize = 4, //pages to show before/after the current page
  anchorSize = 1 //pages to show from the to/start
}: {
  currentPage: number
  totalPages: number
  windowSize?: number
  anchorSize?: number
}): PageNumber[] => {
  // the max we display for a very large array is 2 * anchorsize (start and end), the current page, and 2 * window size either side
  // so if the total pages is less than this then we might as well display everything.
  // for example if we have 7 items in total and you are on page 1 we might
  // as well display all of them rather than 1,2,3,4,5,...7
  if (totalPages <= anchorSize * 2 + windowSize * 2 + 1) {
    return Array.from({ length: totalPages }, (_, index) => index + 1)
  }

  const fromStart = Array.from({ length: anchorSize }, (_, index) => index + 1) //produces 1,2,...anchorSize
  const toEnd = Array.from({ length: anchorSize }, (_, index) => index + 1 + totalPages - anchorSize) //procuces totalPages-anchorsize+1, ..., totalPages - 1, totalPages
  const before = Array.from({ length: windowSize }, (_, index) => currentPage - windowSize + index) // produces currentPage - windowsize, ..., currentPage - 1, currentPage, currentPage + 1, ... currentPage +windowSize
  const after = Array.from({ length: windowSize }, (_, index) => currentPage + index + 1)

  // put together all the parts and remove out of bounds
  const pagesWithDuplicates = [...fromStart, ...before, currentPage, ...after, ...toEnd].filter(
    n => n >= 1 && n <= totalPages
  )

  // remove duplicates
  const pagesWithoutDuplicates = [...new Set(pagesWithDuplicates)] //sets maintain insertion order

  // insert markers for any discontinuities
  return pagesWithoutDuplicates.flatMap((page, index) => {
    if (index > 0 && pagesWithoutDuplicates[index - 1] !== page - 1) {
      // if we've jumped forward
      return ['gap' as const, page]
    } else {
      return page
    }
  })
}

export default Pagination
