import {
  AVAILABILITY_ERRORS,
  CHANGE_REQUEST_ERRORS,
  ModelErrors,
  ObjectError,
  partitionErrors
} from '../library/errors'
import {
  SessionDetailsFormikValues,
  ModuleDurationOverrides,
  SessionDetailsErrors,
  TrainingModules,
  TrainingSession
} from './types'

export const extractSessionConfirmationErrors = (errors: ModelErrors): SessionDetailsErrors => {
  let allAvailabilityErrors: ObjectError[] = []
  let allChangeRequestErrors: ObjectError[] = []

  const nonConfirmationErrors = Object.fromEntries(
    Object.entries(errors)
      .map(([attribute, errors]) => {
        const [availabilityErrors, notAvailabilityErrors] = partitionErrors(errors, AVAILABILITY_ERRORS)
        const [changeRequestErrors, notChangeRequestErrors] = partitionErrors(
          notAvailabilityErrors,
          CHANGE_REQUEST_ERRORS
        )

        allAvailabilityErrors = allAvailabilityErrors.concat(availabilityErrors)
        allChangeRequestErrors = allChangeRequestErrors.concat(changeRequestErrors)

        return [attribute, notChangeRequestErrors]
      })
      .filter(([, errors]) => errors.length > 0)
  )

  return {
    confirmationErrors: {
      availabilityErrors: allAvailabilityErrors,
      changeRequestErrors: allChangeRequestErrors,
      all: allAvailabilityErrors.concat(allChangeRequestErrors)
    },
    nonConfirmationErrors
  }
}

export const formikStateFromTrainingSession = (trainingSession: TrainingSession): SessionDetailsFormikValues => {
  const result: SessionDetailsFormikValues = {
    start_date: trainingSession.start_date || '',
    start_time: trainingSession.start_time || '',
    duration_in_minutes: trainingSession.duration_in_minutes || '',
    permit_unpublished: trainingSession.permit_unpublished,
    trainer_id: trainingSession.trainer_id || '',
    session_type: trainingSession.session_type,
    modules: trainingSession.modules,
    permit_availability_clash: false,
    permit_change_request_clash: trainingSession.permit_change_request_clash,
    video_provider: trainingSession.video_provider,
    auto_start_hosted_environments: trainingSession.auto_start_hosted_environments,
    catchup: trainingSession.catchup,
    adjust_trainer_invoice: true
  }
  if (trainingSession.persisted) {
    result.permit_modules_change_after_setup_sent = trainingSession.permit_modules_change_after_setup_sent
  } else {
    result.user_ids = trainingSession.user_ids
  }
  return result
}

type SumModuleDurationArgs = { selected: string[]; modules: TrainingModules; overrides: ModuleDurationOverrides }
export const sumModuleDurations = ({ selected, modules, overrides }: SumModuleDurationArgs): number => {
  return selected.reduce((total, key) => {
    const duration = overrides[key]?.estimated_minutes ?? modules[key]?.estimated_minutes ?? 0
    return duration + total
  }, 0)
}
