import { ReactNode, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import Composer from './composer'
import { FormattedComment, PersistedComment } from './types'
import useComment from './use_comment'
import UpdatedAt from '../library/updated_at'

type Props = {
  comment: FormattedComment
  editable: boolean
  commentUpdated: (c: PersistedComment) => void
}

const Comment = ({ comment, editable, commentUpdated }: Props) => {
  const [editing, setEditing] = useState(false)

  let editControls: ReactNode | null = null

  const { submitting, updateComment } = useComment({
    comment,
    onUpdate: updatedComment => commentUpdated(updatedComment)
  })

  if (editable && !editing) {
    const hideTooltip = comment.hidden ? 'Unhide Comment' : 'Hide Comment'
    editControls = (
      <div className="flex gap-1 ml-auto">
        <button className="sw-btn btn-icon h-8 w-8" onClick={() => setEditing(true)} aria-label="Edit Comment">
          <FontAwesomeIcon icon={['fas', 'pencil']} />
        </button>
        <button
          disabled={submitting}
          className={`sw-btn btn-icon h-8 w-8 sw-tooltip tooltip-top${comment.hidden ? ' btn-secondary' : ''}`}
          onClick={() => updateComment({ hidden: !comment.hidden })}
          data-tooltip={hideTooltip}
          aria-label={hideTooltip}
        >
          <FontAwesomeIcon icon={['far', comment.hidden ? 'eye' : 'eye-slash']} />
        </button>
      </div>
    )
  }

  let contentOrEditArea: ReactNode

  if (editing) {
    const commentPersisted = (updatedComment: PersistedComment) => {
      commentUpdated(updatedComment)
      setEditing(false)
    }
    contentOrEditArea = (
      <Composer
        comment={comment}
        onCommentPersisted={c => commentPersisted(c)}
        onCancelEdit={() => setEditing(false)}
      />
    )
  } else {
    contentOrEditArea = (
      <div className="pl-2 border-l border-lightergrey" dangerouslySetInnerHTML={{ __html: comment.formattedBody }} />
    )
  }

  const editInfo =
    comment.updated_at != comment.created_at ? (
      <UpdatedAt name={comment.updated_by.name} updated_at={comment.updated_at} custom_prefix="Edited at" />
    ) : null

  return (
    <div className={`mt-6${comment.hidden ? ' text-midgrey' : ''}`} id={`comment-${comment.id}`}>
      <div className="comment flex flex-col gap-2 mx-1">
        <div className="flex flex-wrap items-center">
          <span className="font-semibold">{comment.created_by.name}</span>
          <UpdatedAt updated_at={comment.created_at} custom_prefix="" classNames="mx-2" />
          {editControls}
        </div>
        {contentOrEditArea}
        {editInfo}
      </div>
    </div>
  )
}

export default Comment
