import SWHorizontalFieldWithLabel from './library/sw/sw_horizontal_field_with_label'
import { TrainerInvoiceStub } from './trainer_invoice/constants'
import { ModuleDurationOverrides } from './generated_types/training_session'

type TrainingSessionDurationProps = {
  value: string
  trainerInvoice: TrainerInvoiceStub | null
  durationChanged: boolean
  estimatedMinutes: number
  overrides: ModuleDurationOverrides
  disabled?: boolean
}

const TrainingSessionDuration = ({
  value,
  trainerInvoice = null,
  durationChanged,
  estimatedMinutes,
  overrides,
  disabled
}: TrainingSessionDurationProps) => {
  const durationMismatch = estimatedMinutes && estimatedMinutes != parseInt(value)

  const warning = durationMismatch ? (
    <span className="form-input-hint">
      ⚠️ The estimated duration for this module selection is {estimatedMinutes} minutes
    </span>
  ) : null

  const overridesEntries = Object.entries(overrides)

  const overrideWarning =
    overridesEntries.length > 0
      ? overridesEntries.map(([key, override]) => (
          <span className="form-input-hint" key={key}>
            ⚠️ The coach estimated that {override.estimated_minutes} minutes were needed to complete {key}
          </span>
        ))
      : null

  const invoiceWarning =
    trainerInvoice && durationChanged ? (
      <span className="form-input-hint">
        ⚠️ There is an existing {trainerInvoice.status}{' '}
        <a href={trainerInvoice.url} target="blank">
          coach invoice
        </a>{' '}
        for this session
      </span>
    ) : null

  return (
    <>
      <SWHorizontalFieldWithLabel
        name="duration_in_minutes"
        label="for"
        type="number"
        trailingElement="minutes"
        className="sw-input max-w-[6rem]"
        disabled={disabled}
      />
      <div className="flex flex-col gap-2">
        {overrideWarning}
        {warning}
        {invoiceWarning}
      </div>
    </>
  )
}

export default TrainingSessionDuration
