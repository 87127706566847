import { Field, FieldAttributes } from 'formik'

export type SWFieldWithLabelProps<T> = FieldAttributes<T> & {
  id?: string
  label: string
}

/**
 * Version of library/field_with_label using sw-* classes
 */
const SWFieldWithLabel = <T,>({ id, name, label, ...fieldProps }: SWFieldWithLabelProps<T>) => (
  <>
    <label htmlFor={id || name} className="sw-label">
      {label}
    </label>
    <Field id={id || name} name={name} {...fieldProps} />
  </>
)

export default SWFieldWithLabel
