type Props = {
  modules: Record<string, string>
}

const ModulesDatalist = ({ modules }: Props) => (
  <datalist id="available-modules">
    {Object.entries(modules).map(([key, title], index) => (
      <option key={index} value={key}>
        {title}
      </option>
    ))}
  </datalist>
)

export default ModulesDatalist
