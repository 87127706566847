import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static targets = ['permission']
  declare readonly permissionTargets: HTMLElement[] // The elements to show/hide

  filter(event: InputEvent) {
    const searchString = (event.currentTarget as HTMLInputElement).value.toLowerCase()

    this.permissionTargets.forEach(t => {
      const label = t.dataset.value
      if (label) {
        if (label.includes(searchString)) {
          t.classList.remove('hidden')
        } else {
          t.classList.add('hidden')
        }
      }
    })
  }
}
